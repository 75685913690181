import { AppStorage } from "@/services/app-storage";
import { RdModule, RdModulesManager } from "@radts/reactjs";
import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Loader from "../loader/Loader";
import { AppSession } from "@/services/app-session";
import { LoginStatus } from "@/models/LoginStatus";

const SocialAuth: FC = () => {
  const [searchParam] = useSearchParams();
  useEffect(() => {
    const rdMo = new RdModulesManager<RdModule>();
    const token = searchParam.get("token")?.trim();
    const refreshToken = searchParam.get("refreshToken")?.trim();
    if (token && refreshToken) {
      rdMo.get<AppStorage>("AppStorage").accessToken = token;
      rdMo.get<AppStorage>("AppStorage").refreshToken = refreshToken;
      rdMo.get<AppSession>("AppSession").loginStatus.next(LoginStatus.Success);
    }
  }, []);
  return <Loader />;
};

export default SocialAuth;
