import { SuccessCodeApi } from "@/constants";
import { NotiboxHttpClient } from "..";
import { mapUserInfoModel, mapUserModel } from "../adapters/user-adapter";
import { ErrorApi } from "../models/ErrorModel";
import { UserInfoModel, UserModel } from "../models/UserModel";
import { UserRepository } from "../repositories/UserRepository";

export class UserRepositoryImp implements UserRepository {
  private client: NotiboxHttpClient;
  constructor(c: NotiboxHttpClient) {
    this.client = c;
  }

  public async userInfo(): Promise<UserInfoModel> {
    const resp = await this.client.user.getInfo();
    if (resp?.data.code === SuccessCodeApi) {
      return mapUserInfoModel(resp.data.data);
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async checkPhoneExisted({
    phone,
  }: {
    phone: string;
  }): Promise<boolean> {
    const resp = await this.client.user.checkPhoneIfExisted({ phone: phone });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.existed ?? false;
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async verifyPhone({ phone }: { phone: string }): Promise<{
    sessionId: string;
    countdownTime: number;
  }> {
    const resp = await this.client.user.verifyPhone({ phone: phone });
    if (resp?.data.code === SuccessCodeApi) {
      return {
        sessionId: resp.data.data?.sessionId ?? "",
        countdownTime: resp.data.data?.countdownTime ?? 30,
      };
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async verifyOTP({
    otp,
    phone,
    sessionId,
    email,
  }: {
    otp: string;
    phone: string;
    sessionId: string;
    email?: string;
  }): Promise<boolean> {
    const resp = await this.client.user.verifyOTP({
      verifyOTPRequest: {
        otp: otp,
        phone: phone,
        sessionId: sessionId,
        email: email,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.success ?? false;
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async verifyEmailRegistry({
    email,
    session,
  }: {
    email: string;
    session: string;
  }): Promise<boolean> {
    const resp = await this.client.user.verifyEmailRegistry({
      verifyEmailRegistryRequest: {
        email: email,
        session: session,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.success ?? false;
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async generateUserKey(): Promise<UserModel> {
    const resp = await this.client.user.generateUserKey();
    if (resp?.data.code === SuccessCodeApi) {
      return mapUserModel(resp.data.data);
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async getUserKey(): Promise<string> {
    const resp = await this.client.user.getUserKey();
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.apiKey ?? "";
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async register({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<boolean> {
    const resp = await this.client.user.register({
      v2UserRegisterRequest: {
        email: email,
        password: password,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.session ? true : false;
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }
}
