/* tslint:disable */
/* eslint-disable */
/**
 * Notibox Service
 * Notibox Service
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from "./base";

/**
 *
 * @export
 * @interface AccessTokenResponse
 */
export interface AccessTokenResponse {
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof AccessTokenResponse
   */
  otherClaims?: { [key: string]: object };
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponse
   */
  token?: string;
  /**
   *
   * @type {number}
   * @memberof AccessTokenResponse
   */
  expiresIn?: number;
  /**
   *
   * @type {number}
   * @memberof AccessTokenResponse
   */
  refreshExpiresIn?: number;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponse
   */
  refreshToken?: string;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponse
   */
  tokenType?: string;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponse
   */
  idToken?: string;
  /**
   *
   * @type {number}
   * @memberof AccessTokenResponse
   */
  notBeforePolicy?: number;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponse
   */
  sessionState?: string;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponse
   */
  scope?: string;
}
/**
 *
 * @export
 * @interface AddAdvertisementRequest
 */
export interface AddAdvertisementRequest {
  /**
   *
   * @type {string}
   * @memberof AddAdvertisementRequest
   */
  image?: string;
  /**
   *
   * @type {number}
   * @memberof AddAdvertisementRequest
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof AddAdvertisementRequest
   */
  type: string;
}
/**
 *
 * @export
 * @interface AddEWalletRequest
 */
export interface AddEWalletRequest {
  /**
   *
   * @type {string}
   * @memberof AddEWalletRequest
   */
  shortName: string;
  /**
   *
   * @type {string}
   * @memberof AddEWalletRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AddEWalletRequest
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof AddEWalletRequest
   */
  smallLogo?: string;
  /**
   *
   * @type {string}
   * @memberof AddEWalletRequest
   */
  type?: AddEWalletRequestTypeEnum;
}

export const AddEWalletRequestTypeEnum = {
  Bank: "BANK",
  EWallet: "E_WALLET",
  Umee: "UMEE",
  Klb: "KLB",
} as const;

export type AddEWalletRequestTypeEnum =
  (typeof AddEWalletRequestTypeEnum)[keyof typeof AddEWalletRequestTypeEnum];

/**
 *
 * @export
 * @interface AddEWalletResponse
 */
export interface AddEWalletResponse {
  /**
   *
   * @type {string}
   * @memberof AddEWalletResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AddEWalletResponse
   */
  shortName?: string;
  /**
   *
   * @type {string}
   * @memberof AddEWalletResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AddEWalletResponse
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof AddEWalletResponse
   */
  smallLogo?: string;
}
/**
 *
 * @export
 * @interface AddNewPathRequest
 */
export interface AddNewPathRequest {
  /**
   *
   * @type {string}
   * @memberof AddNewPathRequest
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof AddNewPathRequest
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof AddNewPathRequest
   */
  type: AddNewPathRequestTypeEnum;
}

export const AddNewPathRequestTypeEnum = {
  Xpath: "XPATH",
  Id: "ID",
} as const;

export type AddNewPathRequestTypeEnum =
  (typeof AddNewPathRequestTypeEnum)[keyof typeof AddNewPathRequestTypeEnum];

/**
 *
 * @export
 * @interface AddStaticQrRequest
 */
export interface AddStaticQrRequest {
  /**
   *
   * @type {string}
   * @memberof AddStaticQrRequest
   */
  bankId: string;
  /**
   *
   * @type {string}
   * @memberof AddStaticQrRequest
   */
  qrCode?: string;
  /**
   *
   * @type {string}
   * @memberof AddStaticQrRequest
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof AddStaticQrRequest
   */
  accountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof AddStaticQrRequest
   */
  accountOwner?: string;
  /**
   *
   * @type {string}
   * @memberof AddStaticQrRequest
   */
  shopName?: string;
  /**
   *
   * @type {string}
   * @memberof AddStaticQrRequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof AddStaticQrRequest
   */
  qrContent?: string;
  /**
   *
   * @type {boolean}
   * @memberof AddStaticQrRequest
   */
  isDefault?: boolean;
  /**
   *
   * @type {string}
   * @memberof AddStaticQrRequest
   */
  phone?: string;
}
/**
 *
 * @export
 * @interface Advertisement
 */
export interface Advertisement {
  /**
   *
   * @type {string}
   * @memberof Advertisement
   */
  spaceId?: string;
  /**
   *
   * @type {string}
   * @memberof Advertisement
   */
  tenantId?: string;
  /**
   *
   * @type {string}
   * @memberof Advertisement
   */
  createBy?: string;
  /**
   *
   * @type {string}
   * @memberof Advertisement
   */
  createDate?: string;
  /**
   *
   * @type {string}
   * @memberof Advertisement
   */
  updateBy?: string;
  /**
   *
   * @type {string}
   * @memberof Advertisement
   */
  updateDate?: string;
  /**
   *
   * @type {string}
   * @memberof Advertisement
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Advertisement
   */
  image?: string;
  /**
   *
   * @type {number}
   * @memberof Advertisement
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof Advertisement
   */
  type?: string;
}
/**
 *
 * @export
 * @interface AdvertisementResponse
 */
export interface AdvertisementResponse {
  /**
   *
   * @type {Advertisement}
   * @memberof AdvertisementResponse
   */
  advertisement?: Advertisement;
}
/**
 *
 * @export
 * @interface AuditVersionResponse
 */
export interface AuditVersionResponse {
  /**
   *
   * @type {number}
   * @memberof AuditVersionResponse
   */
  version?: number;
}
/**
 *
 * @export
 * @interface BankHubNotifyRequest
 */
export interface BankHubNotifyRequest {
  /**
   *
   * @type {string}
   * @memberof BankHubNotifyRequest
   */
  environment?: string;
  /**
   *
   * @type {string}
   * @memberof BankHubNotifyRequest
   */
  webhookType?: string;
  /**
   *
   * @type {string}
   * @memberof BankHubNotifyRequest
   */
  webhookCode?: string;
  /**
   *
   * @type {string}
   * @memberof BankHubNotifyRequest
   */
  error?: string;
  /**
   *
   * @type {string}
   * @memberof BankHubNotifyRequest
   */
  grantId?: string;
  /**
   *
   * @type {Transaction}
   * @memberof BankHubNotifyRequest
   */
  transaction?: Transaction;
}
/**
 *
 * @export
 * @interface BankHubNotifyResponse
 */
export interface BankHubNotifyResponse {
  /**
   *
   * @type {boolean}
   * @memberof BankHubNotifyResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface CancelQRCodeOARequest
 */
export interface CancelQRCodeOARequest {
  /**
   *
   * @type {string}
   * @memberof CancelQRCodeOARequest
   */
  hardwareId: string;
}
/**
 *
 * @export
 * @interface CancelTransactionResponse
 */
export interface CancelTransactionResponse {
  /**
   *
   * @type {boolean}
   * @memberof CancelTransactionResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface CheckBankAccountRequest
 */
export interface CheckBankAccountRequest {
  /**
   *
   * @type {string}
   * @memberof CheckBankAccountRequest
   */
  bin: string;
  /**
   *
   * @type {string}
   * @memberof CheckBankAccountRequest
   */
  accountNo: string;
}
/**
 *
 * @export
 * @interface CheckBankAccountResponse
 */
export interface CheckBankAccountResponse {
  /**
   *
   * @type {string}
   * @memberof CheckBankAccountResponse
   */
  accountName?: string;
}
/**
 *
 * @export
 * @interface CheckDeviceOARequest
 */
export interface CheckDeviceOARequest {
  /**
   *
   * @type {string}
   * @memberof CheckDeviceOARequest
   */
  hardwareId: string;
}
/**
 *
 * @export
 * @interface CheckDeviceOAResponse
 */
export interface CheckDeviceOAResponse {
  /**
   *
   * @type {string}
   * @memberof CheckDeviceOAResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof CheckDeviceOAResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof CheckDeviceOAResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof CheckDeviceOAResponse
   */
  pairedTime?: string;
  /**
   *
   * @type {boolean}
   * @memberof CheckDeviceOAResponse
   */
  online?: boolean;
}
/**
 *
 * @export
 * @interface CheckDeviceResponse
 */
export interface CheckDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof CheckDeviceResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof CheckDeviceResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof CheckDeviceResponse
   */
  displayName?: string;
  /**
   *
   * @type {string}
   * @memberof CheckDeviceResponse
   */
  model?: string;
  /**
   *
   * @type {boolean}
   * @memberof CheckDeviceResponse
   */
  online?: boolean;
}
/**
 *
 * @export
 * @interface CheckDeviceWLRequest
 */
export interface CheckDeviceWLRequest {
  /**
   *
   * @type {string}
   * @memberof CheckDeviceWLRequest
   */
  hardwareId: string;
}
/**
 *
 * @export
 * @interface CheckDeviceWLResponse
 */
export interface CheckDeviceWLResponse {
  /**
   *
   * @type {string}
   * @memberof CheckDeviceWLResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof CheckDeviceWLResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof CheckDeviceWLResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof CheckDeviceWLResponse
   */
  pairedUserId?: string;
  /**
   *
   * @type {string}
   * @memberof CheckDeviceWLResponse
   */
  pairedTime?: string;
  /**
   *
   * @type {boolean}
   * @memberof CheckDeviceWLResponse
   */
  online?: boolean;
}
/**
 *
 * @export
 * @interface CheckEmailIfExistedResponse
 */
export interface CheckEmailIfExistedResponse {
  /**
   *
   * @type {boolean}
   * @memberof CheckEmailIfExistedResponse
   */
  existed?: boolean;
}
/**
 *
 * @export
 * @interface CheckForgotPasscodeV2Request
 */
export interface CheckForgotPasscodeV2Request {
  /**
   *
   * @type {string}
   * @memberof CheckForgotPasscodeV2Request
   */
  phone: string;
}
/**
 *
 * @export
 * @interface CheckForgotPasscodeV2Response
 */
export interface CheckForgotPasscodeV2Response {
  /**
   *
   * @type {string}
   * @memberof CheckForgotPasscodeV2Response
   */
  sessionId?: string;
}
/**
 *
 * @export
 * @interface CheckOTPRequest
 */
export interface CheckOTPRequest {
  /**
   *
   * @type {string}
   * @memberof CheckOTPRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof CheckOTPRequest
   */
  otp: string;
}
/**
 *
 * @export
 * @interface CheckPhoneIfExistedResponse
 */
export interface CheckPhoneIfExistedResponse {
  /**
   *
   * @type {boolean}
   * @memberof CheckPhoneIfExistedResponse
   */
  existed?: boolean;
}
/**
 *
 * @export
 * @interface CheckSetupBankAccountResponse
 */
export interface CheckSetupBankAccountResponse {
  /**
   *
   * @type {string}
   * @memberof CheckSetupBankAccountResponse
   */
  bankAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof CheckSetupBankAccountResponse
   */
  bankAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof CheckSetupBankAccountResponse
   */
  nickname?: string;
  /**
   *
   * @type {string}
   * @memberof CheckSetupBankAccountResponse
   */
  shopName?: string;
  /**
   *
   * @type {boolean}
   * @memberof CheckSetupBankAccountResponse
   */
  setup?: boolean;
}
/**
 *
 * @export
 * @interface CheckVersionRequest
 */
export interface CheckVersionRequest {
  /**
   *
   * @type {string}
   * @memberof CheckVersionRequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof CheckVersionRequest
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof CheckVersionRequest
   */
  version: string;
}
/**
 *
 * @export
 * @interface ClientLoginRequest
 */
export interface ClientLoginRequest {
  /**
   *
   * @type {string}
   * @memberof ClientLoginRequest
   */
  clientId: string;
  /**
   *
   * @type {string}
   * @memberof ClientLoginRequest
   */
  secretKey: string;
}
/**
 *
 * @export
 * @interface CmsRegisterDeviceRequest
 */
export interface CmsRegisterDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof CmsRegisterDeviceRequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof CmsRegisterDeviceRequest
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof CmsRegisterDeviceRequest
   */
  version?: string;
}
/**
 *
 * @export
 * @interface ConfigResourceRequest
 */
export interface ConfigResourceRequest {
  /**
   *
   * @type {string}
   * @memberof ConfigResourceRequest
   */
  previewQrBackground?: string;
  /**
   *
   * @type {string}
   * @memberof ConfigResourceRequest
   */
  vietQrLogo?: string;
  /**
   *
   * @type {boolean}
   * @memberof ConfigResourceRequest
   */
  enableApiKey?: boolean;
}
/**
 *
 * @export
 * @interface ConfigResourceResponse
 */
export interface ConfigResourceResponse {
  /**
   *
   * @type {boolean}
   * @memberof ConfigResourceResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface CreateManufacturerTokenRequest
 */
export interface CreateManufacturerTokenRequest {
  /**
   *
   * @type {string}
   * @memberof CreateManufacturerTokenRequest
   */
  manToken: string;
  /**
   *
   * @type {string}
   * @memberof CreateManufacturerTokenRequest
   */
  partnerKey?: string;
}
/**
 *
 * @export
 * @interface CreateManufacturerTokenResponse
 */
export interface CreateManufacturerTokenResponse {
  /**
   *
   * @type {string}
   * @memberof CreateManufacturerTokenResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateManufacturerTokenResponse
   */
  manToken?: string;
  /**
   *
   * @type {number}
   * @memberof CreateManufacturerTokenResponse
   */
  quantity?: number;
}
/**
 *
 * @export
 * @interface CreateTransactionRequest
 */
export interface CreateTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof CreateTransactionRequest
   */
  transactionId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionRequest
   */
  deviceId?: string;
  /**
   *
   * @type {number}
   * @memberof CreateTransactionRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionRequest
   */
  content?: string;
  /**
   *
   * @type {number}
   * @memberof CreateTransactionRequest
   */
  timeout?: number;
}
/**
 *
 * @export
 * @interface CustomDynamicQrOARequest
 */
export interface CustomDynamicQrOARequest {
  /**
   *
   * @type {string}
   * @memberof CustomDynamicQrOARequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof CustomDynamicQrOARequest
   */
  beneficialNo?: string;
  /**
   *
   * @type {string}
   * @memberof CustomDynamicQrOARequest
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof CustomDynamicQrOARequest
   */
  qrCode: string;
  /**
   *
   * @type {number}
   * @memberof CustomDynamicQrOARequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof CustomDynamicQrOARequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof CustomDynamicQrOARequest
   */
  bankBin?: string;
  /**
   *
   * @type {number}
   * @memberof CustomDynamicQrOARequest
   */
  expireTime: number;
}
/**
 *
 * @export
 * @interface CustomDynamicQrWLRequest
 */
export interface CustomDynamicQrWLRequest {
  /**
   *
   * @type {string}
   * @memberof CustomDynamicQrWLRequest
   */
  beneficialNo?: string;
  /**
   *
   * @type {string}
   * @memberof CustomDynamicQrWLRequest
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof CustomDynamicQrWLRequest
   */
  qrCode: string;
  /**
   *
   * @type {number}
   * @memberof CustomDynamicQrWLRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof CustomDynamicQrWLRequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof CustomDynamicQrWLRequest
   */
  bankBin?: string;
  /**
   *
   * @type {number}
   * @memberof CustomDynamicQrWLRequest
   */
  expireTime: number;
}
/**
 *
 * @export
 * @interface CustomStaticQrOARequest
 */
export interface CustomStaticQrOARequest {
  /**
   *
   * @type {string}
   * @memberof CustomStaticQrOARequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof CustomStaticQrOARequest
   */
  beneficialNo?: string;
  /**
   *
   * @type {string}
   * @memberof CustomStaticQrOARequest
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof CustomStaticQrOARequest
   */
  qrCode: string;
  /**
   *
   * @type {number}
   * @memberof CustomStaticQrOARequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof CustomStaticQrOARequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof CustomStaticQrOARequest
   */
  bankBin?: string;
}
/**
 *
 * @export
 * @interface CustomStaticQrWLRequest
 */
export interface CustomStaticQrWLRequest {
  /**
   *
   * @type {string}
   * @memberof CustomStaticQrWLRequest
   */
  beneficialNo?: string;
  /**
   *
   * @type {string}
   * @memberof CustomStaticQrWLRequest
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof CustomStaticQrWLRequest
   */
  qrCode: string;
  /**
   *
   * @type {number}
   * @memberof CustomStaticQrWLRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof CustomStaticQrWLRequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof CustomStaticQrWLRequest
   */
  bankBin?: string;
}
/**
 *
 * @export
 * @interface DateAndRevenueResponse
 */
export interface DateAndRevenueResponse {
  /**
   *
   * @type {string}
   * @memberof DateAndRevenueResponse
   */
  dateTime?: string;
  /**
   *
   * @type {number}
   * @memberof DateAndRevenueResponse
   */
  revenue?: number;
}
/**
 *
 * @export
 * @interface DeleteAdvertisementRequest
 */
export interface DeleteAdvertisementRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteAdvertisementRequest
   */
  id: string;
}
/**
 *
 * @export
 * @interface DeleteAdvertisementResponse
 */
export interface DeleteAdvertisementResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteAdvertisementResponse
   */
  removed?: boolean;
}
/**
 *
 * @export
 * @interface DeleteBankRequest
 */
export interface DeleteBankRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteBankRequest
   */
  id: string;
}
/**
 *
 * @export
 * @interface DeleteBankResponse
 */
export interface DeleteBankResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteBankResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteDeviceRequest
 */
export interface DeleteDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteDeviceRequest
   */
  id: string;
}
/**
 *
 * @export
 * @interface DeleteDeviceResponse
 */
export interface DeleteDeviceResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteDeviceResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteStaticQrByIdRequest
 */
export interface DeleteStaticQrByIdRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteStaticQrByIdRequest
   */
  id: string;
}
/**
 *
 * @export
 * @interface DeleteStaticQrByIdResponse
 */
export interface DeleteStaticQrByIdResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteStaticQrByIdResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteVersionRequest
 */
export interface DeleteVersionRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteVersionRequest
   */
  id: string;
}
/**
 *
 * @export
 * @interface DeleteVersionResponse
 */
export interface DeleteVersionResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteVersionResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DetailTransactionResponse
 */
export interface DetailTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof DetailTransactionResponse
   */
  type?: DetailTransactionResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof DetailTransactionResponse
   */
  fromAccount?: string;
  /**
   *
   * @type {GetBankResponse}
   * @memberof DetailTransactionResponse
   */
  fromBankInfo?: GetBankResponse;
  /**
   *
   * @type {string}
   * @memberof DetailTransactionResponse
   */
  toAccount?: string;
  /**
   *
   * @type {GetBankResponse}
   * @memberof DetailTransactionResponse
   */
  toBankInfo?: GetBankResponse;
  /**
   *
   * @type {number}
   * @memberof DetailTransactionResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof DetailTransactionResponse
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof DetailTransactionResponse
   */
  status?: DetailTransactionResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof DetailTransactionResponse
   */
  txnNumber?: string;
  /**
   *
   * @type {string}
   * @memberof DetailTransactionResponse
   */
  content?: string;
}

export const DetailTransactionResponseTypeEnum = {
  Klb: "KLB",
  Paybox: "PAYBOX",
  Pos: "POS",
  Usee: "USEE",
  PartnerService: "PARTNER_SERVICE",
} as const;

export type DetailTransactionResponseTypeEnum =
  (typeof DetailTransactionResponseTypeEnum)[keyof typeof DetailTransactionResponseTypeEnum];
export const DetailTransactionResponseStatusEnum = {
  None: "NONE",
  Pending: "PENDING",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
} as const;

export type DetailTransactionResponseStatusEnum =
  (typeof DetailTransactionResponseStatusEnum)[keyof typeof DetailTransactionResponseStatusEnum];

/**
 *
 * @export
 * @interface DeviceDetailOAResponse
 */
export interface DeviceDetailOAResponse {
  /**
   *
   * @type {string}
   * @memberof DeviceDetailOAResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailOAResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailOAResponse
   */
  displayName?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailOAResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailOAResponse
   */
  version?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailOAResponse
   */
  hwVersion?: string;
  /**
   *
   * @type {boolean}
   * @memberof DeviceDetailOAResponse
   */
  online?: boolean;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailOAResponse
   */
  deviceToken?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailOAResponse
   */
  createTime?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailOAResponse
   */
  activeTime?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailOAResponse
   */
  pairedTime?: string;
}
/**
 *
 * @export
 * @interface DeviceDetailWLResponse
 */
export interface DeviceDetailWLResponse {
  /**
   *
   * @type {string}
   * @memberof DeviceDetailWLResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailWLResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailWLResponse
   */
  displayName?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailWLResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailWLResponse
   */
  version?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailWLResponse
   */
  hwVersion?: string;
  /**
   *
   * @type {boolean}
   * @memberof DeviceDetailWLResponse
   */
  online?: boolean;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailWLResponse
   */
  refUserId?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailWLResponse
   */
  deviceToken?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailWLResponse
   */
  createTime?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailWLResponse
   */
  activeTime?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceDetailWLResponse
   */
  pairedTime?: string;
}
/**
 *
 * @export
 * @interface DeviceOAResponse
 */
export interface DeviceOAResponse {
  /**
   *
   * @type {string}
   * @memberof DeviceOAResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceOAResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceOAResponse
   */
  displayName?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceOAResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceOAResponse
   */
  version?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceOAResponse
   */
  hwVersion?: string;
  /**
   *
   * @type {boolean}
   * @memberof DeviceOAResponse
   */
  online?: boolean;
  /**
   *
   * @type {string}
   * @memberof DeviceOAResponse
   */
  createTime?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceOAResponse
   */
  activeTime?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceOAResponse
   */
  pairedTime?: string;
}
/**
 *
 * @export
 * @interface DeviceResponse
 */
export interface DeviceResponse {
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  displayName?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  version?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  hwVersion?: string;
  /**
   *
   * @type {boolean}
   * @memberof DeviceResponse
   */
  online?: boolean;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  bankAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  bankAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  bankImageUrl?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  bankName?: string;
}
/**
 *
 * @export
 * @interface DeviceWLResponse
 */
export interface DeviceWLResponse {
  /**
   *
   * @type {string}
   * @memberof DeviceWLResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceWLResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceWLResponse
   */
  displayName?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceWLResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceWLResponse
   */
  version?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceWLResponse
   */
  hwVersion?: string;
  /**
   *
   * @type {boolean}
   * @memberof DeviceWLResponse
   */
  online?: boolean;
  /**
   *
   * @type {string}
   * @memberof DeviceWLResponse
   */
  refUserId?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceWLResponse
   */
  createTime?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceWLResponse
   */
  activeTime?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceWLResponse
   */
  pairedTime?: string;
}
/**
 *
 * @export
 * @interface ExchangeGoogleTokenRequest
 */
export interface ExchangeGoogleTokenRequest {
  /**
   *
   * @type {string}
   * @memberof ExchangeGoogleTokenRequest
   */
  accessToken: string;
}
/**
 *
 * @export
 * @interface ExtensionPathResponse
 */
export interface ExtensionPathResponse {
  /**
   *
   * @type {string}
   * @memberof ExtensionPathResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ExtensionPathResponse
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof ExtensionPathResponse
   */
  value?: string;
  /**
   *
   * @type {string}
   * @memberof ExtensionPathResponse
   */
  type?: ExtensionPathResponseTypeEnum;
}

export const ExtensionPathResponseTypeEnum = {
  Xpath: "XPATH",
  Id: "ID",
} as const;

export type ExtensionPathResponseTypeEnum =
  (typeof ExtensionPathResponseTypeEnum)[keyof typeof ExtensionPathResponseTypeEnum];

/**
 *
 * @export
 * @interface ExternalCreateBankStaticQrRequest
 */
export interface ExternalCreateBankStaticQrRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalCreateBankStaticQrRequest
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateBankStaticQrRequest
   */
  bin: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateBankStaticQrRequest
   */
  accountNo: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateBankStaticQrRequest
   */
  accountName: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateBankStaticQrRequest
   */
  content?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExternalCreateBankStaticQrRequest
   */
  isDefault?: boolean;
}
/**
 *
 * @export
 * @interface ExternalCreateEWalletStaticQrRequest
 */
export interface ExternalCreateEWalletStaticQrRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalCreateEWalletStaticQrRequest
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateEWalletStaticQrRequest
   */
  type: ExternalCreateEWalletStaticQrRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateEWalletStaticQrRequest
   */
  qrCode: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateEWalletStaticQrRequest
   */
  accountNo: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateEWalletStaticQrRequest
   */
  accountName: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateEWalletStaticQrRequest
   */
  content?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExternalCreateEWalletStaticQrRequest
   */
  isDefault?: boolean;
}

export const ExternalCreateEWalletStaticQrRequestTypeEnum = {
  Momo: "MOMO",
  ZaloPay: "ZALO_PAY",
  Moca: "MOCA",
  VnPay: "VN_PAY",
} as const;

export type ExternalCreateEWalletStaticQrRequestTypeEnum =
  (typeof ExternalCreateEWalletStaticQrRequestTypeEnum)[keyof typeof ExternalCreateEWalletStaticQrRequestTypeEnum];

/**
 *
 * @export
 * @interface ExternalCreateTransactionRequest
 */
export interface ExternalCreateTransactionRequest {
  /**
   *
   * @type {number}
   * @memberof ExternalCreateTransactionRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateTransactionRequest
   */
  refTransactionNo: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateTransactionRequest
   */
  content?: string;
  /**
   *
   * @type {number}
   * @memberof ExternalCreateTransactionRequest
   */
  timeout?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateTransactionRequest
   */
  deviceId: string;
}
/**
 *
 * @export
 * @interface ExternalCreateTransactionResponse
 */
export interface ExternalCreateTransactionResponse {
  /**
   *
   * @type {number}
   * @memberof ExternalCreateTransactionResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateTransactionResponse
   */
  bin?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateTransactionResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreateTransactionResponse
   */
  accountName?: string;
}
/**
 *
 * @export
 * @interface ExternalDeviceResponse
 */
export interface ExternalDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof ExternalDeviceResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalDeviceResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalDeviceResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalDeviceResponse
   */
  version?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExternalDeviceResponse
   */
  online?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExternalDeviceResponse
   */
  paired?: boolean;
}
/**
 *
 * @export
 * @interface ExternalGetAllStaticQrRequest
 */
export interface ExternalGetAllStaticQrRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalGetAllStaticQrRequest
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalGetAllStaticQrRequest
   */
  type?: ExternalGetAllStaticQrRequestTypeEnum;
}

export const ExternalGetAllStaticQrRequestTypeEnum = {
  Bank: "BANK",
  EWallet: "E_WALLET",
  Umee: "UMEE",
  Klb: "KLB",
} as const;

export type ExternalGetAllStaticQrRequestTypeEnum =
  (typeof ExternalGetAllStaticQrRequestTypeEnum)[keyof typeof ExternalGetAllStaticQrRequestTypeEnum];

/**
 *
 * @export
 * @interface ExternalGetAllTransactionRequest
 */
export interface ExternalGetAllTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalGetAllTransactionRequest
   */
  fromDate?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalGetAllTransactionRequest
   */
  toDate?: string;
  /**
   *
   * @type {number}
   * @memberof ExternalGetAllTransactionRequest
   */
  minAmount?: number;
  /**
   *
   * @type {number}
   * @memberof ExternalGetAllTransactionRequest
   */
  maxAmount?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalGetAllTransactionRequest
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalGetAllTransactionRequest
   */
  accountNumber?: string;
}
/**
 *
 * @export
 * @interface ExternalNotifyTransactionRequest
 */
export interface ExternalNotifyTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalNotifyTransactionRequest
   */
  refTransactionId: string;
  /**
   *
   * @type {string}
   * @memberof ExternalNotifyTransactionRequest
   */
  bin: string;
  /**
   *
   * @type {string}
   * @memberof ExternalNotifyTransactionRequest
   */
  accountNo: string;
  /**
   *
   * @type {string}
   * @memberof ExternalNotifyTransactionRequest
   */
  accountName: string;
  /**
   *
   * @type {string}
   * @memberof ExternalNotifyTransactionRequest
   */
  fromBin: string;
  /**
   *
   * @type {string}
   * @memberof ExternalNotifyTransactionRequest
   */
  fromAccountNo: string;
  /**
   *
   * @type {number}
   * @memberof ExternalNotifyTransactionRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof ExternalNotifyTransactionRequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalNotifyTransactionRequest
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof ExternalNotifyTransactionRequest
   */
  completedTime: string;
  /**
   *
   * @type {string}
   * @memberof ExternalNotifyTransactionRequest
   */
  timeDateTime?: string;
}
/**
 *
 * @export
 * @interface ExternalPairByHardwareIdRequest
 */
export interface ExternalPairByHardwareIdRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPairByHardwareIdRequest
   */
  hardwareId: string;
}
/**
 *
 * @export
 * @interface ExternalPairDeviceRequest
 */
export interface ExternalPairDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPairDeviceRequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPairDeviceRequest
   */
  pairingKey: string;
}
/**
 *
 * @export
 * @interface ExternalQrDetailResponse
 */
export interface ExternalQrDetailResponse {
  /**
   *
   * @type {string}
   * @memberof ExternalQrDetailResponse
   */
  qrId?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalQrDetailResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalQrDetailResponse
   */
  bankName?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalQrDetailResponse
   */
  bankShortName?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalQrDetailResponse
   */
  bin?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalQrDetailResponse
   */
  type?: ExternalQrDetailResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ExternalQrDetailResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalQrDetailResponse
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalQrDetailResponse
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalQrDetailResponse
   */
  qrCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExternalQrDetailResponse
   */
  isDefault?: boolean;
}

export const ExternalQrDetailResponseTypeEnum = {
  Bank: "BANK",
  EWallet: "E_WALLET",
  Umee: "UMEE",
  Klb: "KLB",
} as const;

export type ExternalQrDetailResponseTypeEnum =
  (typeof ExternalQrDetailResponseTypeEnum)[keyof typeof ExternalQrDetailResponseTypeEnum];

/**
 *
 * @export
 * @interface ExternalQrResponse
 */
export interface ExternalQrResponse {
  /**
   *
   * @type {string}
   * @memberof ExternalQrResponse
   */
  qrId?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalQrResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalQrResponse
   */
  bankName?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalQrResponse
   */
  bankShortName?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalQrResponse
   */
  bin?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalQrResponse
   */
  type?: ExternalQrResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ExternalQrResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalQrResponse
   */
  accountName?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExternalQrResponse
   */
  isDefault?: boolean;
}

export const ExternalQrResponseTypeEnum = {
  Bank: "BANK",
  EWallet: "E_WALLET",
  Umee: "UMEE",
  Klb: "KLB",
} as const;

export type ExternalQrResponseTypeEnum =
  (typeof ExternalQrResponseTypeEnum)[keyof typeof ExternalQrResponseTypeEnum];

/**
 *
 * @export
 * @interface ExternalShowQrRequest
 */
export interface ExternalShowQrRequest {
  /**
   *
   * @type {number}
   * @memberof ExternalShowQrRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof ExternalShowQrRequest
   */
  bin: string;
  /**
   *
   * @type {string}
   * @memberof ExternalShowQrRequest
   */
  accountNo: string;
  /**
   *
   * @type {string}
   * @memberof ExternalShowQrRequest
   */
  accountName: string;
  /**
   *
   * @type {string}
   * @memberof ExternalShowQrRequest
   */
  content?: string;
  /**
   *
   * @type {number}
   * @memberof ExternalShowQrRequest
   */
  timeout?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalShowQrRequest
   */
  deviceId: string;
}
/**
 *
 * @export
 * @interface ExternalTransactionResponse
 */
export interface ExternalTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof ExternalTransactionResponse
   */
  transactionId?: string;
  /**
   *
   * @type {number}
   * @memberof ExternalTransactionResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalTransactionResponse
   */
  completedTime?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalTransactionResponse
   */
  bin?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalTransactionResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalTransactionResponse
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalTransactionResponse
   */
  fromBin?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalTransactionResponse
   */
  fromAccount?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalTransactionResponse
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalTransactionResponse
   */
  refTransactionId?: string;
}
/**
 *
 * @export
 * @interface ExternalUnpairDeviceRequest
 */
export interface ExternalUnpairDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalUnpairDeviceRequest
   */
  deviceId: string;
}
/**
 *
 * @export
 * @interface ExternalUpdateBankStaticQrRequest
 */
export interface ExternalUpdateBankStaticQrRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalUpdateBankStaticQrRequest
   */
  bin: string;
  /**
   *
   * @type {string}
   * @memberof ExternalUpdateBankStaticQrRequest
   */
  accountNo: string;
  /**
   *
   * @type {string}
   * @memberof ExternalUpdateBankStaticQrRequest
   */
  accountName: string;
  /**
   *
   * @type {string}
   * @memberof ExternalUpdateBankStaticQrRequest
   */
  content?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExternalUpdateBankStaticQrRequest
   */
  isDefault?: boolean;
}
/**
 *
 * @export
 * @interface ExternalUpdateEWalletStaticQrRequest
 */
export interface ExternalUpdateEWalletStaticQrRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalUpdateEWalletStaticQrRequest
   */
  type: ExternalUpdateEWalletStaticQrRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ExternalUpdateEWalletStaticQrRequest
   */
  qrCode: string;
  /**
   *
   * @type {string}
   * @memberof ExternalUpdateEWalletStaticQrRequest
   */
  accountNo: string;
  /**
   *
   * @type {string}
   * @memberof ExternalUpdateEWalletStaticQrRequest
   */
  accountName: string;
  /**
   *
   * @type {string}
   * @memberof ExternalUpdateEWalletStaticQrRequest
   */
  content?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExternalUpdateEWalletStaticQrRequest
   */
  isDefault?: boolean;
}

export const ExternalUpdateEWalletStaticQrRequestTypeEnum = {
  Momo: "MOMO",
  ZaloPay: "ZALO_PAY",
  Moca: "MOCA",
  VnPay: "VN_PAY",
} as const;

export type ExternalUpdateEWalletStaticQrRequestTypeEnum =
  (typeof ExternalUpdateEWalletStaticQrRequestTypeEnum)[keyof typeof ExternalUpdateEWalletStaticQrRequestTypeEnum];

/**
 *
 * @export
 * @interface ForceUpdateRequest
 */
export interface ForceUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof ForceUpdateRequest
   */
  deviceId?: string;
}
/**
 *
 * @export
 * @interface ForceUpdateResponse
 */
export interface ForceUpdateResponse {
  /**
   *
   * @type {{ [key: string]: boolean; }}
   * @memberof ForceUpdateResponse
   */
  result?: { [key: string]: boolean };
}
/**
 *
 * @export
 * @interface ForgotPasscodeV2Request
 */
export interface ForgotPasscodeV2Request {
  /**
   *
   * @type {string}
   * @memberof ForgotPasscodeV2Request
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof ForgotPasscodeV2Request
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof ForgotPasscodeV2Request
   */
  passcode?: string;
}
/**
 *
 * @export
 * @interface GenerateQrRequest
 */
export interface GenerateQrRequest {
  /**
   *
   * @type {string}
   * @memberof GenerateQrRequest
   */
  bankId?: string;
  /**
   *
   * @type {string}
   * @memberof GenerateQrRequest
   */
  bankAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof GenerateQrRequest
   */
  content?: string;
}
/**
 *
 * @export
 * @interface GenerateQrResponse
 */
export interface GenerateQrResponse {
  /**
   *
   * @type {string}
   * @memberof GenerateQrResponse
   */
  qr?: string;
}
/**
 *
 * @export
 * @interface GetAllAdvertisementResponse
 */
export interface GetAllAdvertisementResponse {
  /**
   *
   * @type {{ [key: string]: Array<Advertisement>; }}
   * @memberof GetAllAdvertisementResponse
   */
  advertisements?: { [key: string]: Array<Advertisement> };
}
/**
 *
 * @export
 * @interface GetAllBanksRequest
 */
export interface GetAllBanksRequest {
  /**
   *
   * @type {PageableObject}
   * @memberof GetAllBanksRequest
   */
  pageable?: PageableObject;
  /**
   *
   * @type {string}
   * @memberof GetAllBanksRequest
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllBanksRequest
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllBanksRequest
   */
  shortName?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllBanksRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllBanksRequest
   */
  type?: GetAllBanksRequestTypeEnum;
  /**
   *
   * @type {object}
   * @memberof GetAllBanksRequest
   */
  specification?: object;
}

export const GetAllBanksRequestTypeEnum = {
  Bank: "BANK",
  EWallet: "E_WALLET",
  Umee: "UMEE",
  Klb: "KLB",
} as const;

export type GetAllBanksRequestTypeEnum =
  (typeof GetAllBanksRequestTypeEnum)[keyof typeof GetAllBanksRequestTypeEnum];

/**
 *
 * @export
 * @interface GetAllBanksResponse
 */
export interface GetAllBanksResponse {
  /**
   *
   * @type {PageResponseBankGetBankResponse}
   * @memberof GetAllBanksResponse
   */
  banks?: PageResponseBankGetBankResponse;
}
/**
 *
 * @export
 * @interface GetAllDeviceRequest
 */
export interface GetAllDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof GetAllDeviceRequest
   */
  keyword?: string;
  /**
   *
   * @type {PageableObject}
   * @memberof GetAllDeviceRequest
   */
  pageable?: PageableObject;
  /**
   *
   * @type {boolean}
   * @memberof GetAllDeviceRequest
   */
  online?: boolean;
}
/**
 *
 * @export
 * @interface GetAllStaticQrRequest
 */
export interface GetAllStaticQrRequest {
  /**
   *
   * @type {PageableObject}
   * @memberof GetAllStaticQrRequest
   */
  pageable?: PageableObject;
  /**
   *
   * @type {string}
   * @memberof GetAllStaticQrRequest
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllStaticQrRequest
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllStaticQrRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllStaticQrRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllStaticQrRequest
   */
  type?: GetAllStaticQrRequestTypeEnum;
  /**
   *
   * @type {object}
   * @memberof GetAllStaticQrRequest
   */
  specification?: object;
}

export const GetAllStaticQrRequestTypeEnum = {
  Bank: "BANK",
  EWallet: "E_WALLET",
  Umee: "UMEE",
  Klb: "KLB",
} as const;

export type GetAllStaticQrRequestTypeEnum =
  (typeof GetAllStaticQrRequestTypeEnum)[keyof typeof GetAllStaticQrRequestTypeEnum];

/**
 *
 * @export
 * @interface GetAllStaticQrResponse
 */
export interface GetAllStaticQrResponse {
  /**
   *
   * @type {PageResponseStaticQrStaticQrResponse}
   * @memberof GetAllStaticQrResponse
   */
  qrList?: PageResponseStaticQrStaticQrResponse;
}
/**
 *
 * @export
 * @interface GetApiKeyResponse
 */
export interface GetApiKeyResponse {
  /**
   *
   * @type {string}
   * @memberof GetApiKeyResponse
   */
  apiKey?: string;
}
/**
 *
 * @export
 * @interface GetBankResponse
 */
export interface GetBankResponse {
  /**
   *
   * @type {string}
   * @memberof GetBankResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankResponse
   */
  bin?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankResponse
   */
  shortName?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankResponse
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankResponse
   */
  smallLogo?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankResponse
   */
  type?: GetBankResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GetBankResponse
   */
  senderBin?: string;
}

export const GetBankResponseTypeEnum = {
  Bank: "BANK",
  EWallet: "E_WALLET",
  Umee: "UMEE",
  Klb: "KLB",
} as const;

export type GetBankResponseTypeEnum =
  (typeof GetBankResponseTypeEnum)[keyof typeof GetBankResponseTypeEnum];

/**
 *
 * @export
 * @interface GetDeviceListOARequest
 */
export interface GetDeviceListOARequest {
  /**
   *
   * @type {string}
   * @memberof GetDeviceListOARequest
   */
  keyword?: string;
  /**
   *
   * @type {string}
   * @memberof GetDeviceListOARequest
   */
  model?: GetDeviceListOARequestModelEnum;
  /**
   *
   * @type {number}
   * @memberof GetDeviceListOARequest
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof GetDeviceListOARequest
   */
  size?: number;
}

export const GetDeviceListOARequestModelEnum = {
  Pb01: "PB-01",
  Pb02: "PB-02",
  Pb03: "PB-03",
  Pb04: "PB-04",
  Pb06: "PB-06",
  Pb05: "PB-05",
  Gw01: "GW-01",
  Pl01: "PL-01",
  Pm01: "PM-01",
  Td01: "TD-01",
  Rl01: "RL-01",
  Iw01: "IW-01",
  As01: "AS-01",
  Dc01: "DC-01",
  Em01: "EM-01",
} as const;

export type GetDeviceListOARequestModelEnum =
  (typeof GetDeviceListOARequestModelEnum)[keyof typeof GetDeviceListOARequestModelEnum];

/**
 *
 * @export
 * @interface GetDeviceListWLRequest
 */
export interface GetDeviceListWLRequest {
  /**
   *
   * @type {string}
   * @memberof GetDeviceListWLRequest
   */
  keyword?: string;
  /**
   *
   * @type {string}
   * @memberof GetDeviceListWLRequest
   */
  refUserId?: string;
  /**
   *
   * @type {string}
   * @memberof GetDeviceListWLRequest
   */
  model?: GetDeviceListWLRequestModelEnum;
  /**
   *
   * @type {number}
   * @memberof GetDeviceListWLRequest
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof GetDeviceListWLRequest
   */
  size?: number;
}

export const GetDeviceListWLRequestModelEnum = {
  Pb01: "PB-01",
  Pb02: "PB-02",
  Pb03: "PB-03",
  Pb04: "PB-04",
  Pb06: "PB-06",
  Pb05: "PB-05",
  Gw01: "GW-01",
  Pl01: "PL-01",
  Pm01: "PM-01",
  Td01: "TD-01",
  Rl01: "RL-01",
  Iw01: "IW-01",
  As01: "AS-01",
  Dc01: "DC-01",
  Em01: "EM-01",
} as const;

export type GetDeviceListWLRequestModelEnum =
  (typeof GetDeviceListWLRequestModelEnum)[keyof typeof GetDeviceListWLRequestModelEnum];

/**
 *
 * @export
 * @interface GetEmailOTPRequest
 */
export interface GetEmailOTPRequest {
  /**
   *
   * @type {string}
   * @memberof GetEmailOTPRequest
   */
  email?: string;
}
/**
 *
 * @export
 * @interface GetLatestVersionRequest
 */
export interface GetLatestVersionRequest {
  /**
   *
   * @type {string}
   * @memberof GetLatestVersionRequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof GetLatestVersionRequest
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof GetLatestVersionRequest
   */
  version: string;
}
/**
 *
 * @export
 * @interface GetLatestVersionResponse
 */
export interface GetLatestVersionResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetLatestVersionResponse
   */
  update?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetLatestVersionResponse
   */
  fwVersion?: string;
  /**
   *
   * @type {string}
   * @memberof GetLatestVersionResponse
   */
  displayName?: string;
}
/**
 *
 * @export
 * @interface GetListTransactionByDeviceResponse
 */
export interface GetListTransactionByDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof GetListTransactionByDeviceResponse
   */
  transactionId?: string;
  /**
   *
   * @type {string}
   * @memberof GetListTransactionByDeviceResponse
   */
  deviceName?: string;
  /**
   *
   * @type {string}
   * @memberof GetListTransactionByDeviceResponse
   */
  fromAccName?: string;
  /**
   *
   * @type {number}
   * @memberof GetListTransactionByDeviceResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof GetListTransactionByDeviceResponse
   */
  completeTime?: string;
  /**
   *
   * @type {string}
   * @memberof GetListTransactionByDeviceResponse
   */
  bankName?: string;
  /**
   *
   * @type {string}
   * @memberof GetListTransactionByDeviceResponse
   */
  content?: string;
}
/**
 *
 * @export
 * @interface GetMyShopAccountResponse
 */
export interface GetMyShopAccountResponse {
  /**
   *
   * @type {string}
   * @memberof GetMyShopAccountResponse
   */
  bankAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof GetMyShopAccountResponse
   */
  bankAccountName?: string;
}
/**
 *
 * @export
 * @interface GetOTPResponse
 */
export interface GetOTPResponse {
  /**
   *
   * @type {string}
   * @memberof GetOTPResponse
   */
  sessionId?: string;
  /**
   *
   * @type {number}
   * @memberof GetOTPResponse
   */
  countdownTime?: number;
}
/**
 *
 * @export
 * @interface GetResourceResponse
 */
export interface GetResourceResponse {
  /**
   *
   * @type {Resource}
   * @memberof GetResourceResponse
   */
  resource?: Resource;
}
/**
 *
 * @export
 * @interface GetRevenueOfTopDeviceResponse
 */
export interface GetRevenueOfTopDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof GetRevenueOfTopDeviceResponse
   */
  nameDevice?: string;
  /**
   *
   * @type {number}
   * @memberof GetRevenueOfTopDeviceResponse
   */
  totalRevenue?: number;
  /**
   *
   * @type {Array<DateAndRevenueResponse>}
   * @memberof GetRevenueOfTopDeviceResponse
   */
  revenueList?: Array<DateAndRevenueResponse>;
}
/**
 *
 * @export
 * @interface GetTotalRevenueResponse
 */
export interface GetTotalRevenueResponse {
  /**
   *
   * @type {number}
   * @memberof GetTotalRevenueResponse
   */
  totalTransactions?: number;
  /**
   *
   * @type {number}
   * @memberof GetTotalRevenueResponse
   */
  percentageOfTransactions?: number;
  /**
   *
   * @type {number}
   * @memberof GetTotalRevenueResponse
   */
  totalSuccessfulTransactions?: number;
  /**
   *
   * @type {number}
   * @memberof GetTotalRevenueResponse
   */
  percentageOfSuccessfulTransactions?: number;
  /**
   *
   * @type {number}
   * @memberof GetTotalRevenueResponse
   */
  totalRevenue?: number;
  /**
   *
   * @type {number}
   * @memberof GetTotalRevenueResponse
   */
  percentageOfRevenue?: number;
}
/**
 *
 * @export
 * @interface GetTransactionsResponse
 */
export interface GetTransactionsResponse {
  /**
   *
   * @type {string}
   * @memberof GetTransactionsResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetTransactionsResponse
   */
  qrContent?: string;
  /**
   *
   * @type {string}
   * @memberof GetTransactionsResponse
   */
  status?: GetTransactionsResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof GetTransactionsResponse
   */
  type?: GetTransactionsResponseTypeEnum;
  /**
   *
   * @type {number}
   * @memberof GetTransactionsResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof GetTransactionsResponse
   */
  createTime?: string;
  /**
   *
   * @type {string}
   * @memberof GetTransactionsResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetTransactionsResponse
   */
  bin?: string;
  /**
   *
   * @type {string}
   * @memberof GetTransactionsResponse
   */
  virtualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof GetTransactionsResponse
   */
  actualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof GetTransactionsResponse
   */
  content?: string;
  /**
   *
   * @type {number}
   * @memberof GetTransactionsResponse
   */
  millisecond?: number;
  /**
   *
   * @type {string}
   * @memberof GetTransactionsResponse
   */
  txnNumber?: string;
  /**
   *
   * @type {string}
   * @memberof GetTransactionsResponse
   */
  traceId?: string;
}

export const GetTransactionsResponseStatusEnum = {
  None: "NONE",
  Pending: "PENDING",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
} as const;

export type GetTransactionsResponseStatusEnum =
  (typeof GetTransactionsResponseStatusEnum)[keyof typeof GetTransactionsResponseStatusEnum];
export const GetTransactionsResponseTypeEnum = {
  Klb: "KLB",
  Paybox: "PAYBOX",
  Pos: "POS",
  Usee: "USEE",
  PartnerService: "PARTNER_SERVICE",
} as const;

export type GetTransactionsResponseTypeEnum =
  (typeof GetTransactionsResponseTypeEnum)[keyof typeof GetTransactionsResponseTypeEnum];

/**
 *
 * @export
 * @interface GetUserInfoResponse
 */
export interface GetUserInfoResponse {
  /**
   *
   * @type {string}
   * @memberof GetUserInfoResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserInfoResponse
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserInfoResponse
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserInfoResponse
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetUserInfoResponse
   */
  completedEKYC?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetUserInfoResponse
   */
  avatar?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetUserInfoResponse
   */
  verifiedMail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetUserInfoResponse
   */
  verifiedPhone?: boolean;
}
/**
 *
 * @export
 * @interface GetVersionsRequest
 */
export interface GetVersionsRequest {
  /**
   *
   * @type {PageableObject}
   * @memberof GetVersionsRequest
   */
  pageable?: PageableObject;
  /**
   *
   * @type {string}
   * @memberof GetVersionsRequest
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof GetVersionsRequest
   */
  version?: string;
  /**
   *
   * @type {object}
   * @memberof GetVersionsRequest
   */
  specification?: object;
}
/**
 *
 * @export
 * @interface GetVersionsResponse
 */
export interface GetVersionsResponse {
  /**
   *
   * @type {PageResponseVersionVersionResponse}
   * @memberof GetVersionsResponse
   */
  versions?: PageResponseVersionVersionResponse;
}
/**
 *
 * @export
 * @interface HandleImageResponse
 */
export interface HandleImageResponse {
  /**
   *
   * @type {string}
   * @memberof HandleImageResponse
   */
  base64?: string;
}
/**
 *
 * @export
 * @interface LinkBankAccountRequest
 */
export interface LinkBankAccountRequest {
  /**
   *
   * @type {string}
   * @memberof LinkBankAccountRequest
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof LinkBankAccountRequest
   */
  type?: LinkBankAccountRequestTypeEnum;
}

export const LinkBankAccountRequestTypeEnum = {
  Web: "WEB",
  Mobile: "MOBILE",
  Api: "API",
} as const;

export type LinkBankAccountRequestTypeEnum =
  (typeof LinkBankAccountRequestTypeEnum)[keyof typeof LinkBankAccountRequestTypeEnum];

/**
 *
 * @export
 * @interface LinkBankAccountResponse
 */
export interface LinkBankAccountResponse {
  /**
   *
   * @type {string}
   * @memberof LinkBankAccountResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof LinkBankAccountResponse
   */
  bankHubLink?: string;
}
/**
 *
 * @export
 * @interface LinkDeviceOAResponse
 */
export interface LinkDeviceOAResponse {
  /**
   *
   * @type {string}
   * @memberof LinkDeviceOAResponse
   */
  sessionId?: string;
  /**
   *
   * @type {string}
   * @memberof LinkDeviceOAResponse
   */
  pairedTime?: string;
  /**
   *
   * @type {string}
   * @memberof LinkDeviceOAResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof LinkDeviceOAResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof LinkDeviceOAResponse
   */
  model?: string;
  /**
   *
   * @type {boolean}
   * @memberof LinkDeviceOAResponse
   */
  online?: boolean;
}
/**
 *
 * @export
 * @interface LinkDeviceResponse
 */
export interface LinkDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof LinkDeviceResponse
   */
  sessionId?: string;
  /**
   *
   * @type {string}
   * @memberof LinkDeviceResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof LinkDeviceResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof LinkDeviceResponse
   */
  displayName?: string;
  /**
   *
   * @type {string}
   * @memberof LinkDeviceResponse
   */
  model?: string;
  /**
   *
   * @type {boolean}
   * @memberof LinkDeviceResponse
   */
  online?: boolean;
}
/**
 *
 * @export
 * @interface LinkDeviceWLResponse
 */
export interface LinkDeviceWLResponse {
  /**
   *
   * @type {string}
   * @memberof LinkDeviceWLResponse
   */
  sessionId?: string;
  /**
   *
   * @type {string}
   * @memberof LinkDeviceWLResponse
   */
  pairedUserId?: string;
  /**
   *
   * @type {string}
   * @memberof LinkDeviceWLResponse
   */
  pairedTime?: string;
  /**
   *
   * @type {string}
   * @memberof LinkDeviceWLResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof LinkDeviceWLResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof LinkDeviceWLResponse
   */
  model?: string;
  /**
   *
   * @type {boolean}
   * @memberof LinkDeviceWLResponse
   */
  online?: boolean;
}
/**
 *
 * @export
 * @interface LinkWithUserByHwIDRequest
 */
export interface LinkWithUserByHwIDRequest {
  /**
   *
   * @type {string}
   * @memberof LinkWithUserByHwIDRequest
   */
  hardwareId: string;
}
/**
 *
 * @export
 * @interface LinkWithUserResponse
 */
export interface LinkWithUserResponse {
  /**
   *
   * @type {boolean}
   * @memberof LinkWithUserResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof LinkWithUserResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof LinkWithUserResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof LinkWithUserResponse
   */
  displayName?: string;
  /**
   *
   * @type {boolean}
   * @memberof LinkWithUserResponse
   */
  online?: boolean;
}
/**
 *
 * @export
 * @interface ListExtensionPathResponse
 */
export interface ListExtensionPathResponse {
  /**
   *
   * @type {Array<ExtensionPathResponse>}
   * @memberof ListExtensionPathResponse
   */
  extensionPathResponseList?: Array<ExtensionPathResponse>;
}
/**
 *
 * @export
 * @interface ListResponseGetRevenueOfTopDeviceResponse
 */
export interface ListResponseGetRevenueOfTopDeviceResponse {
  /**
   *
   * @type {Array<GetRevenueOfTopDeviceResponse>}
   * @memberof ListResponseGetRevenueOfTopDeviceResponse
   */
  contents?: Array<GetRevenueOfTopDeviceResponse>;
}
/**
 *
 * @export
 * @interface ListResponseGetRevenuesLastFiveResponse
 */
export interface ListResponseGetRevenuesLastFiveResponse {
  /**
   *
   * @type {Array<object>}
   * @memberof ListResponseGetRevenuesLastFiveResponse
   */
  contents?: Array<object>;
}
/**
 *
 * @export
 * @interface LogoutRequest
 */
export interface LogoutRequest {
  /**
   *
   * @type {string}
   * @memberof LogoutRequest
   */
  refreshToken?: string;
}
/**
 *
 * @export
 * @interface LogoutResponse
 */
export interface LogoutResponse {
  /**
   *
   * @type {boolean}
   * @memberof LogoutResponse
   */
  logout?: boolean;
}
/**
 *
 * @export
 * @interface NotifyTransactionOARequest
 */
export interface NotifyTransactionOARequest {
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionOARequest
   */
  hardwareId: string;
  /**
   *
   * @type {number}
   * @memberof NotifyTransactionOARequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionOARequest
   */
  traceId: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionOARequest
   */
  fromAccount?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionOARequest
   */
  fromBin?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionOARequest
   */
  toAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionOARequest
   */
  toAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionOARequest
   */
  toBin?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionOARequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionOARequest
   */
  transactionTime?: string;
}
/**
 *
 * @export
 * @interface NotifyTransactionWLRequest
 */
export interface NotifyTransactionWLRequest {
  /**
   *
   * @type {number}
   * @memberof NotifyTransactionWLRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionWLRequest
   */
  traceId: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionWLRequest
   */
  fromAccount?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionWLRequest
   */
  fromBin?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionWLRequest
   */
  toAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionWLRequest
   */
  toAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionWLRequest
   */
  toBin?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionWLRequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionWLRequest
   */
  transactionTime?: string;
}
/**
 *
 * @export
 * @interface PageResponseBankGetBankResponse
 */
export interface PageResponseBankGetBankResponse {
  /**
   *
   * @type {Array<GetBankResponse>}
   * @memberof PageResponseBankGetBankResponse
   */
  content?: Array<GetBankResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseBankGetBankResponse
   */
  totalPages?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseBankGetBankResponse
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseBankGetBankResponse
   */
  numberOfElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseBankGetBankResponse
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseBankGetBankResponse
   */
  number?: number;
}
/**
 *
 * @export
 * @interface PageResponseDeviceExternalDeviceResponse
 */
export interface PageResponseDeviceExternalDeviceResponse {
  /**
   *
   * @type {Array<ExternalDeviceResponse>}
   * @memberof PageResponseDeviceExternalDeviceResponse
   */
  content?: Array<ExternalDeviceResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceExternalDeviceResponse
   */
  totalPages?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceExternalDeviceResponse
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceExternalDeviceResponse
   */
  numberOfElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceExternalDeviceResponse
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceExternalDeviceResponse
   */
  number?: number;
}
/**
 *
 * @export
 * @interface PageResponseDeviceOAResponse
 */
export interface PageResponseDeviceOAResponse {
  /**
   *
   * @type {Array<DeviceOAResponse>}
   * @memberof PageResponseDeviceOAResponse
   */
  items?: Array<DeviceOAResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceOAResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceOAResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceOAResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceOAResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseDeviceResponse
 */
export interface PageResponseDeviceResponse {
  /**
   *
   * @type {Array<DeviceResponse>}
   * @memberof PageResponseDeviceResponse
   */
  items?: Array<DeviceResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseDeviceWLResponse
 */
export interface PageResponseDeviceWLResponse {
  /**
   *
   * @type {Array<DeviceWLResponse>}
   * @memberof PageResponseDeviceWLResponse
   */
  items?: Array<DeviceWLResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceWLResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceWLResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceWLResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceWLResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseExternalQrResponse
 */
export interface PageResponseExternalQrResponse {
  /**
   *
   * @type {Array<ExternalQrResponse>}
   * @memberof PageResponseExternalQrResponse
   */
  content?: Array<ExternalQrResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseExternalQrResponse
   */
  totalPages?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseExternalQrResponse
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseExternalQrResponse
   */
  numberOfElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseExternalQrResponse
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseExternalQrResponse
   */
  number?: number;
}
/**
 *
 * @export
 * @interface PageResponseGetListTransactionByDeviceResponse
 */
export interface PageResponseGetListTransactionByDeviceResponse {
  /**
   *
   * @type {Array<GetListTransactionByDeviceResponse>}
   * @memberof PageResponseGetListTransactionByDeviceResponse
   */
  items?: Array<GetListTransactionByDeviceResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetListTransactionByDeviceResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetListTransactionByDeviceResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetListTransactionByDeviceResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetListTransactionByDeviceResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseGetTransactionsResponse
 */
export interface PageResponseGetTransactionsResponse {
  /**
   *
   * @type {Array<GetTransactionsResponse>}
   * @memberof PageResponseGetTransactionsResponse
   */
  items?: Array<GetTransactionsResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetTransactionsResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetTransactionsResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetTransactionsResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetTransactionsResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseStaticQrStaticQrResponse
 */
export interface PageResponseStaticQrStaticQrResponse {
  /**
   *
   * @type {Array<StaticQrResponse>}
   * @memberof PageResponseStaticQrStaticQrResponse
   */
  content?: Array<StaticQrResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseStaticQrStaticQrResponse
   */
  totalPages?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseStaticQrStaticQrResponse
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseStaticQrStaticQrResponse
   */
  numberOfElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseStaticQrStaticQrResponse
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseStaticQrStaticQrResponse
   */
  number?: number;
}
/**
 *
 * @export
 * @interface PageResponseTransactionExternalTransactionResponse
 */
export interface PageResponseTransactionExternalTransactionResponse {
  /**
   *
   * @type {Array<ExternalTransactionResponse>}
   * @memberof PageResponseTransactionExternalTransactionResponse
   */
  content?: Array<ExternalTransactionResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseTransactionExternalTransactionResponse
   */
  totalPages?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseTransactionExternalTransactionResponse
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseTransactionExternalTransactionResponse
   */
  numberOfElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseTransactionExternalTransactionResponse
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseTransactionExternalTransactionResponse
   */
  number?: number;
}
/**
 *
 * @export
 * @interface PageResponseVersionVersionResponse
 */
export interface PageResponseVersionVersionResponse {
  /**
   *
   * @type {Array<VersionResponse>}
   * @memberof PageResponseVersionVersionResponse
   */
  content?: Array<VersionResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseVersionVersionResponse
   */
  totalPages?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseVersionVersionResponse
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseVersionVersionResponse
   */
  numberOfElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseVersionVersionResponse
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseVersionVersionResponse
   */
  number?: number;
}
/**
 *
 * @export
 * @interface Pageable
 */
export interface Pageable {
  /**
   *
   * @type {number}
   * @memberof Pageable
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof Pageable
   */
  size?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Pageable
   */
  sort?: Array<string>;
}
/**
 *
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
  /**
   *
   * @type {number}
   * @memberof PageableObject
   */
  offset?: number;
  /**
   *
   * @type {SortObject}
   * @memberof PageableObject
   */
  sort?: SortObject;
  /**
   *
   * @type {number}
   * @memberof PageableObject
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageableObject
   */
  pageSize?: number;
  /**
   *
   * @type {boolean}
   * @memberof PageableObject
   */
  paged?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PageableObject
   */
  unpaged?: boolean;
}
/**
 *
 * @export
 * @interface PaginationAndSortingRequest
 */
export interface PaginationAndSortingRequest {
  /**
   *
   * @type {number}
   * @memberof PaginationAndSortingRequest
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PaginationAndSortingRequest
   */
  page?: number;
  /**
   *
   * @type {string}
   * @memberof PaginationAndSortingRequest
   */
  sortBy?: string;
  /**
   *
   * @type {string}
   * @memberof PaginationAndSortingRequest
   */
  sortDir?: string;
}
/**
 *
 * @export
 * @interface PaymentData
 */
export interface PaymentData {
  /**
   *
   * @type {string}
   * @memberof PaymentData
   */
  referenceNumber?: string;
}
/**
 *
 * @export
 * @interface PreviewQrRequest
 */
export interface PreviewQrRequest {
  /**
   *
   * @type {string}
   * @memberof PreviewQrRequest
   */
  staticQrId?: string;
  /**
   *
   * @type {string}
   * @memberof PreviewQrRequest
   */
  bankId?: string;
  /**
   *
   * @type {string}
   * @memberof PreviewQrRequest
   */
  qrCode?: string;
  /**
   *
   * @type {string}
   * @memberof PreviewQrRequest
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof PreviewQrRequest
   */
  bankAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof PreviewQrRequest
   */
  bankAccountOwner?: string;
  /**
   *
   * @type {string}
   * @memberof PreviewQrRequest
   */
  shopName?: string;
  /**
   *
   * @type {string}
   * @memberof PreviewQrRequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof PreviewQrRequest
   */
  type?: PreviewQrRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PreviewQrRequest
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof PreviewQrRequest
   */
  qrContent?: string;
  /**
   *
   * @type {string}
   * @memberof PreviewQrRequest
   */
  model?: string;
}

export const PreviewQrRequestTypeEnum = {
  Bank: "BANK",
  EWallet: "E_WALLET",
  Umee: "UMEE",
  Klb: "KLB",
} as const;

export type PreviewQrRequestTypeEnum =
  (typeof PreviewQrRequestTypeEnum)[keyof typeof PreviewQrRequestTypeEnum];

/**
 *
 * @export
 * @interface PreviewQrResponse
 */
export interface PreviewQrResponse {
  /**
   *
   * @type {string}
   * @memberof PreviewQrResponse
   */
  preview?: string;
}
/**
 *
 * @export
 * @interface RefreshTokenRequest
 */
export interface RefreshTokenRequest {
  /**
   *
   * @type {string}
   * @memberof RefreshTokenRequest
   */
  refreshToken?: string;
}
/**
 *
 * @export
 * @interface RegisterDeviceRequest
 */
export interface RegisterDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  manToken: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  hwVersion?: string;
}
/**
 *
 * @export
 * @interface RegisterDeviceResponse
 */
export interface RegisterDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceResponse
   */
  hwVersion?: string;
}
/**
 *
 * @export
 * @interface Resource
 */
export interface Resource {
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  spaceId?: string;
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  tenantId?: string;
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  createBy?: string;
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  createDate?: string;
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  updateBy?: string;
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  updateDate?: string;
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  previewQrBackground?: string;
  /**
   *
   * @type {boolean}
   * @memberof Resource
   */
  enableApiKey?: boolean;
  /**
   *
   * @type {number}
   * @memberof Resource
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  vietQrLogo?: string;
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  payboxResourcePath?: string;
}
/**
 *
 * @export
 * @interface ResponseBaseAccessTokenResponse
 */
export interface ResponseBaseAccessTokenResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAccessTokenResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAccessTokenResponse
   */
  message?: string;
  /**
   *
   * @type {AccessTokenResponse}
   * @memberof ResponseBaseAccessTokenResponse
   */
  data?: AccessTokenResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseAddEWalletResponse
 */
export interface ResponseBaseAddEWalletResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAddEWalletResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAddEWalletResponse
   */
  message?: string;
  /**
   *
   * @type {AddEWalletResponse}
   * @memberof ResponseBaseAddEWalletResponse
   */
  data?: AddEWalletResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseAdvertisementResponse
 */
export interface ResponseBaseAdvertisementResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAdvertisementResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAdvertisementResponse
   */
  message?: string;
  /**
   *
   * @type {AdvertisementResponse}
   * @memberof ResponseBaseAdvertisementResponse
   */
  data?: AdvertisementResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseAuditVersionResponse
 */
export interface ResponseBaseAuditVersionResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAuditVersionResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAuditVersionResponse
   */
  message?: string;
  /**
   *
   * @type {AuditVersionResponse}
   * @memberof ResponseBaseAuditVersionResponse
   */
  data?: AuditVersionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseBankHubNotifyResponse
 */
export interface ResponseBaseBankHubNotifyResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseBankHubNotifyResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseBankHubNotifyResponse
   */
  message?: string;
  /**
   *
   * @type {BankHubNotifyResponse}
   * @memberof ResponseBaseBankHubNotifyResponse
   */
  data?: BankHubNotifyResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCancelTransactionResponse
 */
export interface ResponseBaseCancelTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCancelTransactionResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCancelTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {CancelTransactionResponse}
   * @memberof ResponseBaseCancelTransactionResponse
   */
  data?: CancelTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCheckBankAccountResponse
 */
export interface ResponseBaseCheckBankAccountResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckBankAccountResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckBankAccountResponse
   */
  message?: string;
  /**
   *
   * @type {CheckBankAccountResponse}
   * @memberof ResponseBaseCheckBankAccountResponse
   */
  data?: CheckBankAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCheckDeviceOAResponse
 */
export interface ResponseBaseCheckDeviceOAResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckDeviceOAResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckDeviceOAResponse
   */
  message?: string;
  /**
   *
   * @type {CheckDeviceOAResponse}
   * @memberof ResponseBaseCheckDeviceOAResponse
   */
  data?: CheckDeviceOAResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCheckDeviceResponse
 */
export interface ResponseBaseCheckDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckDeviceResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {CheckDeviceResponse}
   * @memberof ResponseBaseCheckDeviceResponse
   */
  data?: CheckDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCheckDeviceWLResponse
 */
export interface ResponseBaseCheckDeviceWLResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckDeviceWLResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckDeviceWLResponse
   */
  message?: string;
  /**
   *
   * @type {CheckDeviceWLResponse}
   * @memberof ResponseBaseCheckDeviceWLResponse
   */
  data?: CheckDeviceWLResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCheckEmailIfExistedResponse
 */
export interface ResponseBaseCheckEmailIfExistedResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckEmailIfExistedResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckEmailIfExistedResponse
   */
  message?: string;
  /**
   *
   * @type {CheckEmailIfExistedResponse}
   * @memberof ResponseBaseCheckEmailIfExistedResponse
   */
  data?: CheckEmailIfExistedResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCheckForgotPasscodeV2Response
 */
export interface ResponseBaseCheckForgotPasscodeV2Response {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckForgotPasscodeV2Response
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckForgotPasscodeV2Response
   */
  message?: string;
  /**
   *
   * @type {CheckForgotPasscodeV2Response}
   * @memberof ResponseBaseCheckForgotPasscodeV2Response
   */
  data?: CheckForgotPasscodeV2Response;
}
/**
 *
 * @export
 * @interface ResponseBaseCheckPhoneIfExistedResponse
 */
export interface ResponseBaseCheckPhoneIfExistedResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckPhoneIfExistedResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckPhoneIfExistedResponse
   */
  message?: string;
  /**
   *
   * @type {CheckPhoneIfExistedResponse}
   * @memberof ResponseBaseCheckPhoneIfExistedResponse
   */
  data?: CheckPhoneIfExistedResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCheckSetupBankAccountResponse
 */
export interface ResponseBaseCheckSetupBankAccountResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckSetupBankAccountResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckSetupBankAccountResponse
   */
  message?: string;
  /**
   *
   * @type {CheckSetupBankAccountResponse}
   * @memberof ResponseBaseCheckSetupBankAccountResponse
   */
  data?: CheckSetupBankAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseConfigResourceResponse
 */
export interface ResponseBaseConfigResourceResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseConfigResourceResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseConfigResourceResponse
   */
  message?: string;
  /**
   *
   * @type {ConfigResourceResponse}
   * @memberof ResponseBaseConfigResourceResponse
   */
  data?: ConfigResourceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCreateManufacturerTokenResponse
 */
export interface ResponseBaseCreateManufacturerTokenResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCreateManufacturerTokenResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCreateManufacturerTokenResponse
   */
  message?: string;
  /**
   *
   * @type {CreateManufacturerTokenResponse}
   * @memberof ResponseBaseCreateManufacturerTokenResponse
   */
  data?: CreateManufacturerTokenResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDeleteAdvertisementResponse
 */
export interface ResponseBaseDeleteAdvertisementResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteAdvertisementResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteAdvertisementResponse
   */
  message?: string;
  /**
   *
   * @type {DeleteAdvertisementResponse}
   * @memberof ResponseBaseDeleteAdvertisementResponse
   */
  data?: DeleteAdvertisementResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDeleteBankResponse
 */
export interface ResponseBaseDeleteBankResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteBankResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteBankResponse
   */
  message?: string;
  /**
   *
   * @type {DeleteBankResponse}
   * @memberof ResponseBaseDeleteBankResponse
   */
  data?: DeleteBankResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDeleteDeviceResponse
 */
export interface ResponseBaseDeleteDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteDeviceResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {DeleteDeviceResponse}
   * @memberof ResponseBaseDeleteDeviceResponse
   */
  data?: DeleteDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDeleteStaticQrByIdResponse
 */
export interface ResponseBaseDeleteStaticQrByIdResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteStaticQrByIdResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteStaticQrByIdResponse
   */
  message?: string;
  /**
   *
   * @type {DeleteStaticQrByIdResponse}
   * @memberof ResponseBaseDeleteStaticQrByIdResponse
   */
  data?: DeleteStaticQrByIdResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDeleteVersionResponse
 */
export interface ResponseBaseDeleteVersionResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteVersionResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteVersionResponse
   */
  message?: string;
  /**
   *
   * @type {DeleteVersionResponse}
   * @memberof ResponseBaseDeleteVersionResponse
   */
  data?: DeleteVersionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDetailTransactionResponse
 */
export interface ResponseBaseDetailTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDetailTransactionResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDetailTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {DetailTransactionResponse}
   * @memberof ResponseBaseDetailTransactionResponse
   */
  data?: DetailTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDeviceDetailOAResponse
 */
export interface ResponseBaseDeviceDetailOAResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeviceDetailOAResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeviceDetailOAResponse
   */
  message?: string;
  /**
   *
   * @type {DeviceDetailOAResponse}
   * @memberof ResponseBaseDeviceDetailOAResponse
   */
  data?: DeviceDetailOAResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDeviceDetailWLResponse
 */
export interface ResponseBaseDeviceDetailWLResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeviceDetailWLResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeviceDetailWLResponse
   */
  message?: string;
  /**
   *
   * @type {DeviceDetailWLResponse}
   * @memberof ResponseBaseDeviceDetailWLResponse
   */
  data?: DeviceDetailWLResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDeviceResponse
 */
export interface ResponseBaseDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeviceResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {DeviceResponse}
   * @memberof ResponseBaseDeviceResponse
   */
  data?: DeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDeviceWLResponse
 */
export interface ResponseBaseDeviceWLResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeviceWLResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeviceWLResponse
   */
  message?: string;
  /**
   *
   * @type {DeviceWLResponse}
   * @memberof ResponseBaseDeviceWLResponse
   */
  data?: DeviceWLResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseExtensionPathResponse
 */
export interface ResponseBaseExtensionPathResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseExtensionPathResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseExtensionPathResponse
   */
  message?: string;
  /**
   *
   * @type {ExtensionPathResponse}
   * @memberof ResponseBaseExtensionPathResponse
   */
  data?: ExtensionPathResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseExternalCreateTransactionResponse
 */
export interface ResponseBaseExternalCreateTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseExternalCreateTransactionResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseExternalCreateTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {ExternalCreateTransactionResponse}
   * @memberof ResponseBaseExternalCreateTransactionResponse
   */
  data?: ExternalCreateTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseExternalDeviceResponse
 */
export interface ResponseBaseExternalDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseExternalDeviceResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseExternalDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {ExternalDeviceResponse}
   * @memberof ResponseBaseExternalDeviceResponse
   */
  data?: ExternalDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseExternalQrDetailResponse
 */
export interface ResponseBaseExternalQrDetailResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseExternalQrDetailResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseExternalQrDetailResponse
   */
  message?: string;
  /**
   *
   * @type {ExternalQrDetailResponse}
   * @memberof ResponseBaseExternalQrDetailResponse
   */
  data?: ExternalQrDetailResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseExternalTransactionResponse
 */
export interface ResponseBaseExternalTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseExternalTransactionResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseExternalTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {ExternalTransactionResponse}
   * @memberof ResponseBaseExternalTransactionResponse
   */
  data?: ExternalTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseForceUpdateResponse
 */
export interface ResponseBaseForceUpdateResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseForceUpdateResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseForceUpdateResponse
   */
  message?: string;
  /**
   *
   * @type {ForceUpdateResponse}
   * @memberof ResponseBaseForceUpdateResponse
   */
  data?: ForceUpdateResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGenerateQrResponse
 */
export interface ResponseBaseGenerateQrResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGenerateQrResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGenerateQrResponse
   */
  message?: string;
  /**
   *
   * @type {GenerateQrResponse}
   * @memberof ResponseBaseGenerateQrResponse
   */
  data?: GenerateQrResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetAllAdvertisementResponse
 */
export interface ResponseBaseGetAllAdvertisementResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllAdvertisementResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllAdvertisementResponse
   */
  message?: string;
  /**
   *
   * @type {GetAllAdvertisementResponse}
   * @memberof ResponseBaseGetAllAdvertisementResponse
   */
  data?: GetAllAdvertisementResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetAllBanksResponse
 */
export interface ResponseBaseGetAllBanksResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllBanksResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllBanksResponse
   */
  message?: string;
  /**
   *
   * @type {GetAllBanksResponse}
   * @memberof ResponseBaseGetAllBanksResponse
   */
  data?: GetAllBanksResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetAllStaticQrResponse
 */
export interface ResponseBaseGetAllStaticQrResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllStaticQrResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllStaticQrResponse
   */
  message?: string;
  /**
   *
   * @type {GetAllStaticQrResponse}
   * @memberof ResponseBaseGetAllStaticQrResponse
   */
  data?: GetAllStaticQrResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetApiKeyResponse
 */
export interface ResponseBaseGetApiKeyResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetApiKeyResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetApiKeyResponse
   */
  message?: string;
  /**
   *
   * @type {GetApiKeyResponse}
   * @memberof ResponseBaseGetApiKeyResponse
   */
  data?: GetApiKeyResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetLatestVersionResponse
 */
export interface ResponseBaseGetLatestVersionResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetLatestVersionResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetLatestVersionResponse
   */
  message?: string;
  /**
   *
   * @type {GetLatestVersionResponse}
   * @memberof ResponseBaseGetLatestVersionResponse
   */
  data?: GetLatestVersionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetMyShopAccountResponse
 */
export interface ResponseBaseGetMyShopAccountResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetMyShopAccountResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetMyShopAccountResponse
   */
  message?: string;
  /**
   *
   * @type {GetMyShopAccountResponse}
   * @memberof ResponseBaseGetMyShopAccountResponse
   */
  data?: GetMyShopAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetOTPResponse
 */
export interface ResponseBaseGetOTPResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetOTPResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetOTPResponse
   */
  message?: string;
  /**
   *
   * @type {GetOTPResponse}
   * @memberof ResponseBaseGetOTPResponse
   */
  data?: GetOTPResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetResourceResponse
 */
export interface ResponseBaseGetResourceResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetResourceResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetResourceResponse
   */
  message?: string;
  /**
   *
   * @type {GetResourceResponse}
   * @memberof ResponseBaseGetResourceResponse
   */
  data?: GetResourceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetTotalRevenueResponse
 */
export interface ResponseBaseGetTotalRevenueResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetTotalRevenueResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetTotalRevenueResponse
   */
  message?: string;
  /**
   *
   * @type {GetTotalRevenueResponse}
   * @memberof ResponseBaseGetTotalRevenueResponse
   */
  data?: GetTotalRevenueResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetUserInfoResponse
 */
export interface ResponseBaseGetUserInfoResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetUserInfoResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetUserInfoResponse
   */
  message?: string;
  /**
   *
   * @type {GetUserInfoResponse}
   * @memberof ResponseBaseGetUserInfoResponse
   */
  data?: GetUserInfoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetVersionsResponse
 */
export interface ResponseBaseGetVersionsResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetVersionsResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetVersionsResponse
   */
  message?: string;
  /**
   *
   * @type {GetVersionsResponse}
   * @memberof ResponseBaseGetVersionsResponse
   */
  data?: GetVersionsResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseHandleImageResponse
 */
export interface ResponseBaseHandleImageResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseHandleImageResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseHandleImageResponse
   */
  message?: string;
  /**
   *
   * @type {HandleImageResponse}
   * @memberof ResponseBaseHandleImageResponse
   */
  data?: HandleImageResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseLinkBankAccountResponse
 */
export interface ResponseBaseLinkBankAccountResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLinkBankAccountResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLinkBankAccountResponse
   */
  message?: string;
  /**
   *
   * @type {LinkBankAccountResponse}
   * @memberof ResponseBaseLinkBankAccountResponse
   */
  data?: LinkBankAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseLinkDeviceOAResponse
 */
export interface ResponseBaseLinkDeviceOAResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLinkDeviceOAResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLinkDeviceOAResponse
   */
  message?: string;
  /**
   *
   * @type {LinkDeviceOAResponse}
   * @memberof ResponseBaseLinkDeviceOAResponse
   */
  data?: LinkDeviceOAResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseLinkDeviceResponse
 */
export interface ResponseBaseLinkDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLinkDeviceResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLinkDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {LinkDeviceResponse}
   * @memberof ResponseBaseLinkDeviceResponse
   */
  data?: LinkDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseLinkDeviceWLResponse
 */
export interface ResponseBaseLinkDeviceWLResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLinkDeviceWLResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLinkDeviceWLResponse
   */
  message?: string;
  /**
   *
   * @type {LinkDeviceWLResponse}
   * @memberof ResponseBaseLinkDeviceWLResponse
   */
  data?: LinkDeviceWLResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseLinkWithUserResponse
 */
export interface ResponseBaseLinkWithUserResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLinkWithUserResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLinkWithUserResponse
   */
  message?: string;
  /**
   *
   * @type {LinkWithUserResponse}
   * @memberof ResponseBaseLinkWithUserResponse
   */
  data?: LinkWithUserResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseListExtensionPathResponse
 */
export interface ResponseBaseListExtensionPathResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseListExtensionPathResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseListExtensionPathResponse
   */
  message?: string;
  /**
   *
   * @type {ListExtensionPathResponse}
   * @memberof ResponseBaseListExtensionPathResponse
   */
  data?: ListExtensionPathResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseListResponseGetRevenueOfTopDeviceResponse
 */
export interface ResponseBaseListResponseGetRevenueOfTopDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseListResponseGetRevenueOfTopDeviceResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseListResponseGetRevenueOfTopDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {ListResponseGetRevenueOfTopDeviceResponse}
   * @memberof ResponseBaseListResponseGetRevenueOfTopDeviceResponse
   */
  data?: ListResponseGetRevenueOfTopDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseListResponseGetRevenuesLastFiveResponse
 */
export interface ResponseBaseListResponseGetRevenuesLastFiveResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseListResponseGetRevenuesLastFiveResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseListResponseGetRevenuesLastFiveResponse
   */
  message?: string;
  /**
   *
   * @type {ListResponseGetRevenuesLastFiveResponse}
   * @memberof ResponseBaseListResponseGetRevenuesLastFiveResponse
   */
  data?: ListResponseGetRevenuesLastFiveResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseLogoutResponse
 */
export interface ResponseBaseLogoutResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLogoutResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLogoutResponse
   */
  message?: string;
  /**
   *
   * @type {LogoutResponse}
   * @memberof ResponseBaseLogoutResponse
   */
  data?: LogoutResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseDeviceExternalDeviceResponse
 */
export interface ResponseBasePageResponseDeviceExternalDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseDeviceExternalDeviceResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseDeviceExternalDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseDeviceExternalDeviceResponse}
   * @memberof ResponseBasePageResponseDeviceExternalDeviceResponse
   */
  data?: PageResponseDeviceExternalDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseDeviceOAResponse
 */
export interface ResponseBasePageResponseDeviceOAResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseDeviceOAResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseDeviceOAResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseDeviceOAResponse}
   * @memberof ResponseBasePageResponseDeviceOAResponse
   */
  data?: PageResponseDeviceOAResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseDeviceResponse
 */
export interface ResponseBasePageResponseDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseDeviceResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseDeviceResponse}
   * @memberof ResponseBasePageResponseDeviceResponse
   */
  data?: PageResponseDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseDeviceWLResponse
 */
export interface ResponseBasePageResponseDeviceWLResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseDeviceWLResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseDeviceWLResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseDeviceWLResponse}
   * @memberof ResponseBasePageResponseDeviceWLResponse
   */
  data?: PageResponseDeviceWLResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseExternalQrResponse
 */
export interface ResponseBasePageResponseExternalQrResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseExternalQrResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseExternalQrResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseExternalQrResponse}
   * @memberof ResponseBasePageResponseExternalQrResponse
   */
  data?: PageResponseExternalQrResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseGetListTransactionByDeviceResponse
 */
export interface ResponseBasePageResponseGetListTransactionByDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseGetListTransactionByDeviceResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseGetListTransactionByDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseGetListTransactionByDeviceResponse}
   * @memberof ResponseBasePageResponseGetListTransactionByDeviceResponse
   */
  data?: PageResponseGetListTransactionByDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseGetTransactionsResponse
 */
export interface ResponseBasePageResponseGetTransactionsResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseGetTransactionsResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseGetTransactionsResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseGetTransactionsResponse}
   * @memberof ResponseBasePageResponseGetTransactionsResponse
   */
  data?: PageResponseGetTransactionsResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseTransactionExternalTransactionResponse
 */
export interface ResponseBasePageResponseTransactionExternalTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseTransactionExternalTransactionResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseTransactionExternalTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseTransactionExternalTransactionResponse}
   * @memberof ResponseBasePageResponseTransactionExternalTransactionResponse
   */
  data?: PageResponseTransactionExternalTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePreviewQrResponse
 */
export interface ResponseBasePreviewQrResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBasePreviewQrResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePreviewQrResponse
   */
  message?: string;
  /**
   *
   * @type {PreviewQrResponse}
   * @memberof ResponseBasePreviewQrResponse
   */
  data?: PreviewQrResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseRegisterDeviceResponse
 */
export interface ResponseBaseRegisterDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseRegisterDeviceResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseRegisterDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {RegisterDeviceResponse}
   * @memberof ResponseBaseRegisterDeviceResponse
   */
  data?: RegisterDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseSetApiKeyResponse
 */
export interface ResponseBaseSetApiKeyResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSetApiKeyResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSetApiKeyResponse
   */
  message?: string;
  /**
   *
   * @type {SetApiKeyResponse}
   * @memberof ResponseBaseSetApiKeyResponse
   */
  data?: SetApiKeyResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseSettingVerifyUserResponse
 */
export interface ResponseBaseSettingVerifyUserResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSettingVerifyUserResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSettingVerifyUserResponse
   */
  message?: string;
  /**
   *
   * @type {SettingVerifyUserResponse}
   * @memberof ResponseBaseSettingVerifyUserResponse
   */
  data?: SettingVerifyUserResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseStaticQrResponse
 */
export interface ResponseBaseStaticQrResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseStaticQrResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseStaticQrResponse
   */
  message?: string;
  /**
   *
   * @type {StaticQrResponse}
   * @memberof ResponseBaseStaticQrResponse
   */
  data?: StaticQrResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseStatusResponse
 */
export interface ResponseBaseStatusResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseStatusResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseStatusResponse
   */
  message?: string;
  /**
   *
   * @type {StatusResponse}
   * @memberof ResponseBaseStatusResponse
   */
  data?: StatusResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseSyncVietQrResponse
 */
export interface ResponseBaseSyncVietQrResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSyncVietQrResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSyncVietQrResponse
   */
  message?: string;
  /**
   *
   * @type {SyncVietQrResponse}
   * @memberof ResponseBaseSyncVietQrResponse
   */
  data?: SyncVietQrResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseTransactionOAResponse
 */
export interface ResponseBaseTransactionOAResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseTransactionOAResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseTransactionOAResponse
   */
  message?: string;
  /**
   *
   * @type {TransactionOAResponse}
   * @memberof ResponseBaseTransactionOAResponse
   */
  data?: TransactionOAResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseTransactionResponse
 */
export interface ResponseBaseTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseTransactionResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {TransactionResponse}
   * @memberof ResponseBaseTransactionResponse
   */
  data?: TransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseTransactionWLResponse
 */
export interface ResponseBaseTransactionWLResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseTransactionWLResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseTransactionWLResponse
   */
  message?: string;
  /**
   *
   * @type {TransactionWLResponse}
   * @memberof ResponseBaseTransactionWLResponse
   */
  data?: TransactionWLResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUpdateApiKeyStatusResponse
 */
export interface ResponseBaseUpdateApiKeyStatusResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUpdateApiKeyStatusResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUpdateApiKeyStatusResponse
   */
  message?: string;
  /**
   *
   * @type {UpdateApiKeyStatusResponse}
   * @memberof ResponseBaseUpdateApiKeyStatusResponse
   */
  data?: UpdateApiKeyStatusResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUpdateBankResponse
 */
export interface ResponseBaseUpdateBankResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUpdateBankResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUpdateBankResponse
   */
  message?: string;
  /**
   *
   * @type {UpdateBankResponse}
   * @memberof ResponseBaseUpdateBankResponse
   */
  data?: UpdateBankResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUpdateDeviceResponse
 */
export interface ResponseBaseUpdateDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUpdateDeviceResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUpdateDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {UpdateDeviceResponse}
   * @memberof ResponseBaseUpdateDeviceResponse
   */
  data?: UpdateDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUpdateLogoResponse
 */
export interface ResponseBaseUpdateLogoResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUpdateLogoResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUpdateLogoResponse
   */
  message?: string;
  /**
   *
   * @type {UpdateLogoResponse}
   * @memberof ResponseBaseUpdateLogoResponse
   */
  data?: UpdateLogoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUpdateUserResponse
 */
export interface ResponseBaseUpdateUserResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUpdateUserResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUpdateUserResponse
   */
  message?: string;
  /**
   *
   * @type {UpdateUserResponse}
   * @memberof ResponseBaseUpdateUserResponse
   */
  data?: UpdateUserResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUserRegisterResponse
 */
export interface ResponseBaseUserRegisterResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUserRegisterResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUserRegisterResponse
   */
  message?: string;
  /**
   *
   * @type {UserRegisterResponse}
   * @memberof ResponseBaseUserRegisterResponse
   */
  data?: UserRegisterResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUserResponse
 */
export interface ResponseBaseUserResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUserResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUserResponse
   */
  message?: string;
  /**
   *
   * @type {UserResponse}
   * @memberof ResponseBaseUserResponse
   */
  data?: UserResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseV2UserRegisterResponse
 */
export interface ResponseBaseV2UserRegisterResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseV2UserRegisterResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseV2UserRegisterResponse
   */
  message?: string;
  /**
   *
   * @type {V2UserRegisterResponse}
   * @memberof ResponseBaseV2UserRegisterResponse
   */
  data?: V2UserRegisterResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseVerifyBankAccountPhoneResponse
 */
export interface ResponseBaseVerifyBankAccountPhoneResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVerifyBankAccountPhoneResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVerifyBankAccountPhoneResponse
   */
  message?: string;
  /**
   *
   * @type {VerifyBankAccountPhoneResponse}
   * @memberof ResponseBaseVerifyBankAccountPhoneResponse
   */
  data?: VerifyBankAccountPhoneResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseVerifyBankAccountResponse
 */
export interface ResponseBaseVerifyBankAccountResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVerifyBankAccountResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVerifyBankAccountResponse
   */
  message?: string;
  /**
   *
   * @type {VerifyBankAccountResponse}
   * @memberof ResponseBaseVerifyBankAccountResponse
   */
  data?: VerifyBankAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseVerifyEmailResponse
 */
export interface ResponseBaseVerifyEmailResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVerifyEmailResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVerifyEmailResponse
   */
  message?: string;
  /**
   *
   * @type {VerifyEmailResponse}
   * @memberof ResponseBaseVerifyEmailResponse
   */
  data?: VerifyEmailResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseVerifyLinkDeviceOAResponse
 */
export interface ResponseBaseVerifyLinkDeviceOAResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVerifyLinkDeviceOAResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVerifyLinkDeviceOAResponse
   */
  message?: string;
  /**
   *
   * @type {VerifyLinkDeviceOAResponse}
   * @memberof ResponseBaseVerifyLinkDeviceOAResponse
   */
  data?: VerifyLinkDeviceOAResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseVerifyLinkDeviceWLResponse
 */
export interface ResponseBaseVerifyLinkDeviceWLResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVerifyLinkDeviceWLResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVerifyLinkDeviceWLResponse
   */
  message?: string;
  /**
   *
   * @type {VerifyLinkDeviceWLResponse}
   * @memberof ResponseBaseVerifyLinkDeviceWLResponse
   */
  data?: VerifyLinkDeviceWLResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseVerifyOTPResponse
 */
export interface ResponseBaseVerifyOTPResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVerifyOTPResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVerifyOTPResponse
   */
  message?: string;
  /**
   *
   * @type {VerifyOTPResponse}
   * @memberof ResponseBaseVerifyOTPResponse
   */
  data?: VerifyOTPResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseVersionResponse
 */
export interface ResponseBaseVersionResponse {
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVersionResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVersionResponse
   */
  message?: string;
  /**
   *
   * @type {VersionResponse}
   * @memberof ResponseBaseVersionResponse
   */
  data?: VersionResponse;
}
/**
 *
 * @export
 * @interface RootAdminLoginRequest
 */
export interface RootAdminLoginRequest {
  /**
   *
   * @type {string}
   * @memberof RootAdminLoginRequest
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof RootAdminLoginRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface SendEmailRequest
 */
export interface SendEmailRequest {
  /**
   *
   * @type {string}
   * @memberof SendEmailRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof SendEmailRequest
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof SendEmailRequest
   */
  recipientName?: string;
  /**
   *
   * @type {string}
   * @memberof SendEmailRequest
   */
  senderName?: string;
  /**
   *
   * @type {string}
   * @memberof SendEmailRequest
   */
  subject?: string;
}
/**
 *
 * @export
 * @interface SetApiKeyRequest
 */
export interface SetApiKeyRequest {
  /**
   *
   * @type {string}
   * @memberof SetApiKeyRequest
   */
  client?: string;
  /**
   *
   * @type {string}
   * @memberof SetApiKeyRequest
   */
  key?: string;
}
/**
 *
 * @export
 * @interface SetApiKeyResponse
 */
export interface SetApiKeyResponse {
  /**
   *
   * @type {boolean}
   * @memberof SetApiKeyResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface SettingVerifyUserResponse
 */
export interface SettingVerifyUserResponse {
  /**
   *
   * @type {string}
   * @memberof SettingVerifyUserResponse
   */
  sessionId?: string;
}
/**
 *
 * @export
 * @interface ShowDynamicQrOARequest
 */
export interface ShowDynamicQrOARequest {
  /**
   *
   * @type {string}
   * @memberof ShowDynamicQrOARequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof ShowDynamicQrOARequest
   */
  beneficialNo: string;
  /**
   *
   * @type {string}
   * @memberof ShowDynamicQrOARequest
   */
  accountName: string;
  /**
   *
   * @type {number}
   * @memberof ShowDynamicQrOARequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof ShowDynamicQrOARequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof ShowDynamicQrOARequest
   */
  bankBin: string;
  /**
   *
   * @type {number}
   * @memberof ShowDynamicQrOARequest
   */
  expireTime?: number;
}
/**
 *
 * @export
 * @interface ShowDynamicQrWLRequest
 */
export interface ShowDynamicQrWLRequest {
  /**
   *
   * @type {string}
   * @memberof ShowDynamicQrWLRequest
   */
  beneficialNo: string;
  /**
   *
   * @type {string}
   * @memberof ShowDynamicQrWLRequest
   */
  accountName: string;
  /**
   *
   * @type {number}
   * @memberof ShowDynamicQrWLRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof ShowDynamicQrWLRequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof ShowDynamicQrWLRequest
   */
  bankBin: string;
  /**
   *
   * @type {number}
   * @memberof ShowDynamicQrWLRequest
   */
  expireTime?: number;
}
/**
 *
 * @export
 * @interface ShowStaticQrOARequest
 */
export interface ShowStaticQrOARequest {
  /**
   *
   * @type {string}
   * @memberof ShowStaticQrOARequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof ShowStaticQrOARequest
   */
  beneficialNo: string;
  /**
   *
   * @type {string}
   * @memberof ShowStaticQrOARequest
   */
  accountName: string;
  /**
   *
   * @type {number}
   * @memberof ShowStaticQrOARequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof ShowStaticQrOARequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof ShowStaticQrOARequest
   */
  bankBin: string;
}
/**
 *
 * @export
 * @interface ShowStaticQrWLRequest
 */
export interface ShowStaticQrWLRequest {
  /**
   *
   * @type {string}
   * @memberof ShowStaticQrWLRequest
   */
  beneficialNo: string;
  /**
   *
   * @type {string}
   * @memberof ShowStaticQrWLRequest
   */
  accountName: string;
  /**
   *
   * @type {number}
   * @memberof ShowStaticQrWLRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof ShowStaticQrWLRequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof ShowStaticQrWLRequest
   */
  bankBin: string;
}
/**
 *
 * @export
 * @interface SimpleLoginRequest
 */
export interface SimpleLoginRequest {
  /**
   *
   * @type {string}
   * @memberof SimpleLoginRequest
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof SimpleLoginRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface SortObject
 */
export interface SortObject {
  /**
   *
   * @type {boolean}
   * @memberof SortObject
   */
  empty?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SortObject
   */
  sorted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SortObject
   */
  unsorted?: boolean;
}
/**
 *
 * @export
 * @interface StaticQrResponse
 */
export interface StaticQrResponse {
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  qrCode?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  accountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  accountOwner?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  shopName?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  bankLogo?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  bankId?: string;
  /**
   *
   * @type {boolean}
   * @memberof StaticQrResponse
   */
  isDefault?: boolean;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  phone?: string;
}
/**
 *
 * @export
 * @interface StatusResponse
 */
export interface StatusResponse {
  /**
   *
   * @type {boolean}
   * @memberof StatusResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface SubmitLinkDeviceOARequest
 */
export interface SubmitLinkDeviceOARequest {
  /**
   *
   * @type {string}
   * @memberof SubmitLinkDeviceOARequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof SubmitLinkDeviceOARequest
   */
  displayName?: string;
  /**
   *
   * @type {string}
   * @memberof SubmitLinkDeviceOARequest
   */
  refUserId?: string;
}
/**
 *
 * @export
 * @interface SubmitLinkDeviceRequest
 */
export interface SubmitLinkDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof SubmitLinkDeviceRequest
   */
  hardwareId: string;
}
/**
 *
 * @export
 * @interface SubmitLinkDeviceWLRequest
 */
export interface SubmitLinkDeviceWLRequest {
  /**
   *
   * @type {string}
   * @memberof SubmitLinkDeviceWLRequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof SubmitLinkDeviceWLRequest
   */
  displayName?: string;
  /**
   *
   * @type {string}
   * @memberof SubmitLinkDeviceWLRequest
   */
  refUserId?: string;
}
/**
 *
 * @export
 * @interface SyncVietQrResponse
 */
export interface SyncVietQrResponse {
  /**
   *
   * @type {boolean}
   * @memberof SyncVietQrResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface Transaction
 */
export interface Transaction {
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  transactionCode?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  reference?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  transactionDate?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  transactionDateTime?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  bookingDate?: string;
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  runningBalance?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  accountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  virtualAccountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  virtualAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  paymentChannel?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  counterAccountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  counterAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  counterAccountBankId?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  counterAccountBankName?: string;
  /**
   *
   * @type {PaymentData}
   * @memberof Transaction
   */
  paymentMeta?: PaymentData;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  referenceNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  fiId?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  fiName?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  fiServiceId?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  fiServiceName?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  currency?: string;
}
/**
 *
 * @export
 * @interface TransactionOAResponse
 */
export interface TransactionOAResponse {
  /**
   *
   * @type {string}
   * @memberof TransactionOAResponse
   */
  transactionId?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionOAResponse
   */
  traceId?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionOAResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionOAResponse
   */
  transactionTime?: string;
}
/**
 *
 * @export
 * @interface TransactionResponse
 */
export interface TransactionResponse {
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  qrContent?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  status?: TransactionResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  type?: TransactionResponseTypeEnum;
  /**
   *
   * @type {number}
   * @memberof TransactionResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  createTime?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  bin?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  virtualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  actualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  content?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionResponse
   */
  millisecond?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  txnNumber?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  traceId?: string;
}

export const TransactionResponseStatusEnum = {
  None: "NONE",
  Pending: "PENDING",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
} as const;

export type TransactionResponseStatusEnum =
  (typeof TransactionResponseStatusEnum)[keyof typeof TransactionResponseStatusEnum];
export const TransactionResponseTypeEnum = {
  Klb: "KLB",
  Paybox: "PAYBOX",
  Pos: "POS",
  Usee: "USEE",
  PartnerService: "PARTNER_SERVICE",
} as const;

export type TransactionResponseTypeEnum =
  (typeof TransactionResponseTypeEnum)[keyof typeof TransactionResponseTypeEnum];

/**
 *
 * @export
 * @interface TransactionWLResponse
 */
export interface TransactionWLResponse {
  /**
   *
   * @type {string}
   * @memberof TransactionWLResponse
   */
  transactionId?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionWLResponse
   */
  traceId?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionWLResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionWLResponse
   */
  transactionTime?: string;
}
/**
 *
 * @export
 * @interface UnLinkBankAccountRequest
 */
export interface UnLinkBankAccountRequest {
  /**
   *
   * @type {string}
   * @memberof UnLinkBankAccountRequest
   */
  deviceId: string;
}
/**
 *
 * @export
 * @interface UnlinkDeviceOARequest
 */
export interface UnlinkDeviceOARequest {
  /**
   *
   * @type {string}
   * @memberof UnlinkDeviceOARequest
   */
  hardwareId: string;
}
/**
 *
 * @export
 * @interface UnlinkWithUserV2Request
 */
export interface UnlinkWithUserV2Request {
  /**
   *
   * @type {string}
   * @memberof UnlinkWithUserV2Request
   */
  deviceId: string;
}
/**
 *
 * @export
 * @interface UpdateAdvertisementRequest
 */
export interface UpdateAdvertisementRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateAdvertisementRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateAdvertisementRequest
   */
  image?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateAdvertisementRequest
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateAdvertisementRequest
   */
  type?: string;
}
/**
 *
 * @export
 * @interface UpdateApiKeyStatusRequest
 */
export interface UpdateApiKeyStatusRequest {
  /**
   *
   * @type {boolean}
   * @memberof UpdateApiKeyStatusRequest
   */
  enableApiKey?: boolean;
}
/**
 *
 * @export
 * @interface UpdateApiKeyStatusResponse
 */
export interface UpdateApiKeyStatusResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateApiKeyStatusResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface UpdateBankRequest
 */
export interface UpdateBankRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateBankRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBankRequest
   */
  shortName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBankRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBankRequest
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBankRequest
   */
  smallLogo?: string;
}
/**
 *
 * @export
 * @interface UpdateBankResponse
 */
export interface UpdateBankResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateBankResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface UpdateDeviceRequest
 */
export interface UpdateDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  displayName?: string;
}
/**
 *
 * @export
 * @interface UpdateDeviceResponse
 */
export interface UpdateDeviceResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateDeviceResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface UpdateLogoResponse
 */
export interface UpdateLogoResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateLogoResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface UpdatePathRequest
 */
export interface UpdatePathRequest {
  /**
   *
   * @type {string}
   * @memberof UpdatePathRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePathRequest
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePathRequest
   */
  value?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePathRequest
   */
  typePath?: UpdatePathRequestTypePathEnum;
}

export const UpdatePathRequestTypePathEnum = {
  Xpath: "XPATH",
  Id: "ID",
} as const;

export type UpdatePathRequestTypePathEnum =
  (typeof UpdatePathRequestTypePathEnum)[keyof typeof UpdatePathRequestTypePathEnum];

/**
 *
 * @export
 * @interface UpdateStaticQrRequest
 */
export interface UpdateStaticQrRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateStaticQrRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateStaticQrRequest
   */
  bankId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateStaticQrRequest
   */
  qrCode?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateStaticQrRequest
   */
  accountOwner?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateStaticQrRequest
   */
  accountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateStaticQrRequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateStaticQrRequest
   */
  shopName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateStaticQrRequest
   */
  qrContent?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateStaticQrRequest
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateStaticQrRequest
   */
  isDefault?: boolean;
}
/**
 *
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  bankAccountNo?: string;
  /**
   *
   * @type {CheckOTPRequest}
   * @memberof UpdateUserRequest
   */
  otpRequest: CheckOTPRequest;
}
/**
 *
 * @export
 * @interface UpdateUserResponse
 */
export interface UpdateUserResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface UserRegisterRequest
 */
export interface UserRegisterRequest {
  /**
   *
   * @type {string}
   * @memberof UserRegisterRequest
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof UserRegisterRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof UserRegisterRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegisterRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof UserRegisterRequest
   */
  inviteCode?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegisterRequest
   */
  otpSession: string;
}
/**
 *
 * @export
 * @interface UserRegisterResponse
 */
export interface UserRegisterResponse {
  /**
   *
   * @type {string}
   * @memberof UserRegisterResponse
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegisterResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegisterResponse
   */
  phone?: string;
  /**
   *
   * @type {object}
   * @memberof UserRegisterResponse
   */
  token?: object;
}
/**
 *
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserResponse
   */
  completedEKYC?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  avatar?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserResponse
   */
  inactive?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  bankAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  apiKey?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserResponse
   */
  verifiedMail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserResponse
   */
  verifiedPhone?: boolean;
}
/**
 *
 * @export
 * @interface V2UserRegisterRequest
 */
export interface V2UserRegisterRequest {
  /**
   *
   * @type {string}
   * @memberof V2UserRegisterRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof V2UserRegisterRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface V2UserRegisterResponse
 */
export interface V2UserRegisterResponse {
  /**
   *
   * @type {string}
   * @memberof V2UserRegisterResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof V2UserRegisterResponse
   */
  session?: string;
}
/**
 *
 * @export
 * @interface VerifyBankAccountPhoneRequest
 */
export interface VerifyBankAccountPhoneRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyBankAccountPhoneRequest
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyBankAccountPhoneRequest
   */
  accountNo: string;
  /**
   *
   * @type {string}
   * @memberof VerifyBankAccountPhoneRequest
   */
  accountName: string;
  /**
   *
   * @type {string}
   * @memberof VerifyBankAccountPhoneRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof VerifyBankAccountPhoneRequest
   */
  otp: string;
}
/**
 *
 * @export
 * @interface VerifyBankAccountPhoneResponse
 */
export interface VerifyBankAccountPhoneResponse {
  /**
   *
   * @type {boolean}
   * @memberof VerifyBankAccountPhoneResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface VerifyBankAccountRequest
 */
export interface VerifyBankAccountRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyBankAccountRequest
   */
  bankAccountNo: string;
}
/**
 *
 * @export
 * @interface VerifyBankAccountResponse
 */
export interface VerifyBankAccountResponse {
  /**
   *
   * @type {string}
   * @memberof VerifyBankAccountResponse
   */
  bankAccountFullName?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyBankAccountResponse
   */
  bankAccountPhone?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyBankAccountResponse
   */
  sessionId?: string;
  /**
   *
   * @type {number}
   * @memberof VerifyBankAccountResponse
   */
  countdownTime?: number;
}
/**
 *
 * @export
 * @interface VerifyEmailRegistryRequest
 */
export interface VerifyEmailRegistryRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyEmailRegistryRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof VerifyEmailRegistryRequest
   */
  session: string;
}
/**
 *
 * @export
 * @interface VerifyEmailRequest
 */
export interface VerifyEmailRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyEmailRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof VerifyEmailRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof VerifyEmailRequest
   */
  otp: string;
}
/**
 *
 * @export
 * @interface VerifyEmailResponse
 */
export interface VerifyEmailResponse {
  /**
   *
   * @type {string}
   * @memberof VerifyEmailResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyEmailResponse
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyEmailResponse
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyEmailResponse
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof VerifyEmailResponse
   */
  completedEKYC?: boolean;
  /**
   *
   * @type {string}
   * @memberof VerifyEmailResponse
   */
  avatar?: string;
  /**
   *
   * @type {boolean}
   * @memberof VerifyEmailResponse
   */
  verifiedMail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VerifyEmailResponse
   */
  verifiedPhone?: boolean;
}
/**
 *
 * @export
 * @interface VerifyForgotPasscodeV2Request
 */
export interface VerifyForgotPasscodeV2Request {
  /**
   *
   * @type {string}
   * @memberof VerifyForgotPasscodeV2Request
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof VerifyForgotPasscodeV2Request
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof VerifyForgotPasscodeV2Request
   */
  otp: string;
}
/**
 *
 * @export
 * @interface VerifyLinkDeviceOARequest
 */
export interface VerifyLinkDeviceOARequest {
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceOARequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceOARequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceOARequest
   */
  refUserId?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceOARequest
   */
  otp: string;
}
/**
 *
 * @export
 * @interface VerifyLinkDeviceOAResponse
 */
export interface VerifyLinkDeviceOAResponse {
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceOAResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceOAResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceOAResponse
   */
  model?: string;
  /**
   *
   * @type {boolean}
   * @memberof VerifyLinkDeviceOAResponse
   */
  online?: boolean;
}
/**
 *
 * @export
 * @interface VerifyLinkDeviceRequest
 */
export interface VerifyLinkDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceRequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceRequest
   */
  otp: string;
}
/**
 *
 * @export
 * @interface VerifyLinkDeviceWLRequest
 */
export interface VerifyLinkDeviceWLRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceWLRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceWLRequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceWLRequest
   */
  refUserId?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceWLRequest
   */
  otp: string;
}
/**
 *
 * @export
 * @interface VerifyLinkDeviceWLResponse
 */
export interface VerifyLinkDeviceWLResponse {
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceWLResponse
   */
  deviceToken?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceWLResponse
   */
  refUserId?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceWLResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceWLResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkDeviceWLResponse
   */
  model?: string;
  /**
   *
   * @type {boolean}
   * @memberof VerifyLinkDeviceWLResponse
   */
  online?: boolean;
}
/**
 *
 * @export
 * @interface VerifyOTPRequest
 */
export interface VerifyOTPRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyOTPRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof VerifyOTPRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof VerifyOTPRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyOTPRequest
   */
  otp: string;
}
/**
 *
 * @export
 * @interface VerifyOTPResponse
 */
export interface VerifyOTPResponse {
  /**
   *
   * @type {boolean}
   * @memberof VerifyOTPResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface VerifyRegistryRequest
 */
export interface VerifyRegistryRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyRegistryRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof VerifyRegistryRequest
   */
  session: string;
}
/**
 *
 * @export
 * @interface VersionResponse
 */
export interface VersionResponse {
  /**
   *
   * @type {string}
   * @memberof VersionResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VersionResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof VersionResponse
   */
  fwVersion?: string;
  /**
   *
   * @type {string}
   * @memberof VersionResponse
   */
  createTime?: string;
  /**
   *
   * @type {string}
   * @memberof VersionResponse
   */
  filePath?: string;
  /**
   *
   * @type {string}
   * @memberof VersionResponse
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof VersionResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof VersionResponse
   */
  createdDate?: string;
  /**
   *
   * @type {string}
   * @memberof VersionResponse
   */
  lastModifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof VersionResponse
   */
  lastModifiedDate?: string;
}

/**
 * AuditControllerApi - axios parameter creator
 * @export
 */
export const AuditControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Lấy version của 1 module
     * @param {GetCurrentVersionModuleEnum} module
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentVersion: async (
      module: GetCurrentVersionModuleEnum,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'module' is not null or undefined
      assertParamExists("getCurrentVersion", "module", module);
      const localVarPath = `/api/audit/v1/currentVersion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (module !== undefined) {
        localVarQueryParameter["module"] = module;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuditControllerApi - functional programming interface
 * @export
 */
export const AuditControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AuditControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Lấy version của 1 module
     * @param {GetCurrentVersionModuleEnum} module
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrentVersion(
      module: GetCurrentVersionModuleEnum,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAuditVersionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCurrentVersion(module, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["AuditControllerApi.getCurrentVersion"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * AuditControllerApi - factory interface
 * @export
 */
export const AuditControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuditControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Lấy version của 1 module
     * @param {AuditControllerApiGetCurrentVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentVersion(
      requestParameters: AuditControllerApiGetCurrentVersionRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseAuditVersionResponse> {
      return localVarFp
        .getCurrentVersion(requestParameters.module, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getCurrentVersion operation in AuditControllerApi.
 * @export
 * @interface AuditControllerApiGetCurrentVersionRequest
 */
export interface AuditControllerApiGetCurrentVersionRequest {
  /**
   *
   * @type {'RESPONSE_MESSAGE'}
   * @memberof AuditControllerApiGetCurrentVersion
   */
  readonly module: GetCurrentVersionModuleEnum;
}

/**
 * AuditControllerApi - object-oriented interface
 * @export
 * @class AuditControllerApi
 * @extends {BaseAPI}
 */
export class AuditControllerApi extends BaseAPI {
  /**
   *
   * @summary Lấy version của 1 module
   * @param {AuditControllerApiGetCurrentVersionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditControllerApi
   */
  public getCurrentVersion(
    requestParameters: AuditControllerApiGetCurrentVersionRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuditControllerApiFp(this.configuration)
      .getCurrentVersion(requestParameters.module, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const GetCurrentVersionModuleEnum = {
  ResponseMessage: "RESPONSE_MESSAGE",
} as const;
export type GetCurrentVersionModuleEnum =
  (typeof GetCurrentVersionModuleEnum)[keyof typeof GetCurrentVersionModuleEnum];

/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary [Forgot Passcode V2] Check Forgot Passcode
     * @param {CheckForgotPasscodeV2Request} checkForgotPasscodeV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkForgotPasscode: async (
      checkForgotPasscodeV2Request: CheckForgotPasscodeV2Request,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'checkForgotPasscodeV2Request' is not null or undefined
      assertParamExists(
        "checkForgotPasscode",
        "checkForgotPasscodeV2Request",
        checkForgotPasscodeV2Request,
      );
      const localVarPath = `/api/auth/v1/checkForgotPasscode`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkForgotPasscodeV2Request,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Client login
     * @param {ClientLoginRequest} clientLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLogin: async (
      clientLoginRequest: ClientLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientLoginRequest' is not null or undefined
      assertParamExists(
        "clientLogin",
        "clientLoginRequest",
        clientLoginRequest,
      );
      const localVarPath = `/api/auth/v1/login/client`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clientLoginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Exchange token
     * @param {ExchangeGoogleTokenRequest} exchangeGoogleTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exchangeToken: async (
      exchangeGoogleTokenRequest: ExchangeGoogleTokenRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'exchangeGoogleTokenRequest' is not null or undefined
      assertParamExists(
        "exchangeToken",
        "exchangeGoogleTokenRequest",
        exchangeGoogleTokenRequest,
      );
      const localVarPath = `/api/auth/v1/exchangeToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        exchangeGoogleTokenRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Forgot Passcode v2] Forgot Passcode
     * @param {ForgotPasscodeV2Request} forgotPasscodeV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPasscodeV2: async (
      forgotPasscodeV2Request: ForgotPasscodeV2Request,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'forgotPasscodeV2Request' is not null or undefined
      assertParamExists(
        "forgotPasscodeV2",
        "forgotPasscodeV2Request",
        forgotPasscodeV2Request,
      );
      const localVarPath = `/api/auth/v1/forgotPasscode`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        forgotPasscodeV2Request,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary logout
     * @param {LogoutRequest} logoutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout: async (
      logoutRequest: LogoutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'logoutRequest' is not null or undefined
      assertParamExists("logout", "logoutRequest", logoutRequest);
      const localVarPath = `/api/auth/v1/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        logoutRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary refresh token
     * @param {RefreshTokenRequest} refreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken: async (
      refreshTokenRequest: RefreshTokenRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'refreshTokenRequest' is not null or undefined
      assertParamExists(
        "refreshToken",
        "refreshTokenRequest",
        refreshTokenRequest,
      );
      const localVarPath = `/api/auth/v1/refreshToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        refreshTokenRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Root admin login
     * @param {RootAdminLoginRequest} rootAdminLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rootLogin: async (
      rootAdminLoginRequest: RootAdminLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'rootAdminLoginRequest' is not null or undefined
      assertParamExists(
        "rootLogin",
        "rootAdminLoginRequest",
        rootAdminLoginRequest,
      );
      const localVarPath = `/api/auth/v1/login/root`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        rootAdminLoginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Login đơn giản, không cần otp
     * @param {SimpleLoginRequest} simpleLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userLogin: async (
      simpleLoginRequest: SimpleLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'simpleLoginRequest' is not null or undefined
      assertParamExists("userLogin", "simpleLoginRequest", simpleLoginRequest);
      const localVarPath = `/api/auth/v1/login/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        simpleLoginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Forgot Passcode V2] Verify OTP
     * @param {VerifyForgotPasscodeV2Request} verifyForgotPasscodeV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyOTPForgotPasscode: async (
      verifyForgotPasscodeV2Request: VerifyForgotPasscodeV2Request,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyForgotPasscodeV2Request' is not null or undefined
      assertParamExists(
        "verifyOTPForgotPasscode",
        "verifyForgotPasscodeV2Request",
        verifyForgotPasscodeV2Request,
      );
      const localVarPath = `/api/auth/v1/verifyForgotPasscode`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyForgotPasscodeV2Request,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AuthControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary [Forgot Passcode V2] Check Forgot Passcode
     * @param {CheckForgotPasscodeV2Request} checkForgotPasscodeV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkForgotPasscode(
      checkForgotPasscodeV2Request: CheckForgotPasscodeV2Request,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCheckForgotPasscodeV2Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkForgotPasscode(
          checkForgotPasscodeV2Request,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["AuthControllerApi.checkForgotPasscode"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Client login
     * @param {ClientLoginRequest} clientLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientLogin(
      clientLoginRequest: ClientLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientLogin(
        clientLoginRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["AuthControllerApi.clientLogin"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Exchange token
     * @param {ExchangeGoogleTokenRequest} exchangeGoogleTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exchangeToken(
      exchangeGoogleTokenRequest: ExchangeGoogleTokenRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exchangeToken(
        exchangeGoogleTokenRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["AuthControllerApi.exchangeToken"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary [Forgot Passcode v2] Forgot Passcode
     * @param {ForgotPasscodeV2Request} forgotPasscodeV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forgotPasscodeV2(
      forgotPasscodeV2Request: ForgotPasscodeV2Request,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.forgotPasscodeV2(
          forgotPasscodeV2Request,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["AuthControllerApi.forgotPasscodeV2"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary logout
     * @param {LogoutRequest} logoutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logout(
      logoutRequest: LogoutRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseLogoutResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logout(
        logoutRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["AuthControllerApi.logout"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary refresh token
     * @param {RefreshTokenRequest} refreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshToken(
      refreshTokenRequest: RefreshTokenRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(
        refreshTokenRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["AuthControllerApi.refreshToken"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Root admin login
     * @param {RootAdminLoginRequest} rootAdminLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rootLogin(
      rootAdminLoginRequest: RootAdminLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rootLogin(
        rootAdminLoginRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["AuthControllerApi.rootLogin"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Login đơn giản, không cần otp
     * @param {SimpleLoginRequest} simpleLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userLogin(
      simpleLoginRequest: SimpleLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userLogin(
        simpleLoginRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["AuthControllerApi.userLogin"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary [Forgot Passcode V2] Verify OTP
     * @param {VerifyForgotPasscodeV2Request} verifyForgotPasscodeV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyOTPForgotPasscode(
      verifyForgotPasscodeV2Request: VerifyForgotPasscodeV2Request,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyOTPForgotPasscode(
          verifyForgotPasscodeV2Request,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["AuthControllerApi.verifyOTPForgotPasscode"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuthControllerApiFp(configuration);
  return {
    /**
     *
     * @summary [Forgot Passcode V2] Check Forgot Passcode
     * @param {AuthControllerApiCheckForgotPasscodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkForgotPasscode(
      requestParameters: AuthControllerApiCheckForgotPasscodeRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseCheckForgotPasscodeV2Response> {
      return localVarFp
        .checkForgotPasscode(
          requestParameters.checkForgotPasscodeV2Request,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Client login
     * @param {AuthControllerApiClientLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLogin(
      requestParameters: AuthControllerApiClientLoginRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseAccessTokenResponse> {
      return localVarFp
        .clientLogin(requestParameters.clientLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Exchange token
     * @param {AuthControllerApiExchangeTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exchangeToken(
      requestParameters: AuthControllerApiExchangeTokenRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseAccessTokenResponse> {
      return localVarFp
        .exchangeToken(requestParameters.exchangeGoogleTokenRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Forgot Passcode v2] Forgot Passcode
     * @param {AuthControllerApiForgotPasscodeV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPasscodeV2(
      requestParameters: AuthControllerApiForgotPasscodeV2Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .forgotPasscodeV2(requestParameters.forgotPasscodeV2Request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary logout
     * @param {AuthControllerApiLogoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(
      requestParameters: AuthControllerApiLogoutRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseLogoutResponse> {
      return localVarFp
        .logout(requestParameters.logoutRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary refresh token
     * @param {AuthControllerApiRefreshTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken(
      requestParameters: AuthControllerApiRefreshTokenRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseAccessTokenResponse> {
      return localVarFp
        .refreshToken(requestParameters.refreshTokenRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Root admin login
     * @param {AuthControllerApiRootLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rootLogin(
      requestParameters: AuthControllerApiRootLoginRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseAccessTokenResponse> {
      return localVarFp
        .rootLogin(requestParameters.rootAdminLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Login đơn giản, không cần otp
     * @param {AuthControllerApiUserLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userLogin(
      requestParameters: AuthControllerApiUserLoginRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseAccessTokenResponse> {
      return localVarFp
        .userLogin(requestParameters.simpleLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Forgot Passcode V2] Verify OTP
     * @param {AuthControllerApiVerifyOTPForgotPasscodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyOTPForgotPasscode(
      requestParameters: AuthControllerApiVerifyOTPForgotPasscodeRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .verifyOTPForgotPasscode(
          requestParameters.verifyForgotPasscodeV2Request,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for checkForgotPasscode operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiCheckForgotPasscodeRequest
 */
export interface AuthControllerApiCheckForgotPasscodeRequest {
  /**
   *
   * @type {CheckForgotPasscodeV2Request}
   * @memberof AuthControllerApiCheckForgotPasscode
   */
  readonly checkForgotPasscodeV2Request: CheckForgotPasscodeV2Request;
}

/**
 * Request parameters for clientLogin operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiClientLoginRequest
 */
export interface AuthControllerApiClientLoginRequest {
  /**
   *
   * @type {ClientLoginRequest}
   * @memberof AuthControllerApiClientLogin
   */
  readonly clientLoginRequest: ClientLoginRequest;
}

/**
 * Request parameters for exchangeToken operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiExchangeTokenRequest
 */
export interface AuthControllerApiExchangeTokenRequest {
  /**
   *
   * @type {ExchangeGoogleTokenRequest}
   * @memberof AuthControllerApiExchangeToken
   */
  readonly exchangeGoogleTokenRequest: ExchangeGoogleTokenRequest;
}

/**
 * Request parameters for forgotPasscodeV2 operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiForgotPasscodeV2Request
 */
export interface AuthControllerApiForgotPasscodeV2Request {
  /**
   *
   * @type {ForgotPasscodeV2Request}
   * @memberof AuthControllerApiForgotPasscodeV2
   */
  readonly forgotPasscodeV2Request: ForgotPasscodeV2Request;
}

/**
 * Request parameters for logout operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiLogoutRequest
 */
export interface AuthControllerApiLogoutRequest {
  /**
   *
   * @type {LogoutRequest}
   * @memberof AuthControllerApiLogout
   */
  readonly logoutRequest: LogoutRequest;
}

/**
 * Request parameters for refreshToken operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiRefreshTokenRequest
 */
export interface AuthControllerApiRefreshTokenRequest {
  /**
   *
   * @type {RefreshTokenRequest}
   * @memberof AuthControllerApiRefreshToken
   */
  readonly refreshTokenRequest: RefreshTokenRequest;
}

/**
 * Request parameters for rootLogin operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiRootLoginRequest
 */
export interface AuthControllerApiRootLoginRequest {
  /**
   *
   * @type {RootAdminLoginRequest}
   * @memberof AuthControllerApiRootLogin
   */
  readonly rootAdminLoginRequest: RootAdminLoginRequest;
}

/**
 * Request parameters for userLogin operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiUserLoginRequest
 */
export interface AuthControllerApiUserLoginRequest {
  /**
   *
   * @type {SimpleLoginRequest}
   * @memberof AuthControllerApiUserLogin
   */
  readonly simpleLoginRequest: SimpleLoginRequest;
}

/**
 * Request parameters for verifyOTPForgotPasscode operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiVerifyOTPForgotPasscodeRequest
 */
export interface AuthControllerApiVerifyOTPForgotPasscodeRequest {
  /**
   *
   * @type {VerifyForgotPasscodeV2Request}
   * @memberof AuthControllerApiVerifyOTPForgotPasscode
   */
  readonly verifyForgotPasscodeV2Request: VerifyForgotPasscodeV2Request;
}

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
  /**
   *
   * @summary [Forgot Passcode V2] Check Forgot Passcode
   * @param {AuthControllerApiCheckForgotPasscodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public checkForgotPasscode(
    requestParameters: AuthControllerApiCheckForgotPasscodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .checkForgotPasscode(
        requestParameters.checkForgotPasscodeV2Request,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Client login
   * @param {AuthControllerApiClientLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public clientLogin(
    requestParameters: AuthControllerApiClientLoginRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .clientLogin(requestParameters.clientLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Exchange token
   * @param {AuthControllerApiExchangeTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public exchangeToken(
    requestParameters: AuthControllerApiExchangeTokenRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .exchangeToken(requestParameters.exchangeGoogleTokenRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Forgot Passcode v2] Forgot Passcode
   * @param {AuthControllerApiForgotPasscodeV2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public forgotPasscodeV2(
    requestParameters: AuthControllerApiForgotPasscodeV2Request,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .forgotPasscodeV2(requestParameters.forgotPasscodeV2Request, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary logout
   * @param {AuthControllerApiLogoutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public logout(
    requestParameters: AuthControllerApiLogoutRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .logout(requestParameters.logoutRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary refresh token
   * @param {AuthControllerApiRefreshTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public refreshToken(
    requestParameters: AuthControllerApiRefreshTokenRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .refreshToken(requestParameters.refreshTokenRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Root admin login
   * @param {AuthControllerApiRootLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public rootLogin(
    requestParameters: AuthControllerApiRootLoginRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .rootLogin(requestParameters.rootAdminLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Login đơn giản, không cần otp
   * @param {AuthControllerApiUserLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public userLogin(
    requestParameters: AuthControllerApiUserLoginRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .userLogin(requestParameters.simpleLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Forgot Passcode V2] Verify OTP
   * @param {AuthControllerApiVerifyOTPForgotPasscodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public verifyOTPForgotPasscode(
    requestParameters: AuthControllerApiVerifyOTPForgotPasscodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .verifyOTPForgotPasscode(
        requestParameters.verifyForgotPasscodeV2Request,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BankControllerApi - axios parameter creator
 * @export
 */
export const BankControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Thêm mới 1 ví điện tử
     * @param {AddEWalletRequest} addEWalletRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addEWallet: async (
      addEWalletRequest: AddEWalletRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addEWalletRequest' is not null or undefined
      assertParamExists("addEWallet", "addEWalletRequest", addEWalletRequest);
      const localVarPath = `/api/admin/bank/v1/eWallet/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addEWalletRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check Account No
     * @param {CheckBankAccountRequest} checkBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkBankAccount: async (
      checkBankAccountRequest: CheckBankAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'checkBankAccountRequest' is not null or undefined
      assertParamExists(
        "checkBankAccount",
        "checkBankAccountRequest",
        checkBankAccountRequest,
      );
      const localVarPath = `/api/admin/bank/v1/checkBankAccount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkBankAccountRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update bank
     * @param {DeleteBankRequest} deleteBankRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBank: async (
      deleteBankRequest: DeleteBankRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteBankRequest' is not null or undefined
      assertParamExists("deleteBank", "deleteBankRequest", deleteBankRequest);
      const localVarPath = `/api/admin/bank/v1/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteBankRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get All Bank
     * @param {GetAllBanksRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBanks: async (
      request: GetAllBanksRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("getAllBanks", "request", request);
      const localVarPath = `/api/admin/bank/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Đồng bộ với Việt QR
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncWithVietQr: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/bank/v1/syncVietQr`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update bank
     * @param {UpdateBankRequest} updateBankRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBank: async (
      updateBankRequest: UpdateBankRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateBankRequest' is not null or undefined
      assertParamExists("updateBank", "updateBankRequest", updateBankRequest);
      const localVarPath = `/api/admin/bank/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateBankRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update logo từ hình ảnh
     * @param {Array<File>} logos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLogo: async (
      logos: Array<File>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'logos' is not null or undefined
      assertParamExists("updateLogo", "logos", logos);
      const localVarPath = `/api/admin/bank/v1/updateLogo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (logos) {
        localVarQueryParameter["logos"] = logos;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BankControllerApi - functional programming interface
 * @export
 */
export const BankControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    BankControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Thêm mới 1 ví điện tử
     * @param {AddEWalletRequest} addEWalletRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addEWallet(
      addEWalletRequest: AddEWalletRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAddEWalletResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addEWallet(
        addEWalletRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["BankControllerApi.addEWallet"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Check Account No
     * @param {CheckBankAccountRequest} checkBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkBankAccount(
      checkBankAccountRequest: CheckBankAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCheckBankAccountResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkBankAccount(
          checkBankAccountRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["BankControllerApi.checkBankAccount"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Update bank
     * @param {DeleteBankRequest} deleteBankRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBank(
      deleteBankRequest: DeleteBankRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeleteBankResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBank(
        deleteBankRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["BankControllerApi.deleteBank"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get All Bank
     * @param {GetAllBanksRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllBanks(
      request: GetAllBanksRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllBanksResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBanks(
        request,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["BankControllerApi.getAllBanks"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Đồng bộ với Việt QR
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncWithVietQr(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSyncVietQrResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.syncWithVietQr(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["BankControllerApi.syncWithVietQr"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Update bank
     * @param {UpdateBankRequest} updateBankRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBank(
      updateBankRequest: UpdateBankRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUpdateBankResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBank(
        updateBankRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["BankControllerApi.updateBank"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Update logo từ hình ảnh
     * @param {Array<File>} logos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLogo(
      logos: Array<File>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUpdateLogoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateLogo(
        logos,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["BankControllerApi.updateLogo"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * BankControllerApi - factory interface
 * @export
 */
export const BankControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BankControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Thêm mới 1 ví điện tử
     * @param {BankControllerApiAddEWalletRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addEWallet(
      requestParameters: BankControllerApiAddEWalletRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseAddEWalletResponse> {
      return localVarFp
        .addEWallet(requestParameters.addEWalletRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Check Account No
     * @param {BankControllerApiCheckBankAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkBankAccount(
      requestParameters: BankControllerApiCheckBankAccountRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseCheckBankAccountResponse> {
      return localVarFp
        .checkBankAccount(requestParameters.checkBankAccountRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update bank
     * @param {BankControllerApiDeleteBankRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBank(
      requestParameters: BankControllerApiDeleteBankRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDeleteBankResponse> {
      return localVarFp
        .deleteBank(requestParameters.deleteBankRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get All Bank
     * @param {BankControllerApiGetAllBanksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBanks(
      requestParameters: BankControllerApiGetAllBanksRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseGetAllBanksResponse> {
      return localVarFp
        .getAllBanks(requestParameters.request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Đồng bộ với Việt QR
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncWithVietQr(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseSyncVietQrResponse> {
      return localVarFp
        .syncWithVietQr(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update bank
     * @param {BankControllerApiUpdateBankRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBank(
      requestParameters: BankControllerApiUpdateBankRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseUpdateBankResponse> {
      return localVarFp
        .updateBank(requestParameters.updateBankRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update logo từ hình ảnh
     * @param {BankControllerApiUpdateLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLogo(
      requestParameters: BankControllerApiUpdateLogoRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseUpdateLogoResponse> {
      return localVarFp
        .updateLogo(requestParameters.logos, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addEWallet operation in BankControllerApi.
 * @export
 * @interface BankControllerApiAddEWalletRequest
 */
export interface BankControllerApiAddEWalletRequest {
  /**
   *
   * @type {AddEWalletRequest}
   * @memberof BankControllerApiAddEWallet
   */
  readonly addEWalletRequest: AddEWalletRequest;
}

/**
 * Request parameters for checkBankAccount operation in BankControllerApi.
 * @export
 * @interface BankControllerApiCheckBankAccountRequest
 */
export interface BankControllerApiCheckBankAccountRequest {
  /**
   *
   * @type {CheckBankAccountRequest}
   * @memberof BankControllerApiCheckBankAccount
   */
  readonly checkBankAccountRequest: CheckBankAccountRequest;
}

/**
 * Request parameters for deleteBank operation in BankControllerApi.
 * @export
 * @interface BankControllerApiDeleteBankRequest
 */
export interface BankControllerApiDeleteBankRequest {
  /**
   *
   * @type {DeleteBankRequest}
   * @memberof BankControllerApiDeleteBank
   */
  readonly deleteBankRequest: DeleteBankRequest;
}

/**
 * Request parameters for getAllBanks operation in BankControllerApi.
 * @export
 * @interface BankControllerApiGetAllBanksRequest
 */
export interface BankControllerApiGetAllBanksRequest {
  /**
   *
   * @type {GetAllBanksRequest}
   * @memberof BankControllerApiGetAllBanks
   */
  readonly request: GetAllBanksRequest;
}

/**
 * Request parameters for updateBank operation in BankControllerApi.
 * @export
 * @interface BankControllerApiUpdateBankRequest
 */
export interface BankControllerApiUpdateBankRequest {
  /**
   *
   * @type {UpdateBankRequest}
   * @memberof BankControllerApiUpdateBank
   */
  readonly updateBankRequest: UpdateBankRequest;
}

/**
 * Request parameters for updateLogo operation in BankControllerApi.
 * @export
 * @interface BankControllerApiUpdateLogoRequest
 */
export interface BankControllerApiUpdateLogoRequest {
  /**
   *
   * @type {Array<File>}
   * @memberof BankControllerApiUpdateLogo
   */
  readonly logos: Array<File>;
}

/**
 * BankControllerApi - object-oriented interface
 * @export
 * @class BankControllerApi
 * @extends {BaseAPI}
 */
export class BankControllerApi extends BaseAPI {
  /**
   *
   * @summary Thêm mới 1 ví điện tử
   * @param {BankControllerApiAddEWalletRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public addEWallet(
    requestParameters: BankControllerApiAddEWalletRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankControllerApiFp(this.configuration)
      .addEWallet(requestParameters.addEWalletRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Check Account No
   * @param {BankControllerApiCheckBankAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public checkBankAccount(
    requestParameters: BankControllerApiCheckBankAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankControllerApiFp(this.configuration)
      .checkBankAccount(requestParameters.checkBankAccountRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update bank
   * @param {BankControllerApiDeleteBankRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public deleteBank(
    requestParameters: BankControllerApiDeleteBankRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankControllerApiFp(this.configuration)
      .deleteBank(requestParameters.deleteBankRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get All Bank
   * @param {BankControllerApiGetAllBanksRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public getAllBanks(
    requestParameters: BankControllerApiGetAllBanksRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankControllerApiFp(this.configuration)
      .getAllBanks(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Đồng bộ với Việt QR
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public syncWithVietQr(options?: AxiosRequestConfig) {
    return BankControllerApiFp(this.configuration)
      .syncWithVietQr(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update bank
   * @param {BankControllerApiUpdateBankRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public updateBank(
    requestParameters: BankControllerApiUpdateBankRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankControllerApiFp(this.configuration)
      .updateBank(requestParameters.updateBankRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update logo từ hình ảnh
   * @param {BankControllerApiUpdateLogoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public updateLogo(
    requestParameters: BankControllerApiUpdateLogoRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankControllerApiFp(this.configuration)
      .updateLogo(requestParameters.logos, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DeviceControllerApi - axios parameter creator
 * @export
 */
export const DeviceControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary [V3] Kiểm tra thiết bị
     * @param {string} hardwareId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkLinkDevice: async (
      hardwareId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hardwareId' is not null or undefined
      assertParamExists("checkLinkDevice", "hardwareId", hardwareId);
      const localVarPath = `/api/device/v1/link/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (hardwareId !== undefined) {
        localVarQueryParameter["hardwareId"] = hardwareId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thêm mới 1 thiết bị
     * @param {CmsRegisterDeviceRequest} cmsRegisterDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsRegisterDevice: async (
      cmsRegisterDeviceRequest: CmsRegisterDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cmsRegisterDeviceRequest' is not null or undefined
      assertParamExists(
        "cmsRegisterDevice",
        "cmsRegisterDeviceRequest",
        cmsRegisterDeviceRequest,
      );
      const localVarPath = `/api/admin/device/v1/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cmsRegisterDeviceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa 1 thiết bị
     * @param {DeleteDeviceRequest} deleteDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDevice: async (
      deleteDeviceRequest: DeleteDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteDeviceRequest' is not null or undefined
      assertParamExists(
        "deleteDevice",
        "deleteDeviceRequest",
        deleteDeviceRequest,
      );
      const localVarPath = `/api/device/v1/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteDeviceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tất cả thiết bị
     * @param {GetAllDeviceRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDevices: async (
      request: GetAllDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("getAllDevices", "request", request);
      const localVarPath = `/api/device/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy 1 thiết bị
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevice3: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDevice3", "id", id);
      const localVarPath = `/api/device/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Liên kết Tài khoản ngân hàng
     * @param {LinkBankAccountRequest} linkBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkBankAccount1: async (
      linkBankAccountRequest: LinkBankAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'linkBankAccountRequest' is not null or undefined
      assertParamExists(
        "linkBankAccount1",
        "linkBankAccountRequest",
        linkBankAccountRequest,
      );
      const localVarPath = `/api/device/v1/linkBankAccount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        linkBankAccountRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [V2] Kết nối thiết bị với user qua hwid
     * @param {LinkWithUserByHwIDRequest} linkWithUserByHwIDRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkByHardwareId: async (
      linkWithUserByHwIDRequest: LinkWithUserByHwIDRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'linkWithUserByHwIDRequest' is not null or undefined
      assertParamExists(
        "linkByHardwareId",
        "linkWithUserByHwIDRequest",
        linkWithUserByHwIDRequest,
      );
      const localVarPath = `/api/device/v2/linkByHardwareId`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        linkWithUserByHwIDRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thêm mới 1 thiết bị
     * @param {RegisterDeviceRequest} registerDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerDevice: async (
      registerDeviceRequest: RegisterDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'registerDeviceRequest' is not null or undefined
      assertParamExists(
        "registerDevice",
        "registerDeviceRequest",
        registerDeviceRequest,
      );
      const localVarPath = `/api/device/v1/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerDeviceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [V3] Yêu cầu liên kết thiết bị
     * @param {SubmitLinkDeviceRequest} submitLinkDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestLinkDevice2: async (
      submitLinkDeviceRequest: SubmitLinkDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'submitLinkDeviceRequest' is not null or undefined
      assertParamExists(
        "requestLinkDevice2",
        "submitLinkDeviceRequest",
        submitLinkDeviceRequest,
      );
      const localVarPath = `/api/device/v1/link/request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        submitLinkDeviceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Huỷ liên kết Tài khoản ngân hàng
     * @param {UnLinkBankAccountRequest} unLinkBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlinkBankAccount: async (
      unLinkBankAccountRequest: UnLinkBankAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'unLinkBankAccountRequest' is not null or undefined
      assertParamExists(
        "unlinkBankAccount",
        "unLinkBankAccountRequest",
        unLinkBankAccountRequest,
      );
      const localVarPath = `/api/device/v1/unlinkBankAccount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        unLinkBankAccountRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [V2] Hủy kết nối thiết bị với user
     * @param {UnlinkWithUserV2Request} unlinkWithUserV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlinkWithUser: async (
      unlinkWithUserV2Request: UnlinkWithUserV2Request,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'unlinkWithUserV2Request' is not null or undefined
      assertParamExists(
        "unlinkWithUser",
        "unlinkWithUserV2Request",
        unlinkWithUserV2Request,
      );
      const localVarPath = `/api/device/v1/unlink`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        unlinkWithUserV2Request,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update thiết bị
     * @param {UpdateDeviceRequest} updateDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDevice: async (
      updateDeviceRequest: UpdateDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDeviceRequest' is not null or undefined
      assertParamExists(
        "updateDevice",
        "updateDeviceRequest",
        updateDeviceRequest,
      );
      const localVarPath = `/api/device/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDeviceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [V3] Yêu cầu liên kết thiết bị
     * @param {VerifyLinkDeviceRequest} verifyLinkDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyLinkDevice2: async (
      verifyLinkDeviceRequest: VerifyLinkDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyLinkDeviceRequest' is not null or undefined
      assertParamExists(
        "verifyLinkDevice2",
        "verifyLinkDeviceRequest",
        verifyLinkDeviceRequest,
      );
      const localVarPath = `/api/device/v1/link/verify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyLinkDeviceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DeviceControllerApi - functional programming interface
 * @export
 */
export const DeviceControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DeviceControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary [V3] Kiểm tra thiết bị
     * @param {string} hardwareId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkLinkDevice(
      hardwareId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCheckDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkLinkDevice(
        hardwareId,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["DeviceControllerApi.checkLinkDevice"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Thêm mới 1 thiết bị
     * @param {CmsRegisterDeviceRequest} cmsRegisterDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsRegisterDevice(
      cmsRegisterDeviceRequest: CmsRegisterDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRegisterDeviceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsRegisterDevice(
          cmsRegisterDeviceRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["DeviceControllerApi.cmsRegisterDevice"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Xóa 1 thiết bị
     * @param {DeleteDeviceRequest} deleteDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDevice(
      deleteDeviceRequest: DeleteDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeleteDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDevice(
        deleteDeviceRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["DeviceControllerApi.deleteDevice"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy tất cả thiết bị
     * @param {GetAllDeviceRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllDevices(
      request: GetAllDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDevices(
        request,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["DeviceControllerApi.getAllDevices"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy 1 thiết bị
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDevice3(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDevice3(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["DeviceControllerApi.getDevice3"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Liên kết Tài khoản ngân hàng
     * @param {LinkBankAccountRequest} linkBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linkBankAccount1(
      linkBankAccountRequest: LinkBankAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseLinkBankAccountResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.linkBankAccount1(
          linkBankAccountRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["DeviceControllerApi.linkBankAccount1"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary [V2] Kết nối thiết bị với user qua hwid
     * @param {LinkWithUserByHwIDRequest} linkWithUserByHwIDRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linkByHardwareId(
      linkWithUserByHwIDRequest: LinkWithUserByHwIDRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseLinkWithUserResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.linkByHardwareId(
          linkWithUserByHwIDRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["DeviceControllerApi.linkByHardwareId"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Thêm mới 1 thiết bị
     * @param {RegisterDeviceRequest} registerDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registerDevice(
      registerDeviceRequest: RegisterDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRegisterDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registerDevice(
        registerDeviceRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["DeviceControllerApi.registerDevice"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary [V3] Yêu cầu liên kết thiết bị
     * @param {SubmitLinkDeviceRequest} submitLinkDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestLinkDevice2(
      submitLinkDeviceRequest: SubmitLinkDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseLinkDeviceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.requestLinkDevice2(
          submitLinkDeviceRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["DeviceControllerApi.requestLinkDevice2"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Huỷ liên kết Tài khoản ngân hàng
     * @param {UnLinkBankAccountRequest} unLinkBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unlinkBankAccount(
      unLinkBankAccountRequest: UnLinkBankAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeviceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.unlinkBankAccount(
          unLinkBankAccountRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["DeviceControllerApi.unlinkBankAccount"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary [V2] Hủy kết nối thiết bị với user
     * @param {UnlinkWithUserV2Request} unlinkWithUserV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unlinkWithUser(
      unlinkWithUserV2Request: UnlinkWithUserV2Request,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unlinkWithUser(
        unlinkWithUserV2Request,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["DeviceControllerApi.unlinkWithUser"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Update thiết bị
     * @param {UpdateDeviceRequest} updateDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDevice(
      updateDeviceRequest: UpdateDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUpdateDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDevice(
        updateDeviceRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["DeviceControllerApi.updateDevice"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary [V3] Yêu cầu liên kết thiết bị
     * @param {VerifyLinkDeviceRequest} verifyLinkDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyLinkDevice2(
      verifyLinkDeviceRequest: VerifyLinkDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyLinkDevice2(
          verifyLinkDeviceRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["DeviceControllerApi.verifyLinkDevice2"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * DeviceControllerApi - factory interface
 * @export
 */
export const DeviceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DeviceControllerApiFp(configuration);
  return {
    /**
     *
     * @summary [V3] Kiểm tra thiết bị
     * @param {DeviceControllerApiCheckLinkDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkLinkDevice(
      requestParameters: DeviceControllerApiCheckLinkDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseCheckDeviceResponse> {
      return localVarFp
        .checkLinkDevice(requestParameters.hardwareId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thêm mới 1 thiết bị
     * @param {DeviceControllerApiCmsRegisterDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsRegisterDevice(
      requestParameters: DeviceControllerApiCmsRegisterDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseRegisterDeviceResponse> {
      return localVarFp
        .cmsRegisterDevice(requestParameters.cmsRegisterDeviceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa 1 thiết bị
     * @param {DeviceControllerApiDeleteDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDevice(
      requestParameters: DeviceControllerApiDeleteDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDeleteDeviceResponse> {
      return localVarFp
        .deleteDevice(requestParameters.deleteDeviceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tất cả thiết bị
     * @param {DeviceControllerApiGetAllDevicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDevices(
      requestParameters: DeviceControllerApiGetAllDevicesRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePageResponseDeviceResponse> {
      return localVarFp
        .getAllDevices(requestParameters.request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy 1 thiết bị
     * @param {DeviceControllerApiGetDevice3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevice3(
      requestParameters: DeviceControllerApiGetDevice3Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDeviceResponse> {
      return localVarFp
        .getDevice3(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Liên kết Tài khoản ngân hàng
     * @param {DeviceControllerApiLinkBankAccount1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkBankAccount1(
      requestParameters: DeviceControllerApiLinkBankAccount1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseLinkBankAccountResponse> {
      return localVarFp
        .linkBankAccount1(requestParameters.linkBankAccountRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [V2] Kết nối thiết bị với user qua hwid
     * @param {DeviceControllerApiLinkByHardwareIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkByHardwareId(
      requestParameters: DeviceControllerApiLinkByHardwareIdRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseLinkWithUserResponse> {
      return localVarFp
        .linkByHardwareId(requestParameters.linkWithUserByHwIDRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thêm mới 1 thiết bị
     * @param {DeviceControllerApiRegisterDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerDevice(
      requestParameters: DeviceControllerApiRegisterDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseRegisterDeviceResponse> {
      return localVarFp
        .registerDevice(requestParameters.registerDeviceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [V3] Yêu cầu liên kết thiết bị
     * @param {DeviceControllerApiRequestLinkDevice2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestLinkDevice2(
      requestParameters: DeviceControllerApiRequestLinkDevice2Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseLinkDeviceResponse> {
      return localVarFp
        .requestLinkDevice2(requestParameters.submitLinkDeviceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Huỷ liên kết Tài khoản ngân hàng
     * @param {DeviceControllerApiUnlinkBankAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlinkBankAccount(
      requestParameters: DeviceControllerApiUnlinkBankAccountRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDeviceResponse> {
      return localVarFp
        .unlinkBankAccount(requestParameters.unLinkBankAccountRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [V2] Hủy kết nối thiết bị với user
     * @param {DeviceControllerApiUnlinkWithUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlinkWithUser(
      requestParameters: DeviceControllerApiUnlinkWithUserRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .unlinkWithUser(requestParameters.unlinkWithUserV2Request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update thiết bị
     * @param {DeviceControllerApiUpdateDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDevice(
      requestParameters: DeviceControllerApiUpdateDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseUpdateDeviceResponse> {
      return localVarFp
        .updateDevice(requestParameters.updateDeviceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [V3] Yêu cầu liên kết thiết bị
     * @param {DeviceControllerApiVerifyLinkDevice2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyLinkDevice2(
      requestParameters: DeviceControllerApiVerifyLinkDevice2Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .verifyLinkDevice2(requestParameters.verifyLinkDeviceRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for checkLinkDevice operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiCheckLinkDeviceRequest
 */
export interface DeviceControllerApiCheckLinkDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiCheckLinkDevice
   */
  readonly hardwareId: string;
}

/**
 * Request parameters for cmsRegisterDevice operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiCmsRegisterDeviceRequest
 */
export interface DeviceControllerApiCmsRegisterDeviceRequest {
  /**
   *
   * @type {CmsRegisterDeviceRequest}
   * @memberof DeviceControllerApiCmsRegisterDevice
   */
  readonly cmsRegisterDeviceRequest: CmsRegisterDeviceRequest;
}

/**
 * Request parameters for deleteDevice operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiDeleteDeviceRequest
 */
export interface DeviceControllerApiDeleteDeviceRequest {
  /**
   *
   * @type {DeleteDeviceRequest}
   * @memberof DeviceControllerApiDeleteDevice
   */
  readonly deleteDeviceRequest: DeleteDeviceRequest;
}

/**
 * Request parameters for getAllDevices operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiGetAllDevicesRequest
 */
export interface DeviceControllerApiGetAllDevicesRequest {
  /**
   *
   * @type {GetAllDeviceRequest}
   * @memberof DeviceControllerApiGetAllDevices
   */
  readonly request: GetAllDeviceRequest;
}

/**
 * Request parameters for getDevice3 operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiGetDevice3Request
 */
export interface DeviceControllerApiGetDevice3Request {
  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiGetDevice3
   */
  readonly id: string;
}

/**
 * Request parameters for linkBankAccount1 operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiLinkBankAccount1Request
 */
export interface DeviceControllerApiLinkBankAccount1Request {
  /**
   *
   * @type {LinkBankAccountRequest}
   * @memberof DeviceControllerApiLinkBankAccount1
   */
  readonly linkBankAccountRequest: LinkBankAccountRequest;
}

/**
 * Request parameters for linkByHardwareId operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiLinkByHardwareIdRequest
 */
export interface DeviceControllerApiLinkByHardwareIdRequest {
  /**
   *
   * @type {LinkWithUserByHwIDRequest}
   * @memberof DeviceControllerApiLinkByHardwareId
   */
  readonly linkWithUserByHwIDRequest: LinkWithUserByHwIDRequest;
}

/**
 * Request parameters for registerDevice operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiRegisterDeviceRequest
 */
export interface DeviceControllerApiRegisterDeviceRequest {
  /**
   *
   * @type {RegisterDeviceRequest}
   * @memberof DeviceControllerApiRegisterDevice
   */
  readonly registerDeviceRequest: RegisterDeviceRequest;
}

/**
 * Request parameters for requestLinkDevice2 operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiRequestLinkDevice2Request
 */
export interface DeviceControllerApiRequestLinkDevice2Request {
  /**
   *
   * @type {SubmitLinkDeviceRequest}
   * @memberof DeviceControllerApiRequestLinkDevice2
   */
  readonly submitLinkDeviceRequest: SubmitLinkDeviceRequest;
}

/**
 * Request parameters for unlinkBankAccount operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiUnlinkBankAccountRequest
 */
export interface DeviceControllerApiUnlinkBankAccountRequest {
  /**
   *
   * @type {UnLinkBankAccountRequest}
   * @memberof DeviceControllerApiUnlinkBankAccount
   */
  readonly unLinkBankAccountRequest: UnLinkBankAccountRequest;
}

/**
 * Request parameters for unlinkWithUser operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiUnlinkWithUserRequest
 */
export interface DeviceControllerApiUnlinkWithUserRequest {
  /**
   *
   * @type {UnlinkWithUserV2Request}
   * @memberof DeviceControllerApiUnlinkWithUser
   */
  readonly unlinkWithUserV2Request: UnlinkWithUserV2Request;
}

/**
 * Request parameters for updateDevice operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiUpdateDeviceRequest
 */
export interface DeviceControllerApiUpdateDeviceRequest {
  /**
   *
   * @type {UpdateDeviceRequest}
   * @memberof DeviceControllerApiUpdateDevice
   */
  readonly updateDeviceRequest: UpdateDeviceRequest;
}

/**
 * Request parameters for verifyLinkDevice2 operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiVerifyLinkDevice2Request
 */
export interface DeviceControllerApiVerifyLinkDevice2Request {
  /**
   *
   * @type {VerifyLinkDeviceRequest}
   * @memberof DeviceControllerApiVerifyLinkDevice2
   */
  readonly verifyLinkDeviceRequest: VerifyLinkDeviceRequest;
}

/**
 * DeviceControllerApi - object-oriented interface
 * @export
 * @class DeviceControllerApi
 * @extends {BaseAPI}
 */
export class DeviceControllerApi extends BaseAPI {
  /**
   *
   * @summary [V3] Kiểm tra thiết bị
   * @param {DeviceControllerApiCheckLinkDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public checkLinkDevice(
    requestParameters: DeviceControllerApiCheckLinkDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .checkLinkDevice(requestParameters.hardwareId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thêm mới 1 thiết bị
   * @param {DeviceControllerApiCmsRegisterDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public cmsRegisterDevice(
    requestParameters: DeviceControllerApiCmsRegisterDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .cmsRegisterDevice(requestParameters.cmsRegisterDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa 1 thiết bị
   * @param {DeviceControllerApiDeleteDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public deleteDevice(
    requestParameters: DeviceControllerApiDeleteDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .deleteDevice(requestParameters.deleteDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tất cả thiết bị
   * @param {DeviceControllerApiGetAllDevicesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public getAllDevices(
    requestParameters: DeviceControllerApiGetAllDevicesRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .getAllDevices(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy 1 thiết bị
   * @param {DeviceControllerApiGetDevice3Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public getDevice3(
    requestParameters: DeviceControllerApiGetDevice3Request,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .getDevice3(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Liên kết Tài khoản ngân hàng
   * @param {DeviceControllerApiLinkBankAccount1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public linkBankAccount1(
    requestParameters: DeviceControllerApiLinkBankAccount1Request,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .linkBankAccount1(requestParameters.linkBankAccountRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [V2] Kết nối thiết bị với user qua hwid
   * @param {DeviceControllerApiLinkByHardwareIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public linkByHardwareId(
    requestParameters: DeviceControllerApiLinkByHardwareIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .linkByHardwareId(requestParameters.linkWithUserByHwIDRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thêm mới 1 thiết bị
   * @param {DeviceControllerApiRegisterDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public registerDevice(
    requestParameters: DeviceControllerApiRegisterDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .registerDevice(requestParameters.registerDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [V3] Yêu cầu liên kết thiết bị
   * @param {DeviceControllerApiRequestLinkDevice2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public requestLinkDevice2(
    requestParameters: DeviceControllerApiRequestLinkDevice2Request,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .requestLinkDevice2(requestParameters.submitLinkDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Huỷ liên kết Tài khoản ngân hàng
   * @param {DeviceControllerApiUnlinkBankAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public unlinkBankAccount(
    requestParameters: DeviceControllerApiUnlinkBankAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .unlinkBankAccount(requestParameters.unLinkBankAccountRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [V2] Hủy kết nối thiết bị với user
   * @param {DeviceControllerApiUnlinkWithUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public unlinkWithUser(
    requestParameters: DeviceControllerApiUnlinkWithUserRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .unlinkWithUser(requestParameters.unlinkWithUserV2Request, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update thiết bị
   * @param {DeviceControllerApiUpdateDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public updateDevice(
    requestParameters: DeviceControllerApiUpdateDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .updateDevice(requestParameters.updateDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [V3] Yêu cầu liên kết thiết bị
   * @param {DeviceControllerApiVerifyLinkDevice2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public verifyLinkDevice2(
    requestParameters: DeviceControllerApiVerifyLinkDevice2Request,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .verifyLinkDevice2(requestParameters.verifyLinkDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExtensionControllerApi - axios parameter creator
 * @export
 */
export const ExtensionControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete path
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("_delete", "id", id);
      const localVarPath = `/api/admin/extensionPath/v1/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add new path variable
     * @param {AddNewPathRequest} addNewPathRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addNew: async (
      addNewPathRequest: AddNewPathRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addNewPathRequest' is not null or undefined
      assertParamExists("addNew", "addNewPathRequest", addNewPathRequest);
      const localVarPath = `/api/admin/extensionPath/v1/addNew`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addNewPathRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/extensionPath/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get detail by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail1: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetail1", "id", id);
      const localVarPath = `/api/admin/extensionPath/v1/getDetail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update by id
     * @param {UpdatePathRequest} updatePathRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update1: async (
      updatePathRequest: UpdatePathRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updatePathRequest' is not null or undefined
      assertParamExists("update1", "updatePathRequest", updatePathRequest);
      const localVarPath = `/api/admin/extensionPath/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePathRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExtensionControllerApi - functional programming interface
 * @export
 */
export const ExtensionControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ExtensionControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete path
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExtensionControllerApi._delete"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Add new path variable
     * @param {AddNewPathRequest} addNewPathRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addNew(
      addNewPathRequest: AddNewPathRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExtensionPathResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addNew(
        addNewPathRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExtensionControllerApi.addNew"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get all
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAll(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseListExtensionPathResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExtensionControllerApi.getAll"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get detail by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetail1(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExtensionPathResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDetail1(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExtensionControllerApi.getDetail1"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Update by id
     * @param {UpdatePathRequest} updatePathRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update1(
      updatePathRequest: UpdatePathRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExtensionPathResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update1(
        updatePathRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExtensionControllerApi.update1"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * ExtensionControllerApi - factory interface
 * @export
 */
export const ExtensionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExtensionControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Delete path
     * @param {ExtensionControllerApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(
      requestParameters: ExtensionControllerApiDeleteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        ._delete(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add new path variable
     * @param {ExtensionControllerApiAddNewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addNew(
      requestParameters: ExtensionControllerApiAddNewRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseExtensionPathResponse> {
      return localVarFp
        .addNew(requestParameters.addNewPathRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseListExtensionPathResponse> {
      return localVarFp
        .getAll(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get detail by id
     * @param {ExtensionControllerApiGetDetail1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail1(
      requestParameters: ExtensionControllerApiGetDetail1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseExtensionPathResponse> {
      return localVarFp
        .getDetail1(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update by id
     * @param {ExtensionControllerApiUpdate1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update1(
      requestParameters: ExtensionControllerApiUpdate1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseExtensionPathResponse> {
      return localVarFp
        .update1(requestParameters.updatePathRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for _delete operation in ExtensionControllerApi.
 * @export
 * @interface ExtensionControllerApiDeleteRequest
 */
export interface ExtensionControllerApiDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof ExtensionControllerApiDelete
   */
  readonly id: string;
}

/**
 * Request parameters for addNew operation in ExtensionControllerApi.
 * @export
 * @interface ExtensionControllerApiAddNewRequest
 */
export interface ExtensionControllerApiAddNewRequest {
  /**
   *
   * @type {AddNewPathRequest}
   * @memberof ExtensionControllerApiAddNew
   */
  readonly addNewPathRequest: AddNewPathRequest;
}

/**
 * Request parameters for getDetail1 operation in ExtensionControllerApi.
 * @export
 * @interface ExtensionControllerApiGetDetail1Request
 */
export interface ExtensionControllerApiGetDetail1Request {
  /**
   *
   * @type {string}
   * @memberof ExtensionControllerApiGetDetail1
   */
  readonly id: string;
}

/**
 * Request parameters for update1 operation in ExtensionControllerApi.
 * @export
 * @interface ExtensionControllerApiUpdate1Request
 */
export interface ExtensionControllerApiUpdate1Request {
  /**
   *
   * @type {UpdatePathRequest}
   * @memberof ExtensionControllerApiUpdate1
   */
  readonly updatePathRequest: UpdatePathRequest;
}

/**
 * ExtensionControllerApi - object-oriented interface
 * @export
 * @class ExtensionControllerApi
 * @extends {BaseAPI}
 */
export class ExtensionControllerApi extends BaseAPI {
  /**
   *
   * @summary Delete path
   * @param {ExtensionControllerApiDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExtensionControllerApi
   */
  public _delete(
    requestParameters: ExtensionControllerApiDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExtensionControllerApiFp(this.configuration)
      ._delete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add new path variable
   * @param {ExtensionControllerApiAddNewRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExtensionControllerApi
   */
  public addNew(
    requestParameters: ExtensionControllerApiAddNewRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExtensionControllerApiFp(this.configuration)
      .addNew(requestParameters.addNewPathRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExtensionControllerApi
   */
  public getAll(options?: AxiosRequestConfig) {
    return ExtensionControllerApiFp(this.configuration)
      .getAll(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get detail by id
   * @param {ExtensionControllerApiGetDetail1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExtensionControllerApi
   */
  public getDetail1(
    requestParameters: ExtensionControllerApiGetDetail1Request,
    options?: AxiosRequestConfig,
  ) {
    return ExtensionControllerApiFp(this.configuration)
      .getDetail1(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update by id
   * @param {ExtensionControllerApiUpdate1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExtensionControllerApi
   */
  public update1(
    requestParameters: ExtensionControllerApiUpdate1Request,
    options?: AxiosRequestConfig,
  ) {
    return ExtensionControllerApiFp(this.configuration)
      .update1(requestParameters.updatePathRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExternalBankhubControllerApi - axios parameter creator
 * @export
 */
export const ExternalBankhubControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Redirect grant
     * @param {string} state
     * @param {string} publicToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callbackGrantToken: async (
      state: string,
      publicToken: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'state' is not null or undefined
      assertParamExists("callbackGrantToken", "state", state);
      // verify required parameter 'publicToken' is not null or undefined
      assertParamExists("callbackGrantToken", "publicToken", publicToken);
      const localVarPath = `/api/bankhub/v1/grantToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (state !== undefined) {
        localVarQueryParameter["state"] = state;
      }

      if (publicToken !== undefined) {
        localVarQueryParameter["publicToken"] = publicToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thông báo giao dịch
     * @param {BankHubNotifyRequest} bankHubNotifyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransaction3: async (
      bankHubNotifyRequest: BankHubNotifyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bankHubNotifyRequest' is not null or undefined
      assertParamExists(
        "notifyTransaction3",
        "bankHubNotifyRequest",
        bankHubNotifyRequest,
      );
      const localVarPath = `/api/bankhub/v1/notify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bankHubNotifyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExternalBankhubControllerApi - functional programming interface
 * @export
 */
export const ExternalBankhubControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ExternalBankhubControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Redirect grant
     * @param {string} state
     * @param {string} publicToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async callbackGrantToken(
      state: string,
      publicToken: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseBankHubNotifyResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.callbackGrantToken(
          state,
          publicToken,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalBankhubControllerApi.callbackGrantToken"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Thông báo giao dịch
     * @param {BankHubNotifyRequest} bankHubNotifyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notifyTransaction3(
      bankHubNotifyRequest: BankHubNotifyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseBankHubNotifyResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notifyTransaction3(
          bankHubNotifyRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalBankhubControllerApi.notifyTransaction3"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * ExternalBankhubControllerApi - factory interface
 * @export
 */
export const ExternalBankhubControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExternalBankhubControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Redirect grant
     * @param {ExternalBankhubControllerApiCallbackGrantTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callbackGrantToken(
      requestParameters: ExternalBankhubControllerApiCallbackGrantTokenRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseBankHubNotifyResponse> {
      return localVarFp
        .callbackGrantToken(
          requestParameters.state,
          requestParameters.publicToken,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thông báo giao dịch
     * @param {ExternalBankhubControllerApiNotifyTransaction3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransaction3(
      requestParameters: ExternalBankhubControllerApiNotifyTransaction3Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseBankHubNotifyResponse> {
      return localVarFp
        .notifyTransaction3(requestParameters.bankHubNotifyRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for callbackGrantToken operation in ExternalBankhubControllerApi.
 * @export
 * @interface ExternalBankhubControllerApiCallbackGrantTokenRequest
 */
export interface ExternalBankhubControllerApiCallbackGrantTokenRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalBankhubControllerApiCallbackGrantToken
   */
  readonly state: string;

  /**
   *
   * @type {string}
   * @memberof ExternalBankhubControllerApiCallbackGrantToken
   */
  readonly publicToken: string;
}

/**
 * Request parameters for notifyTransaction3 operation in ExternalBankhubControllerApi.
 * @export
 * @interface ExternalBankhubControllerApiNotifyTransaction3Request
 */
export interface ExternalBankhubControllerApiNotifyTransaction3Request {
  /**
   *
   * @type {BankHubNotifyRequest}
   * @memberof ExternalBankhubControllerApiNotifyTransaction3
   */
  readonly bankHubNotifyRequest: BankHubNotifyRequest;
}

/**
 * ExternalBankhubControllerApi - object-oriented interface
 * @export
 * @class ExternalBankhubControllerApi
 * @extends {BaseAPI}
 */
export class ExternalBankhubControllerApi extends BaseAPI {
  /**
   *
   * @summary Redirect grant
   * @param {ExternalBankhubControllerApiCallbackGrantTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalBankhubControllerApi
   */
  public callbackGrantToken(
    requestParameters: ExternalBankhubControllerApiCallbackGrantTokenRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalBankhubControllerApiFp(this.configuration)
      .callbackGrantToken(
        requestParameters.state,
        requestParameters.publicToken,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thông báo giao dịch
   * @param {ExternalBankhubControllerApiNotifyTransaction3Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalBankhubControllerApi
   */
  public notifyTransaction3(
    requestParameters: ExternalBankhubControllerApiNotifyTransaction3Request,
    options?: AxiosRequestConfig,
  ) {
    return ExternalBankhubControllerApiFp(this.configuration)
      .notifyTransaction3(requestParameters.bankHubNotifyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExternalDeviceControllerApi - axios parameter creator
 * @export
 */
export const ExternalDeviceControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Kiểm tra thiết bị
     * @param {ExternalPairDeviceRequest} externalPairDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkDevice: async (
      externalPairDeviceRequest: ExternalPairDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'externalPairDeviceRequest' is not null or undefined
      assertParamExists(
        "checkDevice",
        "externalPairDeviceRequest",
        externalPairDeviceRequest,
      );
      const localVarPath = `/api/external/device/v1/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        externalPairDeviceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách thiết bị
     * @param {object} request
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDevice: async (
      request: object,
      pageable: Pageable,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("getAllDevice", "request", request);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists("getAllDevice", "pageable", pageable);
      const localVarPath = `/api/external/device/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (pageable !== undefined) {
        for (const [key, value] of Object.entries(pageable)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy chi tiết thiết bị
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevice2: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDevice2", "id", id);
      const localVarPath = `/api/external/device/v1/{id}/get`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Liên kết Tài khoản ngân hàng
     * @param {LinkBankAccountRequest} linkBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkBankAccount: async (
      linkBankAccountRequest: LinkBankAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'linkBankAccountRequest' is not null or undefined
      assertParamExists(
        "linkBankAccount",
        "linkBankAccountRequest",
        linkBankAccountRequest,
      );
      const localVarPath = `/api/external/device/v1/linkBankAccount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        linkBankAccountRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Liên kết bằng HardwareId
     * @param {ExternalPairByHardwareIdRequest} externalPairByHardwareIdRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pairByHardwareId: async (
      externalPairByHardwareIdRequest: ExternalPairByHardwareIdRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'externalPairByHardwareIdRequest' is not null or undefined
      assertParamExists(
        "pairByHardwareId",
        "externalPairByHardwareIdRequest",
        externalPairByHardwareIdRequest,
      );
      const localVarPath = `/api/external/device/v1/pairByHardwareId`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        externalPairByHardwareIdRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Liên kết thiết bị
     * @param {ExternalPairDeviceRequest} externalPairDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pairDevice: async (
      externalPairDeviceRequest: ExternalPairDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'externalPairDeviceRequest' is not null or undefined
      assertParamExists(
        "pairDevice",
        "externalPairDeviceRequest",
        externalPairDeviceRequest,
      );
      const localVarPath = `/api/external/device/v1/pair`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        externalPairDeviceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Hủy liên kết thiết bị
     * @param {ExternalUnpairDeviceRequest} externalUnpairDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unpairDevice: async (
      externalUnpairDeviceRequest: ExternalUnpairDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'externalUnpairDeviceRequest' is not null or undefined
      assertParamExists(
        "unpairDevice",
        "externalUnpairDeviceRequest",
        externalUnpairDeviceRequest,
      );
      const localVarPath = `/api/external/device/v1/unpair`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        externalUnpairDeviceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExternalDeviceControllerApi - functional programming interface
 * @export
 */
export const ExternalDeviceControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ExternalDeviceControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Kiểm tra thiết bị
     * @param {ExternalPairDeviceRequest} externalPairDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkDevice(
      externalPairDeviceRequest: ExternalPairDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkDevice(
        externalPairDeviceRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalDeviceControllerApi.checkDevice"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy danh sách thiết bị
     * @param {object} request
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllDevice(
      request: object,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseDeviceExternalDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDevice(
        request,
        pageable,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalDeviceControllerApi.getAllDevice"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy chi tiết thiết bị
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDevice2(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDevice2(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalDeviceControllerApi.getDevice2"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Liên kết Tài khoản ngân hàng
     * @param {LinkBankAccountRequest} linkBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linkBankAccount(
      linkBankAccountRequest: LinkBankAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseLinkBankAccountResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.linkBankAccount(
        linkBankAccountRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalDeviceControllerApi.linkBankAccount"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Liên kết bằng HardwareId
     * @param {ExternalPairByHardwareIdRequest} externalPairByHardwareIdRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pairByHardwareId(
      externalPairByHardwareIdRequest: ExternalPairByHardwareIdRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeviceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.pairByHardwareId(
          externalPairByHardwareIdRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalDeviceControllerApi.pairByHardwareId"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Liên kết thiết bị
     * @param {ExternalPairDeviceRequest} externalPairDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pairDevice(
      externalPairDeviceRequest: ExternalPairDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pairDevice(
        externalPairDeviceRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalDeviceControllerApi.pairDevice"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Hủy liên kết thiết bị
     * @param {ExternalUnpairDeviceRequest} externalUnpairDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unpairDevice(
      externalUnpairDeviceRequest: ExternalUnpairDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unpairDevice(
        externalUnpairDeviceRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalDeviceControllerApi.unpairDevice"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * ExternalDeviceControllerApi - factory interface
 * @export
 */
export const ExternalDeviceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExternalDeviceControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Kiểm tra thiết bị
     * @param {ExternalDeviceControllerApiCheckDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkDevice(
      requestParameters: ExternalDeviceControllerApiCheckDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDeviceResponse> {
      return localVarFp
        .checkDevice(requestParameters.externalPairDeviceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách thiết bị
     * @param {ExternalDeviceControllerApiGetAllDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDevice(
      requestParameters: ExternalDeviceControllerApiGetAllDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePageResponseDeviceExternalDeviceResponse> {
      return localVarFp
        .getAllDevice(
          requestParameters.request,
          requestParameters.pageable,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy chi tiết thiết bị
     * @param {ExternalDeviceControllerApiGetDevice2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevice2(
      requestParameters: ExternalDeviceControllerApiGetDevice2Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseExternalDeviceResponse> {
      return localVarFp
        .getDevice2(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Liên kết Tài khoản ngân hàng
     * @param {ExternalDeviceControllerApiLinkBankAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkBankAccount(
      requestParameters: ExternalDeviceControllerApiLinkBankAccountRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseLinkBankAccountResponse> {
      return localVarFp
        .linkBankAccount(requestParameters.linkBankAccountRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Liên kết bằng HardwareId
     * @param {ExternalDeviceControllerApiPairByHardwareIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pairByHardwareId(
      requestParameters: ExternalDeviceControllerApiPairByHardwareIdRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDeviceResponse> {
      return localVarFp
        .pairByHardwareId(
          requestParameters.externalPairByHardwareIdRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Liên kết thiết bị
     * @param {ExternalDeviceControllerApiPairDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pairDevice(
      requestParameters: ExternalDeviceControllerApiPairDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDeviceResponse> {
      return localVarFp
        .pairDevice(requestParameters.externalPairDeviceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Hủy liên kết thiết bị
     * @param {ExternalDeviceControllerApiUnpairDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unpairDevice(
      requestParameters: ExternalDeviceControllerApiUnpairDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .unpairDevice(requestParameters.externalUnpairDeviceRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for checkDevice operation in ExternalDeviceControllerApi.
 * @export
 * @interface ExternalDeviceControllerApiCheckDeviceRequest
 */
export interface ExternalDeviceControllerApiCheckDeviceRequest {
  /**
   *
   * @type {ExternalPairDeviceRequest}
   * @memberof ExternalDeviceControllerApiCheckDevice
   */
  readonly externalPairDeviceRequest: ExternalPairDeviceRequest;
}

/**
 * Request parameters for getAllDevice operation in ExternalDeviceControllerApi.
 * @export
 * @interface ExternalDeviceControllerApiGetAllDeviceRequest
 */
export interface ExternalDeviceControllerApiGetAllDeviceRequest {
  /**
   *
   * @type {object}
   * @memberof ExternalDeviceControllerApiGetAllDevice
   */
  readonly request: object;

  /**
   *
   * @type {Pageable}
   * @memberof ExternalDeviceControllerApiGetAllDevice
   */
  readonly pageable: Pageable;
}

/**
 * Request parameters for getDevice2 operation in ExternalDeviceControllerApi.
 * @export
 * @interface ExternalDeviceControllerApiGetDevice2Request
 */
export interface ExternalDeviceControllerApiGetDevice2Request {
  /**
   *
   * @type {string}
   * @memberof ExternalDeviceControllerApiGetDevice2
   */
  readonly id: string;
}

/**
 * Request parameters for linkBankAccount operation in ExternalDeviceControllerApi.
 * @export
 * @interface ExternalDeviceControllerApiLinkBankAccountRequest
 */
export interface ExternalDeviceControllerApiLinkBankAccountRequest {
  /**
   *
   * @type {LinkBankAccountRequest}
   * @memberof ExternalDeviceControllerApiLinkBankAccount
   */
  readonly linkBankAccountRequest: LinkBankAccountRequest;
}

/**
 * Request parameters for pairByHardwareId operation in ExternalDeviceControllerApi.
 * @export
 * @interface ExternalDeviceControllerApiPairByHardwareIdRequest
 */
export interface ExternalDeviceControllerApiPairByHardwareIdRequest {
  /**
   *
   * @type {ExternalPairByHardwareIdRequest}
   * @memberof ExternalDeviceControllerApiPairByHardwareId
   */
  readonly externalPairByHardwareIdRequest: ExternalPairByHardwareIdRequest;
}

/**
 * Request parameters for pairDevice operation in ExternalDeviceControllerApi.
 * @export
 * @interface ExternalDeviceControllerApiPairDeviceRequest
 */
export interface ExternalDeviceControllerApiPairDeviceRequest {
  /**
   *
   * @type {ExternalPairDeviceRequest}
   * @memberof ExternalDeviceControllerApiPairDevice
   */
  readonly externalPairDeviceRequest: ExternalPairDeviceRequest;
}

/**
 * Request parameters for unpairDevice operation in ExternalDeviceControllerApi.
 * @export
 * @interface ExternalDeviceControllerApiUnpairDeviceRequest
 */
export interface ExternalDeviceControllerApiUnpairDeviceRequest {
  /**
   *
   * @type {ExternalUnpairDeviceRequest}
   * @memberof ExternalDeviceControllerApiUnpairDevice
   */
  readonly externalUnpairDeviceRequest: ExternalUnpairDeviceRequest;
}

/**
 * ExternalDeviceControllerApi - object-oriented interface
 * @export
 * @class ExternalDeviceControllerApi
 * @extends {BaseAPI}
 */
export class ExternalDeviceControllerApi extends BaseAPI {
  /**
   *
   * @summary Kiểm tra thiết bị
   * @param {ExternalDeviceControllerApiCheckDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalDeviceControllerApi
   */
  public checkDevice(
    requestParameters: ExternalDeviceControllerApiCheckDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalDeviceControllerApiFp(this.configuration)
      .checkDevice(requestParameters.externalPairDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách thiết bị
   * @param {ExternalDeviceControllerApiGetAllDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalDeviceControllerApi
   */
  public getAllDevice(
    requestParameters: ExternalDeviceControllerApiGetAllDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalDeviceControllerApiFp(this.configuration)
      .getAllDevice(
        requestParameters.request,
        requestParameters.pageable,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy chi tiết thiết bị
   * @param {ExternalDeviceControllerApiGetDevice2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalDeviceControllerApi
   */
  public getDevice2(
    requestParameters: ExternalDeviceControllerApiGetDevice2Request,
    options?: AxiosRequestConfig,
  ) {
    return ExternalDeviceControllerApiFp(this.configuration)
      .getDevice2(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Liên kết Tài khoản ngân hàng
   * @param {ExternalDeviceControllerApiLinkBankAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalDeviceControllerApi
   */
  public linkBankAccount(
    requestParameters: ExternalDeviceControllerApiLinkBankAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalDeviceControllerApiFp(this.configuration)
      .linkBankAccount(requestParameters.linkBankAccountRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Liên kết bằng HardwareId
   * @param {ExternalDeviceControllerApiPairByHardwareIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalDeviceControllerApi
   */
  public pairByHardwareId(
    requestParameters: ExternalDeviceControllerApiPairByHardwareIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalDeviceControllerApiFp(this.configuration)
      .pairByHardwareId(
        requestParameters.externalPairByHardwareIdRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Liên kết thiết bị
   * @param {ExternalDeviceControllerApiPairDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalDeviceControllerApi
   */
  public pairDevice(
    requestParameters: ExternalDeviceControllerApiPairDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalDeviceControllerApiFp(this.configuration)
      .pairDevice(requestParameters.externalPairDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Hủy liên kết thiết bị
   * @param {ExternalDeviceControllerApiUnpairDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalDeviceControllerApi
   */
  public unpairDevice(
    requestParameters: ExternalDeviceControllerApiUnpairDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalDeviceControllerApiFp(this.configuration)
      .unpairDevice(requestParameters.externalUnpairDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExternalStaticQRControllerApi - axios parameter creator
 * @export
 */
export const ExternalStaticQRControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Tạo một qr ngân hàng tĩnh
     * @param {ExternalCreateBankStaticQrRequest} externalCreateBankStaticQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBankQr: async (
      externalCreateBankStaticQrRequest: ExternalCreateBankStaticQrRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'externalCreateBankStaticQrRequest' is not null or undefined
      assertParamExists(
        "createBankQr",
        "externalCreateBankStaticQrRequest",
        externalCreateBankStaticQrRequest,
      );
      const localVarPath = `/api/external/qr/v1/bank`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        externalCreateBankStaticQrRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo một qr ví điện tử tĩnh
     * @param {ExternalCreateEWalletStaticQrRequest} externalCreateEWalletStaticQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEWalletQr: async (
      externalCreateEWalletStaticQrRequest: ExternalCreateEWalletStaticQrRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'externalCreateEWalletStaticQrRequest' is not null or undefined
      assertParamExists(
        "createEWalletQr",
        "externalCreateEWalletStaticQrRequest",
        externalCreateEWalletStaticQrRequest,
      );
      const localVarPath = `/api/external/qr/v1/eWallet`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        externalCreateEWalletStaticQrRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa một qr tĩnh
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQr: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteQr", "id", id);
      const localVarPath = `/api/external/qr/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách QR tĩnh
     * @param {ExternalGetAllStaticQrRequest} request
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllQr1: async (
      request: ExternalGetAllStaticQrRequest,
      pageable: Pageable,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("getAllQr1", "request", request);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists("getAllQr1", "pageable", pageable);
      const localVarPath = `/api/external/qr/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (pageable !== undefined) {
        for (const [key, value] of Object.entries(pageable)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy chi tiết qr tĩnh
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQr: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getQr", "id", id);
      const localVarPath = `/api/external/qr/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chỉnh sửa một qr ngân hàng tĩnh
     * @param {string} id
     * @param {ExternalUpdateBankStaticQrRequest} externalUpdateBankStaticQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBankQr: async (
      id: string,
      externalUpdateBankStaticQrRequest: ExternalUpdateBankStaticQrRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updateBankQr", "id", id);
      // verify required parameter 'externalUpdateBankStaticQrRequest' is not null or undefined
      assertParamExists(
        "updateBankQr",
        "externalUpdateBankStaticQrRequest",
        externalUpdateBankStaticQrRequest,
      );
      const localVarPath = `/api/external/qr/v1/bank/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        externalUpdateBankStaticQrRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chỉnh sửa một qr ví điện tử tĩnh
     * @param {string} id
     * @param {ExternalUpdateEWalletStaticQrRequest} externalUpdateEWalletStaticQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEWalletQr: async (
      id: string,
      externalUpdateEWalletStaticQrRequest: ExternalUpdateEWalletStaticQrRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updateEWalletQr", "id", id);
      // verify required parameter 'externalUpdateEWalletStaticQrRequest' is not null or undefined
      assertParamExists(
        "updateEWalletQr",
        "externalUpdateEWalletStaticQrRequest",
        externalUpdateEWalletStaticQrRequest,
      );
      const localVarPath = `/api/external/qr/v1/eWallet/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        externalUpdateEWalletStaticQrRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExternalStaticQRControllerApi - functional programming interface
 * @export
 */
export const ExternalStaticQRControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ExternalStaticQRControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Tạo một qr ngân hàng tĩnh
     * @param {ExternalCreateBankStaticQrRequest} externalCreateBankStaticQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createBankQr(
      externalCreateBankStaticQrRequest: ExternalCreateBankStaticQrRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalQrDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createBankQr(
        externalCreateBankStaticQrRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalStaticQRControllerApi.createBankQr"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Tạo một qr ví điện tử tĩnh
     * @param {ExternalCreateEWalletStaticQrRequest} externalCreateEWalletStaticQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEWalletQr(
      externalCreateEWalletStaticQrRequest: ExternalCreateEWalletStaticQrRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalQrDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEWalletQr(
        externalCreateEWalletStaticQrRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalStaticQRControllerApi.createEWalletQr"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Xóa một qr tĩnh
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteQr(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQr(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalStaticQRControllerApi.deleteQr"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy danh sách QR tĩnh
     * @param {ExternalGetAllStaticQrRequest} request
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllQr1(
      request: ExternalGetAllStaticQrRequest,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseExternalQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllQr1(
        request,
        pageable,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalStaticQRControllerApi.getAllQr1"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy chi tiết qr tĩnh
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQr(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalQrDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQr(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalStaticQRControllerApi.getQr"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Chỉnh sửa một qr ngân hàng tĩnh
     * @param {string} id
     * @param {ExternalUpdateBankStaticQrRequest} externalUpdateBankStaticQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBankQr(
      id: string,
      externalUpdateBankStaticQrRequest: ExternalUpdateBankStaticQrRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalQrDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBankQr(
        id,
        externalUpdateBankStaticQrRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalStaticQRControllerApi.updateBankQr"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Chỉnh sửa một qr ví điện tử tĩnh
     * @param {string} id
     * @param {ExternalUpdateEWalletStaticQrRequest} externalUpdateEWalletStaticQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEWalletQr(
      id: string,
      externalUpdateEWalletStaticQrRequest: ExternalUpdateEWalletStaticQrRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalQrDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEWalletQr(
        id,
        externalUpdateEWalletStaticQrRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalStaticQRControllerApi.updateEWalletQr"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * ExternalStaticQRControllerApi - factory interface
 * @export
 */
export const ExternalStaticQRControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExternalStaticQRControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Tạo một qr ngân hàng tĩnh
     * @param {ExternalStaticQRControllerApiCreateBankQrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBankQr(
      requestParameters: ExternalStaticQRControllerApiCreateBankQrRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseExternalQrDetailResponse> {
      return localVarFp
        .createBankQr(
          requestParameters.externalCreateBankStaticQrRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo một qr ví điện tử tĩnh
     * @param {ExternalStaticQRControllerApiCreateEWalletQrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEWalletQr(
      requestParameters: ExternalStaticQRControllerApiCreateEWalletQrRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseExternalQrDetailResponse> {
      return localVarFp
        .createEWalletQr(
          requestParameters.externalCreateEWalletStaticQrRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa một qr tĩnh
     * @param {ExternalStaticQRControllerApiDeleteQrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQr(
      requestParameters: ExternalStaticQRControllerApiDeleteQrRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .deleteQr(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách QR tĩnh
     * @param {ExternalStaticQRControllerApiGetAllQr1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllQr1(
      requestParameters: ExternalStaticQRControllerApiGetAllQr1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePageResponseExternalQrResponse> {
      return localVarFp
        .getAllQr1(
          requestParameters.request,
          requestParameters.pageable,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy chi tiết qr tĩnh
     * @param {ExternalStaticQRControllerApiGetQrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQr(
      requestParameters: ExternalStaticQRControllerApiGetQrRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseExternalQrDetailResponse> {
      return localVarFp
        .getQr(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chỉnh sửa một qr ngân hàng tĩnh
     * @param {ExternalStaticQRControllerApiUpdateBankQrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBankQr(
      requestParameters: ExternalStaticQRControllerApiUpdateBankQrRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseExternalQrDetailResponse> {
      return localVarFp
        .updateBankQr(
          requestParameters.id,
          requestParameters.externalUpdateBankStaticQrRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chỉnh sửa một qr ví điện tử tĩnh
     * @param {ExternalStaticQRControllerApiUpdateEWalletQrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEWalletQr(
      requestParameters: ExternalStaticQRControllerApiUpdateEWalletQrRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseExternalQrDetailResponse> {
      return localVarFp
        .updateEWalletQr(
          requestParameters.id,
          requestParameters.externalUpdateEWalletStaticQrRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createBankQr operation in ExternalStaticQRControllerApi.
 * @export
 * @interface ExternalStaticQRControllerApiCreateBankQrRequest
 */
export interface ExternalStaticQRControllerApiCreateBankQrRequest {
  /**
   *
   * @type {ExternalCreateBankStaticQrRequest}
   * @memberof ExternalStaticQRControllerApiCreateBankQr
   */
  readonly externalCreateBankStaticQrRequest: ExternalCreateBankStaticQrRequest;
}

/**
 * Request parameters for createEWalletQr operation in ExternalStaticQRControllerApi.
 * @export
 * @interface ExternalStaticQRControllerApiCreateEWalletQrRequest
 */
export interface ExternalStaticQRControllerApiCreateEWalletQrRequest {
  /**
   *
   * @type {ExternalCreateEWalletStaticQrRequest}
   * @memberof ExternalStaticQRControllerApiCreateEWalletQr
   */
  readonly externalCreateEWalletStaticQrRequest: ExternalCreateEWalletStaticQrRequest;
}

/**
 * Request parameters for deleteQr operation in ExternalStaticQRControllerApi.
 * @export
 * @interface ExternalStaticQRControllerApiDeleteQrRequest
 */
export interface ExternalStaticQRControllerApiDeleteQrRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalStaticQRControllerApiDeleteQr
   */
  readonly id: string;
}

/**
 * Request parameters for getAllQr1 operation in ExternalStaticQRControllerApi.
 * @export
 * @interface ExternalStaticQRControllerApiGetAllQr1Request
 */
export interface ExternalStaticQRControllerApiGetAllQr1Request {
  /**
   *
   * @type {ExternalGetAllStaticQrRequest}
   * @memberof ExternalStaticQRControllerApiGetAllQr1
   */
  readonly request: ExternalGetAllStaticQrRequest;

  /**
   *
   * @type {Pageable}
   * @memberof ExternalStaticQRControllerApiGetAllQr1
   */
  readonly pageable: Pageable;
}

/**
 * Request parameters for getQr operation in ExternalStaticQRControllerApi.
 * @export
 * @interface ExternalStaticQRControllerApiGetQrRequest
 */
export interface ExternalStaticQRControllerApiGetQrRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalStaticQRControllerApiGetQr
   */
  readonly id: string;
}

/**
 * Request parameters for updateBankQr operation in ExternalStaticQRControllerApi.
 * @export
 * @interface ExternalStaticQRControllerApiUpdateBankQrRequest
 */
export interface ExternalStaticQRControllerApiUpdateBankQrRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalStaticQRControllerApiUpdateBankQr
   */
  readonly id: string;

  /**
   *
   * @type {ExternalUpdateBankStaticQrRequest}
   * @memberof ExternalStaticQRControllerApiUpdateBankQr
   */
  readonly externalUpdateBankStaticQrRequest: ExternalUpdateBankStaticQrRequest;
}

/**
 * Request parameters for updateEWalletQr operation in ExternalStaticQRControllerApi.
 * @export
 * @interface ExternalStaticQRControllerApiUpdateEWalletQrRequest
 */
export interface ExternalStaticQRControllerApiUpdateEWalletQrRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalStaticQRControllerApiUpdateEWalletQr
   */
  readonly id: string;

  /**
   *
   * @type {ExternalUpdateEWalletStaticQrRequest}
   * @memberof ExternalStaticQRControllerApiUpdateEWalletQr
   */
  readonly externalUpdateEWalletStaticQrRequest: ExternalUpdateEWalletStaticQrRequest;
}

/**
 * ExternalStaticQRControllerApi - object-oriented interface
 * @export
 * @class ExternalStaticQRControllerApi
 * @extends {BaseAPI}
 */
export class ExternalStaticQRControllerApi extends BaseAPI {
  /**
   *
   * @summary Tạo một qr ngân hàng tĩnh
   * @param {ExternalStaticQRControllerApiCreateBankQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalStaticQRControllerApi
   */
  public createBankQr(
    requestParameters: ExternalStaticQRControllerApiCreateBankQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalStaticQRControllerApiFp(this.configuration)
      .createBankQr(
        requestParameters.externalCreateBankStaticQrRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo một qr ví điện tử tĩnh
   * @param {ExternalStaticQRControllerApiCreateEWalletQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalStaticQRControllerApi
   */
  public createEWalletQr(
    requestParameters: ExternalStaticQRControllerApiCreateEWalletQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalStaticQRControllerApiFp(this.configuration)
      .createEWalletQr(
        requestParameters.externalCreateEWalletStaticQrRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa một qr tĩnh
   * @param {ExternalStaticQRControllerApiDeleteQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalStaticQRControllerApi
   */
  public deleteQr(
    requestParameters: ExternalStaticQRControllerApiDeleteQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalStaticQRControllerApiFp(this.configuration)
      .deleteQr(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách QR tĩnh
   * @param {ExternalStaticQRControllerApiGetAllQr1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalStaticQRControllerApi
   */
  public getAllQr1(
    requestParameters: ExternalStaticQRControllerApiGetAllQr1Request,
    options?: AxiosRequestConfig,
  ) {
    return ExternalStaticQRControllerApiFp(this.configuration)
      .getAllQr1(requestParameters.request, requestParameters.pageable, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy chi tiết qr tĩnh
   * @param {ExternalStaticQRControllerApiGetQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalStaticQRControllerApi
   */
  public getQr(
    requestParameters: ExternalStaticQRControllerApiGetQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalStaticQRControllerApiFp(this.configuration)
      .getQr(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chỉnh sửa một qr ngân hàng tĩnh
   * @param {ExternalStaticQRControllerApiUpdateBankQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalStaticQRControllerApi
   */
  public updateBankQr(
    requestParameters: ExternalStaticQRControllerApiUpdateBankQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalStaticQRControllerApiFp(this.configuration)
      .updateBankQr(
        requestParameters.id,
        requestParameters.externalUpdateBankStaticQrRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chỉnh sửa một qr ví điện tử tĩnh
   * @param {ExternalStaticQRControllerApiUpdateEWalletQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalStaticQRControllerApi
   */
  public updateEWalletQr(
    requestParameters: ExternalStaticQRControllerApiUpdateEWalletQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalStaticQRControllerApiFp(this.configuration)
      .updateEWalletQr(
        requestParameters.id,
        requestParameters.externalUpdateEWalletStaticQrRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExternalTransactionControllerApi - axios parameter creator
 * @export
 */
export const ExternalTransactionControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Tạo qr động với tài khoản được link với máy
     * @param {ExternalCreateTransactionRequest} externalCreateTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransaction: async (
      externalCreateTransactionRequest: ExternalCreateTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'externalCreateTransactionRequest' is not null or undefined
      assertParamExists(
        "createTransaction",
        "externalCreateTransactionRequest",
        externalCreateTransactionRequest,
      );
      const localVarPath = `/api/external/transaction/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        externalCreateTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách giao dịch
     * @param {ExternalGetAllTransactionRequest} request
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTransaction: async (
      request: ExternalGetAllTransactionRequest,
      pageable: Pageable,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("getAllTransaction", "request", request);
      // verify required parameter 'pageable' is not null or undefined
      assertParamExists("getAllTransaction", "pageable", pageable);
      const localVarPath = `/api/external/transaction/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (pageable !== undefined) {
        for (const [key, value] of Object.entries(pageable)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy chi tiết giao dịch
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransaction1: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getTransaction1", "id", id);
      const localVarPath = `/api/external/transaction/v1/{id}/get`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thông báo giao dịch
     * @param {ExternalNotifyTransactionRequest} externalNotifyTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransaction2: async (
      externalNotifyTransactionRequest: ExternalNotifyTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'externalNotifyTransactionRequest' is not null or undefined
      assertParamExists(
        "notifyTransaction2",
        "externalNotifyTransactionRequest",
        externalNotifyTransactionRequest,
      );
      const localVarPath = `/api/external/transaction/v1/notify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        externalNotifyTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Show mã qr tĩnh
     * @param {ExternalShowQrRequest} externalShowQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showQr: async (
      externalShowQrRequest: ExternalShowQrRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'externalShowQrRequest' is not null or undefined
      assertParamExists(
        "showQr",
        "externalShowQrRequest",
        externalShowQrRequest,
      );
      const localVarPath = `/api/external/transaction/v1/show`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        externalShowQrRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExternalTransactionControllerApi - functional programming interface
 * @export
 */
export const ExternalTransactionControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ExternalTransactionControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Tạo qr động với tài khoản được link với máy
     * @param {ExternalCreateTransactionRequest} externalCreateTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTransaction(
      externalCreateTransactionRequest: ExternalCreateTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalCreateTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createTransaction(
          externalCreateTransactionRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap[
          "ExternalTransactionControllerApi.createTransaction"
        ]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy danh sách giao dịch
     * @param {ExternalGetAllTransactionRequest} request
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllTransaction(
      request: ExternalGetAllTransactionRequest,
      pageable: Pageable,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseTransactionExternalTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllTransaction(
          request,
          pageable,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap[
          "ExternalTransactionControllerApi.getAllTransaction"
        ]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy chi tiết giao dịch
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransaction1(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseTransactionExternalTransactionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransaction1(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap[
          "ExternalTransactionControllerApi.getTransaction1"
        ]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Thông báo giao dịch
     * @param {ExternalNotifyTransactionRequest} externalNotifyTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notifyTransaction2(
      externalNotifyTransactionRequest: ExternalNotifyTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notifyTransaction2(
          externalNotifyTransactionRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap[
          "ExternalTransactionControllerApi.notifyTransaction2"
        ]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Show mã qr tĩnh
     * @param {ExternalShowQrRequest} externalShowQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showQr(
      externalShowQrRequest: ExternalShowQrRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.showQr(
        externalShowQrRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ExternalTransactionControllerApi.showQr"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * ExternalTransactionControllerApi - factory interface
 * @export
 */
export const ExternalTransactionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExternalTransactionControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Tạo qr động với tài khoản được link với máy
     * @param {ExternalTransactionControllerApiCreateTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransaction(
      requestParameters: ExternalTransactionControllerApiCreateTransactionRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseExternalCreateTransactionResponse> {
      return localVarFp
        .createTransaction(
          requestParameters.externalCreateTransactionRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách giao dịch
     * @param {ExternalTransactionControllerApiGetAllTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTransaction(
      requestParameters: ExternalTransactionControllerApiGetAllTransactionRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePageResponseTransactionExternalTransactionResponse> {
      return localVarFp
        .getAllTransaction(
          requestParameters.request,
          requestParameters.pageable,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy chi tiết giao dịch
     * @param {ExternalTransactionControllerApiGetTransaction1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransaction1(
      requestParameters: ExternalTransactionControllerApiGetTransaction1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePageResponseTransactionExternalTransactionResponse> {
      return localVarFp
        .getTransaction1(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thông báo giao dịch
     * @param {ExternalTransactionControllerApiNotifyTransaction2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransaction2(
      requestParameters: ExternalTransactionControllerApiNotifyTransaction2Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseExternalTransactionResponse> {
      return localVarFp
        .notifyTransaction2(
          requestParameters.externalNotifyTransactionRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Show mã qr tĩnh
     * @param {ExternalTransactionControllerApiShowQrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showQr(
      requestParameters: ExternalTransactionControllerApiShowQrRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .showQr(requestParameters.externalShowQrRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createTransaction operation in ExternalTransactionControllerApi.
 * @export
 * @interface ExternalTransactionControllerApiCreateTransactionRequest
 */
export interface ExternalTransactionControllerApiCreateTransactionRequest {
  /**
   *
   * @type {ExternalCreateTransactionRequest}
   * @memberof ExternalTransactionControllerApiCreateTransaction
   */
  readonly externalCreateTransactionRequest: ExternalCreateTransactionRequest;
}

/**
 * Request parameters for getAllTransaction operation in ExternalTransactionControllerApi.
 * @export
 * @interface ExternalTransactionControllerApiGetAllTransactionRequest
 */
export interface ExternalTransactionControllerApiGetAllTransactionRequest {
  /**
   *
   * @type {ExternalGetAllTransactionRequest}
   * @memberof ExternalTransactionControllerApiGetAllTransaction
   */
  readonly request: ExternalGetAllTransactionRequest;

  /**
   *
   * @type {Pageable}
   * @memberof ExternalTransactionControllerApiGetAllTransaction
   */
  readonly pageable: Pageable;
}

/**
 * Request parameters for getTransaction1 operation in ExternalTransactionControllerApi.
 * @export
 * @interface ExternalTransactionControllerApiGetTransaction1Request
 */
export interface ExternalTransactionControllerApiGetTransaction1Request {
  /**
   *
   * @type {string}
   * @memberof ExternalTransactionControllerApiGetTransaction1
   */
  readonly id: string;
}

/**
 * Request parameters for notifyTransaction2 operation in ExternalTransactionControllerApi.
 * @export
 * @interface ExternalTransactionControllerApiNotifyTransaction2Request
 */
export interface ExternalTransactionControllerApiNotifyTransaction2Request {
  /**
   *
   * @type {ExternalNotifyTransactionRequest}
   * @memberof ExternalTransactionControllerApiNotifyTransaction2
   */
  readonly externalNotifyTransactionRequest: ExternalNotifyTransactionRequest;
}

/**
 * Request parameters for showQr operation in ExternalTransactionControllerApi.
 * @export
 * @interface ExternalTransactionControllerApiShowQrRequest
 */
export interface ExternalTransactionControllerApiShowQrRequest {
  /**
   *
   * @type {ExternalShowQrRequest}
   * @memberof ExternalTransactionControllerApiShowQr
   */
  readonly externalShowQrRequest: ExternalShowQrRequest;
}

/**
 * ExternalTransactionControllerApi - object-oriented interface
 * @export
 * @class ExternalTransactionControllerApi
 * @extends {BaseAPI}
 */
export class ExternalTransactionControllerApi extends BaseAPI {
  /**
   *
   * @summary Tạo qr động với tài khoản được link với máy
   * @param {ExternalTransactionControllerApiCreateTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalTransactionControllerApi
   */
  public createTransaction(
    requestParameters: ExternalTransactionControllerApiCreateTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalTransactionControllerApiFp(this.configuration)
      .createTransaction(
        requestParameters.externalCreateTransactionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách giao dịch
   * @param {ExternalTransactionControllerApiGetAllTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalTransactionControllerApi
   */
  public getAllTransaction(
    requestParameters: ExternalTransactionControllerApiGetAllTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalTransactionControllerApiFp(this.configuration)
      .getAllTransaction(
        requestParameters.request,
        requestParameters.pageable,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy chi tiết giao dịch
   * @param {ExternalTransactionControllerApiGetTransaction1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalTransactionControllerApi
   */
  public getTransaction1(
    requestParameters: ExternalTransactionControllerApiGetTransaction1Request,
    options?: AxiosRequestConfig,
  ) {
    return ExternalTransactionControllerApiFp(this.configuration)
      .getTransaction1(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thông báo giao dịch
   * @param {ExternalTransactionControllerApiNotifyTransaction2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalTransactionControllerApi
   */
  public notifyTransaction2(
    requestParameters: ExternalTransactionControllerApiNotifyTransaction2Request,
    options?: AxiosRequestConfig,
  ) {
    return ExternalTransactionControllerApiFp(this.configuration)
      .notifyTransaction2(
        requestParameters.externalNotifyTransactionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Show mã qr tĩnh
   * @param {ExternalTransactionControllerApiShowQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalTransactionControllerApi
   */
  public showQr(
    requestParameters: ExternalTransactionControllerApiShowQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalTransactionControllerApiFp(this.configuration)
      .showQr(requestParameters.externalShowQrRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkHealthy: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/health`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkHealthy(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkHealthy(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["HealthApi.checkHealthy"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HealthApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkHealthy(options?: AxiosRequestConfig): AxiosPromise<string> {
      return localVarFp
        .checkHealthy(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthApi
   */
  public checkHealthy(options?: AxiosRequestConfig) {
    return HealthApiFp(this.configuration)
      .checkHealthy(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MailControllerApi - axios parameter creator
 * @export
 */
export const MailControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {SendEmailRequest} sendEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmailTemplate: async (
      sendEmailRequest: SendEmailRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendEmailRequest' is not null or undefined
      assertParamExists(
        "sendEmailTemplate",
        "sendEmailRequest",
        sendEmailRequest,
      );
      const localVarPath = `/api/notification/v1/sendEmailTemplate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendEmailRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {VerifyRegistryRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendVerifyEmail: async (
      request: VerifyRegistryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("sendVerifyEmail", "request", request);
      const localVarPath = `/api/notification/v1/send-verify-email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MailControllerApi - functional programming interface
 * @export
 */
export const MailControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    MailControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SendEmailRequest} sendEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendEmailTemplate(
      sendEmailRequest: SendEmailRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sendEmailTemplate(
          sendEmailRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["MailControllerApi.sendEmailTemplate"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {VerifyRegistryRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendVerifyEmail(
      request: VerifyRegistryRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendVerifyEmail(
        request,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["MailControllerApi.sendVerifyEmail"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * MailControllerApi - factory interface
 * @export
 */
export const MailControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MailControllerApiFp(configuration);
  return {
    /**
     *
     * @param {MailControllerApiSendEmailTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmailTemplate(
      requestParameters: MailControllerApiSendEmailTemplateRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<string> {
      return localVarFp
        .sendEmailTemplate(requestParameters.sendEmailRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {MailControllerApiSendVerifyEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendVerifyEmail(
      requestParameters: MailControllerApiSendVerifyEmailRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .sendVerifyEmail(requestParameters.request, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for sendEmailTemplate operation in MailControllerApi.
 * @export
 * @interface MailControllerApiSendEmailTemplateRequest
 */
export interface MailControllerApiSendEmailTemplateRequest {
  /**
   *
   * @type {SendEmailRequest}
   * @memberof MailControllerApiSendEmailTemplate
   */
  readonly sendEmailRequest: SendEmailRequest;
}

/**
 * Request parameters for sendVerifyEmail operation in MailControllerApi.
 * @export
 * @interface MailControllerApiSendVerifyEmailRequest
 */
export interface MailControllerApiSendVerifyEmailRequest {
  /**
   *
   * @type {VerifyRegistryRequest}
   * @memberof MailControllerApiSendVerifyEmail
   */
  readonly request: VerifyRegistryRequest;
}

/**
 * MailControllerApi - object-oriented interface
 * @export
 * @class MailControllerApi
 * @extends {BaseAPI}
 */
export class MailControllerApi extends BaseAPI {
  /**
   *
   * @param {MailControllerApiSendEmailTemplateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MailControllerApi
   */
  public sendEmailTemplate(
    requestParameters: MailControllerApiSendEmailTemplateRequest,
    options?: AxiosRequestConfig,
  ) {
    return MailControllerApiFp(this.configuration)
      .sendEmailTemplate(requestParameters.sendEmailRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MailControllerApiSendVerifyEmailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MailControllerApi
   */
  public sendVerifyEmail(
    requestParameters: MailControllerApiSendVerifyEmailRequest,
    options?: AxiosRequestConfig,
  ) {
    return MailControllerApiFp(this.configuration)
      .sendVerifyEmail(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ManufacturerControllerApi - axios parameter creator
 * @export
 */
export const ManufacturerControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Tạo mới 1 manToken
     * @param {CreateManufacturerTokenRequest} createManufacturerTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createManToken: async (
      createManufacturerTokenRequest: CreateManufacturerTokenRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createManufacturerTokenRequest' is not null or undefined
      assertParamExists(
        "createManToken",
        "createManufacturerTokenRequest",
        createManufacturerTokenRequest,
      );
      const localVarPath = `/api/admin/manufacturer/v1/createToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createManufacturerTokenRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ManufacturerControllerApi - functional programming interface
 * @export
 */
export const ManufacturerControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ManufacturerControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Tạo mới 1 manToken
     * @param {CreateManufacturerTokenRequest} createManufacturerTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createManToken(
      createManufacturerTokenRequest: CreateManufacturerTokenRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCreateManufacturerTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createManToken(
        createManufacturerTokenRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ManufacturerControllerApi.createManToken"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * ManufacturerControllerApi - factory interface
 * @export
 */
export const ManufacturerControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ManufacturerControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Tạo mới 1 manToken
     * @param {ManufacturerControllerApiCreateManTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createManToken(
      requestParameters: ManufacturerControllerApiCreateManTokenRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseCreateManufacturerTokenResponse> {
      return localVarFp
        .createManToken(
          requestParameters.createManufacturerTokenRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createManToken operation in ManufacturerControllerApi.
 * @export
 * @interface ManufacturerControllerApiCreateManTokenRequest
 */
export interface ManufacturerControllerApiCreateManTokenRequest {
  /**
   *
   * @type {CreateManufacturerTokenRequest}
   * @memberof ManufacturerControllerApiCreateManToken
   */
  readonly createManufacturerTokenRequest: CreateManufacturerTokenRequest;
}

/**
 * ManufacturerControllerApi - object-oriented interface
 * @export
 * @class ManufacturerControllerApi
 * @extends {BaseAPI}
 */
export class ManufacturerControllerApi extends BaseAPI {
  /**
   *
   * @summary Tạo mới 1 manToken
   * @param {ManufacturerControllerApiCreateManTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturerControllerApi
   */
  public createManToken(
    requestParameters: ManufacturerControllerApiCreateManTokenRequest,
    options?: AxiosRequestConfig,
  ) {
    return ManufacturerControllerApiFp(this.configuration)
      .createManToken(requestParameters.createManufacturerTokenRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OpenApiControllerApi - axios parameter creator
 * @export
 */
export const OpenApiControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Huỷ show QRCode
     * @param {CancelQRCodeOARequest} cancelQRCodeOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelQrCode1: async (
      cancelQRCodeOARequest: CancelQRCodeOARequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cancelQRCodeOARequest' is not null or undefined
      assertParamExists(
        "cancelQrCode1",
        "cancelQRCodeOARequest",
        cancelQRCodeOARequest,
      );
      const localVarPath = `/api/open-api/v1/qrcode/cancel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cancelQRCodeOARequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Link device - Kiểm tra thiết bị
     * @param {CheckDeviceOARequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkDevice2: async (
      request: CheckDeviceOARequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("checkDevice2", "request", request);
      const localVarPath = `/api/open-api/v1/devices/pair/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo qr động - Custom QRCode
     * @param {CustomDynamicQrOARequest} customDynamicQrOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customDynamicQRCode1: async (
      customDynamicQrOARequest: CustomDynamicQrOARequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customDynamicQrOARequest' is not null or undefined
      assertParamExists(
        "customDynamicQRCode1",
        "customDynamicQrOARequest",
        customDynamicQrOARequest,
      );
      const localVarPath = `/api/open-api/v1/qrcode/dynamic/custom`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        customDynamicQrOARequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Device Detail
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevice1: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDevice1", "id", id);
      const localVarPath = `/api/open-api/v1/devices/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách device
     * @param {GetDeviceListOARequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListDevice1: async (
      request: GetDeviceListOARequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("getListDevice1", "request", request);
      const localVarPath = `/api/open-api/v1/devices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thông báo biến động số dư
     * @param {NotifyTransactionOARequest} notifyTransactionOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransaction1: async (
      notifyTransactionOARequest: NotifyTransactionOARequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'notifyTransactionOARequest' is not null or undefined
      assertParamExists(
        "notifyTransaction1",
        "notifyTransactionOARequest",
        notifyTransactionOARequest,
      );
      const localVarPath = `/api/open-api/v1/transactions/notify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notifyTransactionOARequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Link device - Yêu cầu liên kết thiết bị
     * @param {SubmitLinkDeviceOARequest} submitLinkDeviceOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestLinkDevice1: async (
      submitLinkDeviceOARequest: SubmitLinkDeviceOARequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'submitLinkDeviceOARequest' is not null or undefined
      assertParamExists(
        "requestLinkDevice1",
        "submitLinkDeviceOARequest",
        submitLinkDeviceOARequest,
      );
      const localVarPath = `/api/open-api/v1/devices/pair/request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        submitLinkDeviceOARequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo qr động
     * @param {ShowDynamicQrOARequest} showDynamicQrOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showDynamicQRCode1: async (
      showDynamicQrOARequest: ShowDynamicQrOARequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'showDynamicQrOARequest' is not null or undefined
      assertParamExists(
        "showDynamicQRCode1",
        "showDynamicQrOARequest",
        showDynamicQrOARequest,
      );
      const localVarPath = `/api/open-api/v1/qrcode/dynamic`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        showDynamicQrOARequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo qr tĩnh - Dựa vào thông tin để generate
     * @param {ShowStaticQrOARequest} showStaticQrOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showStaticQRCode1: async (
      showStaticQrOARequest: ShowStaticQrOARequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'showStaticQrOARequest' is not null or undefined
      assertParamExists(
        "showStaticQRCode1",
        "showStaticQrOARequest",
        showStaticQrOARequest,
      );
      const localVarPath = `/api/open-api/v1/qrcode/static`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        showStaticQrOARequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo qr tĩnh - Custom QRCode
     * @param {CustomStaticQrOARequest} customStaticQrOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showStaticQRCodeCustom1: async (
      customStaticQrOARequest: CustomStaticQrOARequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customStaticQrOARequest' is not null or undefined
      assertParamExists(
        "showStaticQRCodeCustom1",
        "customStaticQrOARequest",
        customStaticQrOARequest,
      );
      const localVarPath = `/api/open-api/v1/qrcode/static/custom`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        customStaticQrOARequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unlink device
     * @param {UnlinkDeviceOARequest} unlinkDeviceOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlinkDevice1: async (
      unlinkDeviceOARequest: UnlinkDeviceOARequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'unlinkDeviceOARequest' is not null or undefined
      assertParamExists(
        "unlinkDevice1",
        "unlinkDeviceOARequest",
        unlinkDeviceOARequest,
      );
      const localVarPath = `/api/open-api/v1/devices/unpair`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        unlinkDeviceOARequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Link device - Xác thực OTP
     * @param {VerifyLinkDeviceOARequest} verifyLinkDeviceOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyLinkDevice1: async (
      verifyLinkDeviceOARequest: VerifyLinkDeviceOARequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyLinkDeviceOARequest' is not null or undefined
      assertParamExists(
        "verifyLinkDevice1",
        "verifyLinkDeviceOARequest",
        verifyLinkDeviceOARequest,
      );
      const localVarPath = `/api/open-api/v1/devices/pair/verify-otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyLinkDeviceOARequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OpenApiControllerApi - functional programming interface
 * @export
 */
export const OpenApiControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OpenApiControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Huỷ show QRCode
     * @param {CancelQRCodeOARequest} cancelQRCodeOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelQrCode1(
      cancelQRCodeOARequest: CancelQRCodeOARequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelQrCode1(
        cancelQRCodeOARequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OpenApiControllerApi.cancelQrCode1"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Link device - Kiểm tra thiết bị
     * @param {CheckDeviceOARequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkDevice2(
      request: CheckDeviceOARequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCheckDeviceOAResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkDevice2(
        request,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OpenApiControllerApi.checkDevice2"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Tạo qr động - Custom QRCode
     * @param {CustomDynamicQrOARequest} customDynamicQrOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customDynamicQRCode1(
      customDynamicQrOARequest: CustomDynamicQrOARequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.customDynamicQRCode1(
          customDynamicQrOARequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OpenApiControllerApi.customDynamicQRCode1"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get Device Detail
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDevice1(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeviceDetailOAResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDevice1(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OpenApiControllerApi.getDevice1"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy danh sách device
     * @param {GetDeviceListOARequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListDevice1(
      request: GetDeviceListOARequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseDeviceOAResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getListDevice1(
        request,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OpenApiControllerApi.getListDevice1"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Thông báo biến động số dư
     * @param {NotifyTransactionOARequest} notifyTransactionOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notifyTransaction1(
      notifyTransactionOARequest: NotifyTransactionOARequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseTransactionOAResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notifyTransaction1(
          notifyTransactionOARequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OpenApiControllerApi.notifyTransaction1"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Link device - Yêu cầu liên kết thiết bị
     * @param {SubmitLinkDeviceOARequest} submitLinkDeviceOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestLinkDevice1(
      submitLinkDeviceOARequest: SubmitLinkDeviceOARequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseLinkDeviceOAResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.requestLinkDevice1(
          submitLinkDeviceOARequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OpenApiControllerApi.requestLinkDevice1"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Tạo qr động
     * @param {ShowDynamicQrOARequest} showDynamicQrOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showDynamicQRCode1(
      showDynamicQrOARequest: ShowDynamicQrOARequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.showDynamicQRCode1(
          showDynamicQrOARequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OpenApiControllerApi.showDynamicQRCode1"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Tạo qr tĩnh - Dựa vào thông tin để generate
     * @param {ShowStaticQrOARequest} showStaticQrOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showStaticQRCode1(
      showStaticQrOARequest: ShowStaticQrOARequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.showStaticQRCode1(
          showStaticQrOARequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OpenApiControllerApi.showStaticQRCode1"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Tạo qr tĩnh - Custom QRCode
     * @param {CustomStaticQrOARequest} customStaticQrOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showStaticQRCodeCustom1(
      customStaticQrOARequest: CustomStaticQrOARequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.showStaticQRCodeCustom1(
          customStaticQrOARequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OpenApiControllerApi.showStaticQRCodeCustom1"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Unlink device
     * @param {UnlinkDeviceOARequest} unlinkDeviceOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unlinkDevice1(
      unlinkDeviceOARequest: UnlinkDeviceOARequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unlinkDevice1(
        unlinkDeviceOARequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OpenApiControllerApi.unlinkDevice1"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Link device - Xác thực OTP
     * @param {VerifyLinkDeviceOARequest} verifyLinkDeviceOARequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyLinkDevice1(
      verifyLinkDeviceOARequest: VerifyLinkDeviceOARequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVerifyLinkDeviceOAResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyLinkDevice1(
          verifyLinkDeviceOARequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["OpenApiControllerApi.verifyLinkDevice1"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * OpenApiControllerApi - factory interface
 * @export
 */
export const OpenApiControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OpenApiControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Huỷ show QRCode
     * @param {OpenApiControllerApiCancelQrCode1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelQrCode1(
      requestParameters: OpenApiControllerApiCancelQrCode1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .cancelQrCode1(requestParameters.cancelQRCodeOARequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Link device - Kiểm tra thiết bị
     * @param {OpenApiControllerApiCheckDevice2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkDevice2(
      requestParameters: OpenApiControllerApiCheckDevice2Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseCheckDeviceOAResponse> {
      return localVarFp
        .checkDevice2(requestParameters.request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo qr động - Custom QRCode
     * @param {OpenApiControllerApiCustomDynamicQRCode1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customDynamicQRCode1(
      requestParameters: OpenApiControllerApiCustomDynamicQRCode1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .customDynamicQRCode1(
          requestParameters.customDynamicQrOARequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Device Detail
     * @param {OpenApiControllerApiGetDevice1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevice1(
      requestParameters: OpenApiControllerApiGetDevice1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDeviceDetailOAResponse> {
      return localVarFp
        .getDevice1(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách device
     * @param {OpenApiControllerApiGetListDevice1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListDevice1(
      requestParameters: OpenApiControllerApiGetListDevice1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePageResponseDeviceOAResponse> {
      return localVarFp
        .getListDevice1(requestParameters.request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thông báo biến động số dư
     * @param {OpenApiControllerApiNotifyTransaction1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransaction1(
      requestParameters: OpenApiControllerApiNotifyTransaction1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseTransactionOAResponse> {
      return localVarFp
        .notifyTransaction1(
          requestParameters.notifyTransactionOARequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Link device - Yêu cầu liên kết thiết bị
     * @param {OpenApiControllerApiRequestLinkDevice1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestLinkDevice1(
      requestParameters: OpenApiControllerApiRequestLinkDevice1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseLinkDeviceOAResponse> {
      return localVarFp
        .requestLinkDevice1(
          requestParameters.submitLinkDeviceOARequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo qr động
     * @param {OpenApiControllerApiShowDynamicQRCode1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showDynamicQRCode1(
      requestParameters: OpenApiControllerApiShowDynamicQRCode1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .showDynamicQRCode1(requestParameters.showDynamicQrOARequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo qr tĩnh - Dựa vào thông tin để generate
     * @param {OpenApiControllerApiShowStaticQRCode1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showStaticQRCode1(
      requestParameters: OpenApiControllerApiShowStaticQRCode1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .showStaticQRCode1(requestParameters.showStaticQrOARequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo qr tĩnh - Custom QRCode
     * @param {OpenApiControllerApiShowStaticQRCodeCustom1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showStaticQRCodeCustom1(
      requestParameters: OpenApiControllerApiShowStaticQRCodeCustom1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .showStaticQRCodeCustom1(
          requestParameters.customStaticQrOARequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Unlink device
     * @param {OpenApiControllerApiUnlinkDevice1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlinkDevice1(
      requestParameters: OpenApiControllerApiUnlinkDevice1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .unlinkDevice1(requestParameters.unlinkDeviceOARequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Link device - Xác thực OTP
     * @param {OpenApiControllerApiVerifyLinkDevice1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyLinkDevice1(
      requestParameters: OpenApiControllerApiVerifyLinkDevice1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseVerifyLinkDeviceOAResponse> {
      return localVarFp
        .verifyLinkDevice1(requestParameters.verifyLinkDeviceOARequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cancelQrCode1 operation in OpenApiControllerApi.
 * @export
 * @interface OpenApiControllerApiCancelQrCode1Request
 */
export interface OpenApiControllerApiCancelQrCode1Request {
  /**
   *
   * @type {CancelQRCodeOARequest}
   * @memberof OpenApiControllerApiCancelQrCode1
   */
  readonly cancelQRCodeOARequest: CancelQRCodeOARequest;
}

/**
 * Request parameters for checkDevice2 operation in OpenApiControllerApi.
 * @export
 * @interface OpenApiControllerApiCheckDevice2Request
 */
export interface OpenApiControllerApiCheckDevice2Request {
  /**
   *
   * @type {CheckDeviceOARequest}
   * @memberof OpenApiControllerApiCheckDevice2
   */
  readonly request: CheckDeviceOARequest;
}

/**
 * Request parameters for customDynamicQRCode1 operation in OpenApiControllerApi.
 * @export
 * @interface OpenApiControllerApiCustomDynamicQRCode1Request
 */
export interface OpenApiControllerApiCustomDynamicQRCode1Request {
  /**
   *
   * @type {CustomDynamicQrOARequest}
   * @memberof OpenApiControllerApiCustomDynamicQRCode1
   */
  readonly customDynamicQrOARequest: CustomDynamicQrOARequest;
}

/**
 * Request parameters for getDevice1 operation in OpenApiControllerApi.
 * @export
 * @interface OpenApiControllerApiGetDevice1Request
 */
export interface OpenApiControllerApiGetDevice1Request {
  /**
   *
   * @type {string}
   * @memberof OpenApiControllerApiGetDevice1
   */
  readonly id: string;
}

/**
 * Request parameters for getListDevice1 operation in OpenApiControllerApi.
 * @export
 * @interface OpenApiControllerApiGetListDevice1Request
 */
export interface OpenApiControllerApiGetListDevice1Request {
  /**
   *
   * @type {GetDeviceListOARequest}
   * @memberof OpenApiControllerApiGetListDevice1
   */
  readonly request: GetDeviceListOARequest;
}

/**
 * Request parameters for notifyTransaction1 operation in OpenApiControllerApi.
 * @export
 * @interface OpenApiControllerApiNotifyTransaction1Request
 */
export interface OpenApiControllerApiNotifyTransaction1Request {
  /**
   *
   * @type {NotifyTransactionOARequest}
   * @memberof OpenApiControllerApiNotifyTransaction1
   */
  readonly notifyTransactionOARequest: NotifyTransactionOARequest;
}

/**
 * Request parameters for requestLinkDevice1 operation in OpenApiControllerApi.
 * @export
 * @interface OpenApiControllerApiRequestLinkDevice1Request
 */
export interface OpenApiControllerApiRequestLinkDevice1Request {
  /**
   *
   * @type {SubmitLinkDeviceOARequest}
   * @memberof OpenApiControllerApiRequestLinkDevice1
   */
  readonly submitLinkDeviceOARequest: SubmitLinkDeviceOARequest;
}

/**
 * Request parameters for showDynamicQRCode1 operation in OpenApiControllerApi.
 * @export
 * @interface OpenApiControllerApiShowDynamicQRCode1Request
 */
export interface OpenApiControllerApiShowDynamicQRCode1Request {
  /**
   *
   * @type {ShowDynamicQrOARequest}
   * @memberof OpenApiControllerApiShowDynamicQRCode1
   */
  readonly showDynamicQrOARequest: ShowDynamicQrOARequest;
}

/**
 * Request parameters for showStaticQRCode1 operation in OpenApiControllerApi.
 * @export
 * @interface OpenApiControllerApiShowStaticQRCode1Request
 */
export interface OpenApiControllerApiShowStaticQRCode1Request {
  /**
   *
   * @type {ShowStaticQrOARequest}
   * @memberof OpenApiControllerApiShowStaticQRCode1
   */
  readonly showStaticQrOARequest: ShowStaticQrOARequest;
}

/**
 * Request parameters for showStaticQRCodeCustom1 operation in OpenApiControllerApi.
 * @export
 * @interface OpenApiControllerApiShowStaticQRCodeCustom1Request
 */
export interface OpenApiControllerApiShowStaticQRCodeCustom1Request {
  /**
   *
   * @type {CustomStaticQrOARequest}
   * @memberof OpenApiControllerApiShowStaticQRCodeCustom1
   */
  readonly customStaticQrOARequest: CustomStaticQrOARequest;
}

/**
 * Request parameters for unlinkDevice1 operation in OpenApiControllerApi.
 * @export
 * @interface OpenApiControllerApiUnlinkDevice1Request
 */
export interface OpenApiControllerApiUnlinkDevice1Request {
  /**
   *
   * @type {UnlinkDeviceOARequest}
   * @memberof OpenApiControllerApiUnlinkDevice1
   */
  readonly unlinkDeviceOARequest: UnlinkDeviceOARequest;
}

/**
 * Request parameters for verifyLinkDevice1 operation in OpenApiControllerApi.
 * @export
 * @interface OpenApiControllerApiVerifyLinkDevice1Request
 */
export interface OpenApiControllerApiVerifyLinkDevice1Request {
  /**
   *
   * @type {VerifyLinkDeviceOARequest}
   * @memberof OpenApiControllerApiVerifyLinkDevice1
   */
  readonly verifyLinkDeviceOARequest: VerifyLinkDeviceOARequest;
}

/**
 * OpenApiControllerApi - object-oriented interface
 * @export
 * @class OpenApiControllerApi
 * @extends {BaseAPI}
 */
export class OpenApiControllerApi extends BaseAPI {
  /**
   *
   * @summary Huỷ show QRCode
   * @param {OpenApiControllerApiCancelQrCode1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenApiControllerApi
   */
  public cancelQrCode1(
    requestParameters: OpenApiControllerApiCancelQrCode1Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenApiControllerApiFp(this.configuration)
      .cancelQrCode1(requestParameters.cancelQRCodeOARequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Link device - Kiểm tra thiết bị
   * @param {OpenApiControllerApiCheckDevice2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenApiControllerApi
   */
  public checkDevice2(
    requestParameters: OpenApiControllerApiCheckDevice2Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenApiControllerApiFp(this.configuration)
      .checkDevice2(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo qr động - Custom QRCode
   * @param {OpenApiControllerApiCustomDynamicQRCode1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenApiControllerApi
   */
  public customDynamicQRCode1(
    requestParameters: OpenApiControllerApiCustomDynamicQRCode1Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenApiControllerApiFp(this.configuration)
      .customDynamicQRCode1(requestParameters.customDynamicQrOARequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Device Detail
   * @param {OpenApiControllerApiGetDevice1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenApiControllerApi
   */
  public getDevice1(
    requestParameters: OpenApiControllerApiGetDevice1Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenApiControllerApiFp(this.configuration)
      .getDevice1(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách device
   * @param {OpenApiControllerApiGetListDevice1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenApiControllerApi
   */
  public getListDevice1(
    requestParameters: OpenApiControllerApiGetListDevice1Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenApiControllerApiFp(this.configuration)
      .getListDevice1(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thông báo biến động số dư
   * @param {OpenApiControllerApiNotifyTransaction1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenApiControllerApi
   */
  public notifyTransaction1(
    requestParameters: OpenApiControllerApiNotifyTransaction1Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenApiControllerApiFp(this.configuration)
      .notifyTransaction1(requestParameters.notifyTransactionOARequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Link device - Yêu cầu liên kết thiết bị
   * @param {OpenApiControllerApiRequestLinkDevice1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenApiControllerApi
   */
  public requestLinkDevice1(
    requestParameters: OpenApiControllerApiRequestLinkDevice1Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenApiControllerApiFp(this.configuration)
      .requestLinkDevice1(requestParameters.submitLinkDeviceOARequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo qr động
   * @param {OpenApiControllerApiShowDynamicQRCode1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenApiControllerApi
   */
  public showDynamicQRCode1(
    requestParameters: OpenApiControllerApiShowDynamicQRCode1Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenApiControllerApiFp(this.configuration)
      .showDynamicQRCode1(requestParameters.showDynamicQrOARequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo qr tĩnh - Dựa vào thông tin để generate
   * @param {OpenApiControllerApiShowStaticQRCode1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenApiControllerApi
   */
  public showStaticQRCode1(
    requestParameters: OpenApiControllerApiShowStaticQRCode1Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenApiControllerApiFp(this.configuration)
      .showStaticQRCode1(requestParameters.showStaticQrOARequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo qr tĩnh - Custom QRCode
   * @param {OpenApiControllerApiShowStaticQRCodeCustom1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenApiControllerApi
   */
  public showStaticQRCodeCustom1(
    requestParameters: OpenApiControllerApiShowStaticQRCodeCustom1Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenApiControllerApiFp(this.configuration)
      .showStaticQRCodeCustom1(
        requestParameters.customStaticQrOARequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Unlink device
   * @param {OpenApiControllerApiUnlinkDevice1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenApiControllerApi
   */
  public unlinkDevice1(
    requestParameters: OpenApiControllerApiUnlinkDevice1Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenApiControllerApiFp(this.configuration)
      .unlinkDevice1(requestParameters.unlinkDeviceOARequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Link device - Xác thực OTP
   * @param {OpenApiControllerApiVerifyLinkDevice1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenApiControllerApi
   */
  public verifyLinkDevice1(
    requestParameters: OpenApiControllerApiVerifyLinkDevice1Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenApiControllerApiFp(this.configuration)
      .verifyLinkDevice1(requestParameters.verifyLinkDeviceOARequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * POSDeviceControllerApi - axios parameter creator
 * @export
 */
export const POSDeviceControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Huỷ giao dịch
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTransactionPos: async (
      transactionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists("cancelTransactionPos", "transactionId", transactionId);
      const localVarPath = `/api/pos/v1/cancel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (transactionId !== undefined) {
        localVarQueryParameter["transactionId"] = transactionId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary kiểm tra giao dịch
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTransactionPos: async (
      transactionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists("checkTransactionPos", "transactionId", transactionId);
      const localVarPath = `/api/pos/v1/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (transactionId !== undefined) {
        localVarQueryParameter["transactionId"] = transactionId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo 1 transaction mới
     * @param {CreateTransactionRequest} createTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransactionPos: async (
      createTransactionRequest: CreateTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createTransactionRequest' is not null or undefined
      assertParamExists(
        "createTransactionPos",
        "createTransactionRequest",
        createTransactionRequest,
      );
      const localVarPath = `/api/pos/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * POSDeviceControllerApi - functional programming interface
 * @export
 */
export const POSDeviceControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    POSDeviceControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Huỷ giao dịch
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelTransactionPos(
      transactionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCancelTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancelTransactionPos(
          transactionId,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["POSDeviceControllerApi.cancelTransactionPos"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary kiểm tra giao dịch
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkTransactionPos(
      transactionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkTransactionPos(
          transactionId,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["POSDeviceControllerApi.checkTransactionPos"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Tạo 1 transaction mới
     * @param {CreateTransactionRequest} createTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTransactionPos(
      createTransactionRequest: CreateTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createTransactionPos(
          createTransactionRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["POSDeviceControllerApi.createTransactionPos"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * POSDeviceControllerApi - factory interface
 * @export
 */
export const POSDeviceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = POSDeviceControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Huỷ giao dịch
     * @param {POSDeviceControllerApiCancelTransactionPosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTransactionPos(
      requestParameters: POSDeviceControllerApiCancelTransactionPosRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseCancelTransactionResponse> {
      return localVarFp
        .cancelTransactionPos(requestParameters.transactionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary kiểm tra giao dịch
     * @param {POSDeviceControllerApiCheckTransactionPosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTransactionPos(
      requestParameters: POSDeviceControllerApiCheckTransactionPosRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseTransactionResponse> {
      return localVarFp
        .checkTransactionPos(requestParameters.transactionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo 1 transaction mới
     * @param {POSDeviceControllerApiCreateTransactionPosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransactionPos(
      requestParameters: POSDeviceControllerApiCreateTransactionPosRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseTransactionResponse> {
      return localVarFp
        .createTransactionPos(
          requestParameters.createTransactionRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cancelTransactionPos operation in POSDeviceControllerApi.
 * @export
 * @interface POSDeviceControllerApiCancelTransactionPosRequest
 */
export interface POSDeviceControllerApiCancelTransactionPosRequest {
  /**
   *
   * @type {string}
   * @memberof POSDeviceControllerApiCancelTransactionPos
   */
  readonly transactionId: string;
}

/**
 * Request parameters for checkTransactionPos operation in POSDeviceControllerApi.
 * @export
 * @interface POSDeviceControllerApiCheckTransactionPosRequest
 */
export interface POSDeviceControllerApiCheckTransactionPosRequest {
  /**
   *
   * @type {string}
   * @memberof POSDeviceControllerApiCheckTransactionPos
   */
  readonly transactionId: string;
}

/**
 * Request parameters for createTransactionPos operation in POSDeviceControllerApi.
 * @export
 * @interface POSDeviceControllerApiCreateTransactionPosRequest
 */
export interface POSDeviceControllerApiCreateTransactionPosRequest {
  /**
   *
   * @type {CreateTransactionRequest}
   * @memberof POSDeviceControllerApiCreateTransactionPos
   */
  readonly createTransactionRequest: CreateTransactionRequest;
}

/**
 * POSDeviceControllerApi - object-oriented interface
 * @export
 * @class POSDeviceControllerApi
 * @extends {BaseAPI}
 */
export class POSDeviceControllerApi extends BaseAPI {
  /**
   *
   * @summary Huỷ giao dịch
   * @param {POSDeviceControllerApiCancelTransactionPosRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof POSDeviceControllerApi
   */
  public cancelTransactionPos(
    requestParameters: POSDeviceControllerApiCancelTransactionPosRequest,
    options?: AxiosRequestConfig,
  ) {
    return POSDeviceControllerApiFp(this.configuration)
      .cancelTransactionPos(requestParameters.transactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary kiểm tra giao dịch
   * @param {POSDeviceControllerApiCheckTransactionPosRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof POSDeviceControllerApi
   */
  public checkTransactionPos(
    requestParameters: POSDeviceControllerApiCheckTransactionPosRequest,
    options?: AxiosRequestConfig,
  ) {
    return POSDeviceControllerApiFp(this.configuration)
      .checkTransactionPos(requestParameters.transactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo 1 transaction mới
   * @param {POSDeviceControllerApiCreateTransactionPosRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof POSDeviceControllerApi
   */
  public createTransactionPos(
    requestParameters: POSDeviceControllerApiCreateTransactionPosRequest,
    options?: AxiosRequestConfig,
  ) {
    return POSDeviceControllerApiFp(this.configuration)
      .createTransactionPos(requestParameters.createTransactionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ResourceControllerApi - axios parameter creator
 * @export
 */
export const ResourceControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Config resource
     * @param {ConfigResourceRequest} configResourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configResource: async (
      configResourceRequest: ConfigResourceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'configResourceRequest' is not null or undefined
      assertParamExists(
        "configResource",
        "configResourceRequest",
        configResourceRequest,
      );
      const localVarPath = `/api/resource/v1/configResource`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        configResourceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Paybox Resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayboxResource: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/resource/v1/paybox-resource/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResource: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/resource/v1/getResource`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary set Api Key
     * @param {SetApiKeyRequest} setApiKeyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setApiKey: async (
      setApiKeyRequest: SetApiKeyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'setApiKeyRequest' is not null or undefined
      assertParamExists("setApiKey", "setApiKeyRequest", setApiKeyRequest);
      const localVarPath = `/api/resource/v1/setApiKey`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        setApiKeyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Enable/Disable api key
     * @param {UpdateApiKeyStatusRequest} updateApiKeyStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateApiKeyStatus: async (
      updateApiKeyStatusRequest: UpdateApiKeyStatusRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateApiKeyStatusRequest' is not null or undefined
      assertParamExists(
        "updateApiKeyStatus",
        "updateApiKeyStatusRequest",
        updateApiKeyStatusRequest,
      );
      const localVarPath = `/api/resource/v1/updateApiKeyStatus`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateApiKeyStatusRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload Paybox Resource
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadPayboxResource: async (
      file?: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/resource/v1/paybox-resource/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ResourceControllerApi - functional programming interface
 * @export
 */
export const ResourceControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ResourceControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Config resource
     * @param {ConfigResourceRequest} configResourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configResource(
      configResourceRequest: ConfigResourceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseConfigResourceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configResource(
        configResourceRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ResourceControllerApi.configResource"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get Paybox Resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPayboxResource(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPayboxResource(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ResourceControllerApi.getPayboxResource"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getResource(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetResourceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getResource(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ResourceControllerApi.getResource"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary set Api Key
     * @param {SetApiKeyRequest} setApiKeyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setApiKey(
      setApiKeyRequest: SetApiKeyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSetApiKeyResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setApiKey(
        setApiKeyRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ResourceControllerApi.setApiKey"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Enable/Disable api key
     * @param {UpdateApiKeyStatusRequest} updateApiKeyStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateApiKeyStatus(
      updateApiKeyStatusRequest: UpdateApiKeyStatusRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUpdateApiKeyStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateApiKeyStatus(
          updateApiKeyStatusRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ResourceControllerApi.updateApiKeyStatus"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Upload Paybox Resource
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadPayboxResource(
      file?: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadPayboxResource(file, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["ResourceControllerApi.uploadPayboxResource"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * ResourceControllerApi - factory interface
 * @export
 */
export const ResourceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ResourceControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Config resource
     * @param {ResourceControllerApiConfigResourceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configResource(
      requestParameters: ResourceControllerApiConfigResourceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseConfigResourceResponse> {
      return localVarFp
        .configResource(requestParameters.configResourceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Paybox Resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayboxResource(options?: AxiosRequestConfig): AxiosPromise<File> {
      return localVarFp
        .getPayboxResource(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResource(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseGetResourceResponse> {
      return localVarFp
        .getResource(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary set Api Key
     * @param {ResourceControllerApiSetApiKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setApiKey(
      requestParameters: ResourceControllerApiSetApiKeyRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseSetApiKeyResponse> {
      return localVarFp
        .setApiKey(requestParameters.setApiKeyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Enable/Disable api key
     * @param {ResourceControllerApiUpdateApiKeyStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateApiKeyStatus(
      requestParameters: ResourceControllerApiUpdateApiKeyStatusRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseUpdateApiKeyStatusResponse> {
      return localVarFp
        .updateApiKeyStatus(
          requestParameters.updateApiKeyStatusRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload Paybox Resource
     * @param {ResourceControllerApiUploadPayboxResourceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadPayboxResource(
      requestParameters: ResourceControllerApiUploadPayboxResourceRequest = {},
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .uploadPayboxResource(requestParameters.file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for configResource operation in ResourceControllerApi.
 * @export
 * @interface ResourceControllerApiConfigResourceRequest
 */
export interface ResourceControllerApiConfigResourceRequest {
  /**
   *
   * @type {ConfigResourceRequest}
   * @memberof ResourceControllerApiConfigResource
   */
  readonly configResourceRequest: ConfigResourceRequest;
}

/**
 * Request parameters for setApiKey operation in ResourceControllerApi.
 * @export
 * @interface ResourceControllerApiSetApiKeyRequest
 */
export interface ResourceControllerApiSetApiKeyRequest {
  /**
   *
   * @type {SetApiKeyRequest}
   * @memberof ResourceControllerApiSetApiKey
   */
  readonly setApiKeyRequest: SetApiKeyRequest;
}

/**
 * Request parameters for updateApiKeyStatus operation in ResourceControllerApi.
 * @export
 * @interface ResourceControllerApiUpdateApiKeyStatusRequest
 */
export interface ResourceControllerApiUpdateApiKeyStatusRequest {
  /**
   *
   * @type {UpdateApiKeyStatusRequest}
   * @memberof ResourceControllerApiUpdateApiKeyStatus
   */
  readonly updateApiKeyStatusRequest: UpdateApiKeyStatusRequest;
}

/**
 * Request parameters for uploadPayboxResource operation in ResourceControllerApi.
 * @export
 * @interface ResourceControllerApiUploadPayboxResourceRequest
 */
export interface ResourceControllerApiUploadPayboxResourceRequest {
  /**
   *
   * @type {File}
   * @memberof ResourceControllerApiUploadPayboxResource
   */
  readonly file?: File;
}

/**
 * ResourceControllerApi - object-oriented interface
 * @export
 * @class ResourceControllerApi
 * @extends {BaseAPI}
 */
export class ResourceControllerApi extends BaseAPI {
  /**
   *
   * @summary Config resource
   * @param {ResourceControllerApiConfigResourceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceControllerApi
   */
  public configResource(
    requestParameters: ResourceControllerApiConfigResourceRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourceControllerApiFp(this.configuration)
      .configResource(requestParameters.configResourceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Paybox Resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceControllerApi
   */
  public getPayboxResource(options?: AxiosRequestConfig) {
    return ResourceControllerApiFp(this.configuration)
      .getPayboxResource(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceControllerApi
   */
  public getResource(options?: AxiosRequestConfig) {
    return ResourceControllerApiFp(this.configuration)
      .getResource(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary set Api Key
   * @param {ResourceControllerApiSetApiKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceControllerApi
   */
  public setApiKey(
    requestParameters: ResourceControllerApiSetApiKeyRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourceControllerApiFp(this.configuration)
      .setApiKey(requestParameters.setApiKeyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Enable/Disable api key
   * @param {ResourceControllerApiUpdateApiKeyStatusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceControllerApi
   */
  public updateApiKeyStatus(
    requestParameters: ResourceControllerApiUpdateApiKeyStatusRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourceControllerApiFp(this.configuration)
      .updateApiKeyStatus(requestParameters.updateApiKeyStatusRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload Paybox Resource
   * @param {ResourceControllerApiUploadPayboxResourceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceControllerApi
   */
  public uploadPayboxResource(
    requestParameters: ResourceControllerApiUploadPayboxResourceRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return ResourceControllerApiFp(this.configuration)
      .uploadPayboxResource(requestParameters.file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SettingControllerApi - axios parameter creator
 * @export
 */
export const SettingControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Thêm mới quảng cáo
     * @param {AddAdvertisementRequest} addAdvertisementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAdvertisement: async (
      addAdvertisementRequest: AddAdvertisementRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addAdvertisementRequest' is not null or undefined
      assertParamExists(
        "addAdvertisement",
        "addAdvertisementRequest",
        addAdvertisementRequest,
      );
      const localVarPath = `/api/setting/v1/advertisement/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addAdvertisementRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thêm mới mã qr
     * @param {AddStaticQrRequest} addStaticQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addQr: async (
      addStaticQrRequest: AddStaticQrRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addStaticQrRequest' is not null or undefined
      assertParamExists("addQr", "addStaticQrRequest", addStaticQrRequest);
      const localVarPath = `/api/setting/v1/qr/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addStaticQrRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check set up bank account
     * @param {string} [deviceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkSetupBankAccount: async (
      deviceId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/setting/v1/checkSetupBankAccount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deviceId !== undefined) {
        localVarQueryParameter["deviceId"] = deviceId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa quảng cáo theo ID
     * @param {DeleteAdvertisementRequest} deleteAdvertisementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAdvertisement: async (
      deleteAdvertisementRequest: DeleteAdvertisementRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteAdvertisementRequest' is not null or undefined
      assertParamExists(
        "deleteAdvertisement",
        "deleteAdvertisementRequest",
        deleteAdvertisementRequest,
      );
      const localVarPath = `/api/setting/v1/advertisement/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteAdvertisementRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa mã QR theo ID
     * @param {DeleteStaticQrByIdRequest} deleteStaticQrByIdRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQrById: async (
      deleteStaticQrByIdRequest: DeleteStaticQrByIdRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteStaticQrByIdRequest' is not null or undefined
      assertParamExists(
        "deleteQrById",
        "deleteStaticQrByIdRequest",
        deleteStaticQrByIdRequest,
      );
      const localVarPath = `/api/setting/v1/qr/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteStaticQrByIdRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generate mã qr
     * @param {GenerateQrRequest} generateQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateQr: async (
      generateQrRequest: GenerateQrRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'generateQrRequest' is not null or undefined
      assertParamExists("generateQr", "generateQrRequest", generateQrRequest);
      const localVarPath = `/api/setting/v1/qr/generate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        generateQrRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tất cả quảng cáo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAdvertisement: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/setting/v1/advertisement/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tất cả mã qr
     * @param {GetAllStaticQrRequest} request
     * @param {PaginationAndSortingRequest} paginationAndSortingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllQr: async (
      request: GetAllStaticQrRequest,
      paginationAndSortingRequest: PaginationAndSortingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("getAllQr", "request", request);
      // verify required parameter 'paginationAndSortingRequest' is not null or undefined
      assertParamExists(
        "getAllQr",
        "paginationAndSortingRequest",
        paginationAndSortingRequest,
      );
      const localVarPath = `/api/setting/v1/qr/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (paginationAndSortingRequest !== undefined) {
        for (const [key, value] of Object.entries(
          paginationAndSortingRequest,
        )) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tài khoản my shop liên kết
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyShopAccount: async (
      deviceId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists("getMyShopAccount", "deviceId", deviceId);
      const localVarPath = `/api/setting/v1/getMyShopAccount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deviceId !== undefined) {
        localVarQueryParameter["deviceId"] = deviceId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy mã QR theo ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQrById: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getQrById", "id", id);
      const localVarPath = `/api/setting/v1/qr/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Handle static qr
     * @param {File} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleImage: async (
      image: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'image' is not null or undefined
      assertParamExists("handleImage", "image", image);
      const localVarPath = `/api/setting/v1/handleImage`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (image !== undefined) {
        localVarQueryParameter["image"] = image;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Preview mã qr
     * @param {PreviewQrRequest} previewQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preview: async (
      previewQrRequest: PreviewQrRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'previewQrRequest' is not null or undefined
      assertParamExists("preview", "previewQrRequest", previewQrRequest);
      const localVarPath = `/api/setting/v1/qr/preview`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        previewQrRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Đặt qr làm mặc định
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setQrDefault: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("setQrDefault", "id", id);
      const localVarPath = `/api/setting/v1/qr/{id}/setDefault`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update quảng cáo theo ID
     * @param {UpdateAdvertisementRequest} updateAdvertisementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAdvertisement: async (
      updateAdvertisementRequest: UpdateAdvertisementRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateAdvertisementRequest' is not null or undefined
      assertParamExists(
        "updateAdvertisement",
        "updateAdvertisementRequest",
        updateAdvertisementRequest,
      );
      const localVarPath = `/api/setting/v1/advertisement/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAdvertisementRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update mã QR theo ID
     * @param {UpdateStaticQrRequest} updateStaticQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateQrById: async (
      updateStaticQrRequest: UpdateStaticQrRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateStaticQrRequest' is not null or undefined
      assertParamExists(
        "updateQrById",
        "updateStaticQrRequest",
        updateStaticQrRequest,
      );
      const localVarPath = `/api/setting/v1/qr/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateStaticQrRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Verify User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyUser: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/setting/v1/verifyUser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SettingControllerApi - functional programming interface
 * @export
 */
export const SettingControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SettingControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Thêm mới quảng cáo
     * @param {AddAdvertisementRequest} addAdvertisementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addAdvertisement(
      addAdvertisementRequest: AddAdvertisementRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAdvertisementResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addAdvertisement(
          addAdvertisementRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.addAdvertisement"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Thêm mới mã qr
     * @param {AddStaticQrRequest} addStaticQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addQr(
      addStaticQrRequest: AddStaticQrRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStaticQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addQr(
        addStaticQrRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.addQr"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Check set up bank account
     * @param {string} [deviceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkSetupBankAccount(
      deviceId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCheckSetupBankAccountResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkSetupBankAccount(
          deviceId,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.checkSetupBankAccount"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Xóa quảng cáo theo ID
     * @param {DeleteAdvertisementRequest} deleteAdvertisementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAdvertisement(
      deleteAdvertisementRequest: DeleteAdvertisementRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeleteAdvertisementResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAdvertisement(
          deleteAdvertisementRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.deleteAdvertisement"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Xóa mã QR theo ID
     * @param {DeleteStaticQrByIdRequest} deleteStaticQrByIdRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteQrById(
      deleteStaticQrByIdRequest: DeleteStaticQrByIdRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeleteStaticQrByIdResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQrById(
        deleteStaticQrByIdRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.deleteQrById"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Generate mã qr
     * @param {GenerateQrRequest} generateQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateQr(
      generateQrRequest: GenerateQrRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGenerateQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generateQr(
        generateQrRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.generateQr"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy tất cả quảng cáo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAdvertisement(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllAdvertisementResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllAdvertisement(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.getAllAdvertisement"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy tất cả mã qr
     * @param {GetAllStaticQrRequest} request
     * @param {PaginationAndSortingRequest} paginationAndSortingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllQr(
      request: GetAllStaticQrRequest,
      paginationAndSortingRequest: PaginationAndSortingRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllStaticQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllQr(
        request,
        paginationAndSortingRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.getAllQr"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy tài khoản my shop liên kết
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMyShopAccount(
      deviceId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetMyShopAccountResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMyShopAccount(deviceId, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.getMyShopAccount"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy mã QR theo ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQrById(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStaticQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQrById(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.getQrById"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Handle static qr
     * @param {File} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handleImage(
      image: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseHandleImageResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handleImage(
        image,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.handleImage"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Preview mã qr
     * @param {PreviewQrRequest} previewQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async preview(
      previewQrRequest: PreviewQrRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePreviewQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.preview(
        previewQrRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.preview"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Đặt qr làm mặc định
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setQrDefault(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setQrDefault(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.setQrDefault"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Update quảng cáo theo ID
     * @param {UpdateAdvertisementRequest} updateAdvertisementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAdvertisement(
      updateAdvertisementRequest: UpdateAdvertisementRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAdvertisementResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateAdvertisement(
          updateAdvertisementRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.updateAdvertisement"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Update mã QR theo ID
     * @param {UpdateStaticQrRequest} updateStaticQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateQrById(
      updateStaticQrRequest: UpdateStaticQrRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStaticQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateQrById(
        updateStaticQrRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.updateQrById"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Verify User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyUser(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSettingVerifyUserResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyUser(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SettingControllerApi.verifyUser"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * SettingControllerApi - factory interface
 * @export
 */
export const SettingControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SettingControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Thêm mới quảng cáo
     * @param {SettingControllerApiAddAdvertisementRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAdvertisement(
      requestParameters: SettingControllerApiAddAdvertisementRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseAdvertisementResponse> {
      return localVarFp
        .addAdvertisement(requestParameters.addAdvertisementRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thêm mới mã qr
     * @param {SettingControllerApiAddQrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addQr(
      requestParameters: SettingControllerApiAddQrRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStaticQrResponse> {
      return localVarFp
        .addQr(requestParameters.addStaticQrRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Check set up bank account
     * @param {SettingControllerApiCheckSetupBankAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkSetupBankAccount(
      requestParameters: SettingControllerApiCheckSetupBankAccountRequest = {},
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseCheckSetupBankAccountResponse> {
      return localVarFp
        .checkSetupBankAccount(requestParameters.deviceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa quảng cáo theo ID
     * @param {SettingControllerApiDeleteAdvertisementRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAdvertisement(
      requestParameters: SettingControllerApiDeleteAdvertisementRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDeleteAdvertisementResponse> {
      return localVarFp
        .deleteAdvertisement(
          requestParameters.deleteAdvertisementRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa mã QR theo ID
     * @param {SettingControllerApiDeleteQrByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQrById(
      requestParameters: SettingControllerApiDeleteQrByIdRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDeleteStaticQrByIdResponse> {
      return localVarFp
        .deleteQrById(requestParameters.deleteStaticQrByIdRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generate mã qr
     * @param {SettingControllerApiGenerateQrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateQr(
      requestParameters: SettingControllerApiGenerateQrRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseGenerateQrResponse> {
      return localVarFp
        .generateQr(requestParameters.generateQrRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tất cả quảng cáo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAdvertisement(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseGetAllAdvertisementResponse> {
      return localVarFp
        .getAllAdvertisement(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tất cả mã qr
     * @param {SettingControllerApiGetAllQrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllQr(
      requestParameters: SettingControllerApiGetAllQrRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseGetAllStaticQrResponse> {
      return localVarFp
        .getAllQr(
          requestParameters.request,
          requestParameters.paginationAndSortingRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tài khoản my shop liên kết
     * @param {SettingControllerApiGetMyShopAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyShopAccount(
      requestParameters: SettingControllerApiGetMyShopAccountRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseGetMyShopAccountResponse> {
      return localVarFp
        .getMyShopAccount(requestParameters.deviceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy mã QR theo ID
     * @param {SettingControllerApiGetQrByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQrById(
      requestParameters: SettingControllerApiGetQrByIdRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStaticQrResponse> {
      return localVarFp
        .getQrById(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Handle static qr
     * @param {SettingControllerApiHandleImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleImage(
      requestParameters: SettingControllerApiHandleImageRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseHandleImageResponse> {
      return localVarFp
        .handleImage(requestParameters.image, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Preview mã qr
     * @param {SettingControllerApiPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preview(
      requestParameters: SettingControllerApiPreviewRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePreviewQrResponse> {
      return localVarFp
        .preview(requestParameters.previewQrRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Đặt qr làm mặc định
     * @param {SettingControllerApiSetQrDefaultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setQrDefault(
      requestParameters: SettingControllerApiSetQrDefaultRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .setQrDefault(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update quảng cáo theo ID
     * @param {SettingControllerApiUpdateAdvertisementRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAdvertisement(
      requestParameters: SettingControllerApiUpdateAdvertisementRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseAdvertisementResponse> {
      return localVarFp
        .updateAdvertisement(
          requestParameters.updateAdvertisementRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update mã QR theo ID
     * @param {SettingControllerApiUpdateQrByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateQrById(
      requestParameters: SettingControllerApiUpdateQrByIdRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStaticQrResponse> {
      return localVarFp
        .updateQrById(requestParameters.updateStaticQrRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Verify User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyUser(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseSettingVerifyUserResponse> {
      return localVarFp
        .verifyUser(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addAdvertisement operation in SettingControllerApi.
 * @export
 * @interface SettingControllerApiAddAdvertisementRequest
 */
export interface SettingControllerApiAddAdvertisementRequest {
  /**
   *
   * @type {AddAdvertisementRequest}
   * @memberof SettingControllerApiAddAdvertisement
   */
  readonly addAdvertisementRequest: AddAdvertisementRequest;
}

/**
 * Request parameters for addQr operation in SettingControllerApi.
 * @export
 * @interface SettingControllerApiAddQrRequest
 */
export interface SettingControllerApiAddQrRequest {
  /**
   *
   * @type {AddStaticQrRequest}
   * @memberof SettingControllerApiAddQr
   */
  readonly addStaticQrRequest: AddStaticQrRequest;
}

/**
 * Request parameters for checkSetupBankAccount operation in SettingControllerApi.
 * @export
 * @interface SettingControllerApiCheckSetupBankAccountRequest
 */
export interface SettingControllerApiCheckSetupBankAccountRequest {
  /**
   *
   * @type {string}
   * @memberof SettingControllerApiCheckSetupBankAccount
   */
  readonly deviceId?: string;
}

/**
 * Request parameters for deleteAdvertisement operation in SettingControllerApi.
 * @export
 * @interface SettingControllerApiDeleteAdvertisementRequest
 */
export interface SettingControllerApiDeleteAdvertisementRequest {
  /**
   *
   * @type {DeleteAdvertisementRequest}
   * @memberof SettingControllerApiDeleteAdvertisement
   */
  readonly deleteAdvertisementRequest: DeleteAdvertisementRequest;
}

/**
 * Request parameters for deleteQrById operation in SettingControllerApi.
 * @export
 * @interface SettingControllerApiDeleteQrByIdRequest
 */
export interface SettingControllerApiDeleteQrByIdRequest {
  /**
   *
   * @type {DeleteStaticQrByIdRequest}
   * @memberof SettingControllerApiDeleteQrById
   */
  readonly deleteStaticQrByIdRequest: DeleteStaticQrByIdRequest;
}

/**
 * Request parameters for generateQr operation in SettingControllerApi.
 * @export
 * @interface SettingControllerApiGenerateQrRequest
 */
export interface SettingControllerApiGenerateQrRequest {
  /**
   *
   * @type {GenerateQrRequest}
   * @memberof SettingControllerApiGenerateQr
   */
  readonly generateQrRequest: GenerateQrRequest;
}

/**
 * Request parameters for getAllQr operation in SettingControllerApi.
 * @export
 * @interface SettingControllerApiGetAllQrRequest
 */
export interface SettingControllerApiGetAllQrRequest {
  /**
   *
   * @type {GetAllStaticQrRequest}
   * @memberof SettingControllerApiGetAllQr
   */
  readonly request: GetAllStaticQrRequest;

  /**
   *
   * @type {PaginationAndSortingRequest}
   * @memberof SettingControllerApiGetAllQr
   */
  readonly paginationAndSortingRequest: PaginationAndSortingRequest;
}

/**
 * Request parameters for getMyShopAccount operation in SettingControllerApi.
 * @export
 * @interface SettingControllerApiGetMyShopAccountRequest
 */
export interface SettingControllerApiGetMyShopAccountRequest {
  /**
   *
   * @type {string}
   * @memberof SettingControllerApiGetMyShopAccount
   */
  readonly deviceId: string;
}

/**
 * Request parameters for getQrById operation in SettingControllerApi.
 * @export
 * @interface SettingControllerApiGetQrByIdRequest
 */
export interface SettingControllerApiGetQrByIdRequest {
  /**
   *
   * @type {string}
   * @memberof SettingControllerApiGetQrById
   */
  readonly id: string;
}

/**
 * Request parameters for handleImage operation in SettingControllerApi.
 * @export
 * @interface SettingControllerApiHandleImageRequest
 */
export interface SettingControllerApiHandleImageRequest {
  /**
   *
   * @type {File}
   * @memberof SettingControllerApiHandleImage
   */
  readonly image: File;
}

/**
 * Request parameters for preview operation in SettingControllerApi.
 * @export
 * @interface SettingControllerApiPreviewRequest
 */
export interface SettingControllerApiPreviewRequest {
  /**
   *
   * @type {PreviewQrRequest}
   * @memberof SettingControllerApiPreview
   */
  readonly previewQrRequest: PreviewQrRequest;
}

/**
 * Request parameters for setQrDefault operation in SettingControllerApi.
 * @export
 * @interface SettingControllerApiSetQrDefaultRequest
 */
export interface SettingControllerApiSetQrDefaultRequest {
  /**
   *
   * @type {string}
   * @memberof SettingControllerApiSetQrDefault
   */
  readonly id: string;
}

/**
 * Request parameters for updateAdvertisement operation in SettingControllerApi.
 * @export
 * @interface SettingControllerApiUpdateAdvertisementRequest
 */
export interface SettingControllerApiUpdateAdvertisementRequest {
  /**
   *
   * @type {UpdateAdvertisementRequest}
   * @memberof SettingControllerApiUpdateAdvertisement
   */
  readonly updateAdvertisementRequest: UpdateAdvertisementRequest;
}

/**
 * Request parameters for updateQrById operation in SettingControllerApi.
 * @export
 * @interface SettingControllerApiUpdateQrByIdRequest
 */
export interface SettingControllerApiUpdateQrByIdRequest {
  /**
   *
   * @type {UpdateStaticQrRequest}
   * @memberof SettingControllerApiUpdateQrById
   */
  readonly updateStaticQrRequest: UpdateStaticQrRequest;
}

/**
 * SettingControllerApi - object-oriented interface
 * @export
 * @class SettingControllerApi
 * @extends {BaseAPI}
 */
export class SettingControllerApi extends BaseAPI {
  /**
   *
   * @summary Thêm mới quảng cáo
   * @param {SettingControllerApiAddAdvertisementRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public addAdvertisement(
    requestParameters: SettingControllerApiAddAdvertisementRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettingControllerApiFp(this.configuration)
      .addAdvertisement(requestParameters.addAdvertisementRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thêm mới mã qr
   * @param {SettingControllerApiAddQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public addQr(
    requestParameters: SettingControllerApiAddQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettingControllerApiFp(this.configuration)
      .addQr(requestParameters.addStaticQrRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Check set up bank account
   * @param {SettingControllerApiCheckSetupBankAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public checkSetupBankAccount(
    requestParameters: SettingControllerApiCheckSetupBankAccountRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return SettingControllerApiFp(this.configuration)
      .checkSetupBankAccount(requestParameters.deviceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa quảng cáo theo ID
   * @param {SettingControllerApiDeleteAdvertisementRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public deleteAdvertisement(
    requestParameters: SettingControllerApiDeleteAdvertisementRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettingControllerApiFp(this.configuration)
      .deleteAdvertisement(
        requestParameters.deleteAdvertisementRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa mã QR theo ID
   * @param {SettingControllerApiDeleteQrByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public deleteQrById(
    requestParameters: SettingControllerApiDeleteQrByIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettingControllerApiFp(this.configuration)
      .deleteQrById(requestParameters.deleteStaticQrByIdRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generate mã qr
   * @param {SettingControllerApiGenerateQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public generateQr(
    requestParameters: SettingControllerApiGenerateQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettingControllerApiFp(this.configuration)
      .generateQr(requestParameters.generateQrRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tất cả quảng cáo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public getAllAdvertisement(options?: AxiosRequestConfig) {
    return SettingControllerApiFp(this.configuration)
      .getAllAdvertisement(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tất cả mã qr
   * @param {SettingControllerApiGetAllQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public getAllQr(
    requestParameters: SettingControllerApiGetAllQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettingControllerApiFp(this.configuration)
      .getAllQr(
        requestParameters.request,
        requestParameters.paginationAndSortingRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tài khoản my shop liên kết
   * @param {SettingControllerApiGetMyShopAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public getMyShopAccount(
    requestParameters: SettingControllerApiGetMyShopAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettingControllerApiFp(this.configuration)
      .getMyShopAccount(requestParameters.deviceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy mã QR theo ID
   * @param {SettingControllerApiGetQrByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public getQrById(
    requestParameters: SettingControllerApiGetQrByIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettingControllerApiFp(this.configuration)
      .getQrById(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Handle static qr
   * @param {SettingControllerApiHandleImageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public handleImage(
    requestParameters: SettingControllerApiHandleImageRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettingControllerApiFp(this.configuration)
      .handleImage(requestParameters.image, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Preview mã qr
   * @param {SettingControllerApiPreviewRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public preview(
    requestParameters: SettingControllerApiPreviewRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettingControllerApiFp(this.configuration)
      .preview(requestParameters.previewQrRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Đặt qr làm mặc định
   * @param {SettingControllerApiSetQrDefaultRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public setQrDefault(
    requestParameters: SettingControllerApiSetQrDefaultRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettingControllerApiFp(this.configuration)
      .setQrDefault(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update quảng cáo theo ID
   * @param {SettingControllerApiUpdateAdvertisementRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public updateAdvertisement(
    requestParameters: SettingControllerApiUpdateAdvertisementRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettingControllerApiFp(this.configuration)
      .updateAdvertisement(
        requestParameters.updateAdvertisementRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update mã QR theo ID
   * @param {SettingControllerApiUpdateQrByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public updateQrById(
    requestParameters: SettingControllerApiUpdateQrByIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettingControllerApiFp(this.configuration)
      .updateQrById(requestParameters.updateStaticQrRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Verify User
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingControllerApi
   */
  public verifyUser(options?: AxiosRequestConfig) {
    return SettingControllerApiFp(this.configuration)
      .verifyUser(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TransactionControllerApi - axios parameter creator
 * @export
 */
export const TransactionControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Lấy thông tin chi tiết của giao dịch
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetail", "id", id);
      const localVarPath = `/api/transaction/v1/detail/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal] Lấy danh sách biểu đồ cột top 10 thiết bị có tổng doanh thu cao nhất theo thời gian
     * @param {string} fromDate
     * @param {string} toDate
     * @param {string} [deviceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRevenueOfTopDevice: async (
      fromDate: string,
      toDate: string,
      deviceId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fromDate' is not null or undefined
      assertParamExists("getRevenueOfTopDevice", "fromDate", fromDate);
      // verify required parameter 'toDate' is not null or undefined
      assertParamExists("getRevenueOfTopDevice", "toDate", toDate);
      const localVarPath = `/api/transaction/v1/revenue-of-top-device`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (deviceId !== undefined) {
        localVarQueryParameter["deviceId"] = deviceId;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] =
          (fromDate as any) instanceof Date
            ? (fromDate as any).toISOString().substring(0, 10)
            : fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] =
          (toDate as any) instanceof Date
            ? (toDate as any).toISOString().substring(0, 10)
            : toDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Mobile] Lấy danh sách doanh thu 5 tháng hoặc ngày gần nhất
     * @param {GetRevenuesLastFiveTypeEnum} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRevenuesLastFive: async (
      type: GetRevenuesLastFiveTypeEnum,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists("getRevenuesLastFive", "type", type);
      const localVarPath = `/api/transaction/v1/revenue-last-five`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal] Thống kê doanh thu theo ngày/tháng
     * @param {GetTotalRevenueFilterTimeEnum} filterTime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalRevenue: async (
      filterTime: GetTotalRevenueFilterTimeEnum,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'filterTime' is not null or undefined
      assertParamExists("getTotalRevenue", "filterTime", filterTime);
      const localVarPath = `/api/transaction/v1/statistic/revenue`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (filterTime !== undefined) {
        localVarQueryParameter["filterTime"] = filterTime;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get tất cả các transaction with filter
     * @param {string} [id]
     * @param {string} [keyword]
     * @param {GetTransactionStatusEnum} [status]
     * @param {GetTransactionTypeEnum} [type]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [minAmount]
     * @param {number} [maxAmount]
     * @param {string} [deviceId]
     * @param {string} [accountNumber]
     * @param {number} [size]
     * @param {number} [page]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransaction: async (
      id?: string,
      keyword?: string,
      status?: GetTransactionStatusEnum,
      type?: GetTransactionTypeEnum,
      fromDate?: string,
      toDate?: string,
      minAmount?: number,
      maxAmount?: number,
      deviceId?: string,
      accountNumber?: string,
      size?: number,
      page?: number,
      sortBy?: string,
      sortDir?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/transaction/v1/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] =
          (fromDate as any) instanceof Date
            ? (fromDate as any).toISOString()
            : fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] =
          (toDate as any) instanceof Date
            ? (toDate as any).toISOString()
            : toDate;
      }

      if (minAmount !== undefined) {
        localVarQueryParameter["minAmount"] = minAmount;
      }

      if (maxAmount !== undefined) {
        localVarQueryParameter["maxAmount"] = maxAmount;
      }

      if (deviceId !== undefined) {
        localVarQueryParameter["deviceId"] = deviceId;
      }

      if (accountNumber !== undefined) {
        localVarQueryParameter["accountNumber"] = accountNumber;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      if (sortDir !== undefined) {
        localVarQueryParameter["sortDir"] = sortDir;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal] Lấy danh sách giao dịch theo thiết bị
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [deviceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionByDevice: async (
      page?: number,
      size?: number,
      sort?: Array<string>,
      deviceId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/transaction/v1/transactions-by-device`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      if (deviceId !== undefined) {
        localVarQueryParameter["deviceId"] = deviceId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TransactionControllerApi - functional programming interface
 * @export
 */
export const TransactionControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    TransactionControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Lấy thông tin chi tiết của giao dịch
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetail(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDetailTransactionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDetail(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["TransactionControllerApi.getDetail"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary [Portal] Lấy danh sách biểu đồ cột top 10 thiết bị có tổng doanh thu cao nhất theo thời gian
     * @param {string} fromDate
     * @param {string} toDate
     * @param {string} [deviceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRevenueOfTopDevice(
      fromDate: string,
      toDate: string,
      deviceId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseListResponseGetRevenueOfTopDeviceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRevenueOfTopDevice(
          fromDate,
          toDate,
          deviceId,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["TransactionControllerApi.getRevenueOfTopDevice"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary [Mobile] Lấy danh sách doanh thu 5 tháng hoặc ngày gần nhất
     * @param {GetRevenuesLastFiveTypeEnum} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRevenuesLastFive(
      type: GetRevenuesLastFiveTypeEnum,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseListResponseGetRevenuesLastFiveResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRevenuesLastFive(type, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["TransactionControllerApi.getRevenuesLastFive"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary [Portal] Thống kê doanh thu theo ngày/tháng
     * @param {GetTotalRevenueFilterTimeEnum} filterTime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalRevenue(
      filterTime: GetTotalRevenueFilterTimeEnum,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetTotalRevenueResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalRevenue(
        filterTime,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["TransactionControllerApi.getTotalRevenue"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get tất cả các transaction with filter
     * @param {string} [id]
     * @param {string} [keyword]
     * @param {GetTransactionStatusEnum} [status]
     * @param {GetTransactionTypeEnum} [type]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [minAmount]
     * @param {number} [maxAmount]
     * @param {string} [deviceId]
     * @param {string} [accountNumber]
     * @param {number} [size]
     * @param {number} [page]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransaction(
      id?: string,
      keyword?: string,
      status?: GetTransactionStatusEnum,
      type?: GetTransactionTypeEnum,
      fromDate?: string,
      toDate?: string,
      minAmount?: number,
      maxAmount?: number,
      deviceId?: string,
      accountNumber?: string,
      size?: number,
      page?: number,
      sortBy?: string,
      sortDir?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseGetTransactionsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransaction(
        id,
        keyword,
        status,
        type,
        fromDate,
        toDate,
        minAmount,
        maxAmount,
        deviceId,
        accountNumber,
        size,
        page,
        sortBy,
        sortDir,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["TransactionControllerApi.getTransaction"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary [Portal] Lấy danh sách giao dịch theo thiết bị
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [deviceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionByDevice(
      page?: number,
      size?: number,
      sort?: Array<string>,
      deviceId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseGetListTransactionByDeviceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTransactionByDevice(
          page,
          size,
          sort,
          deviceId,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["TransactionControllerApi.getTransactionByDevice"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * TransactionControllerApi - factory interface
 * @export
 */
export const TransactionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TransactionControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Lấy thông tin chi tiết của giao dịch
     * @param {TransactionControllerApiGetDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail(
      requestParameters: TransactionControllerApiGetDetailRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDetailTransactionResponse> {
      return localVarFp
        .getDetail(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal] Lấy danh sách biểu đồ cột top 10 thiết bị có tổng doanh thu cao nhất theo thời gian
     * @param {TransactionControllerApiGetRevenueOfTopDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRevenueOfTopDevice(
      requestParameters: TransactionControllerApiGetRevenueOfTopDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseListResponseGetRevenueOfTopDeviceResponse> {
      return localVarFp
        .getRevenueOfTopDevice(
          requestParameters.fromDate,
          requestParameters.toDate,
          requestParameters.deviceId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Mobile] Lấy danh sách doanh thu 5 tháng hoặc ngày gần nhất
     * @param {TransactionControllerApiGetRevenuesLastFiveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRevenuesLastFive(
      requestParameters: TransactionControllerApiGetRevenuesLastFiveRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseListResponseGetRevenuesLastFiveResponse> {
      return localVarFp
        .getRevenuesLastFive(requestParameters.type, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal] Thống kê doanh thu theo ngày/tháng
     * @param {TransactionControllerApiGetTotalRevenueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalRevenue(
      requestParameters: TransactionControllerApiGetTotalRevenueRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseGetTotalRevenueResponse> {
      return localVarFp
        .getTotalRevenue(requestParameters.filterTime, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get tất cả các transaction with filter
     * @param {TransactionControllerApiGetTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransaction(
      requestParameters: TransactionControllerApiGetTransactionRequest = {},
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePageResponseGetTransactionsResponse> {
      return localVarFp
        .getTransaction(
          requestParameters.id,
          requestParameters.keyword,
          requestParameters.status,
          requestParameters.type,
          requestParameters.fromDate,
          requestParameters.toDate,
          requestParameters.minAmount,
          requestParameters.maxAmount,
          requestParameters.deviceId,
          requestParameters.accountNumber,
          requestParameters.size,
          requestParameters.page,
          requestParameters.sortBy,
          requestParameters.sortDir,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal] Lấy danh sách giao dịch theo thiết bị
     * @param {TransactionControllerApiGetTransactionByDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionByDevice(
      requestParameters: TransactionControllerApiGetTransactionByDeviceRequest = {},
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePageResponseGetListTransactionByDeviceResponse> {
      return localVarFp
        .getTransactionByDevice(
          requestParameters.page,
          requestParameters.size,
          requestParameters.sort,
          requestParameters.deviceId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getDetail operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiGetDetailRequest
 */
export interface TransactionControllerApiGetDetailRequest {
  /**
   *
   * @type {string}
   * @memberof TransactionControllerApiGetDetail
   */
  readonly id: string;
}

/**
 * Request parameters for getRevenueOfTopDevice operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiGetRevenueOfTopDeviceRequest
 */
export interface TransactionControllerApiGetRevenueOfTopDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof TransactionControllerApiGetRevenueOfTopDevice
   */
  readonly fromDate: string;

  /**
   *
   * @type {string}
   * @memberof TransactionControllerApiGetRevenueOfTopDevice
   */
  readonly toDate: string;

  /**
   *
   * @type {string}
   * @memberof TransactionControllerApiGetRevenueOfTopDevice
   */
  readonly deviceId?: string;
}

/**
 * Request parameters for getRevenuesLastFive operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiGetRevenuesLastFiveRequest
 */
export interface TransactionControllerApiGetRevenuesLastFiveRequest {
  /**
   *
   * @type {'DAY' | 'MONTH'}
   * @memberof TransactionControllerApiGetRevenuesLastFive
   */
  readonly type: GetRevenuesLastFiveTypeEnum;
}

/**
 * Request parameters for getTotalRevenue operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiGetTotalRevenueRequest
 */
export interface TransactionControllerApiGetTotalRevenueRequest {
  /**
   *
   * @type {'DAY' | 'MONTH'}
   * @memberof TransactionControllerApiGetTotalRevenue
   */
  readonly filterTime: GetTotalRevenueFilterTimeEnum;
}

/**
 * Request parameters for getTransaction operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiGetTransactionRequest
 */
export interface TransactionControllerApiGetTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof TransactionControllerApiGetTransaction
   */
  readonly id?: string;

  /**
   *
   * @type {string}
   * @memberof TransactionControllerApiGetTransaction
   */
  readonly keyword?: string;

  /**
   *
   * @type {'NONE' | 'PENDING' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT'}
   * @memberof TransactionControllerApiGetTransaction
   */
  readonly status?: GetTransactionStatusEnum;

  /**
   *
   * @type {'KLB' | 'PAYBOX' | 'POS' | 'USEE' | 'PARTNER_SERVICE'}
   * @memberof TransactionControllerApiGetTransaction
   */
  readonly type?: GetTransactionTypeEnum;

  /**
   *
   * @type {string}
   * @memberof TransactionControllerApiGetTransaction
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof TransactionControllerApiGetTransaction
   */
  readonly toDate?: string;

  /**
   *
   * @type {number}
   * @memberof TransactionControllerApiGetTransaction
   */
  readonly minAmount?: number;

  /**
   *
   * @type {number}
   * @memberof TransactionControllerApiGetTransaction
   */
  readonly maxAmount?: number;

  /**
   *
   * @type {string}
   * @memberof TransactionControllerApiGetTransaction
   */
  readonly deviceId?: string;

  /**
   *
   * @type {string}
   * @memberof TransactionControllerApiGetTransaction
   */
  readonly accountNumber?: string;

  /**
   *
   * @type {number}
   * @memberof TransactionControllerApiGetTransaction
   */
  readonly size?: number;

  /**
   *
   * @type {number}
   * @memberof TransactionControllerApiGetTransaction
   */
  readonly page?: number;

  /**
   *
   * @type {string}
   * @memberof TransactionControllerApiGetTransaction
   */
  readonly sortBy?: string;

  /**
   *
   * @type {string}
   * @memberof TransactionControllerApiGetTransaction
   */
  readonly sortDir?: string;
}

/**
 * Request parameters for getTransactionByDevice operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiGetTransactionByDeviceRequest
 */
export interface TransactionControllerApiGetTransactionByDeviceRequest {
  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof TransactionControllerApiGetTransactionByDevice
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof TransactionControllerApiGetTransactionByDevice
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof TransactionControllerApiGetTransactionByDevice
   */
  readonly sort?: Array<string>;

  /**
   *
   * @type {string}
   * @memberof TransactionControllerApiGetTransactionByDevice
   */
  readonly deviceId?: string;
}

/**
 * TransactionControllerApi - object-oriented interface
 * @export
 * @class TransactionControllerApi
 * @extends {BaseAPI}
 */
export class TransactionControllerApi extends BaseAPI {
  /**
   *
   * @summary Lấy thông tin chi tiết của giao dịch
   * @param {TransactionControllerApiGetDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionControllerApi
   */
  public getDetail(
    requestParameters: TransactionControllerApiGetDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return TransactionControllerApiFp(this.configuration)
      .getDetail(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal] Lấy danh sách biểu đồ cột top 10 thiết bị có tổng doanh thu cao nhất theo thời gian
   * @param {TransactionControllerApiGetRevenueOfTopDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionControllerApi
   */
  public getRevenueOfTopDevice(
    requestParameters: TransactionControllerApiGetRevenueOfTopDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return TransactionControllerApiFp(this.configuration)
      .getRevenueOfTopDevice(
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.deviceId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Mobile] Lấy danh sách doanh thu 5 tháng hoặc ngày gần nhất
   * @param {TransactionControllerApiGetRevenuesLastFiveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionControllerApi
   */
  public getRevenuesLastFive(
    requestParameters: TransactionControllerApiGetRevenuesLastFiveRequest,
    options?: AxiosRequestConfig,
  ) {
    return TransactionControllerApiFp(this.configuration)
      .getRevenuesLastFive(requestParameters.type, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal] Thống kê doanh thu theo ngày/tháng
   * @param {TransactionControllerApiGetTotalRevenueRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionControllerApi
   */
  public getTotalRevenue(
    requestParameters: TransactionControllerApiGetTotalRevenueRequest,
    options?: AxiosRequestConfig,
  ) {
    return TransactionControllerApiFp(this.configuration)
      .getTotalRevenue(requestParameters.filterTime, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get tất cả các transaction with filter
   * @param {TransactionControllerApiGetTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionControllerApi
   */
  public getTransaction(
    requestParameters: TransactionControllerApiGetTransactionRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return TransactionControllerApiFp(this.configuration)
      .getTransaction(
        requestParameters.id,
        requestParameters.keyword,
        requestParameters.status,
        requestParameters.type,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.minAmount,
        requestParameters.maxAmount,
        requestParameters.deviceId,
        requestParameters.accountNumber,
        requestParameters.size,
        requestParameters.page,
        requestParameters.sortBy,
        requestParameters.sortDir,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal] Lấy danh sách giao dịch theo thiết bị
   * @param {TransactionControllerApiGetTransactionByDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionControllerApi
   */
  public getTransactionByDevice(
    requestParameters: TransactionControllerApiGetTransactionByDeviceRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return TransactionControllerApiFp(this.configuration)
      .getTransactionByDevice(
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        requestParameters.deviceId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const GetRevenuesLastFiveTypeEnum = {
  Day: "DAY",
  Month: "MONTH",
} as const;
export type GetRevenuesLastFiveTypeEnum =
  (typeof GetRevenuesLastFiveTypeEnum)[keyof typeof GetRevenuesLastFiveTypeEnum];
/**
 * @export
 */
export const GetTotalRevenueFilterTimeEnum = {
  Day: "DAY",
  Month: "MONTH",
} as const;
export type GetTotalRevenueFilterTimeEnum =
  (typeof GetTotalRevenueFilterTimeEnum)[keyof typeof GetTotalRevenueFilterTimeEnum];
/**
 * @export
 */
export const GetTransactionStatusEnum = {
  None: "NONE",
  Pending: "PENDING",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
} as const;
export type GetTransactionStatusEnum =
  (typeof GetTransactionStatusEnum)[keyof typeof GetTransactionStatusEnum];
/**
 * @export
 */
export const GetTransactionTypeEnum = {
  Klb: "KLB",
  Paybox: "PAYBOX",
  Pos: "POS",
  Usee: "USEE",
  PartnerService: "PARTNER_SERVICE",
} as const;
export type GetTransactionTypeEnum =
  (typeof GetTransactionTypeEnum)[keyof typeof GetTransactionTypeEnum];

/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Kiểm tra email có tồn tại chưa
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkEmailIfExisted: async (
      email: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'email' is not null or undefined
      assertParamExists("checkEmailIfExisted", "email", email);
      const localVarPath = `/api/user/v1/checkEmailIfExisted`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (email !== undefined) {
        localVarQueryParameter["email"] = email;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Kiểm tra phone có tồn tại chưa
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkPhoneIfExisted: async (
      phone: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'phone' is not null or undefined
      assertParamExists("checkPhoneIfExisted", "phone", phone);
      const localVarPath = `/api/user/v1/checkPhoneIfExisted`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (phone !== undefined) {
        localVarQueryParameter["phone"] = phone;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo API key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateUserKey: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/user/v1/generateApiKey`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy thông tin người dùng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/user/v1/getInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy API key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserKey: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/user/v1/get-api-key`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary V2 - Đăng ký user mới
     * @param {V2UserRegisterRequest} v2UserRegisterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register: async (
      v2UserRegisterRequest: V2UserRegisterRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2UserRegisterRequest' is not null or undefined
      assertParamExists(
        "register",
        "v2UserRegisterRequest",
        v2UserRegisterRequest,
      );
      const localVarPath = `/api/user/v2/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2UserRegisterRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Đăng ký user mới
     * @param {UserRegisterRequest} userRegisterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register1: async (
      userRegisterRequest: UserRegisterRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userRegisterRequest' is not null or undefined
      assertParamExists(
        "register1",
        "userRegisterRequest",
        userRegisterRequest,
      );
      const localVarPath = `/api/user/v1/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userRegisterRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update thông tin account
     * @param {UpdateUserRequest} updateUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      updateUserRequest: UpdateUserRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateUserRequest' is not null or undefined
      assertParamExists("update", "updateUserRequest", updateUserRequest);
      const localVarPath = `/api/user/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Verify bank account number
     * @param {VerifyBankAccountRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyBankAccount: async (
      request: VerifyBankAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("verifyBankAccount", "request", request);
      const localVarPath = `/api/user/v1/verifyBankAccount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Verify bank account phone number
     * @param {VerifyBankAccountPhoneRequest} verifyBankAccountPhoneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyBankAccountPhone: async (
      verifyBankAccountPhoneRequest: VerifyBankAccountPhoneRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyBankAccountPhoneRequest' is not null or undefined
      assertParamExists(
        "verifyBankAccountPhone",
        "verifyBankAccountPhoneRequest",
        verifyBankAccountPhoneRequest,
      );
      const localVarPath = `/api/user/v1/verifyBankAccountPhone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyBankAccountPhoneRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Verify otp email
     * @param {VerifyEmailRequest} verifyEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyEmail: async (
      verifyEmailRequest: VerifyEmailRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyEmailRequest' is not null or undefined
      assertParamExists(
        "verifyEmail",
        "verifyEmailRequest",
        verifyEmailRequest,
      );
      const localVarPath = `/api/user/v1/verifyEmail/verify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyEmailRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy otp để xác nhận email
     * @param {GetEmailOTPRequest} getEmailOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyEmailGetOTP: async (
      getEmailOTPRequest: GetEmailOTPRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getEmailOTPRequest' is not null or undefined
      assertParamExists(
        "verifyEmailGetOTP",
        "getEmailOTPRequest",
        getEmailOTPRequest,
      );
      const localVarPath = `/api/user/v1/verifyEmail/getOTP`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getEmailOTPRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xác thực Email đăng ký
     * @param {VerifyEmailRegistryRequest} verifyEmailRegistryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyEmailRegistry: async (
      verifyEmailRegistryRequest: VerifyEmailRegistryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyEmailRegistryRequest' is not null or undefined
      assertParamExists(
        "verifyEmailRegistry",
        "verifyEmailRegistryRequest",
        verifyEmailRegistryRequest,
      );
      const localVarPath = `/api/user/v1/verify-email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyEmailRegistryRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xác thực OTP
     * @param {VerifyOTPRequest} verifyOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyOTP: async (
      verifyOTPRequest: VerifyOTPRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyOTPRequest' is not null or undefined
      assertParamExists("verifyOTP", "verifyOTPRequest", verifyOTPRequest);
      const localVarPath = `/api/user/v1/verifyOTP`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyOTPRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gửi OTP để xác thực số điện thoại
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyPhone: async (
      phone: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'phone' is not null or undefined
      assertParamExists("verifyPhone", "phone", phone);
      const localVarPath = `/api/user/v1/verifyPhone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (phone !== undefined) {
        localVarQueryParameter["phone"] = phone;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UserControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Kiểm tra email có tồn tại chưa
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkEmailIfExisted(
      email: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCheckEmailIfExistedResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkEmailIfExisted(email, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.checkEmailIfExisted"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Kiểm tra phone có tồn tại chưa
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkPhoneIfExisted(
      phone: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCheckPhoneIfExistedResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkPhoneIfExisted(phone, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.checkPhoneIfExisted"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Tạo API key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateUserKey(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUserResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.generateUserKey(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.generateUserKey"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy thông tin người dùng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInfo(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetUserInfoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInfo(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.getInfo"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy API key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserKey(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetApiKeyResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserKey(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.getUserKey"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary V2 - Đăng ký user mới
     * @param {V2UserRegisterRequest} v2UserRegisterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async register(
      v2UserRegisterRequest: V2UserRegisterRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseV2UserRegisterResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.register(
        v2UserRegisterRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.register"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Đăng ký user mới
     * @param {UserRegisterRequest} userRegisterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async register1(
      userRegisterRequest: UserRegisterRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUserRegisterResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.register1(
        userRegisterRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.register1"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Update thông tin account
     * @param {UpdateUserRequest} updateUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      updateUserRequest: UpdateUserRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUpdateUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(
        updateUserRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.update"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Verify bank account number
     * @param {VerifyBankAccountRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyBankAccount(
      request: VerifyBankAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVerifyBankAccountResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyBankAccount(request, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.verifyBankAccount"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Verify bank account phone number
     * @param {VerifyBankAccountPhoneRequest} verifyBankAccountPhoneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyBankAccountPhone(
      verifyBankAccountPhoneRequest: VerifyBankAccountPhoneRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVerifyBankAccountPhoneResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyBankAccountPhone(
          verifyBankAccountPhoneRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.verifyBankAccountPhone"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Verify otp email
     * @param {VerifyEmailRequest} verifyEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyEmail(
      verifyEmailRequest: VerifyEmailRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVerifyEmailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEmail(
        verifyEmailRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.verifyEmail"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy otp để xác nhận email
     * @param {GetEmailOTPRequest} getEmailOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyEmailGetOTP(
      getEmailOTPRequest: GetEmailOTPRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetOTPResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyEmailGetOTP(
          getEmailOTPRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.verifyEmailGetOTP"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Xác thực Email đăng ký
     * @param {VerifyEmailRegistryRequest} verifyEmailRegistryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyEmailRegistry(
      verifyEmailRegistryRequest: VerifyEmailRegistryRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyEmailRegistry(
          verifyEmailRegistryRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.verifyEmailRegistry"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Xác thực OTP
     * @param {VerifyOTPRequest} verifyOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyOTP(
      verifyOTPRequest: VerifyOTPRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVerifyOTPResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyOTP(
        verifyOTPRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.verifyOTP"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Gửi OTP để xác thực số điện thoại
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyPhone(
      phone: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetOTPResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyPhone(
        phone,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.verifyPhone"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Kiểm tra email có tồn tại chưa
     * @param {UserControllerApiCheckEmailIfExistedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkEmailIfExisted(
      requestParameters: UserControllerApiCheckEmailIfExistedRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseCheckEmailIfExistedResponse> {
      return localVarFp
        .checkEmailIfExisted(requestParameters.email, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Kiểm tra phone có tồn tại chưa
     * @param {UserControllerApiCheckPhoneIfExistedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkPhoneIfExisted(
      requestParameters: UserControllerApiCheckPhoneIfExistedRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseCheckPhoneIfExistedResponse> {
      return localVarFp
        .checkPhoneIfExisted(requestParameters.phone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo API key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateUserKey(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseUserResponse> {
      return localVarFp
        .generateUserKey(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin người dùng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfo(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseGetUserInfoResponse> {
      return localVarFp
        .getInfo(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy API key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserKey(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseGetApiKeyResponse> {
      return localVarFp
        .getUserKey(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary V2 - Đăng ký user mới
     * @param {UserControllerApiRegisterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register(
      requestParameters: UserControllerApiRegisterRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseV2UserRegisterResponse> {
      return localVarFp
        .register(requestParameters.v2UserRegisterRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Đăng ký user mới
     * @param {UserControllerApiRegister1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register1(
      requestParameters: UserControllerApiRegister1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseUserRegisterResponse> {
      return localVarFp
        .register1(requestParameters.userRegisterRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update thông tin account
     * @param {UserControllerApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(
      requestParameters: UserControllerApiUpdateRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseUpdateUserResponse> {
      return localVarFp
        .update(requestParameters.updateUserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Verify bank account number
     * @param {UserControllerApiVerifyBankAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyBankAccount(
      requestParameters: UserControllerApiVerifyBankAccountRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseVerifyBankAccountResponse> {
      return localVarFp
        .verifyBankAccount(requestParameters.request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Verify bank account phone number
     * @param {UserControllerApiVerifyBankAccountPhoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyBankAccountPhone(
      requestParameters: UserControllerApiVerifyBankAccountPhoneRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseVerifyBankAccountPhoneResponse> {
      return localVarFp
        .verifyBankAccountPhone(
          requestParameters.verifyBankAccountPhoneRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Verify otp email
     * @param {UserControllerApiVerifyEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyEmail(
      requestParameters: UserControllerApiVerifyEmailRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseVerifyEmailResponse> {
      return localVarFp
        .verifyEmail(requestParameters.verifyEmailRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy otp để xác nhận email
     * @param {UserControllerApiVerifyEmailGetOTPRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyEmailGetOTP(
      requestParameters: UserControllerApiVerifyEmailGetOTPRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseGetOTPResponse> {
      return localVarFp
        .verifyEmailGetOTP(requestParameters.getEmailOTPRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xác thực Email đăng ký
     * @param {UserControllerApiVerifyEmailRegistryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyEmailRegistry(
      requestParameters: UserControllerApiVerifyEmailRegistryRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .verifyEmailRegistry(
          requestParameters.verifyEmailRegistryRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xác thực OTP
     * @param {UserControllerApiVerifyOTPRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyOTP(
      requestParameters: UserControllerApiVerifyOTPRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseVerifyOTPResponse> {
      return localVarFp
        .verifyOTP(requestParameters.verifyOTPRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Gửi OTP để xác thực số điện thoại
     * @param {UserControllerApiVerifyPhoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyPhone(
      requestParameters: UserControllerApiVerifyPhoneRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseGetOTPResponse> {
      return localVarFp
        .verifyPhone(requestParameters.phone, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for checkEmailIfExisted operation in UserControllerApi.
 * @export
 * @interface UserControllerApiCheckEmailIfExistedRequest
 */
export interface UserControllerApiCheckEmailIfExistedRequest {
  /**
   *
   * @type {string}
   * @memberof UserControllerApiCheckEmailIfExisted
   */
  readonly email: string;
}

/**
 * Request parameters for checkPhoneIfExisted operation in UserControllerApi.
 * @export
 * @interface UserControllerApiCheckPhoneIfExistedRequest
 */
export interface UserControllerApiCheckPhoneIfExistedRequest {
  /**
   *
   * @type {string}
   * @memberof UserControllerApiCheckPhoneIfExisted
   */
  readonly phone: string;
}

/**
 * Request parameters for register operation in UserControllerApi.
 * @export
 * @interface UserControllerApiRegisterRequest
 */
export interface UserControllerApiRegisterRequest {
  /**
   *
   * @type {V2UserRegisterRequest}
   * @memberof UserControllerApiRegister
   */
  readonly v2UserRegisterRequest: V2UserRegisterRequest;
}

/**
 * Request parameters for register1 operation in UserControllerApi.
 * @export
 * @interface UserControllerApiRegister1Request
 */
export interface UserControllerApiRegister1Request {
  /**
   *
   * @type {UserRegisterRequest}
   * @memberof UserControllerApiRegister1
   */
  readonly userRegisterRequest: UserRegisterRequest;
}

/**
 * Request parameters for update operation in UserControllerApi.
 * @export
 * @interface UserControllerApiUpdateRequest
 */
export interface UserControllerApiUpdateRequest {
  /**
   *
   * @type {UpdateUserRequest}
   * @memberof UserControllerApiUpdate
   */
  readonly updateUserRequest: UpdateUserRequest;
}

/**
 * Request parameters for verifyBankAccount operation in UserControllerApi.
 * @export
 * @interface UserControllerApiVerifyBankAccountRequest
 */
export interface UserControllerApiVerifyBankAccountRequest {
  /**
   *
   * @type {VerifyBankAccountRequest}
   * @memberof UserControllerApiVerifyBankAccount
   */
  readonly request: VerifyBankAccountRequest;
}

/**
 * Request parameters for verifyBankAccountPhone operation in UserControllerApi.
 * @export
 * @interface UserControllerApiVerifyBankAccountPhoneRequest
 */
export interface UserControllerApiVerifyBankAccountPhoneRequest {
  /**
   *
   * @type {VerifyBankAccountPhoneRequest}
   * @memberof UserControllerApiVerifyBankAccountPhone
   */
  readonly verifyBankAccountPhoneRequest: VerifyBankAccountPhoneRequest;
}

/**
 * Request parameters for verifyEmail operation in UserControllerApi.
 * @export
 * @interface UserControllerApiVerifyEmailRequest
 */
export interface UserControllerApiVerifyEmailRequest {
  /**
   *
   * @type {VerifyEmailRequest}
   * @memberof UserControllerApiVerifyEmail
   */
  readonly verifyEmailRequest: VerifyEmailRequest;
}

/**
 * Request parameters for verifyEmailGetOTP operation in UserControllerApi.
 * @export
 * @interface UserControllerApiVerifyEmailGetOTPRequest
 */
export interface UserControllerApiVerifyEmailGetOTPRequest {
  /**
   *
   * @type {GetEmailOTPRequest}
   * @memberof UserControllerApiVerifyEmailGetOTP
   */
  readonly getEmailOTPRequest: GetEmailOTPRequest;
}

/**
 * Request parameters for verifyEmailRegistry operation in UserControllerApi.
 * @export
 * @interface UserControllerApiVerifyEmailRegistryRequest
 */
export interface UserControllerApiVerifyEmailRegistryRequest {
  /**
   *
   * @type {VerifyEmailRegistryRequest}
   * @memberof UserControllerApiVerifyEmailRegistry
   */
  readonly verifyEmailRegistryRequest: VerifyEmailRegistryRequest;
}

/**
 * Request parameters for verifyOTP operation in UserControllerApi.
 * @export
 * @interface UserControllerApiVerifyOTPRequest
 */
export interface UserControllerApiVerifyOTPRequest {
  /**
   *
   * @type {VerifyOTPRequest}
   * @memberof UserControllerApiVerifyOTP
   */
  readonly verifyOTPRequest: VerifyOTPRequest;
}

/**
 * Request parameters for verifyPhone operation in UserControllerApi.
 * @export
 * @interface UserControllerApiVerifyPhoneRequest
 */
export interface UserControllerApiVerifyPhoneRequest {
  /**
   *
   * @type {string}
   * @memberof UserControllerApiVerifyPhone
   */
  readonly phone: string;
}

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
  /**
   *
   * @summary Kiểm tra email có tồn tại chưa
   * @param {UserControllerApiCheckEmailIfExistedRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public checkEmailIfExisted(
    requestParameters: UserControllerApiCheckEmailIfExistedRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .checkEmailIfExisted(requestParameters.email, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Kiểm tra phone có tồn tại chưa
   * @param {UserControllerApiCheckPhoneIfExistedRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public checkPhoneIfExisted(
    requestParameters: UserControllerApiCheckPhoneIfExistedRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .checkPhoneIfExisted(requestParameters.phone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo API key
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public generateUserKey(options?: AxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .generateUserKey(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin người dùng
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public getInfo(options?: AxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .getInfo(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy API key
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public getUserKey(options?: AxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .getUserKey(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary V2 - Đăng ký user mới
   * @param {UserControllerApiRegisterRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public register(
    requestParameters: UserControllerApiRegisterRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .register(requestParameters.v2UserRegisterRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Đăng ký user mới
   * @param {UserControllerApiRegister1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public register1(
    requestParameters: UserControllerApiRegister1Request,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .register1(requestParameters.userRegisterRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update thông tin account
   * @param {UserControllerApiUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public update(
    requestParameters: UserControllerApiUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .update(requestParameters.updateUserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Verify bank account number
   * @param {UserControllerApiVerifyBankAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public verifyBankAccount(
    requestParameters: UserControllerApiVerifyBankAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .verifyBankAccount(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Verify bank account phone number
   * @param {UserControllerApiVerifyBankAccountPhoneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public verifyBankAccountPhone(
    requestParameters: UserControllerApiVerifyBankAccountPhoneRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .verifyBankAccountPhone(
        requestParameters.verifyBankAccountPhoneRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Verify otp email
   * @param {UserControllerApiVerifyEmailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public verifyEmail(
    requestParameters: UserControllerApiVerifyEmailRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .verifyEmail(requestParameters.verifyEmailRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy otp để xác nhận email
   * @param {UserControllerApiVerifyEmailGetOTPRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public verifyEmailGetOTP(
    requestParameters: UserControllerApiVerifyEmailGetOTPRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .verifyEmailGetOTP(requestParameters.getEmailOTPRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xác thực Email đăng ký
   * @param {UserControllerApiVerifyEmailRegistryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public verifyEmailRegistry(
    requestParameters: UserControllerApiVerifyEmailRegistryRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .verifyEmailRegistry(
        requestParameters.verifyEmailRegistryRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xác thực OTP
   * @param {UserControllerApiVerifyOTPRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public verifyOTP(
    requestParameters: UserControllerApiVerifyOTPRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .verifyOTP(requestParameters.verifyOTPRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gửi OTP để xác thực số điện thoại
   * @param {UserControllerApiVerifyPhoneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public verifyPhone(
    requestParameters: UserControllerApiVerifyPhoneRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .verifyPhone(requestParameters.phone, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VersionControllerApi - axios parameter creator
 * @export
 */
export const VersionControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Thêm mới version
     * @param {string} model
     * @param {string} version
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addVersion: async (
      model: string,
      version: string,
      file: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'model' is not null or undefined
      assertParamExists("addVersion", "model", model);
      // verify required parameter 'version' is not null or undefined
      assertParamExists("addVersion", "version", version);
      // verify required parameter 'file' is not null or undefined
      assertParamExists("addVersion", "file", file);
      const localVarPath = `/api/admin/version/v1/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (model !== undefined) {
        localVarFormParams.append("model", model as any);
      }

      if (version !== undefined) {
        localVarFormParams.append("version", version as any);
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check version
     * @param {CheckVersionRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkVersion: async (
      request: CheckVersionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("checkVersion", "request", request);
      const localVarPath = `/api/version/v1/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check version
     * @param {CheckVersionRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkVersion1: async (
      request: CheckVersionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("checkVersion1", "request", request);
      const localVarPath = `/api/admin/version/v1/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa version
     * @param {DeleteVersionRequest} deleteVersionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteVersion: async (
      deleteVersionRequest: DeleteVersionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteVersionRequest' is not null or undefined
      assertParamExists(
        "deleteVersion",
        "deleteVersionRequest",
        deleteVersionRequest,
      );
      const localVarPath = `/api/admin/version/v1/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteVersionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Force Update
     * @param {ForceUpdateRequest} forceUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forceUpdateVersion: async (
      forceUpdateRequest: ForceUpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'forceUpdateRequest' is not null or undefined
      assertParamExists(
        "forceUpdateVersion",
        "forceUpdateRequest",
        forceUpdateRequest,
      );
      const localVarPath = `/api/admin/version/v1/forceUpdate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        forceUpdateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get latest version
     * @param {GetLatestVersionRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestVersion: async (
      request: GetLatestVersionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("getLatestVersion", "request", request);
      const localVarPath = `/api/admin/version/v1/getLatestVersion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get versions
     * @param {GetVersionsRequest} request
     * @param {PaginationAndSortingRequest} paginationAndSortingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersion: async (
      request: GetVersionsRequest,
      paginationAndSortingRequest: PaginationAndSortingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("getVersion", "request", request);
      // verify required parameter 'paginationAndSortingRequest' is not null or undefined
      assertParamExists(
        "getVersion",
        "paginationAndSortingRequest",
        paginationAndSortingRequest,
      );
      const localVarPath = `/api/admin/version/v1/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (paginationAndSortingRequest !== undefined) {
        for (const [key, value] of Object.entries(
          paginationAndSortingRequest,
        )) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * VersionControllerApi - functional programming interface
 * @export
 */
export const VersionControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    VersionControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Thêm mới version
     * @param {string} model
     * @param {string} version
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addVersion(
      model: string,
      version: string,
      file: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVersionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addVersion(
        model,
        version,
        file,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["VersionControllerApi.addVersion"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Check version
     * @param {CheckVersionRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkVersion(
      request: CheckVersionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkVersion(
        request,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["VersionControllerApi.checkVersion"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Check version
     * @param {CheckVersionRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkVersion1(
      request: CheckVersionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkVersion1(
        request,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["VersionControllerApi.checkVersion1"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Xóa version
     * @param {DeleteVersionRequest} deleteVersionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteVersion(
      deleteVersionRequest: DeleteVersionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeleteVersionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVersion(
        deleteVersionRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["VersionControllerApi.deleteVersion"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Force Update
     * @param {ForceUpdateRequest} forceUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forceUpdateVersion(
      forceUpdateRequest: ForceUpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseForceUpdateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.forceUpdateVersion(
          forceUpdateRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["VersionControllerApi.forceUpdateVersion"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get latest version
     * @param {GetLatestVersionRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLatestVersion(
      request: GetLatestVersionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetLatestVersionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLatestVersion(request, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["VersionControllerApi.getLatestVersion"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get versions
     * @param {GetVersionsRequest} request
     * @param {PaginationAndSortingRequest} paginationAndSortingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVersion(
      request: GetVersionsRequest,
      paginationAndSortingRequest: PaginationAndSortingRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetVersionsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(
        request,
        paginationAndSortingRequest,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["VersionControllerApi.getVersion"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * VersionControllerApi - factory interface
 * @export
 */
export const VersionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = VersionControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Thêm mới version
     * @param {VersionControllerApiAddVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addVersion(
      requestParameters: VersionControllerApiAddVersionRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseVersionResponse> {
      return localVarFp
        .addVersion(
          requestParameters.model,
          requestParameters.version,
          requestParameters.file,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Check version
     * @param {VersionControllerApiCheckVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkVersion(
      requestParameters: VersionControllerApiCheckVersionRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<File> {
      return localVarFp
        .checkVersion(requestParameters.request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Check version
     * @param {VersionControllerApiCheckVersion1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkVersion1(
      requestParameters: VersionControllerApiCheckVersion1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<File> {
      return localVarFp
        .checkVersion1(requestParameters.request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa version
     * @param {VersionControllerApiDeleteVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteVersion(
      requestParameters: VersionControllerApiDeleteVersionRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDeleteVersionResponse> {
      return localVarFp
        .deleteVersion(requestParameters.deleteVersionRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Force Update
     * @param {VersionControllerApiForceUpdateVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forceUpdateVersion(
      requestParameters: VersionControllerApiForceUpdateVersionRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseForceUpdateResponse> {
      return localVarFp
        .forceUpdateVersion(requestParameters.forceUpdateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get latest version
     * @param {VersionControllerApiGetLatestVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestVersion(
      requestParameters: VersionControllerApiGetLatestVersionRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseGetLatestVersionResponse> {
      return localVarFp
        .getLatestVersion(requestParameters.request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get versions
     * @param {VersionControllerApiGetVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersion(
      requestParameters: VersionControllerApiGetVersionRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseGetVersionsResponse> {
      return localVarFp
        .getVersion(
          requestParameters.request,
          requestParameters.paginationAndSortingRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addVersion operation in VersionControllerApi.
 * @export
 * @interface VersionControllerApiAddVersionRequest
 */
export interface VersionControllerApiAddVersionRequest {
  /**
   *
   * @type {string}
   * @memberof VersionControllerApiAddVersion
   */
  readonly model: string;

  /**
   *
   * @type {string}
   * @memberof VersionControllerApiAddVersion
   */
  readonly version: string;

  /**
   *
   * @type {File}
   * @memberof VersionControllerApiAddVersion
   */
  readonly file: File;
}

/**
 * Request parameters for checkVersion operation in VersionControllerApi.
 * @export
 * @interface VersionControllerApiCheckVersionRequest
 */
export interface VersionControllerApiCheckVersionRequest {
  /**
   *
   * @type {CheckVersionRequest}
   * @memberof VersionControllerApiCheckVersion
   */
  readonly request: CheckVersionRequest;
}

/**
 * Request parameters for checkVersion1 operation in VersionControllerApi.
 * @export
 * @interface VersionControllerApiCheckVersion1Request
 */
export interface VersionControllerApiCheckVersion1Request {
  /**
   *
   * @type {CheckVersionRequest}
   * @memberof VersionControllerApiCheckVersion1
   */
  readonly request: CheckVersionRequest;
}

/**
 * Request parameters for deleteVersion operation in VersionControllerApi.
 * @export
 * @interface VersionControllerApiDeleteVersionRequest
 */
export interface VersionControllerApiDeleteVersionRequest {
  /**
   *
   * @type {DeleteVersionRequest}
   * @memberof VersionControllerApiDeleteVersion
   */
  readonly deleteVersionRequest: DeleteVersionRequest;
}

/**
 * Request parameters for forceUpdateVersion operation in VersionControllerApi.
 * @export
 * @interface VersionControllerApiForceUpdateVersionRequest
 */
export interface VersionControllerApiForceUpdateVersionRequest {
  /**
   *
   * @type {ForceUpdateRequest}
   * @memberof VersionControllerApiForceUpdateVersion
   */
  readonly forceUpdateRequest: ForceUpdateRequest;
}

/**
 * Request parameters for getLatestVersion operation in VersionControllerApi.
 * @export
 * @interface VersionControllerApiGetLatestVersionRequest
 */
export interface VersionControllerApiGetLatestVersionRequest {
  /**
   *
   * @type {GetLatestVersionRequest}
   * @memberof VersionControllerApiGetLatestVersion
   */
  readonly request: GetLatestVersionRequest;
}

/**
 * Request parameters for getVersion operation in VersionControllerApi.
 * @export
 * @interface VersionControllerApiGetVersionRequest
 */
export interface VersionControllerApiGetVersionRequest {
  /**
   *
   * @type {GetVersionsRequest}
   * @memberof VersionControllerApiGetVersion
   */
  readonly request: GetVersionsRequest;

  /**
   *
   * @type {PaginationAndSortingRequest}
   * @memberof VersionControllerApiGetVersion
   */
  readonly paginationAndSortingRequest: PaginationAndSortingRequest;
}

/**
 * VersionControllerApi - object-oriented interface
 * @export
 * @class VersionControllerApi
 * @extends {BaseAPI}
 */
export class VersionControllerApi extends BaseAPI {
  /**
   *
   * @summary Thêm mới version
   * @param {VersionControllerApiAddVersionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionControllerApi
   */
  public addVersion(
    requestParameters: VersionControllerApiAddVersionRequest,
    options?: AxiosRequestConfig,
  ) {
    return VersionControllerApiFp(this.configuration)
      .addVersion(
        requestParameters.model,
        requestParameters.version,
        requestParameters.file,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Check version
   * @param {VersionControllerApiCheckVersionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionControllerApi
   */
  public checkVersion(
    requestParameters: VersionControllerApiCheckVersionRequest,
    options?: AxiosRequestConfig,
  ) {
    return VersionControllerApiFp(this.configuration)
      .checkVersion(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Check version
   * @param {VersionControllerApiCheckVersion1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionControllerApi
   */
  public checkVersion1(
    requestParameters: VersionControllerApiCheckVersion1Request,
    options?: AxiosRequestConfig,
  ) {
    return VersionControllerApiFp(this.configuration)
      .checkVersion1(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa version
   * @param {VersionControllerApiDeleteVersionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionControllerApi
   */
  public deleteVersion(
    requestParameters: VersionControllerApiDeleteVersionRequest,
    options?: AxiosRequestConfig,
  ) {
    return VersionControllerApiFp(this.configuration)
      .deleteVersion(requestParameters.deleteVersionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Force Update
   * @param {VersionControllerApiForceUpdateVersionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionControllerApi
   */
  public forceUpdateVersion(
    requestParameters: VersionControllerApiForceUpdateVersionRequest,
    options?: AxiosRequestConfig,
  ) {
    return VersionControllerApiFp(this.configuration)
      .forceUpdateVersion(requestParameters.forceUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get latest version
   * @param {VersionControllerApiGetLatestVersionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionControllerApi
   */
  public getLatestVersion(
    requestParameters: VersionControllerApiGetLatestVersionRequest,
    options?: AxiosRequestConfig,
  ) {
    return VersionControllerApiFp(this.configuration)
      .getLatestVersion(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get versions
   * @param {VersionControllerApiGetVersionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionControllerApi
   */
  public getVersion(
    requestParameters: VersionControllerApiGetVersionRequest,
    options?: AxiosRequestConfig,
  ) {
    return VersionControllerApiFp(this.configuration)
      .getVersion(
        requestParameters.request,
        requestParameters.paginationAndSortingRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WhitelabelControllerApi - axios parameter creator
 * @export
 */
export const WhitelabelControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Huỷ show QRCode
     * @param {string} deviceToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelQrCode: async (
      deviceToken: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceToken' is not null or undefined
      assertParamExists("cancelQrCode", "deviceToken", deviceToken);
      const localVarPath = `/api/whitelabel/v1/qrcode/cancel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deviceToken != null) {
        localVarHeaderParameter["deviceToken"] = String(deviceToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Link device - Kiểm tra thiết bị
     * @param {CheckDeviceWLRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkDevice1: async (
      request: CheckDeviceWLRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("checkDevice1", "request", request);
      const localVarPath = `/api/whitelabel/v1/devices/pair/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo qr động - Custom QRCode
     * @param {string} deviceToken
     * @param {CustomDynamicQrWLRequest} customDynamicQrWLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customDynamicQRCode: async (
      deviceToken: string,
      customDynamicQrWLRequest: CustomDynamicQrWLRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceToken' is not null or undefined
      assertParamExists("customDynamicQRCode", "deviceToken", deviceToken);
      // verify required parameter 'customDynamicQrWLRequest' is not null or undefined
      assertParamExists(
        "customDynamicQRCode",
        "customDynamicQrWLRequest",
        customDynamicQrWLRequest,
      );
      const localVarPath = `/api/whitelabel/v1/qrcode/dynamic/custom`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deviceToken != null) {
        localVarHeaderParameter["deviceToken"] = String(deviceToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        customDynamicQrWLRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Device Detail
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevice: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDevice", "id", id);
      const localVarPath = `/api/whitelabel/v1/devices/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Device Detail with grant
     * @param {string} deviceToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeviceByGrantedToken: async (
      deviceToken: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceToken' is not null or undefined
      assertParamExists("getDeviceByGrantedToken", "deviceToken", deviceToken);
      const localVarPath = `/api/whitelabel/v1/devices/paired`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deviceToken != null) {
        localVarHeaderParameter["deviceToken"] = String(deviceToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách device
     * @param {GetDeviceListWLRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListDevice: async (
      request: GetDeviceListWLRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists("getListDevice", "request", request);
      const localVarPath = `/api/whitelabel/v1/devices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (request !== undefined) {
        for (const [key, value] of Object.entries(request)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thông báo biến động số dư
     * @param {string} deviceToken
     * @param {NotifyTransactionWLRequest} notifyTransactionWLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransaction: async (
      deviceToken: string,
      notifyTransactionWLRequest: NotifyTransactionWLRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceToken' is not null or undefined
      assertParamExists("notifyTransaction", "deviceToken", deviceToken);
      // verify required parameter 'notifyTransactionWLRequest' is not null or undefined
      assertParamExists(
        "notifyTransaction",
        "notifyTransactionWLRequest",
        notifyTransactionWLRequest,
      );
      const localVarPath = `/api/whitelabel/v1/transactions/notify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deviceToken != null) {
        localVarHeaderParameter["deviceToken"] = String(deviceToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notifyTransactionWLRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Link device - Yêu cầu liên kết thiết bị
     * @param {SubmitLinkDeviceWLRequest} submitLinkDeviceWLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestLinkDevice: async (
      submitLinkDeviceWLRequest: SubmitLinkDeviceWLRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'submitLinkDeviceWLRequest' is not null or undefined
      assertParamExists(
        "requestLinkDevice",
        "submitLinkDeviceWLRequest",
        submitLinkDeviceWLRequest,
      );
      const localVarPath = `/api/whitelabel/v1/devices/pair/request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        submitLinkDeviceWLRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo qr động
     * @param {string} deviceToken
     * @param {ShowDynamicQrWLRequest} showDynamicQrWLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showDynamicQRCode: async (
      deviceToken: string,
      showDynamicQrWLRequest: ShowDynamicQrWLRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceToken' is not null or undefined
      assertParamExists("showDynamicQRCode", "deviceToken", deviceToken);
      // verify required parameter 'showDynamicQrWLRequest' is not null or undefined
      assertParamExists(
        "showDynamicQRCode",
        "showDynamicQrWLRequest",
        showDynamicQrWLRequest,
      );
      const localVarPath = `/api/whitelabel/v1/qrcode/dynamic`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deviceToken != null) {
        localVarHeaderParameter["deviceToken"] = String(deviceToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        showDynamicQrWLRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo qr tĩnh - Dựa vào thông tin để generate
     * @param {string} deviceToken
     * @param {ShowStaticQrWLRequest} showStaticQrWLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showStaticQRCode: async (
      deviceToken: string,
      showStaticQrWLRequest: ShowStaticQrWLRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceToken' is not null or undefined
      assertParamExists("showStaticQRCode", "deviceToken", deviceToken);
      // verify required parameter 'showStaticQrWLRequest' is not null or undefined
      assertParamExists(
        "showStaticQRCode",
        "showStaticQrWLRequest",
        showStaticQrWLRequest,
      );
      const localVarPath = `/api/whitelabel/v1/qrcode/static`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deviceToken != null) {
        localVarHeaderParameter["deviceToken"] = String(deviceToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        showStaticQrWLRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo qr tĩnh - Custom QRCode
     * @param {string} deviceToken
     * @param {CustomStaticQrWLRequest} customStaticQrWLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showStaticQRCodeCustom: async (
      deviceToken: string,
      customStaticQrWLRequest: CustomStaticQrWLRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceToken' is not null or undefined
      assertParamExists("showStaticQRCodeCustom", "deviceToken", deviceToken);
      // verify required parameter 'customStaticQrWLRequest' is not null or undefined
      assertParamExists(
        "showStaticQRCodeCustom",
        "customStaticQrWLRequest",
        customStaticQrWLRequest,
      );
      const localVarPath = `/api/whitelabel/v1/qrcode/static/custom`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deviceToken != null) {
        localVarHeaderParameter["deviceToken"] = String(deviceToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        customStaticQrWLRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unlink device
     * @param {string} deviceToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlinkDevice: async (
      deviceToken: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceToken' is not null or undefined
      assertParamExists("unlinkDevice", "deviceToken", deviceToken);
      const localVarPath = `/api/whitelabel/v1/devices/unpair`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deviceToken != null) {
        localVarHeaderParameter["deviceToken"] = String(deviceToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Link device - Xác thực OTP
     * @param {VerifyLinkDeviceWLRequest} verifyLinkDeviceWLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyLinkDevice: async (
      verifyLinkDeviceWLRequest: VerifyLinkDeviceWLRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyLinkDeviceWLRequest' is not null or undefined
      assertParamExists(
        "verifyLinkDevice",
        "verifyLinkDeviceWLRequest",
        verifyLinkDeviceWLRequest,
      );
      const localVarPath = `/api/whitelabel/v1/devices/pair/verify-otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyLinkDeviceWLRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WhitelabelControllerApi - functional programming interface
 * @export
 */
export const WhitelabelControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    WhitelabelControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Huỷ show QRCode
     * @param {string} deviceToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelQrCode(
      deviceToken: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelQrCode(
        deviceToken,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["WhitelabelControllerApi.cancelQrCode"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Link device - Kiểm tra thiết bị
     * @param {CheckDeviceWLRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkDevice1(
      request: CheckDeviceWLRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCheckDeviceWLResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkDevice1(
        request,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["WhitelabelControllerApi.checkDevice1"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Tạo qr động - Custom QRCode
     * @param {string} deviceToken
     * @param {CustomDynamicQrWLRequest} customDynamicQrWLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customDynamicQRCode(
      deviceToken: string,
      customDynamicQrWLRequest: CustomDynamicQrWLRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.customDynamicQRCode(
          deviceToken,
          customDynamicQrWLRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["WhitelabelControllerApi.customDynamicQRCode"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get Device Detail
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDevice(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeviceDetailWLResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDevice(
        id,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["WhitelabelControllerApi.getDevice"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get Device Detail with grant
     * @param {string} deviceToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDeviceByGrantedToken(
      deviceToken: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeviceWLResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDeviceByGrantedToken(
          deviceToken,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["WhitelabelControllerApi.getDeviceByGrantedToken"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Lấy danh sách device
     * @param {GetDeviceListWLRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListDevice(
      request: GetDeviceListWLRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseDeviceWLResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getListDevice(
        request,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["WhitelabelControllerApi.getListDevice"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Thông báo biến động số dư
     * @param {string} deviceToken
     * @param {NotifyTransactionWLRequest} notifyTransactionWLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notifyTransaction(
      deviceToken: string,
      notifyTransactionWLRequest: NotifyTransactionWLRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseTransactionWLResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notifyTransaction(
          deviceToken,
          notifyTransactionWLRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["WhitelabelControllerApi.notifyTransaction"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Link device - Yêu cầu liên kết thiết bị
     * @param {SubmitLinkDeviceWLRequest} submitLinkDeviceWLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestLinkDevice(
      submitLinkDeviceWLRequest: SubmitLinkDeviceWLRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseLinkDeviceWLResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.requestLinkDevice(
          submitLinkDeviceWLRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["WhitelabelControllerApi.requestLinkDevice"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Tạo qr động
     * @param {string} deviceToken
     * @param {ShowDynamicQrWLRequest} showDynamicQrWLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showDynamicQRCode(
      deviceToken: string,
      showDynamicQrWLRequest: ShowDynamicQrWLRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.showDynamicQRCode(
          deviceToken,
          showDynamicQrWLRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["WhitelabelControllerApi.showDynamicQRCode"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Tạo qr tĩnh - Dựa vào thông tin để generate
     * @param {string} deviceToken
     * @param {ShowStaticQrWLRequest} showStaticQrWLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showStaticQRCode(
      deviceToken: string,
      showStaticQrWLRequest: ShowStaticQrWLRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.showStaticQRCode(
          deviceToken,
          showStaticQrWLRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["WhitelabelControllerApi.showStaticQRCode"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Tạo qr tĩnh - Custom QRCode
     * @param {string} deviceToken
     * @param {CustomStaticQrWLRequest} customStaticQrWLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showStaticQRCodeCustom(
      deviceToken: string,
      customStaticQrWLRequest: CustomStaticQrWLRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.showStaticQRCodeCustom(
          deviceToken,
          customStaticQrWLRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["WhitelabelControllerApi.showStaticQRCodeCustom"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Unlink device
     * @param {string} deviceToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unlinkDevice(
      deviceToken: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unlinkDevice(
        deviceToken,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["WhitelabelControllerApi.unlinkDevice"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Link device - Xác thực OTP
     * @param {VerifyLinkDeviceWLRequest} verifyLinkDeviceWLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyLinkDevice(
      verifyLinkDeviceWLRequest: VerifyLinkDeviceWLRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVerifyLinkDeviceWLResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyLinkDevice(
          verifyLinkDeviceWLRequest,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["WhitelabelControllerApi.verifyLinkDevice"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * WhitelabelControllerApi - factory interface
 * @export
 */
export const WhitelabelControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WhitelabelControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Huỷ show QRCode
     * @param {WhitelabelControllerApiCancelQrCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelQrCode(
      requestParameters: WhitelabelControllerApiCancelQrCodeRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .cancelQrCode(requestParameters.deviceToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Link device - Kiểm tra thiết bị
     * @param {WhitelabelControllerApiCheckDevice1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkDevice1(
      requestParameters: WhitelabelControllerApiCheckDevice1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseCheckDeviceWLResponse> {
      return localVarFp
        .checkDevice1(requestParameters.request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo qr động - Custom QRCode
     * @param {WhitelabelControllerApiCustomDynamicQRCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customDynamicQRCode(
      requestParameters: WhitelabelControllerApiCustomDynamicQRCodeRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .customDynamicQRCode(
          requestParameters.deviceToken,
          requestParameters.customDynamicQrWLRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Device Detail
     * @param {WhitelabelControllerApiGetDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevice(
      requestParameters: WhitelabelControllerApiGetDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDeviceDetailWLResponse> {
      return localVarFp
        .getDevice(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Device Detail with grant
     * @param {WhitelabelControllerApiGetDeviceByGrantedTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeviceByGrantedToken(
      requestParameters: WhitelabelControllerApiGetDeviceByGrantedTokenRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDeviceWLResponse> {
      return localVarFp
        .getDeviceByGrantedToken(requestParameters.deviceToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách device
     * @param {WhitelabelControllerApiGetListDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListDevice(
      requestParameters: WhitelabelControllerApiGetListDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePageResponseDeviceWLResponse> {
      return localVarFp
        .getListDevice(requestParameters.request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thông báo biến động số dư
     * @param {WhitelabelControllerApiNotifyTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransaction(
      requestParameters: WhitelabelControllerApiNotifyTransactionRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseTransactionWLResponse> {
      return localVarFp
        .notifyTransaction(
          requestParameters.deviceToken,
          requestParameters.notifyTransactionWLRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Link device - Yêu cầu liên kết thiết bị
     * @param {WhitelabelControllerApiRequestLinkDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestLinkDevice(
      requestParameters: WhitelabelControllerApiRequestLinkDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseLinkDeviceWLResponse> {
      return localVarFp
        .requestLinkDevice(requestParameters.submitLinkDeviceWLRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo qr động
     * @param {WhitelabelControllerApiShowDynamicQRCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showDynamicQRCode(
      requestParameters: WhitelabelControllerApiShowDynamicQRCodeRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .showDynamicQRCode(
          requestParameters.deviceToken,
          requestParameters.showDynamicQrWLRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo qr tĩnh - Dựa vào thông tin để generate
     * @param {WhitelabelControllerApiShowStaticQRCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showStaticQRCode(
      requestParameters: WhitelabelControllerApiShowStaticQRCodeRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .showStaticQRCode(
          requestParameters.deviceToken,
          requestParameters.showStaticQrWLRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo qr tĩnh - Custom QRCode
     * @param {WhitelabelControllerApiShowStaticQRCodeCustomRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showStaticQRCodeCustom(
      requestParameters: WhitelabelControllerApiShowStaticQRCodeCustomRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .showStaticQRCodeCustom(
          requestParameters.deviceToken,
          requestParameters.customStaticQrWLRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Unlink device
     * @param {WhitelabelControllerApiUnlinkDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlinkDevice(
      requestParameters: WhitelabelControllerApiUnlinkDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .unlinkDevice(requestParameters.deviceToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Link device - Xác thực OTP
     * @param {WhitelabelControllerApiVerifyLinkDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyLinkDevice(
      requestParameters: WhitelabelControllerApiVerifyLinkDeviceRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseVerifyLinkDeviceWLResponse> {
      return localVarFp
        .verifyLinkDevice(requestParameters.verifyLinkDeviceWLRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cancelQrCode operation in WhitelabelControllerApi.
 * @export
 * @interface WhitelabelControllerApiCancelQrCodeRequest
 */
export interface WhitelabelControllerApiCancelQrCodeRequest {
  /**
   *
   * @type {string}
   * @memberof WhitelabelControllerApiCancelQrCode
   */
  readonly deviceToken: string;
}

/**
 * Request parameters for checkDevice1 operation in WhitelabelControllerApi.
 * @export
 * @interface WhitelabelControllerApiCheckDevice1Request
 */
export interface WhitelabelControllerApiCheckDevice1Request {
  /**
   *
   * @type {CheckDeviceWLRequest}
   * @memberof WhitelabelControllerApiCheckDevice1
   */
  readonly request: CheckDeviceWLRequest;
}

/**
 * Request parameters for customDynamicQRCode operation in WhitelabelControllerApi.
 * @export
 * @interface WhitelabelControllerApiCustomDynamicQRCodeRequest
 */
export interface WhitelabelControllerApiCustomDynamicQRCodeRequest {
  /**
   *
   * @type {string}
   * @memberof WhitelabelControllerApiCustomDynamicQRCode
   */
  readonly deviceToken: string;

  /**
   *
   * @type {CustomDynamicQrWLRequest}
   * @memberof WhitelabelControllerApiCustomDynamicQRCode
   */
  readonly customDynamicQrWLRequest: CustomDynamicQrWLRequest;
}

/**
 * Request parameters for getDevice operation in WhitelabelControllerApi.
 * @export
 * @interface WhitelabelControllerApiGetDeviceRequest
 */
export interface WhitelabelControllerApiGetDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof WhitelabelControllerApiGetDevice
   */
  readonly id: string;
}

/**
 * Request parameters for getDeviceByGrantedToken operation in WhitelabelControllerApi.
 * @export
 * @interface WhitelabelControllerApiGetDeviceByGrantedTokenRequest
 */
export interface WhitelabelControllerApiGetDeviceByGrantedTokenRequest {
  /**
   *
   * @type {string}
   * @memberof WhitelabelControllerApiGetDeviceByGrantedToken
   */
  readonly deviceToken: string;
}

/**
 * Request parameters for getListDevice operation in WhitelabelControllerApi.
 * @export
 * @interface WhitelabelControllerApiGetListDeviceRequest
 */
export interface WhitelabelControllerApiGetListDeviceRequest {
  /**
   *
   * @type {GetDeviceListWLRequest}
   * @memberof WhitelabelControllerApiGetListDevice
   */
  readonly request: GetDeviceListWLRequest;
}

/**
 * Request parameters for notifyTransaction operation in WhitelabelControllerApi.
 * @export
 * @interface WhitelabelControllerApiNotifyTransactionRequest
 */
export interface WhitelabelControllerApiNotifyTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof WhitelabelControllerApiNotifyTransaction
   */
  readonly deviceToken: string;

  /**
   *
   * @type {NotifyTransactionWLRequest}
   * @memberof WhitelabelControllerApiNotifyTransaction
   */
  readonly notifyTransactionWLRequest: NotifyTransactionWLRequest;
}

/**
 * Request parameters for requestLinkDevice operation in WhitelabelControllerApi.
 * @export
 * @interface WhitelabelControllerApiRequestLinkDeviceRequest
 */
export interface WhitelabelControllerApiRequestLinkDeviceRequest {
  /**
   *
   * @type {SubmitLinkDeviceWLRequest}
   * @memberof WhitelabelControllerApiRequestLinkDevice
   */
  readonly submitLinkDeviceWLRequest: SubmitLinkDeviceWLRequest;
}

/**
 * Request parameters for showDynamicQRCode operation in WhitelabelControllerApi.
 * @export
 * @interface WhitelabelControllerApiShowDynamicQRCodeRequest
 */
export interface WhitelabelControllerApiShowDynamicQRCodeRequest {
  /**
   *
   * @type {string}
   * @memberof WhitelabelControllerApiShowDynamicQRCode
   */
  readonly deviceToken: string;

  /**
   *
   * @type {ShowDynamicQrWLRequest}
   * @memberof WhitelabelControllerApiShowDynamicQRCode
   */
  readonly showDynamicQrWLRequest: ShowDynamicQrWLRequest;
}

/**
 * Request parameters for showStaticQRCode operation in WhitelabelControllerApi.
 * @export
 * @interface WhitelabelControllerApiShowStaticQRCodeRequest
 */
export interface WhitelabelControllerApiShowStaticQRCodeRequest {
  /**
   *
   * @type {string}
   * @memberof WhitelabelControllerApiShowStaticQRCode
   */
  readonly deviceToken: string;

  /**
   *
   * @type {ShowStaticQrWLRequest}
   * @memberof WhitelabelControllerApiShowStaticQRCode
   */
  readonly showStaticQrWLRequest: ShowStaticQrWLRequest;
}

/**
 * Request parameters for showStaticQRCodeCustom operation in WhitelabelControllerApi.
 * @export
 * @interface WhitelabelControllerApiShowStaticQRCodeCustomRequest
 */
export interface WhitelabelControllerApiShowStaticQRCodeCustomRequest {
  /**
   *
   * @type {string}
   * @memberof WhitelabelControllerApiShowStaticQRCodeCustom
   */
  readonly deviceToken: string;

  /**
   *
   * @type {CustomStaticQrWLRequest}
   * @memberof WhitelabelControllerApiShowStaticQRCodeCustom
   */
  readonly customStaticQrWLRequest: CustomStaticQrWLRequest;
}

/**
 * Request parameters for unlinkDevice operation in WhitelabelControllerApi.
 * @export
 * @interface WhitelabelControllerApiUnlinkDeviceRequest
 */
export interface WhitelabelControllerApiUnlinkDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof WhitelabelControllerApiUnlinkDevice
   */
  readonly deviceToken: string;
}

/**
 * Request parameters for verifyLinkDevice operation in WhitelabelControllerApi.
 * @export
 * @interface WhitelabelControllerApiVerifyLinkDeviceRequest
 */
export interface WhitelabelControllerApiVerifyLinkDeviceRequest {
  /**
   *
   * @type {VerifyLinkDeviceWLRequest}
   * @memberof WhitelabelControllerApiVerifyLinkDevice
   */
  readonly verifyLinkDeviceWLRequest: VerifyLinkDeviceWLRequest;
}

/**
 * WhitelabelControllerApi - object-oriented interface
 * @export
 * @class WhitelabelControllerApi
 * @extends {BaseAPI}
 */
export class WhitelabelControllerApi extends BaseAPI {
  /**
   *
   * @summary Huỷ show QRCode
   * @param {WhitelabelControllerApiCancelQrCodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WhitelabelControllerApi
   */
  public cancelQrCode(
    requestParameters: WhitelabelControllerApiCancelQrCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return WhitelabelControllerApiFp(this.configuration)
      .cancelQrCode(requestParameters.deviceToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Link device - Kiểm tra thiết bị
   * @param {WhitelabelControllerApiCheckDevice1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WhitelabelControllerApi
   */
  public checkDevice1(
    requestParameters: WhitelabelControllerApiCheckDevice1Request,
    options?: AxiosRequestConfig,
  ) {
    return WhitelabelControllerApiFp(this.configuration)
      .checkDevice1(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo qr động - Custom QRCode
   * @param {WhitelabelControllerApiCustomDynamicQRCodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WhitelabelControllerApi
   */
  public customDynamicQRCode(
    requestParameters: WhitelabelControllerApiCustomDynamicQRCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return WhitelabelControllerApiFp(this.configuration)
      .customDynamicQRCode(
        requestParameters.deviceToken,
        requestParameters.customDynamicQrWLRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Device Detail
   * @param {WhitelabelControllerApiGetDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WhitelabelControllerApi
   */
  public getDevice(
    requestParameters: WhitelabelControllerApiGetDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return WhitelabelControllerApiFp(this.configuration)
      .getDevice(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Device Detail with grant
   * @param {WhitelabelControllerApiGetDeviceByGrantedTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WhitelabelControllerApi
   */
  public getDeviceByGrantedToken(
    requestParameters: WhitelabelControllerApiGetDeviceByGrantedTokenRequest,
    options?: AxiosRequestConfig,
  ) {
    return WhitelabelControllerApiFp(this.configuration)
      .getDeviceByGrantedToken(requestParameters.deviceToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách device
   * @param {WhitelabelControllerApiGetListDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WhitelabelControllerApi
   */
  public getListDevice(
    requestParameters: WhitelabelControllerApiGetListDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return WhitelabelControllerApiFp(this.configuration)
      .getListDevice(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thông báo biến động số dư
   * @param {WhitelabelControllerApiNotifyTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WhitelabelControllerApi
   */
  public notifyTransaction(
    requestParameters: WhitelabelControllerApiNotifyTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return WhitelabelControllerApiFp(this.configuration)
      .notifyTransaction(
        requestParameters.deviceToken,
        requestParameters.notifyTransactionWLRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Link device - Yêu cầu liên kết thiết bị
   * @param {WhitelabelControllerApiRequestLinkDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WhitelabelControllerApi
   */
  public requestLinkDevice(
    requestParameters: WhitelabelControllerApiRequestLinkDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return WhitelabelControllerApiFp(this.configuration)
      .requestLinkDevice(requestParameters.submitLinkDeviceWLRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo qr động
   * @param {WhitelabelControllerApiShowDynamicQRCodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WhitelabelControllerApi
   */
  public showDynamicQRCode(
    requestParameters: WhitelabelControllerApiShowDynamicQRCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return WhitelabelControllerApiFp(this.configuration)
      .showDynamicQRCode(
        requestParameters.deviceToken,
        requestParameters.showDynamicQrWLRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo qr tĩnh - Dựa vào thông tin để generate
   * @param {WhitelabelControllerApiShowStaticQRCodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WhitelabelControllerApi
   */
  public showStaticQRCode(
    requestParameters: WhitelabelControllerApiShowStaticQRCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return WhitelabelControllerApiFp(this.configuration)
      .showStaticQRCode(
        requestParameters.deviceToken,
        requestParameters.showStaticQrWLRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo qr tĩnh - Custom QRCode
   * @param {WhitelabelControllerApiShowStaticQRCodeCustomRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WhitelabelControllerApi
   */
  public showStaticQRCodeCustom(
    requestParameters: WhitelabelControllerApiShowStaticQRCodeCustomRequest,
    options?: AxiosRequestConfig,
  ) {
    return WhitelabelControllerApiFp(this.configuration)
      .showStaticQRCodeCustom(
        requestParameters.deviceToken,
        requestParameters.customStaticQrWLRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Unlink device
   * @param {WhitelabelControllerApiUnlinkDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WhitelabelControllerApi
   */
  public unlinkDevice(
    requestParameters: WhitelabelControllerApiUnlinkDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return WhitelabelControllerApiFp(this.configuration)
      .unlinkDevice(requestParameters.deviceToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Link device - Xác thực OTP
   * @param {WhitelabelControllerApiVerifyLinkDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WhitelabelControllerApi
   */
  public verifyLinkDevice(
    requestParameters: WhitelabelControllerApiVerifyLinkDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return WhitelabelControllerApiFp(this.configuration)
      .verifyLinkDevice(requestParameters.verifyLinkDeviceWLRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
