// import { imgLogoSidebar } from "@/services/assets";
import "../style.less";
import "./style.less";
import { ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";
// import { IconFinance } from "@/components/icons/IconFinance";
import { NavlinkPath } from "@/services/router-config";
import { IconLink } from "@/components/icons/IconLink";
import { IconTablet } from "@/components/icons/IconTablet";
import { IconHistory } from "@/components/icons/IconHistory";
import { UserInfoModel } from "@/api/notibox/models/UserModel";
import { Dropdown } from "antd";
import { IconArrowUp } from "@/components/icons/IconArrowUp";
import { rdModal, useRdApp } from "@radts/reactjs";
import Popup from "@/components/popup/Popup";
import { AppSession } from "@/services/app-session";
import { LoginStatus } from "@/models/LoginStatus";
import { IconLogout } from "@/components/icons/IconLogout";
import { imgLogoAuth } from "@/services/assets";
import { IconFinance } from "@/components/icons/IconFinance";

const NavigationTab = ({
  icon,
  title,
  isSelectted,
  to,
}: {
  icon: ReactNode;
  title: string;
  isSelectted: boolean;
  to: string;
}) => {
  return (
    <NavLink
      className={"sidebar-navigation__tab-navlink"}
      style={{
        backgroundColor: isSelectted ? "#D1E9FF" : undefined,
      }}
      to={to}
    >
      {icon}
      <span
        style={{
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "24px",
          color: isSelectted ? "#175CD3" : "#737373",
        }}
      >
        {title}
      </span>
    </NavLink>
  );
};

export const SideBar = ({ userInfo }: { userInfo?: UserInfoModel }) => {
  const { modules } = useRdApp();
  const location = useLocation();
  function onTapLogout() {
    rdModal(
      <Popup
        type={"danger"}
        title={"Thông báo"}
        content={"Bạn có chắc chắn muốn đăng xuất?"}
        labelCancel={"Huỷ"}
        labelConfirm={"Xác nhận"}
        onCancel={() => {
          rdModal(null);
        }}
        onConfirm={async () => {
          rdModal(null);
          modules
            ?.get<AppSession>("AppSession")
            .loginStatus.next(LoginStatus.Expired);
        }}
      />,
    );
  }
  return (
    <aside id="side-bar-app" className="app-aside">
      <img
        alt={"logo-app"}
        src={imgLogoAuth}
        style={{ height: "16px", width: "122px", padding: "0px 16px" }}
      />
      <nav className="sidebar-navigation">
        <div
          className="column"
          style={{
            width: "100%",
            gap: "4px",
            height: "auto",
          }}
        >
          <NavigationTab
            icon={
              <IconFinance
                color={
                  location.pathname.startsWith(NavlinkPath.statistic)
                    ? "#175CD3"
                    : "rgba(163, 163, 163, 1)"
                }
              />
            }
            isSelectted={location.pathname.startsWith(NavlinkPath.statistic)}
            title="Báo cáo thống kê"
            to={NavlinkPath.statistic}
          />
          <NavigationTab
            icon={
              <IconHistory
                color={
                  location.pathname.startsWith(NavlinkPath.transactionHistory)
                    ? "#175CD3"
                    : "rgba(163, 163, 163, 1)"
                }
              />
            }
            isSelectted={location.pathname.startsWith(
              NavlinkPath.transactionHistory,
            )}
            title="Lịch sử giao dịch"
            to={NavlinkPath.transactionHistory}
          />
          <NavigationTab
            icon={
              <IconTablet
                color={
                  location.pathname.startsWith(NavlinkPath.devices)
                    ? "#175CD3"
                    : "rgba(163, 163, 163, 1)"
                }
              />
            }
            isSelectted={location.pathname.startsWith(NavlinkPath.devices)}
            title="Quản lý thiết bị"
            to={NavlinkPath.devices}
          />
          <NavigationTab
            icon={
              <IconLink
                color={
                  location.pathname.startsWith(NavlinkPath.integration)
                    ? "#175CD3"
                    : "rgba(163, 163, 163, 1)"
                }
              />
            }
            isSelectted={location.pathname.startsWith(NavlinkPath.integration)}
            title="Quản lý kết nối"
            to={NavlinkPath.integration}
          />
        </div>
      </nav>
      <Dropdown
        trigger={["click"]}
        menu={{
          items: [
            {
              key: "logout",
              label: (
                <span
                  onClick={() => {
                    onTapLogout();
                  }}
                  className={"text-sm--reg row"}
                  style={{
                    color: "#141414",
                    width: "100%",
                    padding: "8px",
                    gap: "8px",
                  }}
                >
                  <IconLogout />
                  <span>Đăng xuất</span>
                </span>
              ),
              style: { padding: "0px", width: "100%" },
            },
          ],
        }}
        placement="topRight"
      >
        <div className="sidebar-navigation__tab-profile">
          <div
            className={"row"}
            style={{
              width: "48px",
              height: "48px",
              borderRadius: "100%",
              backgroundColor: "#FFC96B",
            }}
          >
            {userInfo?.avatar && (
              <img
                src={userInfo.avatar}
                alt={"avatar"}
                style={{
                  aspectRatio: 1,
                  height: "100%",
                }}
              />
            )}
          </div>
          <div
            className={"column text-overflow"}
            style={{
              width: "100%",
              flex: 1,
              alignItems: "flex-start",
            }}
          >
            <span
              className={"text-sm--semibold"}
              style={{ color: "#424242", textAlign: "start" }}
            >
              {userInfo?.fullName}
            </span>
            <span
              className={"text-sm--reg"}
              style={{ color: "#525252", textAlign: "start" }}
            >
              {userInfo?.phone}
            </span>
          </div>
          <div className={"row"} style={{ width: "24px", height: "24px" }}>
            <IconArrowUp />
          </div>
        </div>
      </Dropdown>
    </aside>
  );
};
