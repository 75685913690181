import { GetBankResponse } from "../generated-sources";
import { BankModel } from "../models/BankModel";

export function mapBankModel(dto?: GetBankResponse): BankModel {
  return {
    id: dto?.id ?? "",
    bin: dto?.bin ?? "",
    code: dto?.code ?? "",
    logo: dto?.logo ?? "",
    name: dto?.name ?? "",
    senderBin: dto?.senderBin ?? "",
    shortName: dto?.shortName ?? "",
    smallLogo: dto?.smallLogo ?? "",
    type: dto?.type ?? "BANK",
  };
}
