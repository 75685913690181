import { Environment } from "./environment";

const icPath = `${Environment.host}/icons${
  Environment.themePrefix.toString().length > 0
    ? `/${Environment.themePrefix.toString()}`
    : ""
}`;
const imgPath = `${Environment.host}/images${
  Environment.themePrefix.toString().length > 0
    ? `/${Environment.themePrefix.toString()}`
    : ""
}`;

// icons
export const icMenu = `${icPath}/ic-menu.svg`;
export const icDeviceX40 = `${icPath}/ic-device-x40.svg`;

// images
export const imgLogoSidebar = `${imgPath}/img-logo-app.png`;
export const imgLogoAuth = `${imgPath}/img-logo-app-auth.png`;
