import { NotiboxHttpClient, NotiboxRepository } from "@/api/notibox";
import { Configuration } from "@/api/notibox/generated-sources";
import { RdModule } from "@radts/reactjs";
import axios from "axios";
import { onError, onRequest, onResponse } from "./intercepter";

export class AppRepository extends RdModule {
  public readonly key: symbol;

  public readonly repo: NotiboxRepository;
  constructor(basePath?: string) {
    super();
    this.key = Symbol("AppRepository");
    axios.interceptors.request.clear();
    axios.interceptors.response.clear();
    axios.defaults.timeout = 60 * 1000;
    axios.interceptors.request.use(onRequest, onError);
    axios.interceptors.response.use(onResponse, onError);
    this.repo = new NotiboxRepository(
      new NotiboxHttpClient(
        new Configuration({
          basePath: basePath,
        }),
      ),
    );
  }

  public getName(): string {
    return this.key.description ?? "AppRepository";
  }
}
