import { BankModel } from "./BankModel";

export interface TransactionModel {
  id: string;
  qrContent: string;
  status: TransactionStatusEnum;
  type: TransactionTypeEnum;
  amount: number;
  createTime: string;
  description: string;
  bin: string;
  virtualAccount: string;
  actualAccount: string;
  content: string;
  millisecond: number;
  txnNumber: string;
  traceId: string;
}

export enum TransactionStatusEnum {
  NONE = "NONE",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  CANCELED = "CANCELED",
  FAIL = "FAIL",
  TIMEOUT = "TIMEOUT",
}

export enum TransactionTypeEnum {
  KLB = "KLB",
  PAYBOX = "PAYBOX",
  POS = "POS",
  USEE = "USEE",
  PARTNER_SERVICE = "PARTNER_SERVICE",
}

export interface GetTransactionRequestModel {
  id?: string;
  keyword?: string;
  status?: TransactionStatusEnum;
  type?: TransactionTypeEnum;
  fromDate?: string;
  toDate?: string;
  minAmount?: number;
  maxAmount?: number;
  deviceId?: string;
  accountNumber?: string;
  size?: number;
  page?: number;
  sortBy?: string;
  sortDir?: string;
}

export interface DetailTransactionModel {
  type: TransactionTypeEnum;
  fromAccount: string;
  fromBankInfo: BankModel;
  toAccount: string;
  toBankInfo: BankModel;
  amount: number;
  time: string;
  status: TransactionStatusEnum;
  txnNumber: string;
  content: string;
}

export interface GetRevenueOfTopDeviceModel {
  nameDevice: string;
  totalRevenue: number;
  revenueList: Array<DateAndRevenueModel>;
}

export interface DateAndRevenueModel {
  dateTime: string;
  revenue?: number;
}

export enum RevenueFilterTimeEnum {
  DAY = "DAY",
  MONTH = "MONTH",
}

export interface TotalRevenueModel {
  totalTransactions: number;
  percentageOfTransactions: number;
  totalSuccessfulTransactions: number;
  percentageOfSuccessfulTransactions: number;
  totalRevenue: number;
  percentageOfRevenue: number;
}

export interface TransactionByDeviceModel {
  transactionId: string;
  deviceName: string;
  fromAccName: string;
  amount: number;
  completeTime: string;
  bankName: string;
}
