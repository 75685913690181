import CardChart from "./components/CardChart";
import CardFilter from "./components/CardFilter";
import CardHistory from "./components/CardHistory";

const StatisticPage = () => {
  return (
    <div className="animation-faded--in w-full  flex flex-col gap-4 pt-4 px-6">
      <div className="display-xs--semibold">Báo cáo thống kê</div>
      <CardFilter />
      <CardChart />
      <CardHistory />
    </div>
  );
};

export default StatisticPage;
