import { PAGE_SIZE, SuccessCodeApi } from "@/constants";
import { NotiboxHttpClient } from "..";
import { mapStaticQr } from "../adapters/static-qr-asapter";
import { ErrorApi } from "../models/ErrorModel";
import { QrType } from "../models/QrType";
import { StaticQrModel } from "../models/StaticQr";
import { SettingRepository } from "../repositories/SettingRepository";

export class SettingRepositoryImp implements SettingRepository {
  private client: NotiboxHttpClient;
  constructor(c: NotiboxHttpClient) {
    this.client = c;
  }

  public async listQrByDeviceId({
    deviceid,
    size = PAGE_SIZE,
    page,
    sortBy = "createTime",
    sortDir = "desc",
  }: {
    deviceid?: string;
    size?: number;
    page?: number;
    sortBy?: string;
    sortDir?: string;
  }): Promise<StaticQrModel[]> {
    const resp = await this.client.setting.getAllQr({
      paginationAndSortingRequest: {
        size: size,
        page: page,
        sortBy: sortBy,
        sortDir: sortDir,
      },
      request: {
        deviceId: deviceid,
        // description,
        // id,
        // name,
        // pageable,
        // specification,
        // type,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.qrList?.content?.map((d) => mapStaticQr(d)) ?? [];
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async createQr({
    bankId,
    deviceId,
    accountNumber,
    accountOwner,
    content,
    isDefault,
    phone,
    qrCode,
    qrContent,
    shopName,
  }: {
    bankId: string;
    deviceId: string;
    accountNumber: string;
    accountOwner?: string;
    content?: string;
    isDefault?: boolean;
    phone?: string;
    qrCode?: string;
    qrContent?: string;
    shopName: string;
  }): Promise<StaticQrModel> {
    const resp = await this.client.setting.addQr({
      addStaticQrRequest: {
        bankId: bankId,
        deviceId: deviceId,
        accountNumber: accountNumber,
        accountOwner: accountOwner,
        content: content,
        isDefault: isDefault,
        phone: phone,
        qrCode: qrCode,
        qrContent: qrContent,
        shopName: shopName,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return mapStaticQr(resp.data.data);
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async updateQr({
    id,
    bankId,
    accountNumber,
    accountOwner,
    content,
    isDefault,
    phone,
    qrCode,
    qrContent,
    shopName,
  }: {
    id: string;
    bankId?: string;
    accountNumber?: string;
    accountOwner?: string;
    content?: string;
    isDefault?: boolean;
    phone?: string;
    qrCode?: string;
    qrContent?: string;
    shopName?: string;
  }): Promise<StaticQrModel> {
    const resp = await this.client.setting.updateQrById({
      updateStaticQrRequest: {
        id: id,
        bankId: bankId,
        accountNumber: accountNumber,
        accountOwner: accountOwner,
        content: content,
        isDefault: isDefault,
        phone: phone,
        qrCode: qrCode,
        qrContent: qrContent,
        shopName: shopName,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return mapStaticQr(resp.data.data);
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async deleteQr({ id }: { id: string }): Promise<boolean> {
    const resp = await this.client.setting.deleteQrById({
      deleteStaticQrByIdRequest: {
        id: id,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.success ?? false;
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async previewQr({
    staticQrId,
    bankAccountNo,
    bankAccountOwner,
    bankId,
    shopName,
    type,
    content,
    deviceId,
    qrCode,
    qrContent,
  }: {
    staticQrId?: string;
    bankAccountNo?: string;
    bankAccountOwner?: string;
    bankId?: string;
    shopName?: string;
    type?: QrType;
    content?: string;
    deviceId?: string;
    qrCode?: string;
    qrContent?: string;
  }): Promise<string> {
    const resp = await this.client.setting.preview({
      previewQrRequest: {
        staticQrId: staticQrId,
        bankAccountNo: bankAccountNo,
        bankAccountOwner: bankAccountOwner,
        bankId: bankId,
        shopName: shopName,
        type: type,
        content: content,
        deviceId: deviceId,
        qrCode: qrCode,
        qrContent: qrContent,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.preview ?? "";
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }
}
