/**
 * NalinkPath is enum hold all router in app
 */

export enum NavlinkPath {
  // root
  root = "/",

  /**
   * home
   */
  home = "/home",

  /**
   * auth
   */
  auth = "/auth",
  socialAuthRedirect = `${auth}/oauth2/redirect`,

  /**
   * login
   */
  login = `${auth}/login`,

  /**
   * register
   */
  register = `${auth}/register`,

  /**
   * forgotPassword
   */
  forgotPassword = `${auth}/forgot-password`,

  /**
   * register
   */
  createPassword = `${auth}/create-password`,

  /**
   * statistic
   */
  statistic = `${home}/statistic`,

  /**
   * transaction history
   */
  transactionHistory = `${home}/transaction-history`,
  transactionHistoryDetail = `${transactionHistory}/:transactionId`,

  /**
   * devices
   */
  devices = `${home}/devices`,
  addDeviceCode = `${devices}/add-device-code`,
  addDeviceSerial = `${devices}/add-device-serial`,
  detailDevice = `${devices}/:deviceId`,
  createQr = `${devices}/:deviceId/create-qr`,
  detailQr = `${devices}/:deviceId/:qrId`,

  /**
   * integration
   */
  integration = `${home}/integration`,
}
