import { RevenueFilterTimeEnum } from "@/api/notibox/models/TransactionModel";
import { CardCustom } from "@/components/card-custom/CardCustom";
import { AppRepository } from "@/services/app-repositories";
import { useRdApp, useRdBloc, useRdQuery } from "@radts/reactjs";
import { Button, Col, Row, Space } from "antd";
import React from "react";

const CardFilter = () => {
  const { modules } = useRdApp();

  const [state, setState] = useRdBloc<{ filter: RevenueFilterTimeEnum }>({
    filter: RevenueFilterTimeEnum.MONTH,
  });
  const { data: totlalRevenue } = useRdQuery({
    queryKey: ["get-DashboardPageTransactions", state.filter],
    queryFn: async () => {
      const ret = await modules
        ?.get<AppRepository>("AppRepository")
        .repo.transaction.getTotalRevenue({
          filterTime: state.filter,
        });
      return ret;
    },
  });
  return (
    <CardCustom
      style={{
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        className={"row text-md--semibold"}
        style={{
          width: "100%",
          padding: "20px",
          textAlign: "start",
          borderBottom: "1px solid #D6D6D6",
        }}
      >
        Tổng quan chung
      </div>
      <div className="w-full grid gap-4 p-6">
        <div className="flex justify-center gap-3 items-center w-full">
          <div className="flex">
            <Space.Compact>
              <Button
                onClick={() => {
                  state.filter = RevenueFilterTimeEnum.DAY;
                  setState();
                }}
                type="primary"
                ghost={state.filter !== RevenueFilterTimeEnum.DAY}
              >
                Theo ngày
              </Button>
              <Button
                onClick={() => {
                  state.filter = RevenueFilterTimeEnum.MONTH;
                  setState();
                }}
                type="primary"
                ghost={state.filter !== RevenueFilterTimeEnum.MONTH}
              >
                Theo tháng
              </Button>
            </Space.Compact>
          </div>
        </div>
        <Row gutter={[16, 16]} className="">
          <Col xs={24} sm={12} lg={24 / 3}>
            <div className="border border-[#E3E4E6] rounded-xl bg-[#F9FAFA] flex flex-col items-center justify-center p-4 gap-3">
              <div className="text-base font-semibold text-[#5E5D64]">
                GIAO DỊCH PHÁT SINH
              </div>
              <div className="text-[32px] font-semibold leading-10">
                {totlalRevenue?.totalTransactions !== undefined
                  ? totlalRevenue?.totalTransactions
                  : "---"}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={12} lg={24 / 3}>
            <div className="border border-[#E3E4E6] rounded-xl bg-[#F9FAFA] flex flex-col items-center justify-center p-4 gap-3">
              <div className="text-base font-semibold text-[#5E5D64]">
                GIAO DỊCH THÀNH CÔNG
              </div>
              <div className="text-[32px] font-semibold leading-10">
                {totlalRevenue?.totalSuccessfulTransactions !== undefined
                  ? totlalRevenue?.totalSuccessfulTransactions
                  : "---"}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={12} lg={24 / 3}>
            <div className="border border-[#E3E4E6] rounded-xl bg-[#F9FAFA] flex flex-col items-center justify-center p-4 gap-3">
              <div className="text-base font-semibold text-[#5E5D64]">
                DOANH THU
              </div>
              <div className="text-[32px] font-semibold leading-10 ">
                {totlalRevenue?.totalRevenue !== undefined ? (
                  <span className="text-[#1890ff]">
                    {totlalRevenue?.totalRevenue.toLocaleString("vi-VN", {
                      style: "currency",
                      currency: "VND",
                    })}
                  </span>
                ) : (
                  "---"
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </CardCustom>
  );
};

export default CardFilter;
