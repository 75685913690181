import {
  AuthControllerApi,
  BankControllerApi,
  Configuration,
  DeviceControllerApi,
  SettingControllerApi,
  TransactionControllerApi,
  UserControllerApi,
} from "./generated-sources";
import { AuthRepositoryImp } from "./implements/AuthRepositoryImp";
import { UserRepositoryImp } from "./implements/UserRepositoryImp";
import { AuthRepository } from "./repositories/AuthRepository";
import { UserRepository } from "./repositories/UserRepository";
import { DeviceRepository } from "@/api/notibox/repositories/DeviceRepository";
import { DeviceRepositoryImp } from "@/api/notibox/implements/DeviceRepositoryImp";
import { SettingRepository } from "./repositories/SettingRepository";
import { BankRepository } from "./repositories/BankRepository";
import { SettingRepositoryImp } from "./implements/SettingRepositoryImp";
import { BankRepositoryImp } from "./implements/BankRepositoryImp";
import { TransactionRepository } from "./repositories/TransactionRepository";
import { TransactionRepositoryImp } from "./implements/TransactionRepositoryImp";

export class NotiboxHttpClient {
  public readonly auth: AuthControllerApi;
  public readonly user: UserControllerApi;
  public readonly device: DeviceControllerApi;
  public readonly setting: SettingControllerApi;
  public readonly bank: BankControllerApi;
  public readonly transaction: TransactionControllerApi;

  constructor(cf: Configuration) {
    this.auth = new AuthControllerApi(cf, cf.basePath);
    this.user = new UserControllerApi(cf, cf.basePath);
    this.device = new DeviceControllerApi(cf, cf.basePath);
    this.setting = new SettingControllerApi(cf, cf.basePath);
    this.bank = new BankControllerApi(cf, cf.basePath);
    this.transaction = new TransactionControllerApi(cf, cf.basePath);
  }
}

export class NotiboxRepository {
  public notiboxClient: NotiboxHttpClient;
  public readonly auth: AuthRepository;
  public readonly user: UserRepository;
  public readonly device: DeviceRepository;
  public readonly setting: SettingRepository;
  public readonly bank: BankRepository;
  public readonly transaction: TransactionRepository;

  constructor(client: NotiboxHttpClient) {
    this.notiboxClient = client;
    this.auth = new AuthRepositoryImp(this.notiboxClient);
    this.user = new UserRepositoryImp(this.notiboxClient);
    this.device = new DeviceRepositoryImp(this.notiboxClient);
    this.setting = new SettingRepositoryImp(this.notiboxClient);
    this.bank = new BankRepositoryImp(this.notiboxClient);
    this.transaction = new TransactionRepositoryImp(this.notiboxClient);
  }
}
