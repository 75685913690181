import { useCallback, useEffect, useRef } from "react";
import { Header } from "./components/Header";
import { SideBar } from "./components/SideBar";
import "./style.less";
import { Outlet } from "react-router-dom";
import { useRdApp, useRdQuery } from "@radts/reactjs";
import { AppRepository } from "@/services/app-repositories";

const HomePage = () => {
  const { modules } = useRdApp();
  const refTogle = useRef<boolean>(false);

  const toggleMenu = useCallback(() => {
    const sidebar = document.getElementById("side-bar-app");
    if (sidebar) {
      sidebar.style.display = "flex";
      requestAnimationFrame(() => {
        if (refTogle.current) {
          refTogle.current = false;
          sidebar.classList.remove("animation-faded--in");
          sidebar.classList.add("animation-faded--out");
          sidebar.style.translate = "0%";
        } else {
          refTogle.current = true;
          sidebar.classList.remove("animation-faded--out");
          sidebar.classList.add("animation-faded--in");
          sidebar.style.display = "flex";
          sidebar.style.translate = "100%";
        }
      });
    }
  }, []);

  const { isLoading, data } = useRdQuery({
    queryKey: ["get-user-info-in-home-page"],
    queryFn: async () => {
      const ret = await modules
        ?.get<AppRepository>("AppRepository")
        .repo.user.userInfo();
      return ret ?? null;
    },
  });

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 1113) {
        refTogle.current = true;
        toggleMenu();
      }
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <Header
        onToggleMenu={() => {
          toggleMenu();
        }}
      />
      <section className="app-body">
        <SideBar userInfo={data ?? undefined} />
        <main
          onClick={() => {
            if (window.innerWidth <= 1113) {
              refTogle.current = true;
              toggleMenu();
            }
          }}
          className="app-content"
        >
          <Outlet />
        </main>
      </section>
      <footer></footer>
    </>
  );
};

export default HomePage;
