import { AccessTokenResponse } from "../generated-sources";
import { AccessTokenModel } from "../models/TokenModel";

export function mapAccessTokenModel(
  dto?: AccessTokenResponse,
): AccessTokenModel {
  return {
    idToken: dto?.idToken,
    expiresIn: dto?.expiresIn,
    notBeforePolicy: dto?.notBeforePolicy,
    otherClaims: dto?.otherClaims,
    refreshExpiresIn: dto?.refreshExpiresIn,
    refreshToken: dto?.refreshToken,
    scope: dto?.scope,
    sessionState: dto?.sessionState,
    token: dto?.token,
    tokenType: dto?.tokenType,
  };
}
