import React from "react";
import { IconPopupDangerX22 } from "./components/IconPopupDangerX22";
import { IconPopupInfoX22 } from "./components/IconPopupInfoX22";
import { IconPopupSuccessX22 } from "./components/IconPopupSuccessX22";
import { IconPopupWarningX22 } from "./components/IconPopupWarningX22";
import "./popup.css";

type PopupType = "success" | "warning" | "info" | "danger";

const Popup = ({
  title = "title",
  content = "content",
  type = "info",
  styleWrap,
  onCancel,
  onConfirm,
  labelCancel = "No",
  labelConfirm = "Done",
}: {
  title?: React.ReactNode;
  content?: React.ReactNode;
  type?: PopupType;
  onCancel?: () => void;
  onConfirm?: () => void;
  styleWrap?: React.CSSProperties;
  labelCancel?: string;
  labelConfirm?: string;
}) => {
  const bgButtonConfirm: string =
    type === "info" || type === "success"
      ? "button-item--primary"
      : "button-item--dangerous";

  function BuildIconPopup() {
    let iconPopup;
    switch (type) {
      case "success":
        iconPopup = <IconPopupSuccessX22 />;
        break;
      case "warning":
        iconPopup = <IconPopupWarningX22 />;
        break;
      case "info":
        iconPopup = <IconPopupInfoX22 />;
        break;
      case "danger":
        iconPopup = <IconPopupDangerX22 />;
        break;
      default:
        iconPopup = <IconPopupInfoX22 />;
    }
    return iconPopup;
  }

  return (
    <div className="popup-wrap" style={styleWrap}>
      <div className="popup__body">
        <div className="popup__body__icon">{BuildIconPopup()}</div>
        <div className="popup__body__content-wrap">
          <div className="title">{title}</div>
          <div className="content">{content}</div>
        </div>
      </div>
      <div className="popup__button-list">
        {onCancel && (
          <div
            className="button-item button-item--secondary"
            onClick={onCancel}
          >
            {labelCancel}
          </div>
        )}
        {onConfirm && (
          <div className={`button-item ${bgButtonConfirm}`} onClick={onConfirm}>
            {labelConfirm}
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;
