export const formatMoneyValue = (
  value: number,
  fixedBillion: number = 0,
  fixedMillion: number = 0,
) => {
  if (value >= 1000000000) {
    return (value / 1000000000).toFixed(fixedBillion) + " Tỉ";
  } else if (value >= 1000000) {
    return (value / 1000000).toFixed(fixedMillion) + " Triệu";
  } else if (value >= 1000) {
    return (value / 1000).toFixed(0) + "K";
  } else {
    return value.toString();
  }
};
