import { CSSProperties } from "react";
import "./card-custom.css";

export const CardCustom: React.FunctionComponent<{
  children?: React.ReactNode;
  classNameOverlay?: string;
  style?: CSSProperties;
  onClick?: () => void;
}> = ({
  children,
  classNameOverlay,
  style,
  onClick,
}: {
  children?: React.ReactNode;
  classNameOverlay?: string;
  style?: CSSProperties;
  onClick?: () => void;
}) => {
  return (
    <div
      className={
        classNameOverlay ? `card-custom ${classNameOverlay}` : `card-custom`
      }
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
