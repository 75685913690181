import { TransactionByDeviceModel } from "@/api/notibox/models/TransactionModel";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";

export const columnsTransactionHistory = () => {
  const columns: ColumnsType<TransactionByDeviceModel> = [
    {
      title: "Thời gian giao dịch",
      dataIndex: "completeTime",
      key: "completeTime",
      render: (value) => (
        <>{value ? dayjs.unix(Number(value)).format("DD/MM/YYYY") : "---"}</>
      ),
    },
    {
      title: "Tên thiết bị",
      dataIndex: "deviceName",
      key: "deviceName",
      render: (value) => <>{value ? value : "---"}</>,
    },
    {
      title: "Tài khoản nhận",
      dataIndex: "fromAccName",
      key: "fromAccName",
      render: (value, record) => (
        <>
          {value ? (
            <div>
              <div className="font-semibold">{value}</div>
              <div>{record.bankName}</div>
            </div>
          ) : (
            "---"
          )}
        </>
      ),
    },
    {
      title: "Số tiền",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (value) => (
        <>
          {value && value >= 0
            ? value.toLocaleString("vi-VN", {
                style: "currency",
                currency: "VND",
              })
            : ""}
        </>
      ),
    },
  ];

  return columns;
};
