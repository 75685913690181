import { PageableModel } from "../models/PageAble";

export function mapPageabbleGeneric<TReq, TRes>(
  callBackMapDataItem: (d?: TReq) => TRes,
  dto?: PageableModel<TReq>,
): PageableModel<TRes> {
  return {
    pageNumber: dto?.pageNumber ?? 0,
    pageSize: dto?.pageSize ?? 0,
    totalPage: dto?.totalPage ?? 0,
    totalSize: dto?.totalSize ?? 0,
    items: dto?.items.map(callBackMapDataItem) ?? [],
  };
}
