export const IconLogout = ({ color = "#A3A3A3" }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 19.0125H11.9875V20.9875H5C4.45334 20.9875 3.98568 20.793 3.59634 20.4037C3.207 20.0143 3.0125 19.5467 3.0125 19V5C3.0125 4.45334 3.207 3.98568 3.59634 3.59634C3.98568 3.207 4.45334 3.0125 5 3.0125H11.9875V4.9875H5H4.9875V5V19V19.0125H5ZM17.175 12.9875H9.0125V11.0125H17.175H17.2052L17.1838 10.9912L14.6424 8.44977L16.0002 7.01792L20.9823 12L16.0002 16.9821L14.6424 15.5502L17.1838 13.0088L17.2052 12.9875H17.175Z"
        fill={color}
        stroke={color}
        strokeWidth="0.025"
      />
    </svg>
  );
};
