export const IconFinance = ({
  color = "rgba(115, 115, 115, 1)",
}: {
  color?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V3H5V19H21V21H5ZM6 18V9H10V18H6ZM11 18V4H15V18H11ZM16 18V13H20V18H16Z"
        fill={color}
      />
    </svg>
  );
};
