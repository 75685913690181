import ReactDOM from "react-dom/client";
import "./index.less";
import "@/styles";
import { RouterProvider } from "react-router-dom";
import { appRouterConfig } from "./router";
import { RdModule, RdModulesManager, rdI18n } from "@radts/reactjs";
import { Environment } from "./services/environment";
import { AppSession } from "./services/app-session";
import { AppRepository } from "./services/app-repositories";
import { AppStorage } from "./services/app-storage";

const rdManager = new RdModulesManager<RdModule>();
rdManager
  .use(new AppStorage())
  .use(new AppSession())
  .use(new AppRepository(Environment.hostApi.toString()));

const _root = ReactDOM.createRoot(document.getElementById("root")!, {
  identifierPrefix: Environment.componentPrefix.toString(),
  onRecoverableError(error) {
    console.error(error);
  },
});

export const router = appRouterConfig(rdManager);

rdI18n
  .init({
    supportedLngs: ["en", "vi"],
    debug: false,
    fallbackLng: "vi",
    lng: "vi",
    ns: ["translation"],
    defaultNS: "translation",
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${Environment.host}/languages/{{lng}}/{{ns}}.json`,
    },
  })
  .finally(() => {
    _root.render(<RouterProvider router={router} />);
  });
