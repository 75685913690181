export const OverlayComponent = ({
  children,
  onTapBackGround,
  onTapChildren,
  classChildren,
  classBackground,
  id,
}: {
  children: React.ReactNode;
  onTapBackGround: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onTapChildren: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  classChildren: string | undefined;
  classBackground: string | undefined;
  id?: string;
}) => {
  return (
    <div id={id} className={classBackground} onClick={onTapBackGround}>
      <div
        className={`${classChildren ?? ""} scroll-y-modal`}
        onClick={onTapChildren}
        style={{
          maxHeight: "100vh",
          height: "fit-content",
          width: "fit-content",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {children}
      </div>
    </div>
  );
};
