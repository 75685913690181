export const IconPopupDangerX22 = ({
  color = "#FF4D4F",
}: {
  color?: string;
}) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_215_168664)">
        <path
          d="M15.2574 7.14076C15.2574 7.03273 15.169 6.94434 15.0609 6.94434L13.4404 6.9517L10.9998 9.8613L8.56161 6.95416L6.93862 6.94679C6.83058 6.94679 6.74219 7.03273 6.74219 7.14322C6.74219 7.18987 6.75937 7.23407 6.78884 7.2709L9.98326 11.0767L6.78884 14.88C6.75917 14.916 6.74271 14.9611 6.74219 15.0077C6.74219 15.1158 6.83058 15.2042 6.93862 15.2042L8.56161 15.1968L10.9998 12.2872L13.4379 15.1943L15.0585 15.2017C15.1665 15.2017 15.2549 15.1158 15.2549 15.0053C15.2549 14.9586 15.2377 14.9144 15.2083 14.8776L12.0187 11.0742L15.2132 7.26844C15.2426 7.23407 15.2574 7.18742 15.2574 7.14076Z"
          fill={color}
        />
        <path
          d="M11 0.0244141C4.92545 0.0244141 0 4.94986 0 11.0244C0 17.099 4.92545 22.0244 11 22.0244C17.0746 22.0244 22 17.099 22 11.0244C22 4.94986 17.0746 0.0244141 11 0.0244141ZM11 20.1583C5.9567 20.1583 1.86607 16.0677 1.86607 11.0244C1.86607 5.98111 5.9567 1.89049 11 1.89049C16.0433 1.89049 20.1339 5.98111 20.1339 11.0244C20.1339 16.0677 16.0433 20.1583 11 20.1583Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_215_168664">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
