export enum Environment {
  host = import.meta.env.NOTIBOX_HOST ?? "http://10.20.1.213:5000",
  hostApi = import.meta.env.NOTIBOX_HOST_API ??
    "https://api.tiktzuki.com/fnb-cms",
  basePath = import.meta.env.NOTIBOX_BASE_PATH ?? "/",
  componentPrefix = import.meta.env.NOTIBOX_COMPONENT_PREFIX ?? "NOTIBOX",
  hostAuthGoogle = import.meta.env.NOTIBOX_AUTH_PATH_API_LOGIN_GOOGLE ??
    "/oauth2/authorize/google",
  themePrefix = import.meta.env.NOTIBOX_THEME_TEMPLATE_PREFIX ?? "",
}
