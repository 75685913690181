import { DeviceRepository } from "@/api/notibox/repositories/DeviceRepository";
import { NotiboxHttpClient } from "@/api/notibox";
import { ErrorApi } from "@/api/notibox/models/ErrorModel";
import {
  mapDeviceModel,
  mapLinkedDeviceModel,
} from "@/api/notibox/adapters/device-adapter";
import {
  DeviceModel,
  LinkedDeviceModel,
} from "@/api/notibox/models/DeviceModel";
import { SuccessCodeApi } from "@/constants";

export class DeviceRepositoryImp implements DeviceRepository {
  private client: NotiboxHttpClient;
  constructor(c: NotiboxHttpClient) {
    this.client = c;
  }

  // public async linkByParingKey({
  //   pairingKey,
  // }: {
  //   pairingKey: string;
  // }): Promise<{
  //   deviceId: string;
  //   displayName: string;
  // }> {
  //   const resp = await this.client.device.linkWithUser({
  //     linkWithUserV2Request: {
  //       pairingKey: pairingKey,
  //     },
  //   });
  //   if (resp?.data.code === SuccessCodeApi) {
  //     return {
  //       deviceId: resp.data.data?.deviceId ?? "",
  //       displayName: resp.data.data?.displayName ?? "",
  //     };
  //   } else {
  //     throw new ErrorApi({
  //       code: resp.data.code,
  //       message: resp.data.message,
  //       cause: resp.request,
  //     });
  //   }
  // }

  public async linkByHardwareId({
    hardwareId,
  }: {
    hardwareId: string;
  }): Promise<{
    deviceId: string;
    displayName: string;
  }> {
    const resp = await this.client.device.linkByHardwareId({
      linkWithUserByHwIDRequest: {
        hardwareId: hardwareId,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return {
        deviceId: resp.data.data?.deviceId ?? "",
        displayName: resp.data.data?.displayName ?? "",
      };
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async linkBankAccount({ deviceId }: { deviceId: string }): Promise<{
    deviceId: string;
    bankHubLink: string;
  }> {
    const resp = await this.client.device.linkBankAccount1({
      linkBankAccountRequest: {
        deviceId: deviceId,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return {
        deviceId: resp.data.data?.deviceId ?? "",
        bankHubLink: resp.data.data?.bankHubLink ?? "",
      };
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async listDevices({
    keyword,
    offset,
    paged,
    pageNumber,
    pageSize,
    online,
  }: {
    keyword?: string;
    offset?: number;
    paged?: boolean;
    pageNumber?: number;
    pageSize?: number;
    online?: boolean;
  }): Promise<DeviceModel[]> {
    const resp = await this.client.device.getAllDevices({
      request: {
        keyword,
        pageable: {
          offset,
          paged,
          pageNumber,
          pageSize,
        },
        online,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.items?.map((d) => mapDeviceModel(d)) ?? [];
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async unLinkDevice({
    deviceId,
  }: {
    deviceId: string;
  }): Promise<boolean> {
    const resp = await this.client.device.unlinkWithUser({
      unlinkWithUserV2Request: { deviceId: deviceId },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.success ?? false;
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async detailDevice({
    deviceId,
  }: {
    deviceId: string;
  }): Promise<DeviceModel> {
    const resp = await this.client.device.getDevice3({
      id: deviceId,
    });
    if (resp?.data.code === SuccessCodeApi) {
      return mapDeviceModel(resp.data.data);
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async updateDevice({
    id,
    displayName,
  }: {
    id: string;
    displayName?: string;
  }): Promise<boolean> {
    const resp = await this.client.device.updateDevice({
      updateDeviceRequest: {
        id: id,
        displayName: displayName,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.success ?? false;
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async checkDeviceInfoV3({
    hardwareId,
  }: {
    hardwareId: string;
  }): Promise<LinkedDeviceModel> {
    const resp = await this.client.device.checkLinkDevice({
      hardwareId: hardwareId,
    });
    if (resp?.data.code === SuccessCodeApi) {
      return mapLinkedDeviceModel(resp.data.data);
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async requestLinkDeviceV3({
    hardwareId,
  }: {
    hardwareId: string;
  }): Promise<LinkedDeviceModel> {
    const resp = await this.client.device.requestLinkDevice2({
      submitLinkDeviceRequest: {
        hardwareId: hardwareId,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return mapLinkedDeviceModel(resp.data.data);
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async verifyLinkDeviceV3({
    hardwareId,
    otp,
    sessionId,
  }: {
    hardwareId: string;
    otp: string;
    sessionId: string;
  }): Promise<boolean> {
    const resp = await this.client.device.verifyLinkDevice2({
      verifyLinkDeviceRequest: {
        hardwareId: hardwareId,
        otp: otp,
        sessionId: sessionId,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.success ?? false;
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async unlinkBankAccount({
    deviceId,
  }: {
    deviceId: string;
  }): Promise<boolean> {
    const resp = await this.client.device.unlinkBankAccount({
      unLinkBankAccountRequest: { deviceId: deviceId },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.id ? true : false;
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }
}
