import {
  DeviceModel,
  LinkedDeviceModel,
} from "@/api/notibox/models/DeviceModel";
import {
  DeviceResponse,
  LinkDeviceResponse,
} from "@/api/notibox/generated-sources";

export function mapDeviceModel(dto?: DeviceResponse): DeviceModel {
  return {
    id: dto?.id ?? "",
    displayName: dto?.displayName ?? "",
    model: dto?.model ?? "",
    hardwareId: dto?.hardwareId ?? "",
    hwVersion: dto?.hwVersion ?? "",
    // location: dto?.location ?? "",
    online: dto?.online ?? false,
    // manToken: dto?.manToken ?? "",
    version: dto?.version ?? "",
    bankAccountNo: dto?.bankAccountNo ?? "",
    bankAccountName: dto?.bankAccountName ?? "",
    bankImageUrl: dto?.bankImageUrl ?? "",
    bankName: dto?.bankName ?? "",
  };
}

export function mapLinkedDeviceModel(
  dto?: LinkDeviceResponse,
): LinkedDeviceModel {
  return {
    displayName: dto?.displayName ?? "",
    model: dto?.model ?? "",
    hardwareId: dto?.hardwareId ?? "",
    online: dto?.online ?? false,
    deviceId: dto?.deviceId ?? "",
    sessionId: dto?.sessionId ?? "",
  };
}
