import { CardCustom } from "@/components/card-custom/CardCustom";
import { AppRepository } from "@/services/app-repositories";
import { rdLoading, useRdApp, useRdBloc, useRdQuery } from "@radts/reactjs";
import { Table } from "antd";
import React from "react";
import { columnsTransactionHistory } from "../data/columnsTransactionHistory";
type TransactionHistoryListState = {
  page: number;
  pageCurrent: number;
  pageSize?: number;
  deviceId?: string;
};
const CardHistory = () => {
  const { modules } = useRdApp();
  const [state, setState] = useRdBloc<TransactionHistoryListState>({
    page: 0,
    pageCurrent: 1,
  });
  const {
    isLoading: isLoadingTransHistoryList,
    data: transHistoryList,
    refetch: refetchTransHistoryList,
  } = useRdQuery({
    queryKey: ["TransactionHistoryList-1âsdasd"],
    queryFn: async () => {
      try {
        rdLoading(true);
        const ret = await modules
          ?.get<AppRepository>("AppRepository")
          .repo.transaction.getTransactionByDevice({
            deviceId: state.deviceId,
            // deviceId: "db8f771b-b4fb-49cf-875f-8b99ec9e064e",
            page: state.page,
            size: 10,
          });
        return ret;
      } catch (error: any) {
        console.error(error);
      } finally {
        rdLoading(false);
      }
    },
  });
  if (isLoadingTransHistoryList) return null;
  return (
    <CardCustom
      style={{
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        className={"row text-md--semibold"}
        style={{
          width: "100%",
          padding: "20px",
          textAlign: "start",
          borderBottom: "1px solid #D6D6D6",
        }}
      >
        Giao dịch gần đây
      </div>
      <Table
        className="customed-table custom-scroll-bar w-full h-full overflow-auto"
        rowKey="id"
        loading={isLoadingTransHistoryList}
        dataSource={transHistoryList?.items ?? []}
        columns={columnsTransactionHistory()}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: false,
          current: state.pageCurrent,
          pageSize: state.pageSize,
          total: 0,
          onChange: async (page, pageSize) => {
            state.pageSize = pageSize;
            state.page = page - 1;
            state.pageCurrent = page;
            setState();
            await refetchTransHistoryList();
          },
        }}
      />
    </CardCustom>
  );
};

export default CardHistory;
