export const IconTablet = ({
  color = "rgba(115, 115, 115, 1)",
}: {
  color?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11.5V10H16V11.5H8ZM9 14.5V13H15V14.5H9ZM3 20C2.45 20 1.97917 19.8042 1.5875 19.4125C1.19583 19.0208 1 18.55 1 18V6C1 5.45 1.19583 4.97917 1.5875 4.5875C1.97917 4.19583 2.45 4 3 4H21C21.55 4 22.0208 4.19583 22.4125 4.5875C22.8042 4.97917 23 5.45 23 6V18C23 18.55 22.8042 19.0208 22.4125 19.4125C22.0208 19.8042 21.55 20 21 20H3ZM6 18H18V6H6V18Z"
        fill={color}
      />
    </svg>
  );
};
