import { AppRepository } from "@/services/app-repositories";
import { formatMoneyValue } from "@/utils/convertUnitMoney";
import { useRdApp, useRdBloc, useRdQuery } from "@radts/reactjs";
import { Card, DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import * as echarts from "echarts";
import { useEffect, useId } from "react";

const CardChart = () => {
  const idChart = useId();
  const { modules } = useRdApp();
  const [state, setState] = useRdBloc<{
    deviceId?: string;
    fromDate?: string;
    toDate?: string;
  }>({
    fromDate: dayjs().add(-30, "d").format("DD/MM/YYYY"),
    toDate: dayjs().format("DD/MM/YYYY"),
  });
  const { data: dataChart } = useRdQuery({
    queryKey: [
      "dataChart-StatisticPage",
      state.deviceId,
      state.fromDate,
      state.toDate,
    ],
    queryFn: async () => {
      const ret = await modules
        ?.get<AppRepository>("AppRepository")
        .repo.transaction.getRevenueOfTopDevice({
          // id: state.deviceId !== "ALL" ? state.deviceId : undefined,
          fromDate: dayjs(state.fromDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
          toDate: dayjs(state.toDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        });

      return ret;
    },
  });

  // const { data: listDevices } = useRdQuery({
  //   queryKey: ["get-list-devices-in-StatisticPage"],
  //   queryFn: async () => {
  //     const ret = await modules
  //       ?.get<AppRepository>("AppRepository")
  //       .repo.device.listDevices({});
  //     return ret ?? [];
  //   },
  // });

  useEffect(() => {
    if (idChart) {
      const chartDom = document.getElementById(idChart);
      if (chartDom) {
        const myChart = echarts.init(chartDom);

        const option: echarts.EChartsOption = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: dataChart?.map((item) => item.nameDevice),
          },
          grid: {
            right: 16,
            bottom: 50,
            width: "auto",
          },

          xAxis: {
            type: "category",
            boundaryGap: false,
            data: dataChart?.map((item) =>
              item.revenueList.map((revenue) =>
                dayjs(revenue.dateTime, "DD-MM-YYYY").format("DD/MM"),
              ),
            )[0],
            name: "Thời gian (ngày)",
            nameLocation: "middle",
            nameGap: 40,
            nameTextStyle: {
              fontFamily: "Inter",
              color: "rgba(24, 23, 25, 1)",
              fontSize: 12,
              fontWeight: 500,
              lineHeight: 18,
              verticalAlign: "middle",
            },
          },
          yAxis: {
            nameGap: 130,
            name: "Doanh thu (đ)",
            nameLocation: "middle",
            nameTextStyle: {
              fontFamily: "Inter",
              color: "rgba(24, 23, 25, 1)",
              fontSize: 12,
              fontWeight: 500,
              lineHeight: 18,
              verticalAlign: "middle",
            },
            axisLabel: {
              fontFamily: "Inter",
              color: "rgba(24, 23, 25, 1)",
              fontSize: 12,
              fontWeight: 400,
              lineHeight: 18,
              formatter: (value) => {
                return formatMoneyValue(value);
              },
            },
            type: "value",
            boundaryGap: [0, "100%"],
          },
          series: dataChart?.map((item) => ({
            name: item.nameDevice,
            type: "line",
            stack: "Total",
            smooth: true,
            data: item.revenueList.map((revenue) => revenue.revenue),
          })),
        };

        myChart.setOption(option);
      }
    }
  }, [idChart, dataChart]);

  return (
    <>
      <Card
        className="static-chart-dashboard border border-[#E3E4E6] rounded-xl"
        title={<span className="text-base font-bold">Top 10 Doanh thu</span>}
      >
        <div className="grid gap-[10px] w-full">
          <div className="w-full flex justify-between">
            <div className="flex gap-4">
              {/* <Select
                style={{ width: 148 }}
                defaultValue="Tất cả thiết bị"
                popupMatchSelectWidth={200}
                size="large"
                onChange={(e) => {
                  console.log(e);
                  state.deviceId = e;
                  setState();
                }}
              >
                <Select.Option value="ALL">Tất cả thiết bị</Select.Option>
                {listDevices &&
                  listDevices.map((d, i) => {
                    return (
                      <Select.Option value={d.id} key={d.id + i.toString()}>
                        {d.displayName}
                      </Select.Option>
                    );
                  })}
              </Select> */}
              <div className="w-full flex flex-col gap-2">
                <span className="text-base font-bold">Chọn ngày</span>
                <DatePicker.RangePicker
                  size="large"
                  presets={[
                    {
                      label: "Ngày hôm nay",
                      value: [dayjs(), dayjs().endOf("day")],
                    },
                    {
                      label: "7 ngày gần đây",
                      value: [dayjs().add(-7, "d"), dayjs()],
                    },
                    {
                      label: "14 ngày gần đây",
                      value: [dayjs().add(-14, "d"), dayjs()],
                    },
                    {
                      label: "30 ngày gần đây",
                      value: [dayjs().add(-30, "d"), dayjs()],
                    },
                    {
                      label: "90 ngày gần đây",
                      value: [dayjs().add(-90, "d"), dayjs()],
                    },
                  ]}
                  format="DD/MM/YYYY"
                  placeholder={["Từ ngày", "Đến ngày"]}
                  disabledDate={(current) =>
                    current && current > dayjs().endOf("day")
                  }
                  allowClear={false}
                  defaultValue={[
                    dayjs(state.fromDate, "DD/MM/YYYY"),
                    dayjs(state.toDate, "DD/MM/YYYY"),
                  ]}
                  onChange={(
                    dates: null | (Dayjs | null)[],
                    dateStrings: string[],
                  ) => {
                    if (dates) {
                      state.fromDate = dateStrings[0];
                      state.toDate = dateStrings[1];
                      setState();
                    } else {
                      console.log("Clear");
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <div className="text-md--reg">Tổng số tiền giao dịch</div>
              <div className="display-xs--semibold">
                {dataChart
                  ?.reduce((acc, item) => acc + item.totalRevenue, 0)
                  .toLocaleString("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  })}
              </div>
            </div>
          </div>

          <div id={idChart} className="h-96 w-full" />
        </div>
      </Card>
    </>
  );
};
export default CardChart;
