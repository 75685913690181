import { SuccessCodeApi } from "@/constants";
import { NotiboxHttpClient } from "..";
import { mapBankModel } from "../adapters/bank-adapter";
import { BankModel } from "../models/BankModel";
import { ErrorApi } from "../models/ErrorModel";
import { BankRepository } from "../repositories/BankRepository";

export class BankRepositoryImp implements BankRepository {
  private client: NotiboxHttpClient;
  constructor(c: NotiboxHttpClient) {
    this.client = c;
  }

  public async listBankByType({
    type,
    keyworks,
  }: {
    type: "BANK" | "E_WALLET";
    keyworks?: string;
  }): Promise<BankModel[]> {
    const resp = await this.client.bank.getAllBanks({
      request: {
        type: type,
        code:
          keyworks && keyworks.toUpperCase() === keyworks
            ? keyworks.replaceAll(" ", "")
            : undefined,
        name:
          keyworks && keyworks.toUpperCase() === keyworks
            ? undefined
            : keyworks?.replaceAll(" ", ""),
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.banks?.content?.map((d) => mapBankModel(d)) ?? [];
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async checkBankAccount({
    accountNo,
    bin,
  }: {
    accountNo: string;
    bin: string;
  }): Promise<string> {
    const resp = await this.client.bank.checkBankAccount({
      checkBankAccountRequest: {
        accountNo: accountNo,
        bin: bin,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.accountName ?? "";
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }
}
