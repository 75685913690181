import { useRdApp, useRdQuery } from "@radts/reactjs";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { NavlinkPath } from "./services/router-config";
import { AppStorage } from "./services/app-storage";
import { AppRepository } from "./services/app-repositories";
import { AppSession } from "./services/app-session";
import { LoginStatus } from "./models/LoginStatus";

function App() {
  const { modules } = useRdApp();
  const navigate = useNavigate();

  const { isLoading, isError } = useRdQuery({
    queryKey: ["get-access-token-validation"],
    queryFn: async () => {
      // fake here
      // if (location.pathname.startsWith(NavlinkPath.home)) {
      //   return 0;
      // }
      // fake here

      if (
        location.pathname.replace("/", "") !== "" &&
        !location.pathname.startsWith(NavlinkPath.home) &&
        !location.pathname.startsWith(NavlinkPath.auth)
      ) {
        return 0;
      }

      try {
        if (
          (modules?.get<AppStorage>("AppStorage")?.refreshToken ?? "").length >
          0
        ) {
          const ret = await modules
            ?.get<AppRepository>("AppRepository")
            .repo.auth.refreshToken({
              refreshToken:
                modules?.get<AppStorage>("AppStorage")?.refreshToken ?? "",
            });
          modules!.get<AppStorage>("AppStorage")!.accessToken =
            ret?.token ?? "";
          modules!.get<AppStorage>("AppStorage")!.refreshToken =
            ret?.refreshToken ?? "";
          modules
            ?.get<AppSession>("AppSession")
            ?.loginStatus.next(LoginStatus.Success);
          return LoginStatus.Success;
        }
        modules
          ?.get<AppSession>("AppSession")
          ?.loginStatus.next(LoginStatus.Expired);
        return LoginStatus.Expired;
      } catch (error) {
        console.error(error);
        modules
          ?.get<AppSession>("AppSession")
          ?.loginStatus.next(LoginStatus.Expired);
        return LoginStatus.Expired;
      }
    },
  });

  useEffect(() => {
    if (!isLoading) {
      let currentLoginStatus = LoginStatus.Idle;

      // stream login status
      modules
        ?.get<AppSession>("AppSession")
        .loginStatus.subscribe(async (v) => {
          if (currentLoginStatus === v) {
            // return;
          } else {
            currentLoginStatus = v;
          }

          if (v === LoginStatus.Success) {
            modules!.get<AppStorage>("AppStorage")!.isLogin = true;
            if (
              location.pathname.startsWith(NavlinkPath.auth) ||
              location.pathname.replace("/", "") === ""
            ) {
              navigate(`${NavlinkPath.home}${window.location.search}`, {
                replace: true,
              });
            } else {
              navigate(`${location.pathname}${window.location.search}`, {
                replace: true,
              });
            }

            // const userInfo =
            //   await appBloc.repository.qrfyRepo.user.getUserInfo();
          } else if (v === LoginStatus.Expired) {
            modules?.get<AppStorage>("AppStorage")?.clearAll();
            if (modules?.get<AppStorage>("AppStorage")?.refreshToken !== "") {
              modules?.get<AppRepository>("AppRepository").repo.auth.logout({
                refreshToken:
                  modules?.get<AppStorage>("AppStorage").refreshToken,
              });
            }
            if (!location.pathname.startsWith(NavlinkPath.auth)) {
              navigate(`${NavlinkPath.login}${window.location.search}`, {
                replace: true,
              });
            }
          }
        });
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      document.getElementById("root-app-animation-loading")?.remove();
    }
  }, [isLoading]);

  if (isLoading || isError) {
    return <></>;
  }
  return <Outlet />;
}

export default App;
