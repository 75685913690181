import {
  DateAndRevenueResponse,
  DetailTransactionResponse,
  GetListTransactionByDeviceResponse,
  GetRevenueOfTopDeviceResponse,
  GetTotalRevenueResponse,
  TransactionResponse,
} from "../generated-sources";
import {
  DateAndRevenueModel,
  DetailTransactionModel,
  GetRevenueOfTopDeviceModel,
  TotalRevenueModel,
  TransactionByDeviceModel,
  TransactionModel,
  TransactionStatusEnum,
  TransactionTypeEnum,
} from "../models/TransactionModel";
import { mapBankModel } from "./bank-adapter";

export function mapTransactionModel(
  dto?: TransactionResponse,
): TransactionModel {
  return {
    id: dto?.id ?? "",
    qrContent: dto?.qrContent ?? "",
    status:
      TransactionStatusEnum[
        dto?.status ?? ("NONE" as keyof typeof TransactionStatusEnum)
      ],
    type: TransactionTypeEnum[
      dto?.type ?? ("POS" as keyof typeof TransactionTypeEnum)
    ],
    amount: dto?.amount ?? -1,
    createTime: dto?.createTime ?? "",
    description: dto?.description ?? "",
    bin: dto?.bin ?? "",
    virtualAccount: dto?.virtualAccount ?? "",
    actualAccount: dto?.actualAccount ?? "",
    content: dto?.content ?? "",
    millisecond: dto?.millisecond ?? -1,
    txnNumber: dto?.txnNumber ?? "",
    traceId: dto?.traceId ?? "",
  };
}

export function mapDetailTransactionModel(
  dto?: DetailTransactionResponse,
): DetailTransactionModel {
  return {
    amount: dto?.amount ?? -1,
    content: dto?.content ?? "",
    fromAccount: dto?.fromAccount ?? "",
    fromBankInfo: mapBankModel(dto?.fromBankInfo),
    status:
      TransactionStatusEnum[
        dto?.status ?? ("NONE" as keyof typeof TransactionStatusEnum)
      ],
    toAccount: dto?.toAccount ?? "",
    toBankInfo: mapBankModel(dto?.toBankInfo),
    time: dto?.time ?? "",
    txnNumber: dto?.txnNumber ?? "",
    type: TransactionTypeEnum[
      dto?.type ?? ("POS" as keyof typeof TransactionTypeEnum)
    ],
  };
}

export function mapGetRevenueOfTopDeviceModel(
  dto?: GetRevenueOfTopDeviceResponse,
): GetRevenueOfTopDeviceModel {
  return {
    nameDevice: dto?.nameDevice ?? "",
    totalRevenue: dto?.totalRevenue ?? 0,
    revenueList: dto?.revenueList?.map(mapDateAndRevenueResponse) ?? [],
  };
}

export function mapDateAndRevenueResponse(
  dto?: DateAndRevenueResponse,
): DateAndRevenueModel {
  return {
    dateTime: dto?.dateTime ?? "",
    revenue: dto?.revenue ?? undefined,
  };
}

export function mapTotalRevenueModel(
  dto?: GetTotalRevenueResponse,
): TotalRevenueModel {
  return {
    totalTransactions: dto?.totalTransactions ?? -1,
    percentageOfTransactions: dto?.percentageOfTransactions ?? -1,
    totalSuccessfulTransactions: dto?.totalSuccessfulTransactions ?? -1,
    percentageOfSuccessfulTransactions:
      dto?.percentageOfSuccessfulTransactions ?? -1,
    totalRevenue: dto?.totalRevenue ?? -1,
    percentageOfRevenue: dto?.percentageOfRevenue ?? -1,
  };
}

export function mapTransactionByDeviceModel(
  dto?: GetListTransactionByDeviceResponse,
): TransactionByDeviceModel {
  return {
    transactionId: dto?.transactionId ?? "",
    amount: dto?.amount ?? -1,
    bankName: dto?.bankName ?? "",
    completeTime: dto?.completeTime ?? "",
    deviceName: dto?.deviceName ?? "",
    fromAccName: dto?.fromAccName ?? "",
  };
}
