import { StaticQrResponse } from "../generated-sources";
import { StaticQrModel } from "../models/StaticQr";

export function mapStaticQr(dto?: StaticQrResponse): StaticQrModel {
  return {
    id: dto?.id ?? "",
    accountNumber: dto?.accountNumber ?? "",
    accountOwner: dto?.accountOwner ?? "",
    bankId: dto?.bankId ?? "",
    bankLogo: dto?.bankLogo ?? "",
    content: dto?.content ?? "",
    description: dto?.description ?? "",
    deviceId: dto?.deviceId ?? "",
    isDefault: dto?.isDefault ?? false,
    logo: dto?.logo ?? "",
    name: dto?.name ?? "",
    phone: dto?.phone ?? "",
    qrCode: dto?.qrCode ?? "",
    shopName: dto?.shopName ?? "",
    type: dto?.type ?? "",
  };
}
