import { FC } from "react";
import { icMenu } from "../../../services/assets";
import "../style.less";

interface HeaderProps {
  onToggleMenu: () => void;
}

export const Header: FC<HeaderProps> = ({ onToggleMenu }) => {
  return (
    <header className="app-header">
      <div
        onClick={() => {
          onToggleMenu();
        }}
        style={{
          width: "32px",
          height: "32px",
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
        }}
      >
        <img src={icMenu} />
      </div>
    </header>
  );
};
