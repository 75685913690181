import { AccessTokenModel } from "../models/TokenModel";
import { AuthRepository } from "../repositories/AuthRepository";
import { ErrorApi } from "../models/ErrorModel";
import { mapAccessTokenModel } from "../adapters/access-token-adapter";
import { NotiboxHttpClient } from "..";
import { SuccessCodeApi } from "@/constants";

export class AuthRepositoryImp implements AuthRepository {
  private client: NotiboxHttpClient;
  constructor(c: NotiboxHttpClient) {
    this.client = c;
  }

  public async login({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<AccessTokenModel> {
    const resp = await this.client.auth.userLogin({
      simpleLoginRequest: {
        username: username,
        password: password,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return mapAccessTokenModel(resp.data.data);
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async logout({
    refreshToken,
  }: {
    refreshToken: string;
  }): Promise<boolean> {
    const resp = await this.client.auth.logout({
      logoutRequest: {
        refreshToken: refreshToken,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return resp.data.data?.logout ?? false;
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async refreshToken({
    refreshToken,
  }: {
    refreshToken: string;
  }): Promise<AccessTokenModel> {
    const resp = await this.client.auth.refreshToken({
      refreshTokenRequest: {
        refreshToken: refreshToken,
      },
    });
    if (resp?.data.code === SuccessCodeApi) {
      return mapAccessTokenModel(resp.data.data);
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }
}
