import { LoginStatus } from "@/models/LoginStatus";
import { RdModulesManager } from "@radts/reactjs";
import axios, { AxiosResponse } from "axios";
import { InternalAxiosRequestConfig } from "axios";
import { AppSession } from "./app-session";
import { AppStorage } from "./app-storage";
import { AppRepository } from "./app-repositories";
import { Environment } from "./environment";
import { ErrorApi } from "@/api/notibox/models/ErrorModel";

export function onResponse(res: AxiosResponse) {
  return Promise.resolve(res);
}

export function onRequest(req: InternalAxiosRequestConfig) {
  if (req.headers !== undefined) {
    req.headers["Accept-Language"] = "vi";
    const storage = new RdModulesManager().get<AppStorage>("AppStorage");
    if ((req.url ?? "").startsWith(Environment.hostApi.toString())) {
      if (storage.accessToken !== "") {
        req.headers["Authorization"] = `Bearer ${storage.accessToken}`;
      }
    }
    // orther api host
    // ---------------
  }
  return Promise.resolve(req);
}
export async function onError(e: any) {
  console.error(">>>ERROR");
  console.error(e);
  const { config } = e;
  const originalReq = { ...config };

  const session = new RdModulesManager().get<AppSession>("AppSession");
  const storage = new RdModulesManager().get<AppStorage>("AppStorage");
  const api = new RdModulesManager().get<AppRepository>("AppRepository");

  switch (e?.response?.status?.toString()) {
    case "401":
      try {
        if (storage.refreshToken.length > 0 && storage.countRefreshToken < 10) {
          storage.countRefreshToken = storage.countRefreshToken + 1;
          const retRefresh = await api.repo.auth.refreshToken({
            refreshToken: storage.refreshToken,
          });
          if (retRefresh.refreshToken && retRefresh.token) {
            storage.accessToken = retRefresh.token;
            storage.refreshToken = retRefresh.refreshToken;
            storage.language = "vi";
            if (originalReq) {
              originalReq.headers.Authorization = `Bearer ${retRefresh.token}`;
              setTimeout(() => {
                storage.countRefreshToken = 0;
              }, 20000);
              return Promise.resolve(axios(originalReq));
            }
          }
        }
        throw new ErrorApi({
          code: e.response.data.code,
          message: e.response.data.message,
          cause: e,
        });
      } catch (error) {
        session?.loginStatus.next(LoginStatus.Expired);
        return Promise.reject(
          new ErrorApi({
            code: e.response.data.code,
            message: e.response.data.message,
            cause: e,
          }),
        );
      }
  }

  return Promise.reject(
    new ErrorApi({
      code: e.response.data.code,
      message: e.response.data.message,
      cause: e,
    }),
  );
}
