import { PAGE_SIZE, SuccessCodeApi } from "@/constants";
import { NotiboxHttpClient } from "..";
import { mapPageabbleGeneric } from "../adapters/page-able-adapter";
import {
  mapDetailTransactionModel,
  mapGetRevenueOfTopDeviceModel,
  mapTotalRevenueModel,
  mapTransactionByDeviceModel,
  mapTransactionModel,
} from "../adapters/transaction-adapter";
import {
  GetListTransactionByDeviceResponse,
  TransactionResponse,
} from "../generated-sources";
import { ErrorApi } from "../models/ErrorModel";
import { PageableModel } from "../models/PageAble";
import {
  DetailTransactionModel,
  GetRevenueOfTopDeviceModel,
  GetTransactionRequestModel,
  RevenueFilterTimeEnum,
  TotalRevenueModel,
  TransactionByDeviceModel,
  TransactionModel,
} from "../models/TransactionModel";
import { TransactionRepository } from "../repositories/TransactionRepository";

export class TransactionRepositoryImp implements TransactionRepository {
  private client: NotiboxHttpClient;
  constructor(c: NotiboxHttpClient) {
    this.client = c;
  }
  public async getTransactionList({
    id,
    keyword,
    status,
    type,
    fromDate,
    toDate,
    minAmount,
    maxAmount,
    deviceId,
    accountNumber,
    size = PAGE_SIZE,
    page,
    sortBy = "completeTime",
    sortDir = "desc",
  }: GetTransactionRequestModel): Promise<PageableModel<TransactionModel>> {
    const resp = await this.client.transaction.getTransaction({
      id: id,
      keyword: keyword,
      status: status,
      type: type,
      fromDate: fromDate,
      toDate: toDate,
      minAmount: minAmount,
      maxAmount: maxAmount,
      deviceId: deviceId,
      accountNumber: accountNumber,
      size: size,
      page: page,
      sortBy: sortBy,
      sortDir: sortDir,
    });

    if (resp?.data.code === SuccessCodeApi) {
      return mapPageabbleGeneric<TransactionResponse, TransactionModel>(
        mapTransactionModel,
        resp.data.data as PageableModel<TransactionResponse>,
      );
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async getDetailTransaction({
    id,
  }: {
    id: string;
  }): Promise<DetailTransactionModel> {
    const resp = await this.client.transaction.getDetail({ id: id });

    if (resp?.data.code === SuccessCodeApi) {
      return mapDetailTransactionModel(resp.data.data);
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }
  public async getRevenueOfTopDevice({
    id,
    fromDate,
    toDate,
  }: {
    id?: string;
    fromDate: string;
    toDate: string;
  }): Promise<GetRevenueOfTopDeviceModel[]> {
    const resp = await this.client.transaction.getRevenueOfTopDevice({
      deviceId: id,
      fromDate: fromDate,
      toDate: toDate,
    });

    if (resp?.data.code === SuccessCodeApi) {
      return (
        resp.data.data?.contents?.map((d) =>
          mapGetRevenueOfTopDeviceModel(d),
        ) ?? []
      );
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }
  public async getTotalRevenue({
    filterTime,
  }: {
    filterTime: RevenueFilterTimeEnum;
  }): Promise<TotalRevenueModel> {
    const resp = await this.client.transaction.getTotalRevenue({ filterTime });

    if (resp?.data.code === SuccessCodeApi) {
      return mapTotalRevenueModel(resp.data.data);
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }

  public async getTransactionByDevice({
    deviceId,
    page,
    size,
    sort,
  }: {
    deviceId?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
  }): Promise<PageableModel<TransactionByDeviceModel>> {
    const resp = await this.client.transaction.getTransactionByDevice({
      deviceId: deviceId,
      page: page,
      size: size,
      sort: sort,
    });

    if (resp?.data.code === SuccessCodeApi) {
      return mapPageabbleGeneric<
        GetListTransactionByDeviceResponse,
        TransactionByDeviceModel
      >(
        mapTransactionByDeviceModel,
        resp.data.data as PageableModel<GetListTransactionByDeviceResponse>,
      );
    } else {
      throw new ErrorApi({
        code: resp.data.code,
        message: resp.data.message,
        cause: resp.request,
      });
    }
  }
}
