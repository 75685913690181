import { LoginStatus } from "@/models/LoginStatus";
import { RdModule } from "@radts/reactjs";
import { BehaviorSubject } from "rxjs";

export class AppSession extends RdModule {
  public key: symbol;
  constructor() {
    super();
    this.key = Symbol("AppSession");
    this.loginStatus = new BehaviorSubject<LoginStatus>(LoginStatus.Idle);
  }
  getName(): string {
    return this.key.description ?? "AppSession";
  }

  static getClassName(): string {
    return "AppSession";
  }

  public loginStatus: BehaviorSubject<LoginStatus>;

  public closeAll() {
    this.loginStatus.complete();
  }
}
