import { RdAppExtends, RdModule, RdModulesManager } from "@radts/reactjs";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { lazy, Suspense } from "react";
import { Environment } from "@/services/environment";
import { NavlinkPath } from "./services/router-config";
import { AuthLayout } from "./page/auth";
import Loader from "./components/loader/Loader";
import { OverlayComponent } from "./components/overlay/OverlayComponent";
import App from "@/App";
import HomePage from "@/page/home";
import SocialAuth from "./components/social-auth";
import StatisticPage from "./page/statistic";

const IntegrationPage = lazy(() => import("@/page/integration"));

// auth
const LoginPage = lazy(() => import("@/page/login"));
const RegisterPage = lazy(() => import("@/page/register"));
const ForgotPasswordPage = lazy(() => import("@/page/forgot-password"));
const CreatePasswordPage = lazy(() => import("@/page/create-password"));

// devices
const DevicePage = lazy(() => import("@/page/devices"));
const AddDeviceByCodePage = lazy(() => import("@/page/add-device-code"));
const AddDeviceByHardwareIdPage = lazy(
  () => import("@/page/add-device-serial"),
);
const DeviceDetailPage = lazy(() => import("@/page/device-detail"));

// qrs
const CreateQr = lazy(() => import("@/page/create-qr"));

// transactions
const TransactionHistoryListPage = lazy(
  () => import("@/page/transaction-history/transaction-history-list"),
);
const TransactionHistoryDetailPage = lazy(
  () => import("@/page/transaction-history/transaction-history-detail"),
);

export function appRouterConfig(
  rdModules: RdModulesManager<RdModule> | undefined,
) {
  return createBrowserRouter(
    [
      {
        path: NavlinkPath.root,
        element: (
          <RdAppExtends
            appProps={{
              modules: rdModules,
              configs: {
                loading: (
                  <OverlayComponent
                    classChildren={undefined}
                    classBackground="overlay-loading"
                    onTapBackGround={() => {}}
                    onTapChildren={() => {}}
                  >
                    <div
                      className="column"
                      style={{
                        width: "280px",
                        height: "280px",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "transparent",
                        overflow: "hidden",
                      }}
                    >
                      <Suspense>
                        <Loader height={240} width={240} />
                      </Suspense>
                    </div>
                  </OverlayComponent>
                ),
                closeModalOnTapOutside: true,
                classBackgroundModel: "overlay-loading",
              },
            }}
          >
            <App />
          </RdAppExtends>
        ),
        children: [
          {
            path: NavlinkPath.home,
            element: <HomePage />,
            children: [
              {
                index: true,
                element: <Navigate to={NavlinkPath.statistic} replace />,
              },
              {
                path: NavlinkPath.statistic,
                element: (
                  <Suspense>
                    <StatisticPage />
                  </Suspense>
                ),
              },
              {
                path: NavlinkPath.transactionHistory,
                element: (
                  <Suspense>
                    <Outlet />
                  </Suspense>
                ),
                children: [
                  {
                    index: true,
                    element: <TransactionHistoryListPage />,
                  },

                  {
                    path: NavlinkPath.transactionHistoryDetail,
                    element: <TransactionHistoryDetailPage />,
                  },
                ],
              },
              {
                path: NavlinkPath.devices,
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: (
                      <Suspense>
                        <DevicePage />
                      </Suspense>
                    ),
                  },
                  {
                    path: NavlinkPath.addDeviceCode,
                    element: (
                      <Suspense>
                        <AddDeviceByCodePage />
                      </Suspense>
                    ),
                  },
                  {
                    path: NavlinkPath.addDeviceSerial,
                    element: (
                      <Suspense>
                        <AddDeviceByHardwareIdPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: NavlinkPath.detailDevice,
                    element: <Outlet />,
                    children: [
                      {
                        index: true,
                        element: (
                          <Suspense>
                            <DeviceDetailPage />
                          </Suspense>
                        ),
                      },
                      {
                        path: NavlinkPath.createQr,
                        element: (
                          <Suspense>
                            <CreateQr />
                          </Suspense>
                        ),
                      },
                      {
                        path: NavlinkPath.detailQr,
                        element: (
                          <Suspense>
                            <CreateQr />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: NavlinkPath.integration,
                element: (
                  <Suspense>
                    <IntegrationPage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: NavlinkPath.auth,
            element: (
              <AuthLayout>
                <Outlet />
              </AuthLayout>
            ),
            children: [
              {
                index: true,
                element: (
                  <Navigate
                    replace
                    to={`${NavlinkPath.login}${window.location.search}`}
                  />
                ),
              },
              {
                path: NavlinkPath.login,
                element: (
                  <Suspense>
                    <LoginPage />
                  </Suspense>
                ),
              },
              {
                path: NavlinkPath.register,
                element: (
                  <Suspense>
                    <RegisterPage />
                  </Suspense>
                ),
              },
              {
                path: NavlinkPath.forgotPassword,
                element: (
                  <Suspense>
                    <ForgotPasswordPage />
                  </Suspense>
                ),
              },
              {
                path: NavlinkPath.createPassword,
                element: (
                  <Suspense>
                    <CreatePasswordPage />
                  </Suspense>
                ),
              },
              {
                path: NavlinkPath.socialAuthRedirect,
                element: (
                  <Suspense>
                    <SocialAuth />
                  </Suspense>
                ),
              },
            ],
          },
        ],
        errorElement: (
          <Suspense>
            <div style={{ width: "100vw", display: "block" }}>
              Không thấy gì
            </div>
          </Suspense>
        ),
      },
    ],
    { basename: Environment.basePath.toString() },
  );
}
