import { GetUserInfoResponse, UserResponse } from "../generated-sources";
import { UserInfoModel, UserModel } from "../models/UserModel";

export function mapUserInfoModel(dto?: GetUserInfoResponse): UserInfoModel {
  return {
    id: dto?.id ?? "",
    avatar: dto?.avatar ?? "",
    completedEKYC: dto?.completedEKYC ?? false,
    email: dto?.email ?? "",
    fullName: dto?.fullName ?? "",
    phone: dto?.phone ?? "",
    verifiedMail: dto?.verifiedMail ?? false,
    verifiedPhone: dto?.verifiedPhone ?? false,
  };
}

export function mapUserModel(dto?: UserResponse): UserModel {
  return {
    id: dto?.id ?? "",
    avatar: dto?.avatar ?? "",
    completedEKYC: dto?.completedEKYC ?? false,
    email: dto?.email ?? "",
    fullName: dto?.fullName ?? "",
    phone: dto?.phone ?? "",
    verifiedMail: dto?.verifiedMail ?? false,
    verifiedPhone: dto?.verifiedPhone ?? false,
    apiKey: dto?.apiKey ?? "",
    inactive: dto?.inactive ?? false,
    bankAccountNo: dto?.bankAccountNo ?? "",
  };
}
